import { MealPhotoQueueResponse } from 'api/generated/MNT';

import { useLocation } from 'react-router';
import { awaitingVerificationString } from './ListMealHistoryView';

import { Chip, ChipTypeMap, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverridableComponent } from '@mui/types';
import { useAuth } from 'context/appContext';
import { useMealQueueService } from 'services/MealQueueService';

const OverlapIconDiv = styled('div')({
  display: 'flex',
  transition: 'margin 100ms',

  '&:first-child': {
    marginLeft: '0 !important',
  },

  '.queue-summary-overlap-icons &:not(:empty)': {
    '&:hover': {
      zIndex: 1,
    },
    marginLeft: -10,
  },
  '.queue-summary-overlap-icons:hover &:not(:empty)': {
    marginLeft: 0,
  },
});

const IconChip: OverridableComponent<ChipTypeMap> = (props: any) => {
  return (
    <OverlapIconDiv>
      <Chip {...props} />
    </OverlapIconDiv>
  );
};

const SCORE_THRESHOLD = 0.7;

const classifyItem = (item: MealPhotoQueueResponse): [string | null, number | null] => {
  const actualItems = item.existing_items?.filter(item => item.food_name != awaitingVerificationString)?.length;
  if (actualItems) {
    return [actualItems > 1 ? 'complex' : 'simple', 1];
  }

  if (!item?.photo_cv_results?.length) {
    return [null, null];
  }

  const simpleComplexRes = (item.photo_cv_results || [])
    .filter(cv => cv.classifier === 'simple_complex');

  const classif = simpleComplexRes[0]?.prediction || null;
  if (!classif) {
    return [null, null];
  }

  const score = simpleComplexRes[0]?.score || 0;
  if (score < SCORE_THRESHOLD) {
    return [null, null];
  }

  return [classif, score];
};

const QueueLevelIcon = (props: {
  queue: MealPhotoQueueResponse,
}) => {
  const { queue: item } = props;
  const queueReviewLevel = item.queue_metadata?.review_effective_level;
  if (!queueReviewLevel) {
    return null;
  }
  const color = queueReviewLevel == 1 ? 'success' : queueReviewLevel == 2 ? 'warning' : 'error';

  return (
    <IconChip
      color={color}
      title={`Level ${queueReviewLevel}`}
      label={`L${queueReviewLevel}`}
      size="small"
      variant="light"
      sx={{ borderColor: color, borderStyle: 'solid', borderWidth: 1 }}
    />
  );
};

export const QueuePriorityIcon = (props: {
  queue: MealPhotoQueueResponse,
  size?: 'small' | 'medium' | 'large',
}) => {
  return <PatientPriorityIcon {...(props.queue?.queue_priority ?? props.queue ?? {})} size={props.size} />;
};

export const PatientPriorityIcon = (props: {
  is_priority?: boolean,
  is_ultra_priority?: boolean,
  is_priority_patient?: boolean,
  is_ultra_priority_patient?: boolean,
  reason?: string | null,
  size?: 'small' | 'medium' | 'large',
}) => {
  const theme = useTheme();
  function prioritySwitch<T>(ultra: T, priority: T): T | null {
    return props.is_ultra_priority || props.is_ultra_priority_patient
      ? ultra
      : props.is_priority || props.is_priority_patient
      ? priority
      : null;
  }
  const title = props.reason ?? prioritySwitch('ultra priority', 'priority');
  if (!title) {
    return null;
  }

  return (
    <IconChip
      title={title}
      label="P"
      color="error"
      size={props.size ?? 'small'}
      variant={prioritySwitch('filled', 'light')!}
      sx={{
        borderColor: theme.palette.error.main + '!important',
        borderStyle: 'solid',
        borderWidth: 1,
      }}
    />
  );
};

const QueueOwnerIcon = (props: {
  queue: MealPhotoQueueResponse,
}) => {
  const { queue } = props;
  const mpq = useMealQueueService();
  const location = useLocation();
  const { authInfo } = useAuth();
  const theme = useTheme();

  if (!queue.first_reviewer_user_id) {
    return null;
  }

  const ownedQueues = mpq.ownedQueues;
  const [curPath, curQueueId] = location.pathname.split('/').slice(-2);
  const userColor = curPath == 'queue-item' && ownedQueues.find(q => q.id == +curQueueId)
    ? 'primary'
    : 'error';

  const label = queue.first_reviewer_user_id == authInfo?.reviewer_id
    ? 'you'
    : queue.first_reviewer_user_id.toString();
  const color = queue.first_reviewer_user_id == authInfo?.reviewer_id ? userColor : 'secondary';

  return (
    <IconChip
      title={'owner: ' + label}
      label={label}
      color={color}
      size="small"
      variant={label == 'you' ? 'filled' : 'light'}
      sx={{
        borderColor: theme.palette[color].main + '!important',
        borderStyle: 'solid',
        borderWidth: 1,
      }}
    />
  );
};

const QueueIsAutoLoggedIcon = (props: {
  queue: MealPhotoQueueResponse,
}) => {
  const { queue } = props;
  if (!queue.is_processed || queue.first_reviewer_user_id) {
    return null;
  }

  return (
    <IconChip
      title="auto logged"
      label="A"
      color="info"
      size="small"
      variant="light"
      sx={{ borderColor: 'info.main', borderStyle: 'solid', borderWidth: 1 }}
    />
  );
};

const CustomItemIcon = () => {
  return (
    <IconChip
      title="Custom item"
      label="C"
      color="warning"
      size="small"
      variant="light"
      sx={{ borderColor: 'warning.main', borderStyle: 'solid', borderWidth: 1 }}
    />
  );
};

export const QueueSummaryIcons = (props: {
  queue: MealPhotoQueueResponse,
  hideClassif?: boolean,
  hideOwner?: boolean,
  showCustomIcon?: boolean,
}) => {
  const { queue } = props;
  if (!queue) {
    return null;
  }

  return (
    <div
      style={{ display: 'inline-flex', gap: 2 }}
      className="queue-summary-overlap-icons"
    >
      {!props.hideClassif && <QueueLevelIcon queue={queue} />}
      <QueueIsAutoLoggedIcon queue={queue} />
      <QueuePriorityIcon queue={queue} />
      {props.showCustomIcon && <CustomItemIcon />}
      {!props.hideOwner && <QueueOwnerIcon queue={queue} />}
    </div>
  );
};
