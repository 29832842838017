import React, { MouseEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import { DeleteFilled, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  InputAdornment,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
  styled,
  Switch,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import mixpanel from 'mixpanel-browser';

import { Info, Report } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  FoodComponentResponse,
  type FoodResponse,
  FoodServingUnit,
  type MealItemCustomAddonResponse,
  MealItemResponse,
  MealResponse,
  NutrientEstimatesRequest,
  type PreparationMethodEnum,
} from 'api/generated/MNT';
import { type MealItem, type MealQueueItem } from 'apiClients/mpq';
import { getFoodByQuery, SearchItem } from 'apiClients/search';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';
import { FoodDrawer, useFoodDrawerState } from 'pages/QueueItem/meal-builder/FoodDrawer';
import {
  RelevantNutrients,
  useQueueItemEditor,
  useRelevantNutrients,
} from 'pages/QueueItem/services/QueueItemEditorService';
import { type DraftItem, formatDraftItemFoodName, MealItemFoodMatchDetailsSearch } from 'types/DraftItem';
import {
  mealHistoryItemToMealItem,
  mealItemGetNutrientOverrides,
  mealItemGetNutrientValue,
  mealItemGetUpdatedNutrientOverrides,
  nutrientGetDef,
  recentItemToMealItem,
} from 'utils/mealItems';

import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { mdiAlphaCBoxOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Add,
  ArrowDropDown,
  ChangeCircle,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Dangerous,
  Delete,
  Done,
  Edit,
  FileCopy,
  Help,
  SubdirectoryArrowRight,
  Warning,
} from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { Draggable, Droppable } from 'components/DragAndDrop';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import { SpellCheck } from 'components/spell-check';
import { useFeatures } from 'context/FeatureContext';
import { NUTRIENT_100G, nutrientScale } from 'food-editor/components/food-editor';
import { FoodComponent } from 'food-editor/types';
import { formatNumber } from 'food-editor/utils/utils';
import _ from 'lodash';
import { useFoodDetails } from 'services/FoodDetailsService';
import { getCustomItemSourceLink } from 'services/FoodEngineService';
import mergeRefs from 'utils/mergeRefs';
import { round } from 'utils/numerical';
import { DEFAULT_SERVING_SIZE_OPTIONS, defaultSearchItem, MEAL_PREPARATION_METHODS } from '../../../constants';
import { formatPercent } from '../services/QueueItemEditorService';
import { FoodSearchDropdownInput } from './FoodSearchComponents';
import { ImLblMatchDetailsIcon, MealPhotoQueueImageAutomaticLabels } from './MealPhotoQueueImageAutomaticLabels';
import { MealPushQuestions } from './MealPushQuestions';
import { checkPctEaten, getItemWeightWithoutAddons } from './MealSummary';

export const AddonScaleTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export const floatOrNull = (value: number | string | null | undefined) => {
  if (value === null || value === undefined) {
    return null;
  }

  const parsed = typeof value === 'number'
    ? value
    : parseFloat(value);
  return isNaN(parsed) ? null : parsed;
};

type SizedItem = {
  percent_eaten?: number,
  servings?: number,
  serving_unit_amount: number,
  serving_unit_label: string,
};

export function formatMealItemSizing(
  item: SizedItem,
  parent?: MealItem | null,
  whiteSpace: 'normal' | 'nowrap' = 'nowrap',
) {
  const pctEaten = item.percent_eaten ?? parent?.percent_eaten ?? 1;
  const formattedPctEaten = formatPercent(pctEaten);

  const displayPctEaten = [
    formattedPctEaten != '100%' && String.fromCharCode(0x00D7) + formattedPctEaten,
  ].filter(Boolean);

  return (
    <span>
      <span
        style={{ whiteSpace: 'nowrap' }}
      >
        {item.servings}&times;{item.serving_unit_amount}g{!!displayPctEaten.length
          && displayPctEaten}&nbsp;
      </span>
      <span
        style={{ whiteSpace: whiteSpace }}
      >
        ({item.serving_unit_label})
      </span>
    </span>
  );
}

const getMealItemAdultServings = (draftItem: DraftItem) => {
  const foodAdultServingSizeG = draftItem.searchItem?.adult_serving_size_g;
  if (!foodAdultServingSizeG) {
    return;
  }
  const mealItemServSizeG = draftItem.item.serving_unit_amount * draftItem.item.servings
    * (draftItem.item.percent_eaten ?? 1);
  const adultServings = mealItemServSizeG / foodAdultServingSizeG;

  return '= ' + formatNumber(adultServings, 1) + ' adult servings';
};

export const useDragAndDropMealItems = () => {
  const { addAddon, addAddonAsItem, addItemAsAddon, removeAddon, removeDraftItem } = useQueueItemEditor();
  const [disabledDroppables, setDisabledDroppables] = useState(['mealItem', 'addon']);

  const handleDragStart = (e: DragStartEvent) => {
    const { active } = e;
    const dragData = active.data.current as {
      item: DraftItem | MealItemCustomAddonResponse,
      mealItem?: DraftItem,
      mealItemIndex?: number,
    };
    if (dragData.mealItem) {
      // Dragging an addon, disable parent meal item droppables
      setDisabledDroppables([`addonOf:${dragData.mealItemIndex}`]);
    } else {
      // Dragging a meal item, disable all meal item droppables, also disable own addon droppable
      setDisabledDroppables(['mealItem', `${active.id}`]);
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (over) {
      const dragData = active.data.current as {
        item: DraftItem | MealItemCustomAddonResponse,
        mealItem?: DraftItem,
        mealItemIndex?: number,
      };
      const dropData = over.data.current as {
        mealItem?: DraftItem,
        mealItemIndex?: number,
        dropBefore?: DraftItem,
      };

      if (dropData.mealItem && dragData.mealItem && dragData.mealItemIndex !== dropData.mealItemIndex) {
        const addon = dragData.item as MealItemCustomAddonResponse;
        mixpanel.track('Item drag and drop', {
          'Action': 'addon moved',
          'Food name': addon.food_name,
          'Servings': addon.servings,
          'Serving unit': addon.serving_unit_label,
          'Serving unit amount': addon.serving_unit_amount,
          'Source meal item': formatDraftItemFoodName(dragData.mealItem),
          'Target meal item': formatDraftItemFoodName(dropData.mealItem),
        });
        addAddon({
          targetItem: dropData.mealItem,
          addonItem: addon,
        });
        removeAddon({
          sourceItem: dragData.mealItem,
          addonItem: addon,
          silent: true,
        });
      } else if (dropData.mealItem && !dragData.mealItem) {
        const mealItem = dragData.item as DraftItem;
        mixpanel.track('Item drag and drop', {
          'Action': 'addon added',
          'Food name': mealItem.item.food_name,
          'Servings': mealItem.item.servings,
          'Serving unit': mealItem.item.serving_unit_label,
          'Serving unit amount': mealItem.item.serving_unit_amount,
          'Meal item name': formatDraftItemFoodName(dropData.mealItem),
        });
        addItemAsAddon({
          targetItem: dropData.mealItem,
          addonMealItem: mealItem.item,
          focusTargetItem: false,
          keepMealItemSize: true,
        });
        removeDraftItem(dragData.item as DraftItem, { silent: true });
      } else if (!dropData.mealItem && dragData.mealItem) {
        const addon = dragData.item as MealItemCustomAddonResponse;
        mixpanel.track('Item drag and drop', {
          'Action': 'addon to item',
          'Food name': addon.food_name,
          'Servings': addon.servings,
          'Serving unit': addon.serving_unit_label,
          'Serving unit amount': addon.serving_unit_amount,
          'Source meal item': formatDraftItemFoodName(dragData.mealItem),
        });
        addAddonAsItem({ addonItem: addon, insertBefore: dropData.dropBefore });
        removeAddon({
          sourceItem: dragData.mealItem,
          addonItem: addon,
          silent: true,
        });
      }
    }
    setDisabledDroppables(['mealItem', 'addon']);
  };

  return {
    disabledDroppables,
    handleDragStart,
    handleDragEnd,
  };
};

export const QueueMealItems = (props: {
  onEditCallback?: () => void,
  onDeleteCallback?: (item: DraftItem) => void,
}) => {
  const [showCopiedMessagePopup, setShowCopiedMessagePopup] = useState(false);
  const editor = useQueueItemEditor();

  const reverseDraftItems = [...editor.draftItems].reverse();
  const firstEditIndex = reverseDraftItems.findIndex((item) => item == editor.selectedItem);
  const topSummaryItems = firstEditIndex > -1 ? reverseDraftItems.slice(0, firstEditIndex) : [];
  const bottomSummaryItems = firstEditIndex > -1 ? reverseDraftItems.slice(firstEditIndex + 1) : reverseDraftItems;

  const handleEditDraftItem = (item: DraftItem) => {
    editor.selectedItemSet(item);
    if (props.onEditCallback) {
      props.onEditCallback();
    }
  };

  const foodDrawer = useFoodDrawerState();
  const handleSwapDraftItem = (item: DraftItem) => {
    mixpanel.track('Meal item swap clicked', {
      'Food name': item.item.food_name || item.searchItem?.name || item.queryText || '',
    });
    foodDrawer.setIsSwap(true);
    handleEditDraftItem(item);
  };

  const handleRemoveDraftItem = (item: DraftItem) => {
    editor.removeDraftItem(item);
    if (props.onDeleteCallback) {
      props.onDeleteCallback(item);
    }
  };

  const handleCopy = (item: DraftItem) => {
    setShowCopiedMessagePopup(true);
    setTimeout(() => {
      setShowCopiedMessagePopup(false);
    }, 800);
    editor.setCopiedItem({ ...item, pushQuestionUpdate: undefined });
  };

  const handleDuplicate = (item: DraftItem) => {
    editor.duplicateDraftItem(item);

    if (props.onEditCallback) {
      props.onEditCallback();
    }
  };

  const handlePasteAsAddon = (item: DraftItem) => {
    if (!editor.copiedItem) {
      return;
    }
    const addons = item.item.custom_addons || [];
    editor.updateDraftItemMealItem(item, {
      custom_addons: [
        ...addons,
        {
          ...editor.copiedItem.item,
          food_image_url: null,
          food_name_translations: null,
          serving_unit_label_translations: null,
        },
      ],
    });
  };

  const handlePaste = () => {
    if (!editor.copiedItem) {
      return;
    }

    if (editor.selectedItem) {
      mixpanel.track('Meal item pasted', {
        Target: 'Addon',
      });
      const addons = editor.selectedItem.item.custom_addons || [];
      editor.updateDraftItemMealItem(editor.selectedItem, {
        custom_addons: [
          ...addons,
          {
            ...editor.copiedItem.item,
            food_image_url: null,
            food_name_translations: null,
            serving_unit_label_translations: null,
          },
        ],
      });
      return;
    }
    mixpanel.track('Meal item pasted', {
      Target: 'Meal',
    });
    editor.addDraftItem({ ...editor.copiedItem, id: null });
    if (props.onEditCallback) {
      props.onEditCallback();
    }
  };

  const numNonBeverage = [...editor.draftItems].filter(i =>
    !i.item.food_ontology
    || (!!i.item.food_ontology && i.item.food_ontology[0] != 'beverage')
  ).length;

  const { disabledDroppables, handleDragStart, handleDragEnd } = useDragAndDropMealItems();

  return (
    <MainCard content={false} sx={{ overflow: 'unset' }}>
      <FoodDrawer />
      <MealPhotoQueueImageAutomaticLabels queueItem={editor.queueItem} />
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        autoScroll={{ layoutShiftCompensation: false, enabled: false }}
      >
        {editor.selectedItem
          ? (
            <>
              {topSummaryItems.length > 0 && (
                <>
                  <CardContent>
                    <Stack spacing={2}>
                      {topSummaryItems.map((item, index) => (
                        <DraftItemSummary
                          key={item.id || index}
                          onEdit={() => handleEditDraftItem(item)}
                          onSwap={() => handleSwapDraftItem(item)}
                          onRemove={() => handleRemoveDraftItem(item)}
                          draftItem={item}
                          copiedItem={editor.copiedItem}
                          onCopy={() => handleCopy(item)}
                          onPaste={() => handlePasteAsAddon(item)}
                          onDuplicate={() => handleDuplicate(item)}
                          queueItem={editor.queueItem}
                          dragAndDropIndex={index + 1}
                          disabledDroppables={['mealItem', 'addon']}
                          dragIsDisabled={true}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                  <Divider />
                </>
              )}
              <span
                onCopy={() => {
                  const selection = document.activeElement;
                  if (!editor.selectedItem || selection?.tagName == 'INPUT') {
                    return;
                  }
                  handleCopy(editor.selectedItem);
                }}
                onPaste={() => {
                  const selection = document.activeElement;
                  if (!editor.selectedItem || !editor.copiedItem || selection?.tagName == 'INPUT') {
                    return;
                  }
                  handlePaste();
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <MealItemEditor
                      queueItem={editor.queueItem}
                      draftItem={editor.selectedItem}
                      preventWindowScroll={topSummaryItems.length > 0}
                    />
                  </Stack>
                </CardContent>
                <Divider />
                <MealItemEditorActions
                  onCopy={handleCopy}
                  onPaste={handlePaste}
                  showCopiedMessagePopup={showCopiedMessagePopup}
                />
              </span>
              {bottomSummaryItems.length > 0 && (
                <>
                  <Divider />
                  <CardContent>
                    <Stack spacing={2}>
                      {bottomSummaryItems.map((item, index) => (
                        <DraftItemSummary
                          key={item.id || index}
                          draftItem={item}
                          copiedItem={editor.copiedItem}
                          queueItem={editor.queueItem}
                          onEdit={() => handleEditDraftItem(item)}
                          onSwap={() => handleSwapDraftItem(item)}
                          onRemove={() => handleRemoveDraftItem(item)}
                          onCopy={() => handleCopy(item)}
                          onPaste={() => handlePasteAsAddon(item)}
                          onDuplicate={() => handleDuplicate(item)}
                          dragAndDropIndex={topSummaryItems.length + index + 1}
                          disabledDroppables={['mealItem', 'addon']}
                          dragIsDisabled={true}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                </>
              )}
            </>
          )
          : (
            <CardContent>
              <Stack
                spacing={2}
                onPaste={() => {
                  if (!editor.copiedItem) {
                    return;
                  }
                  handlePaste();
                }}
              >
                {[...editor.draftItems].reverse().filter(i =>
                  !i.item.food_ontology
                  || (!!i.item.food_ontology && i.item.food_ontology[0] != 'beverage')
                ).map((item, index) => (
                  <DraftItemSummary
                    key={item.id || index}
                    draftItem={item}
                    copiedItem={editor.copiedItem}
                    queueItem={editor.queueItem}
                    onEdit={() => handleEditDraftItem(item)}
                    onSwap={() => handleSwapDraftItem(item)}
                    onRemove={() => handleRemoveDraftItem(item)}
                    onCopy={() => handleCopy(item)}
                    onPaste={() => handlePasteAsAddon(item)}
                    onDuplicate={() => handleDuplicate(item)}
                    dragAndDropIndex={index + 1}
                    disabledDroppables={disabledDroppables}
                  />
                ))}
                {[...editor.draftItems].reverse().filter(i =>
                  !!i.item.food_ontology && i.item.food_ontology[0] == 'beverage'
                ).map((item, index) => (
                  <DraftItemSummary
                    key={item.id || index}
                    draftItem={item}
                    copiedItem={editor.copiedItem}
                    queueItem={editor.queueItem}
                    onEdit={() => handleEditDraftItem(item)}
                    onSwap={() => handleSwapDraftItem(item)}
                    onRemove={() => handleRemoveDraftItem(item)}
                    onCopy={() => handleCopy(item)}
                    onPaste={() => handlePasteAsAddon(item)}
                    onDuplicate={() => handleDuplicate(item)}
                    dragAndDropIndex={numNonBeverage + index + 1}
                    disabledDroppables={disabledDroppables}
                  />
                ))}
              </Stack>
            </CardContent>
          )}
      </DndContext>
    </MainCard>
  );
};

export const MealBuilderActions = ({
  onCreateNew,
  onRequestAssistance,
  onEscalate,
  onPaste,
  queueItem,
  copiedItem,
  mealDeleted,
}: {
  onCreateNew: () => void,
  onRequestAssistance: () => void,
  onEscalate: () => void,
  onPaste: () => void,
  queueItem: MealQueueItem,
  copiedItem: DraftItem | null,
  mealDeleted?: boolean,
}) => {
  const { authInfo } = useAuth();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClose = (event: Event) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  };

  return (
    <Stack direction="row" spacing={1}>
      <ButtonGroup disabled={mealDeleted} variant="contained" ref={anchorRef} disableElevation>
        <Button onClick={onCreateNew} variant="contained" startIcon={<Add />}>Add a meal item</Button>
        {!!copiedItem && (
          <Button
            size="extraSmall"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <ArrowDropDown />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        placement="bottom-end"
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={() => {
                      setMenuOpen(false);
                      onPaste();
                    }}
                  >
                    Paste: {formatDraftItemFoodName(copiedItem)}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Button disabled={mealDeleted} onClick={onRequestAssistance} variant="outlined" startIcon={<Help />}>
        Request assistance
      </Button>
      {authInfo?.reviewer_id === queueItem.first_reviewer_user_id && (
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={onEscalate}
          startIcon={<Report />}
        >
          Escalate
        </Button>
      )}
    </Stack>
  );
};

export type DraftItemWarning = {
  message: string,
  isFatal: boolean,
};

export const draftItemGetWarnings = (args: {
  draftItem: DraftItem,
  relevantNutrients: RelevantNutrients,
}): DraftItemWarning | null => {
  const { draftItem, relevantNutrients } = args;
  if (!draftItem) {
    return {
      message: 'No draft item',
      isFatal: true,
    };
  }

  const { item, searchItem } = draftItem;

  if (!item.food_name) {
    return {
      message: 'Food has not been matched',
      isFatal: true,
    };
  }

  const addonWarnings = item.custom_addons?.map(addon => {
    if (!addon.food_name) {
      return {
        message: 'Addon has no food name',
        isFatal: true,
      };
    }

    if (!addon.serving_unit_label || !addon.servings) {
      return {
        message: `Addon '${addon.food_name}' is missing a serving unit or quantity`,
        isFatal: true,
      };
    }
    return null;
  }).filter(x => !!x);

  if (addonWarnings?.length) {
    return addonWarnings[0];
  }

  if (!item.serving_unit_label || !item.serving_unit_amount) {
    return {
      message: 'Serving unit or quantity is missing',
      isFatal: true,
    };
  }

  if (isNaN(item.servings) || !item.servings) {
    return {
      message: 'Serving size is not valid',
      isFatal: true,
    };
  }

  if (item.servings <= 0.1) {
    return {
      message: 'Serving size is too small',
      isFatal: true,
    };
  }

  const missingNutrients = item.custom_item
    ? relevantNutrients.map(nutrient => {
      if (!nutrient.patientVisible) {
        return null;
      }
      const value = mealItemGetNutrientValue({
        item: item,
        foodNutrients: null,
        nutrient: nutrient.nutrient,
        // We need to provide an initial nutrient state for patients
        // independent of if the patient entered an override
        // in case the patient resets their nutrients
        ignoreSources: ['patient'],
      }).value;
      if (value != null && !isNaN(+value) && +value >= 0) {
        return null;
      }
      return nutrient.nutrient;
    }).filter(Boolean) as string[]
    : [];
  if (missingNutrients.length) {
    return {
      message: `Custom missing nutrients: ${missingNutrients.join(', ')}`,
      isFatal: false,
    };
  }

  if (!searchItem || item.custom_item) {
    return null;
  }

  const servingUnits = searchItem?.serving_units
    ? searchItem?.serving_units.filter(su => su.label == item.serving_unit_label)
    : [];

  if (servingUnits.length == 0) {
    return {
      message: `Serving label '${item.serving_unit_label}' does not exist in the database`,
      isFatal: true,
    };
  }

  const servingUnitsCorrectAmount = servingUnits.filter(su => su.amount == item.serving_unit_amount);
  if (servingUnitsCorrectAmount.length == 0) {
    return {
      message: `The serving unit amount for ${item.serving_unit_label} has changed`,
      isFatal: true,
    };
  }

  return null;
};

export const MealItemEditor = ({
  queueItem,
  draftItem,
  preventWindowScroll: preventWindowSroll,
}: {
  queueItem: MealQueueItem,
  draftItem: DraftItem | null,
  preventWindowScroll?: boolean,
}) => {
  const { updateDraftItemMealItem, updateDraftItem } = useQueueItemEditor();
  const { servingUnitOptions, servingUnit, quantityOptions, quantity, servingAmount } = useFoodItemServings(draftItem);
  const [prevQuantity, setPrevQuantity] = useState<number | null>(null);
  const [quantityInput, setQuantityInput] = useState<string>('');
  const [showAllMacros, setShowAllMacros] = useState(false);
  const { authInfo } = useAuth();
  const isCustom = !!draftItem?.item?.custom_item;
  const relevantNutrients = useRelevantNutrients({
    context: 'item',
    showAllMacros: showAllMacros,
    includeNutrients: (
      Object.entries(
        draftItem?.item && mealItemGetNutrientOverrides({
              item: draftItem.item,
              // We only want reviewer-entered nutrients to be additionally shown
              // if Show Macros is not toggled on
              sourceName: 'reviewer',
            })
          || {},
      )
        .filter(([_, value]) => value != null)
        .map(([key, _]) => key)
    ),
  });
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const foodDrawer = useFoodDrawerState();
  const flags = useFeatures();

  useEffect(() => {
    // I'm very sorry, this is a bit gross. The goal here is that, when an item is edited,
    // the editor should scroll into view. Using `elem.scrollIntoView()` is not ideal because
    // it doesn't consider the sticky header. So, we scroll to the top of the page instead.
    if (!preventWindowSroll) {
      window.scrollTo(0, 0);
    }
  }, []);

  const getSearchItem = async (draftItem: DraftItem) => {
    const food = await foodApi.appApiFoodFoodSearchGetFoodQuery({
      food_name: draftItem.item.food_name,
    });
    updateDraftItem(draftItem, { searchItem: food.data });
  };

  useEffect(() => {
    if (queueItem.is_processed && draftItem?.item.food_name && !draftItem.searchItem) {
      getSearchItem(draftItem);
    }
  }, []);

  useEffect(() => {
    if (!draftItem) {
      return;
    }
    if (!draftItem?.item?.food_name || foodDrawer.isSwap) {
      foodDrawer.show({
        initialSearchText: draftItem.queryText || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftItem]);

  const handleFoodNameFocus = () => {
    foodDrawer.show({
      initialSearchText: (
        draftItem?.queryText
        || draftItem?.searchItem?.name
        || draftItem?.item.food_name || ''
      ),
    });
  };

  useEffect(() => {
    setQuantityInput(quantity?.toString() || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servingUnit]);
  // Note: if things break, can try using this dep array instead:
  // }, [JSON.stringify(quantityOptions)] );

  const handlePreparationMethodChange = (
    event: React.SyntheticEvent<Element, Event> | null,
    value: string | undefined,
  ) => {
    if (!draftItem || value === null || !(value === undefined || (value in MEAL_PREPARATION_METHODS))) {
      return;
    }
    updateDraftItemMealItem(draftItem, {
      preparation_method: value as PreparationMethodEnum,
    });
  };

  const handleCustomQuantityChange = (value: string | null) => {
    if (!draftItem || !draftItem.item.custom_item) {
      return;
    }
    setQuantityInput(value || '');

    updateDraftItemMealItem(draftItem, {
      servings: parseFloat(value || '0'),
    });
  };

  const handleServingUnitChange = (value: FoodServingUnit) => {
    if (!draftItem || (!draftItem.searchItem && !draftItem.item.custom_item)) {
      return;
    }

    const oldItem = draftItem.item;
    const newItem: MealItem = {
      ...oldItem,
      serving_unit_label: value.label,
      serving_unit_amount: value.amount,
      // If the item didn't previously have a serving unit - indicated by
      // serving_unit_amount=0 - then it's likely safe to override the servings
      // with the default label qty. Otherwise, it could be surprising (the
      // *ideal* fix here is to show an option suggesting the new size... but
      // this is an okay fix for now)
      servings: oldItem.serving_unit_amount ? oldItem.servings : value.default_label_qty ?? 1,
    };
    handleServingAmountChange(draftItem.item.servings.toString(), value.amount);
    updateDraftItemMealItem(draftItem, newItem);
    // This ref is currently being commented out below due to an underlying issue
    // with mergeRefs/related interfering with autocomplete dropdown width.
    setTimeout(() => {
      quantityInputRef.current?.focus();
    }, 1);
  };

  const handleServingAmountChange = (value: string | null, servingUnitAmount: number) => {
    const scaleFactor = !value || !(+value)
      ? null
      : +value * servingUnitAmount
        / (prevQuantity || servingUnitAmount * 1);
    if (draftItem?.item.custom_addons?.length) {
      if (!scaleFactor) {
        return;
      }
      const newScaleFactor = addonScaleFactor ? addonScaleFactor * scaleFactor : scaleFactor;
      setAddonScaleFactor(Math.round((newScaleFactor + Number.EPSILON) * 100) / 100);
    }
    setPrevQuantity(old =>
      !value || !(+value)
        ? (old ?? (draftItem?.item.servings ? draftItem?.item.servings * servingUnitAmount : null))
        : +value * servingUnitAmount
    );
  };

  const handleQuantityChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    handleServingAmountChange(value, draftItem?.item.serving_unit_amount || 1);
    if (!draftItem || !draftItem.searchItem) {
      if (draftItem?.item.custom_item) {
        handleCustomQuantityChange(value);
      }
      return;
    }
    setQuantityInput(value || '');
    updateDraftItemMealItem(draftItem, {
      serving_type_label: null,
      serving_type_multiplier: null,
      servings: parseFloat(value || '0'),
    });
  };

  const itemWeight = !!draftItem
    && round(getItemWeightWithoutAddons(draftItem.item, draftItem.item.percent_eaten), 2);
  const adultServingEquivalent = useMemo(() => {
    if (!draftItem?.item.food_name) {
      return null;
    }
    return <>{formatMealItemSizing(draftItem.item)} {draftItem.searchItem && getMealItemAdultServings(draftItem)}</>;
  }, [draftItem]);

  const handleShowMacroSwitch = () => {
    setShowAllMacros(!showAllMacros);
  };

  const handleNutrientChange = (newNutrients: Partial<NutrientEstimatesRequest>) => {
    if (!draftItem) {
      return;
    }

    updateDraftItemMealItem(draftItem, {
      nutrient_overrides: mealItemGetUpdatedNutrientOverrides({
        nutrientOverrides: draftItem.item.nutrient_overrides,
        sourceName: 'reviewer',
        sourceId: '' + authInfo?.reviewer_id,
        newNutrients: Object.fromEntries(
          Object.entries(newNutrients).map(([key, val]) => [
            key,
            nutrientScale({
              value: val,
              // nutrients are entered on the meal item level, before percent eaten
              from: draftItem.item.servings * draftItem.item.serving_unit_amount,
              to: NUTRIENT_100G,
            }),
          ]),
        ),
      }),
    });
  };

  const selectedServingUnitKey = `${servingUnit}:${servingAmount}`;
  const servingUnitKey = (s: FoodServingUnit) => `${s.label}:${s.amount}`;

  const validServingUnits = (servingUnitOptions as FoodServingUnit[]).reduce(
    (dict: Record<string, boolean>, serv: FoodServingUnit) => {
      dict[servingUnitKey(serv)] = draftItem?.item.custom_item
        ? true
        : draftItem?.searchItem?.serving_units
        ? draftItem.searchItem.serving_units.some(su => su.label === serv.label && su.amount == serv.amount)
        : false;
      return dict;
    },
    {},
  );

  const [addonScaleFactor, setAddonScaleFactor] = useState<number | ''>(1);

  const handleAddonScaleFieldChange = (evt: any) => {
    if (evt.target.validity.patternMismatch) {
      return;
    }
    if (!evt.target.value) {
      setAddonScaleFactor('');
      return;
    }

    setAddonScaleFactor(evt.target.value);
  };

  const handleScaleAddons = () => {
    if (draftItem && addonScaleFactor) {
      const addons = draftItem.item.custom_addons || [];
      updateDraftItemMealItem(draftItem, {
        custom_addons: addons.map(addon => {
          return { ...addon, servings: Math.round(((addon.servings * addonScaleFactor) + Number.EPSILON) * 100) / 100 };
        }),
      });
    }
    setAddonScaleFactor('');
  };

  return (
    <Stack spacing={1}>
      {!!draftItem?.item.note && draftItem.item.note != queueItem.patient_note && (
        <Typography variant="h5">
          Meal item note: {draftItem?.item.note}
        </Typography>
      )}

      {flags.interactive_queue_table && !!draftItem && (
        <FoodPatientHistorySuggestionsList draftItem={draftItem} style={{ paddingBottom: '1rem' }} />
      )}

      <Stack direction="row" spacing={1}>
        <Grid item xs={5}>
          <Stack spacing={0.5}>
            <Typography>
              Meal Item
              {isCustom && (
                <Typography display="inline" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                  &nbsp;({draftItem?.searchItem?.name || defaultSearchItem.name})
                </Typography>
              )} {draftItem?.imLblMatchResult
                && <ImLblMatchDetailsIcon match={draftItem.imLblMatchResult} />}
            </Typography>
            <OutlinedInput
              fullWidth
              placeholder="Choose a food"
              value={draftItem?.item?.food_name || ''}
              onClick={handleFoodNameFocus}
              onKeyDown={handleFoodNameFocus}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    edge="end"
                    onClick={handleFoodNameFocus}
                  >
                    <EditOutlined />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
        {!!draftItem && (
          <Grid item>
            <Stack spacing={0.5}>
              <Typography>&nbsp;</Typography>
              <MealPushQuestions size="medium" queueItem={queueItem} draftItem={draftItem} />
            </Stack>
          </Grid>
        )}
        <Grid item xs={3}>
          <Stack spacing={0.5}>
            <Typography color={validServingUnits[selectedServingUnitKey] ? 'black' : 'error'}>
              Serving Unit
            </Typography>
            <Select
              disabled={servingUnitOptions.length === 0}
              value={selectedServingUnitKey}
              error={!validServingUnits[selectedServingUnitKey]}
              onChange={event => {
                const v = servingUnitOptions.find(v => servingUnitKey(v) == event.target.value);
                if (!v) {
                  return;
                }
                handleServingUnitChange(v);
              }}
            >
              {servingUnitOptions.map((v, i) => (
                <MenuItem
                  key={i}
                  disabled={!!v.label.match(/^--------/)}
                  value={servingUnitKey(v)}
                  style={validServingUnits[servingUnitKey(v)]
                    ? { textDecoration: 'none' }
                    : { textDecoration: 'line-through', color: 'red' }}
                >
                  {v.label + ' (' + v.amount + 'g)'}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={0.5}>
            <Typography>Quantity</Typography>
            <Autocomplete
              freeSolo
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    onBlur={event => handleQuantityChange(event, event.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      // Passing in multiple refs currently interferes with the autocomplete
                      // dropdown width, comment out the autofocus ref for now until it can be
                      // more fully investigated.
                      inputRef: mergeRefs(params.InputProps.ref /*, quantityInputRef*/),
                    }}
                  />
                );
              }}
              options={quantityOptions}
              inputValue={quantityInput}
              onInputChange={(event, value) => {
                setQuantityInput(value);
              }}
              filterOptions={x => x}
              onChange={handleQuantityChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={1} alignContent="center" paddingTop={3}>
          {adultServingEquivalent && (
            <Tooltip
              title={adultServingEquivalent}
            >
              <Typography color="grey">({itemWeight}g)</Typography>
            </Tooltip>
          )}
        </Grid>
      </Stack>

      <Stack>
        {draftItem?.searchItem?.adult_serving_size_g && (
          <Typography color="grey">
            Adult Serving Size: {formatNumber(draftItem?.searchItem.adult_serving_size_g, 1)}g
          </Typography>
        )}

        {draftItem?.searchItem?.custom_tip && (
          <Typography color="error">Tip: {draftItem?.searchItem.custom_tip}</Typography>
        )}
      </Stack>

      {draftItem?.item?.food_name && isCustom && (
        <SpellCheck
          term={draftItem.item.food_name}
          updateSpelling={(correctSpelling) => {
            updateDraftItemMealItem(draftItem, { food_name: correctSpelling });
          }}
        />
      )}

      {draftItem?.item && (
        <Stack direction="row" spacing={1}>
          <FoodNameAliasInput
            item={draftItem.item}
            onValueChange={(food_name_alias) => {
              updateDraftItemMealItem(draftItem, { food_name_alias });
            }}
          />
          <PercentEatenSlider
            item={draftItem.item}
            onValueChange={(percent_eaten) => {
              updateDraftItemMealItem(draftItem, { percent_eaten });
            }}
          />
        </Stack>
      )}

      {(draftItem?.item?.food_name_alias && draftItem?.item?.food_name_alias != '') && (
        <SpellCheck
          term={draftItem.item.food_name_alias}
          updateSpelling={(correctSpelling) => {
            updateDraftItemMealItem(draftItem, { food_name_alias: correctSpelling });
          }}
        />
      )}

      <Stack direction="row">
        <Box>
          <RadioGroup row onChange={handlePreparationMethodChange} value={draftItem?.item.preparation_method}>
            {Object.entries(MEAL_PREPARATION_METHODS).map(([key, value]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                checked={key == draftItem?.item.preparation_method}
                onClick={(event) => {
                  console.log('click', key, draftItem?.item.preparation_method);
                  if (key == draftItem?.item.preparation_method) {
                    handlePreparationMethodChange(null, undefined);
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                label={
                  <span style={{ fontWeight: (key == queueItem.preparation_method) ? 'bold' : undefined }}>
                    {value}
                  </span>
                }
              />
            ))}
          </RadioGroup>
        </Box>

        <Box flex={1} />

        <Box>
          {isCustom && (
            <FormControlLabel
              style={{ color: 'lightgrey', pointerEvents: 'none', marginRight: 0 }}
              control={
                <Switch
                  size="small"
                  color="default"
                  sx={{ opacity: '0.5', pointerEvents: 'auto', marginRight: 0 }}
                />
              }
              label="Show Macros"
              checked={showAllMacros}
              onChange={handleShowMacroSwitch}
            />
          )}
        </Box>
      </Stack>

      {(draftItem?.item && (isCustom && !!relevantNutrients.length)) && (
        <CustomItemMacrosEditor
          item={draftItem.item}
          relevantNutrients={relevantNutrients}
          onNutrientChange={handleNutrientChange}
        />
      )}

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Add Ons</Typography>
        {!!draftItem?.item.custom_addons?.length && (
          <FormGroup row>
            <Button
              disableElevation
              sx={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              variant="outlined"
              size="small"
              onClick={handleScaleAddons}
            >
              Scale addons by
            </Button>
            <AddonScaleTextField
              sx={{ maxWidth: 80 }}
              variant="outlined"
              size="small"
              type="number"
              inputProps={{ min: 0, pattern: '^[0-9]*\\.?[0-9]*$' }}
              placeholder="Scale Factor"
              value={addonScaleFactor}
              onChange={handleAddonScaleFieldChange}
            />
          </FormGroup>
        )}
      </Stack>
      {!!draftItem && (
        <MealItemAddonsEditor
          queueItem={queueItem}
          draftItem={draftItem}
        />
      )}
    </Stack>
  );
};

export const MealItemEditorActions = (props: {
  onCopy?: (item: DraftItem) => void,
  onPaste?: () => void,
  showCopiedMessagePopup?: boolean,
}) => {
  const editor = useQueueItemEditor();
  const relevantNutrients = useRelevantNutrients({
    context: 'item',
    showAllMacros: false,
  });
  const invalidReason = useMemo(() => {
    if (!editor.selectedItem) {
      return null;
    }
    return draftItemGetWarnings({ draftItem: editor.selectedItem, relevantNutrients });
  }, [editor.selectedItem, relevantNutrients]);

  const handleCancelDraftItemEdit = () => {
    editor.selectedItemCancel();
  };
  return (
    <CardActions>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1 }}>
        <Stack direction="row" spacing={1} sx={{ px: 1.5, py: 0.75 }}>
          <Tooltip title={invalidReason?.message}>
            <Button
              variant="contained"
              size="small"
              color={invalidReason?.isFatal
                ? 'secondary'
                : invalidReason
                ? 'error'
                : (editor.selectedItem?.item?.patient_id
                    && editor.selectedItem?.item?.patient_id
                      == editor.selectedItem?.item?.last_updated_by_user_id)
                ? 'warning'
                : undefined}
              style={{
                cursor: invalidReason?.isFatal ? 'pointer' : undefined,
              }}
              onClick={() => {
                if (invalidReason?.isFatal) {
                  return;
                }
                editor.selectedItemDone();
              }}
              startIcon={<Done />}
            >
              Done
            </Button>
          </Tooltip>
          {!!props.onCopy && (
            <Tooltip
              placement="top"
              title="Copied!"
              arrow
              open={props.showCopiedMessagePopup}
              disableHoverListener
              disableTouchListener
              disableFocusListener
            >
              <Button
                variant="contained"
                size="small"
                disabled={!editor.selectedItem}
                onClick={() => {
                  if (!editor.selectedItem) {
                    return;
                  }
                  props.onCopy?.(editor.selectedItem);
                }}
                startIcon={<ContentCopy />}
              >
                Copy
              </Button>
            </Tooltip>
          )}
          {!!editor.copiedItem && !!props.onPaste && (
            <Button
              variant="outlined"
              size="small"
              disabled={!editor.copiedItem}
              onClick={props.onPaste}
              startIcon={<ContentPaste />}
            >
              Paste: {formatDraftItemFoodName(editor.copiedItem)}
            </Button>
          )}
          {editor.selectedItem?.item?.patient_id
            && editor.selectedItem?.item?.patient_id == editor.selectedItem?.item?.last_updated_by_user_id
            && (
              <Alert severity="warning" variant="outlined">
                Patient has already edited this meal item!
              </Alert>
            )}
        </Stack>
        <Button
          sx={{ marginLeft: 'auto !important' }}
          size="small"
          color="error"
          onClick={() => {
            handleCancelDraftItemEdit();
          }}
        >
          Cancel
        </Button>
      </Stack>
    </CardActions>
  );
};

export const useFoodItemServings = (foodItem: DraftItem | FoodComponent | null) => {
  if (!foodItem) {
    return {
      servingUnitOptions: [],
      servingUnit: null,
      quantityOptions: [],
      quantity: null,
    };
  }

  const food = {
    ...foodItem,
    ...(Object.hasOwn(foodItem, 'item') ? (foodItem as DraftItem).item : {}),
  };

  const servingUnitOptions = (
    food.searchItem
      ? food.searchItem.serving_units || []
      : defaultSearchItem.serving_units || []
  ).concat();

  const currentKnownServingUnit = servingUnitOptions.find(v => (
    v.label == food.serving_unit_label
    && v.amount == food.serving_unit_amount
  ));

  if (!currentKnownServingUnit) {
    const sizingOptions = [...DEFAULT_SERVING_SIZE_OPTIONS];
    if (food.servings && !sizingOptions.includes('' + food.servings)) {
      sizingOptions.push('' + food.servings);
      sizingOptions.sort((a, b) => parseFloat(a) - parseFloat(b));
    }

    servingUnitOptions.splice(0, 0, {
      label: food.serving_unit_label || 'UNKNOWN',
      label_translations: {},
      amount: food.serving_unit_amount || NaN,
      default_label_qty: food.servings ?? 1,
      food_sizing_options: sizingOptions,
    });
  }

  const selectedUnit = food.serving_unit_label;
  const selectedAmount = food.serving_unit_amount;
  const selectedQuantity = food.servings;
  const selectedUnitDetails = servingUnitOptions.find(v => v.label === selectedUnit);

  const buildSizingOptions = (unit: FoodServingUnit | null | undefined) => {
    const options = unit?.food_sizing_options || [];

    const push = (value: number | string | undefined) => {
      const v = typeof value === 'number'
        ? value.toFixed(2).replace(/\.?0+$/, '')
        : value;
      if (!v) {
        return;
      }
      if (!options.some(o => +o == +v)) {
        options.push(v);
      }
    };

    push(selectedQuantity);

    if (unit?.default_label_qty) {
      push(unit.default_label_qty * 0.5);
      push(unit.default_label_qty * 0.75);
      push(unit.default_label_qty);
      push(unit.default_label_qty * 1.5);
      push(unit.default_label_qty * 2);
    }

    if (!options.length) {
      options.push(...DEFAULT_SERVING_SIZE_OPTIONS);
    }

    options.sort((a, b) => +a - +b);
    return options;
  };

  return {
    servingUnitOptions,
    servingUnit: selectedUnit,
    quantityOptions: buildSizingOptions(selectedUnitDetails),
    quantity: selectedQuantity,
    servingAmount: selectedAmount,
  };
};

export const useAddonServings = (
  addon: MealItemCustomAddonResponse | FoodComponentResponse,
  searchItem: FoodResponse | null,
) => {
  if (!searchItem?.serving_units) {
    return {
      servingUnitOptions: [],
      servingUnit: null,
      quantityOptions: [],
    };
  }
  const servingUnitOptions = [...searchItem.serving_units];
  const servingUnit = (searchItem.serving_units).find(v => v.label === addon.serving_unit_label) || null;
  const quantityOptions = servingUnit?.food_sizing_options || [];
  return {
    servingUnitOptions,
    servingUnit,
    quantityOptions,
  };
};

const emptyAddon: MealItemCustomAddonResponse = {
  id: 0,
  food_name: '',
  serving_unit_label: 'g',
  serving_unit_amount: 0,
  food_image_url: null,
  servings: 0,
  food_name_translations: null,
  serving_unit_label_translations: null,
};

export const MealItemAddonsEditor = ({
  queueItem,
  draftItem,
  disableAutofocus,
}: {
  queueItem: MealQueueItem,
  draftItem: DraftItem,
  disableAutofocus?: boolean,
}) => {
  const quantityRef = useRef<HTMLInputElement>(null);
  const [lastUpdated, setLastUpdated] = useState(null as null | MealItemCustomAddonResponse);
  const { updateDraftItemMealItem } = useQueueItemEditor();
  const addons = draftItem.item?.custom_addons?.length
    ? draftItem.item.custom_addons.concat(emptyAddon)
    : [emptyAddon];

  const toAutofocusIdx = useMemo(() => {
    // Autofocus either:
    // 1. The first addon which does not have both a serving_unit_label and servings
    //    (this can happen when an addon is added via `editor.addItemAsAddon`)
    // 2. The last addon in the list
    return addons.findIndex(a => !a.serving_unit_label || !a.servings) ?? addons.length - 1;
    // This should only happen when the component is first rendered to avoid
    // focus being stolen while editing is in progress.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('toAutofocusIdx', toAutofocusIdx);

  // Expect draftItem addons to not have placeholder at the end
  const removePlaceholderAddon = (addons: MealItemCustomAddonResponse[]) => addons.filter(a => a.food_name);

  const deleteAddon = (addon: MealItemCustomAddonResponse) => {
    setLastUpdated(null);
    updateDraftItemMealItem(draftItem, {
      custom_addons: removePlaceholderAddon(addons.filter(a => a !== addon)),
    });
  };

  const updateAddon = (oldAddon: MealItemCustomAddonResponse, newAddon: MealItemCustomAddonResponse) => {
    setLastUpdated(newAddon);
    updateDraftItemMealItem(draftItem, {
      custom_addons: removePlaceholderAddon(addons.map(a => a === oldAddon ? newAddon : a)),
    });
  };

  const setFocusOnQuantity = () => {
    if (quantityRef?.current) {
      quantityRef.current.focus();
    }
  };

  return (
    <Stack spacing={0.5}>
      {addons.map((addon, i) => (
        <MealItemAddon
          key={i}
          queueItem={queueItem}
          addon={addon}
          mealItem={draftItem.item}
          quantityRef={addon === lastUpdated ? quantityRef : null}
          autofocus={!lastUpdated && i === toAutofocusIdx && !disableAutofocus}
          updateAddon={update => updateAddon(addon, update)}
          deleteAddon={() => deleteAddon(addon)}
          setFocusOnQuantity={setFocusOnQuantity}
        />
      ))}
    </Stack>
  );
};

export const MealItemAddon = (props: {
  queueItem: MealQueueItem,
  addon: MealItemCustomAddonResponse,
  mealItem: MealItem,
  quantityRef: React.RefObject<HTMLInputElement> | null,
  autofocus: boolean,
  updateAddon: (addon: MealItemCustomAddonResponse) => void,
  deleteAddon: () => void,
  setFocusOnQuantity: () => void,
}) => {
  const { authInfo } = useAuth();
  const flags = useFeatures();
  const { addon, quantityRef, updateAddon, setFocusOnQuantity } = props;
  const foodQuery = useQuery(['food', addon.food_name], async () => {
    if (!addon.food_name) {
      return null;
    }
    return getFoodByQuery(addon.food_name, authInfo!.access_token);
  });
  const { servingUnitOptions, servingUnit, quantityOptions } = useAddonServings(addon, foodQuery.data ?? null);
  const [quantity, setQuantity] = useState<string>('');
  const quantitySigFigs = 5;

  const handleQuantityChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value === null) {
      return;
    }
    if (!value.match(/^\d*\.?\d*$/)) {
      return;
    }
    const finalValue = (value).substring(0, quantitySigFigs);
    setQuantity(finalValue);
    updateAddon({
      ...addon,
      servings: parseFloat(finalValue) || 0,
    });
  };

  useEffect(() => {
    if (addon.servings) {
      setQuantity(addon.servings.toString());
    }
  }, [addon.servings]);

  useEffect(() => {
    if (foodQuery?.isFetched) {
      setFocusOnQuantity();
    }
    setQuantity(addon.servings ? addon.servings.toString() : '');
  }, [foodQuery.isFetched, addon.food_name, addon.serving_unit_label]);

  const autofocusField = useMemo(() => {
    if (!props.autofocus) {
      return null;
    }

    if (!addon.food_name) {
      return 'food_name';
    }

    if (!addon.serving_unit_label) {
      return 'serving_unit_label';
    }

    if (!addon.servings) {
      return 'servings';
    }

    return 'food_name';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autofocus]);

  const servingUnitInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autofocusField === 'serving_unit_label' && servingUnitInputRef.current) {
      servingUnitInputRef.current.focus();
    }
  }, [autofocusField]);

  const getDefaultServingUnit = (servingUnits: FoodServingUnit[]) => {
    return servingUnits.reduce((prev, current) => {
      if (current.addon && !prev.addon) {
        return current;
      } else if (!prev.ranking && current.ranking) {
        return current;
      } else if (prev.ranking && current.ranking && current.ranking < prev.ranking) {
        return current;
      }
      return prev;
    }, servingUnits[0]);
  };

  const itemWeight = !!addon.food_name
    && round(getItemWeightWithoutAddons(addon, props.mealItem?.percent_eaten), 2);

  return (
    <Box key={addon.food_name}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <FoodSearchDropdownInput
              context={{
                context_user_id: props.queueItem.patient_id,
                context_addon_of: props.mealItem?.food_name,
              }}
              initialSearchText={addon.food_name}
              autoFocus={autofocusField == 'food_name'}
              onItemSelect={(item, index, source) => {
                const defaultServingUnit = (item.serving_units && item.serving_units.length > 0)
                  ? getDefaultServingUnit(item.serving_units)
                  : { label: 'g', amount: 1, default_label_qty: 1 };
                updateAddon({
                  ...addon,
                  food_name: item.name,
                  serving_unit_label: defaultServingUnit.label,
                  serving_unit_amount: defaultServingUnit.amount,
                  servings: defaultServingUnit.default_label_qty ?? 1,
                });
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={0.5}>
            <Select
              disabled={!foodQuery.data?.serving_units}
              value={addon.serving_unit_label}
              inputProps={{ ref: servingUnitInputRef }}
              onChange={(evt) => {
                if (!foodQuery?.data) {
                  return;
                }
                const servingUnit = foodQuery.data.serving_units?.find(v => v.label === evt.target.value);
                if (servingUnit) {
                  updateAddon({
                    ...addon,
                    serving_unit_label: servingUnit.label,
                    serving_unit_amount: servingUnit.amount,
                    servings: servingUnit.default_label_qty ?? 1,
                  });
                }
              }}
            >
              {servingUnitOptions.map((v, i) => (
                <MenuItem
                  key={v.label + '-' + i}
                  disabled={!!v.label.match(/^--------/)}
                  value={v.label}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={0.5}>
            <Autocomplete
              disabled={!quantityOptions.length}
              freeSolo
              openOnFocus
              options={quantityOptions}
              value={quantity}
              inputValue={quantity}
              onChange={handleQuantityChange}
              onInputChange={handleQuantityChange}
              onBlur={(event) => {
                if (quantity === '' || parseFloat(quantity) === 0) {
                  handleQuantityChange(event, servingUnit?.default_label_qty?.toString() ?? null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={autofocusField == 'servings'}
                  inputRef={quantityRef}
                />
              )}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  placement="right"
                >
                  {props.children}
                </Popper>
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={1} alignContent="center">
          {addon.food_name !== '' && (
            <Tooltip title={formatMealItemSizing(addon, props.mealItem)}>
              <Typography color="grey">({itemWeight}g)</Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={1}>
          {addon.food_name !== '' && (
            <Tooltip title="Delete add on" placement="top">
              <IconButton
                size="large"
                color="primary"
                onClick={() => {
                  props.deleteAddon();
                  setQuantity('');
                }}
              >
                <DeleteFilled />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const DraftItemActionsMenu = (props: {
  draftItem: DraftItem,
  onEdit: () => void,
  onRemove: () => void,
  onCopy: () => void,
  onPaste: () => void,
  onDuplicate: () => void,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const editor = useQueueItemEditor();
  const otherDraftItems = useMemo(() => {
    return editor.draftItems.filter(di => di !== props.draftItem).reverse();
  }, [editor.draftItems, props.draftItem]);

  return (
    <>
      <Button
        disabled={editor.mealDeleted}
        size="small"
        variant="contained"
        onClick={e => setMenuAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
      >
        {(props.draftItem.item?.custom_item || !props.draftItem.item.food_name)
          ? [
            <MenuItem key="a" disabled>
              <ListItemIcon>
                <Warning fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Custom items cannot be converted to addons
              </ListItemText>
            </MenuItem>,
            <Divider key="b" />,
          ]
          : otherDraftItems.length > 0 && [
            <MenuItem key="a" disabled>
              <ListItemText>
                Convert to addon of...
              </ListItemText>
            </MenuItem>,
            ...otherDraftItems.map((di, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  setMenuAnchorEl(null);
                  editor.addItemAsAddon({
                    targetItem: di,
                    addonMealItem: props.draftItem.item,
                    focusTargetItem: false,
                    keepMealItemSize: true,
                  });
                  editor.removeDraftItem(props.draftItem, { silent: true });
                }}
              >
                <ListItemIcon>
                  <SubdirectoryArrowRight fontSize="small" style={{ color: '#ccc' }} />
                </ListItemIcon>
                <ListItemText>
                  {formatDraftItemFoodName(di)}
                </ListItemText>
              </MenuItem>
            )),
            <Divider key="b" />,
          ]}
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            props.onCopy();
          }}
        >
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Copy
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            props.onCopy();
            props.onRemove();
          }}
        >
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Cut
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            props.onDuplicate();
          }}
        >
          <ListItemIcon>
            <FileCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Duplicate
          </ListItemText>
        </MenuItem>

        {!!editor.copiedItem && [
          <Divider key="a" />,
          <MenuItem
            key="b"
            onClick={() => {
              setMenuAnchorEl(null);
              props.onPaste();
            }}
          >
            <ListItemIcon>
              <ContentPaste fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Paste addon: {editor.copiedItem.item.food_name}
            </ListItemText>
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export const DraftItemSummary = (props: {
  draftItem: DraftItem,
  copiedItem: DraftItem | null,
  queueItem: MealQueueItem,
  onEdit: () => void,
  onSwap: () => void,
  onRemove: () => void,
  onCopy: () => void,
  onPaste: () => void,
  onDuplicate: () => void,
  dragAndDropIndex: number,
  disabledDroppables: string[],
  dragIsDisabled?: boolean,
}) => {
  const { mealDeleted } = useQueueItemEditor();
  const { draftItem, queueItem } = props;
  const flags = useFeatures();
  const mealItemSubscript = [
    !!draftItem.item.food_name_alias && `"${draftItem.item.food_name}"`,
    // draftItem.item.custom_item && (draftItem.searchItem?.name || defaultSearchItem.name),
  ].filter(Boolean).join('; ');
  const didPatientEdit = !!draftItem.item.patient_id
    && draftItem.item.last_updated_by_user_id == draftItem.item.patient_id;

  const relevantNutrients = useRelevantNutrients({
    context: 'item',
  });

  const itemWarnings = useMemo(() => {
    return draftItemGetWarnings({ draftItem, relevantNutrients });
  }, [draftItem, relevantNutrients]);

  const draggableId = `${draftItem.id || props.dragAndDropIndex}-draggable`;
  const addonDroppablesIsDisabled = props.disabledDroppables.includes('addon')
    || props.disabledDroppables.includes(`addonOf:${props.dragAndDropIndex}`)
    || props.disabledDroppables.includes(draggableId);

  return (
    <>
      <Draggable
        id={draggableId}
        data={{ item: draftItem }}
        disabled={draftItem.item?.custom_item || !draftItem.item.food_name || props.dragIsDisabled}
      >
        <Box
          onCopy={() => props.onCopy()}
          sx={{ backgroundColor: '#f5f5f5', border: '1px solid #d9d9d9', padding: 2 }}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ width: 1 }}
            >
              <Stack>
                <Typography variant="h4" style={{ marginTop: 2, marginBottom: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {!!itemWarnings && (
                      <Tooltip title={itemWarnings.message} arrow>
                        <IconButton
                          onClick={props.onEdit}
                          color="error"
                        >
                          <Dangerous />
                        </IconButton>
                      </Tooltip>
                    )}
                    {draftItem.item.custom_item && <MealItemIcons draftItem={draftItem} />}
                    {!!(draftItem.item.food_name_alias || draftItem.item.food_name) && (
                      <span>
                        {draftItem.item.food_name_alias || draftItem.item.food_name}
                      </span>
                    )}
                    {!(draftItem.item.food_name_alias || draftItem.item.food_name) && (
                      <em>{draftItem.queryText || '!!! no food !!!'}</em>
                    )}
                    <Typography display="inline" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                      {mealItemSubscript && '(' + mealItemSubscript + ')'}
                    </Typography>
                  </Stack>
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                  {formatMealItemSizing(draftItem.item)} {draftItem.searchItem && getMealItemAdultServings(draftItem)}
                </Typography>
              </Stack>

              <Stack spacing={0.2}>
                <Stack direction="row" spacing={1}>
                  {draftItem.imLblMatchResult
                    && (
                      <div style={{ display: 'inline-block', width: 20, height: 20, fontSize: 20 }}>
                        <ImLblMatchDetailsIcon match={draftItem.imLblMatchResult} />
                      </div>
                    )}
                  <Stack justifyContent="center">
                    <MealPushQuestions
                      queueItem={queueItem}
                      draftItem={draftItem}
                    />
                  </Stack>
                  <Button
                    disabled={mealDeleted}
                    variant="contained"
                    size="small"
                    onClick={props.onEdit}
                    startIcon={<Edit />}
                  >
                    Edit
                  </Button>
                  <DraftItemActionsMenu
                    draftItem={draftItem}
                    onEdit={props.onEdit}
                    onRemove={props.onRemove}
                    onCopy={props.onCopy}
                    onPaste={props.onPaste}
                    onDuplicate={props.onDuplicate}
                  />
                  <Button
                    disabled={mealDeleted}
                    variant="contained"
                    size="small"
                    onClick={props.onSwap}
                    startIcon={<ChangeCircle />}
                  >
                    Swap
                  </Button>
                  <IconButton
                    disabled={mealDeleted}
                    variant="outlined"
                    color="error"
                    onClick={props.onRemove}
                    title="Remove"
                  >
                    <Delete />
                  </IconButton>
                </Stack>
                {didPatientEdit && (
                  <Typography
                    variant="body1"
                    sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                  >
                    patient edited
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {draftItem.item.custom_addons && draftItem.item.custom_addons.length > 0
                ? (
                  <Stack spacing={0.25} width="100%">
                    <Typography variant="h5">Add ons</Typography>
                    {draftItem.item.custom_addons.map(addon => (
                      <Draggable
                        key={addon.food_name}
                        id={`${addon.food_name}-draggable`}
                        data={{ item: addon, mealItem: draftItem, mealItemIndex: props.dragAndDropIndex }}
                        disabled={props.dragIsDisabled}
                      >
                        <Typography variant="body1" paddingLeft={1}>
                          {addon.food_name} ({addon.servings} {addon.serving_unit_label})
                        </Typography>
                      </Draggable>
                    ))}
                  </Stack>
                )
                : <div />}
              <DraftItemNoteOnRight draftItem={draftItem} />
            </Stack>
            <Stack width="100%">
              <Droppable
                id={`${draftItem.id || props.dragAndDropIndex}-addon`}
                data={{ mealItem: draftItem, mealItemIndex: props.dragAndDropIndex }}
                disabled={addonDroppablesIsDisabled}
              />
            </Stack>
            {!!draftItem.item.note && draftItem.item.note != queueItem.patient_note && (
              <Typography variant="h5">Meal item note: {draftItem.item.note}</Typography>
            )}
            <FoodPatientHistorySuggestionsList draftItem={draftItem} />
          </Stack>
        </Box>
      </Draggable>
      <Droppable
        id={`${draftItem.id || props.dragAndDropIndex}-after`}
        data={{ dropBefore: draftItem }}
        disabled={props.disabledDroppables.includes('mealItem')}
      />
    </>
  );
};

const DraftItemNoteOnRight = (props: {
  draftItem: DraftItem,
}) => {
  const { draftItem } = props;
  const foodNote = draftItem.searchItem?.note;
  const prepMethodNote = draftItem.item.preparation_method
      && draftItem.item.preparation_method !== 'other'
      && draftItem.item.preparation_method !== null
    ? MEAL_PREPARATION_METHODS[draftItem.item.preparation_method]
    : null;
  const patientEditedNote = draftItem.item.nutrient_overrides?.aa_patient?.source_name === 'patient';
  if (!(foodNote || prepMethodNote || patientEditedNote)) {
    return null;
  }
  return (
    <Box sx={{ backgroundColor: '#1890ff0a', padding: 1, width: 200 }}>
      {foodNote && (
        <>
          <Typography variant="subtitle1">Description:</Typography> <Typography>{foodNote}</Typography>
        </>
      )}
      {patientEditedNote && (
        <Typography variant="subtitle1">
          <Info color="info" fontSize="small" /> Macros edited by patient
        </Typography>
      )}
      {prepMethodNote && (
        <Typography variant="subtitle1">
          <Info color="info" fontSize="small" /> {prepMethodNote}
        </Typography>
      )}
    </Box>
  );
};

const MealItemIcons = (props: { draftItem: DraftItem }) => {
  const mealItem = props.draftItem.item;

  if (!mealItem.custom_item) {
    return null;
  }

  const externalSourceLink = getCustomItemSourceLink({
    custom_item_source: mealItem.custom_item_source,
    custom_item_source_id: mealItem.custom_item_source_id,
  });

  const tooltip = (
    <>
      <span>Source: {mealItem.custom_item_source}</span>
      <br />
      <span>ID: {mealItem.custom_item_source_id}</span>
    </>
  );

  return (
    <Tooltip title={tooltip}>
      <Link href={externalSourceLink ?? undefined} target="_blank">
        <Icon path={mdiAlphaCBoxOutline} size="1.5em" />
      </Link>
    </Tooltip>
  );
};

const FoodPatientHistorySuggestionsList = (props: { draftItem: DraftItem, style?: React.CSSProperties }) => {
  const { draftItem, style } = props;
  const editor = useQueueItemEditor();
  const foodDetailsPopup = useFoodDetailsPopup();

  const { foodLookupName, foodHistorySuggestions } = editor.getFoodHistorySuggestions(draftItem);

  const handleClick = async (e: MouseEvent, itemSuggestion: MealItemResponse, index: number) => {
    const mealItem = recentItemToMealItem(editor.queueItem, itemSuggestion);
    const food = await foodApi.appApiFoodFoodSearchGetFoodQuery({
      food_name: itemSuggestion.food_name,
    });
    const searchItem: SearchItem | null = food.data || null;
    const newServingUnitOptions = searchItem?.serving_units || [];
    if (newServingUnitOptions.find(unit => unit.label == draftItem.item.serving_unit_label)) {
      mealItem.serving_unit_label = draftItem.item.serving_unit_label;
      mealItem.serving_unit_amount = draftItem.item.serving_unit_amount;
    }
    const foodMatchDetails: MealItemFoodMatchDetailsSearch = {
      user_type: 'internal',
      source_name: 'search',
      source_details: {
        mixpanel: {
          Type: 'Regular item',
          Source: 'User meal item history',
          'Food name': mealItem.food_name,
          'Search text': foodLookupName,
          'Add or update': 'update',
          Index: index,
          Editor: 'draft item',
        },
      },
    };
    mixpanel.track('Meal item selected', foodMatchDetails.source_details.mixpanel);
    const newSourceDetails = {
      ...(draftItem.foodMatchDetails?.source_details || {}),
      ...foodMatchDetails.source_details,
    };
    editor.updateDraftItem(draftItem, {
      item: {
        ...(draftItem.item || {}),
        ...mealItem,
        food_name_alias: (
          draftItem.item.food_name_alias
            ? draftItem.item.food_name_alias
            : mealItem.food_name_alias
        ),
        percent_eaten: draftItem.item.percent_eaten ?? mealItem.percent_eaten,
        addons: [...draftItem.item.addons],
      },
      searchItem: searchItem,
      foodMatchDetails: {
        ...foodMatchDetails,
        source_details: newSourceDetails,
      },
    });
    foodDetailsPopup.forceClear();
  };
  const handleRecommendedMealClick = (mealResponse: MealResponse, index: number, source?: string) => {
    mixpanel.track('Meal item selected', {
      Type: 'Regular item',
      Source: source,
      'Item count': mealResponse.meal_items.length,
      Index: index,
    });
    editor.addBulkDraftItems({
      replaceSelected: true,
      items: mealResponse.meal_items.map(mealHistoryItemToMealItem).map((item) => ({
        id: null,
        item,
        searchItem: null,
        queryText: null,
        foodMatchDetails: null,
      } satisfies DraftItem)),
    });
    foodDetailsPopup.forceClear();
  };
  return (
    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" sx={style}>
      {foodHistorySuggestions.slice(0, 5).map((mi, index) => (
        <Chip
          key={mi.foodName}
          avatar={<Avatar>{mi.count}</Avatar>}
          color={mi.inDrafts && mi.foodName === foodLookupName ? 'success' : undefined}
          label={mi.foodName}
          title={mi.foodName}
          onClick={(e) => handleClick(e, mi.item, index)}
          size="small"
          style={{ maxWidth: 150 }}
          {...foodDetailsPopup.getShowOnHoverProps({
            mealItem: mi.item,
            anchorTo: 'element',
            showRecentMealsWithQueueId: editor.queueItem.id,
            handleMealClick: handleRecommendedMealClick,
          })}
        />
      ))}
    </Stack>
  );
};

const FoodNameAliasInput = (props: {
  item: MealItem,
  onValueChange: (newValue: string | null) => void,
}) => {
  const { item } = props;
  const [enabled, setEnabled] = useState(!!item.food_name_alias);
  const [prevAlias, setPrevAlias] = useState(item.food_name_alias);
  const flags = useFeatures();

  if (!flags.labeling_use_food_name_alias) {
    return null;
  }

  return (
    <Box flex={1}>
      <Typography>
        Food Alias
      </Typography>
      <Box flex={1}>
        <OutlinedInput
          fullWidth
          value={item.food_name_alias || ''}
          onChange={e => {
            props.onValueChange(e.target.value || null);
          }}
          placeholder={prevAlias ?? 'Food alias'}
          disabled={!enabled}
          startAdornment={
            <InputAdornment position="start">
              <Checkbox
                checked={enabled}
                onChange={() => {
                  setEnabled(!enabled);
                  if (enabled) {
                    setPrevAlias(item.food_name_alias);
                    props.onValueChange(null);
                  } else {
                    props.onValueChange(prevAlias ?? null);
                  }
                }}
                color="primary"
                style={{ padding: 0 }}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title={
                  <span>
                    The food name which will appear in the patient's meal log.<br />{' '}
                    For example, if the patient logged "<i>grandma's chicken soup</i>",{' '}
                    then "<i>chicken soup</i>" should be logged, and the alias{' '}
                    "<i>grandma's chicken soup</i>" should be used.
                  </span>
                }
              >
                <IconButton
                  color="secondary"
                  edge="end"
                >
                  <InfoCircleOutlined />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Box>
    </Box>
  );
};

const CustomItemMacrosEditor = (props: {
  item: MealItem,
  relevantNutrients: ReturnType<typeof useRelevantNutrients>,
  onNutrientChange: (newNutrients: Partial<NutrientEstimatesRequest>) => void,
}) => {
  const { item, relevantNutrients } = props;

  // TODO: once the external search API is in place, this should be replaced with
  // a request to the external search API to get the nutrients.
  const baseFoodName = item.custom_item && item.custom_item_source == 'ia_db'
    ? item.custom_item_source_id
    : item.food_name;
  const selectedFoodDetails = useFoodDetails(baseFoodName);

  const calculatedNutrients = React.useMemo(() => {
    if (!item || selectedFoodDetails.query.isLoading) {
      return {};
    }

    const foodNutrients = selectedFoodDetails?.usda_nutrition ?? null;
    return Object.fromEntries(
      relevantNutrients.map((n) => {
        return [
          n.nutrient,
          mealItemGetNutrientValue({
            item,
            foodNutrients,
            nutrient: n.nutrient,
            ignoreCustomEstimates: true,
            // Input values in meal item component are before percent eaten
            ignorePctEaten: true,
            // We want suggested values from external source/usda only
            ignoreSources: ['patient', 'reviewer'],
          }),
        ];
      }),
    );
  }, [item, item.nutrient_overrides, relevantNutrients, selectedFoodDetails]);

  const [currentValues, setCurrentValues] = useState({} as Record<string, string>);

  useEffect(() => {
    const reviewerOverrides = mealItemGetNutrientOverrides({
      item: item,
      sourceName: 'reviewer',
    });
    setCurrentValues(
      Object.fromEntries(
        Object.entries(reviewerOverrides || {}).map(([key, val]) => [
          key,
          // we want the nutrient amount at the meal item level before percent eaten
          nutrientScale({
            value: val,
            from: NUTRIENT_100G,
            to: item.servings * item.serving_unit_amount,
          }),
        ]).map(
          ([k, v]) => {
            const nutrient = nutrientGetDef(k as any);
            return [
              k,
              typeof v == 'number' ? nutrient.formatNumber(v as number) : '',
            ];
          },
        ),
      ),
    );
  }, [item.nutrient_overrides, item.servings, item.serving_unit_amount]);

  return (
    <div>
      {
        /* note: this <div /> is necessary to make sure left margins are correct:
              https://github.com/mui/material-ui/issues/29221
            */
      }
      <Grid container spacing={1}>
        {relevantNutrients.map(n => {
          const nutrient = calculatedNutrients[n.nutrient];
          const valueNumStr = nutrient?.def?.formatNumber(nutrient?.value);
          return (
            <Grid key={n.nutrient} item xs={3}>
              <Stack spacing={0.5}>
                <Typography>
                  {n.label}
                  {!!nutrient && (
                    <>
                      {(((currentValues[n.nutrient] ?? '') != '') || isNaN(+nutrient.value))
                        ? (
                          <Typography
                            display="inline"
                            sx={{
                              cursor: 'pointer',
                              color: 'text.secondary',
                            }}
                            onClick={() => {
                              props.onNutrientChange({ [n.nutrient]: nutrient.value });
                              setCurrentValues(prev => ({
                                ...prev,
                                [n.nutrient]: valueNumStr,
                              }));
                            }}
                          >
                            &nbsp;({nutrient.valueStr ?? ''})
                          </Typography>
                        )
                        : (
                          <Tooltip title={`Set value to ${nutrient.valueStr}`}>
                            <Typography
                              display="inline"
                              sx={{
                                cursor: 'pointer',
                                color: 'text.secondary',
                              }}
                              onClick={() => {
                                props.onNutrientChange({ [n.nutrient]: nutrient.value });
                                setCurrentValues(prev => ({
                                  ...prev,
                                  [n.nutrient]: valueNumStr,
                                }));
                              }}
                            >
                              &nbsp;{nutrient.valueStr ?? ''} <KeyboardReturnIcon fontSize="small" />
                            </Typography>
                          </Tooltip>
                        )}
                      {!!nutrient.info.length && (
                        <Tooltip
                          title={<span style={{ whiteSpace: 'pre-line' }}>{nutrient.info.join('\n')}</span>}
                        >
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {' '}
                            <Info color="info" fontSize="small" />
                          </span>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Typography>
                <Tooltip title="Total for meal (ignoring serving count)">
                  <OutlinedInput
                    fullWidth
                    value={currentValues[n.nutrient] ?? ''}
                    error={n.patientVisible && (currentValues[n.nutrient] ?? '') == ''}
                    onChange={(evt) => {
                      setCurrentValues((prev) => ({
                        ...prev,
                        [n.nutrient]: evt.target.value,
                      }));
                    }}
                    disabled={selectedFoodDetails.query.isLoading}
                    onBlur={(evt) => {
                      evt && props.onNutrientChange({ [n.nutrient]: floatOrNull(evt.target.value) });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography>{n.suffix}</Typography>
                      </InputAdornment>
                    }
                  />
                </Tooltip>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const PercentEatenSlider = (props: {
  item: MealItem,
  onValueChange: (newPercentEaten: number) => void,
}) => {
  const [percentEaten, setPercentEaten] = useState(
    props.item.percent_eaten != null ? props.item.percent_eaten * 100 : 100,
  );

  const handleSliderChange = (evt: Event, newValue: number | number[]) => {
    setPercentEaten(newValue as number);
  };

  const handleSliderChangeCommited = (evt: SyntheticEvent | Event, newValue: number | number[]) => {
    setPercentEaten(newValue as number);
    props.onValueChange((newValue as number) / 100);
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPercentEaten(evt.target.value === '' ? 0 : Number(evt.target.value));
  };

  const handleBlur = () => {
    const newValue = _.clamp(Math.ceil(percentEaten / 5) * 5, 0, 100);

    setPercentEaten(newValue);
    props.onValueChange(newValue / 100);
  };

  useEffect(() => {
    setPercentEaten(props.item.percent_eaten != null ? props.item.percent_eaten * 100 : 100);
  }, [props.item]);

  return (
    <Box flex={1}>
      <Typography>
        Percent Eaten
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <Slider
            value={percentEaten}
            step={5}
            min={5}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommited}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={Math.trunc(percentEaten)}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 5,
              min: 5,
              max: 100,
              type: 'tel',
            }}
            endAdornment={
              <InputAdornment position="end" style={{ marginLeft: -10 }}>
                %
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
