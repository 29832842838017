import { foodEngineApi } from 'api';
import { FoodEngineFoodResponse } from 'api/generated/food-engine';
import { MealItemResponse } from 'api/generated/MNT';
import { FoodSearchSelection } from 'pages/QueueItem/meal-builder/FoodDrawer';
import { _useFoodQuery, UseFoodDetailsOpts, UseFoodRes } from './FoodDetailsService';
import { ExternalFoodSearchResult } from './foodSearch/useFoodSearch';

/**
 * `useFoodEngineFood(foodId | foodId[])` is a hook which fetches (with
 * caching) food with id ``foodId`` from the food engine.
 *
 * `useFoodEngineFood(null)` will return an empty object.
 *
 * > useFoodEngineFood(null)
 * { query: { isLoading: false, isDone: true } }
 * > useFoodEngineFood('usda:1234')
 * { query: { isLoading: ..., ... }, ...FoodEngineFoodResponse }
 * > useFoodDetails(['usda:1234', 'nutrx:abcd'])
 * { 'usda:1234': { query: ..., ...FoodEngineFoodResponse }, 'nutrx:abcd': { query: ..., ...FoodEngineFoodResponse } }
 */
export function useFoodEngineFood(
  foodIds: string | null | undefined,
  opts?: UseFoodDetailsOpts,
): UseFoodRes<FoodEngineFoodResponse>;
export function useFoodEngineFood<Name extends string>(
  foodIds: Name[],
  opts?: UseFoodDetailsOpts,
): {
  [key in Name]: UseFoodRes<FoodEngineFoodResponse>;
};
export function useFoodEngineFood(
  foodIds: string[] | string | null | undefined,
  opts?: UseFoodDetailsOpts,
) {
  return _useFoodQuery(
    foodIds,
    opts ?? {},
    (foodId) =>
      foodEngineApi.getById({
        id: foodId,
      }),
    getFoodEngineFoodQueryKey,
  );
}

export const getFoodEngineFoodQueryKey = (foodId: string) => {
  return ['food-engine', foodId];
};

export function getExternalItemSourceLink(item: ExternalFoodSearchResult): string | null {
  return getCustomItemSourceLink({
    custom_item_source: item.sourceId,
    custom_item_source_id: item.externalId,
  });
}

export type CustomItemSource = Pick<MealItemResponse, 'custom_item_source' | 'custom_item_source_id'>;

export function getCustomItemSourceLink(opts: CustomItemSource | null): string | null {
  if (!opts || !opts.custom_item_source || !opts.custom_item_source_id) {
    return null;
  }

  const sourceId = opts.custom_item_source == 'ia_db'
    ? opts.custom_item_source_id
    : opts.custom_item_source_id.replace(/^.*?:/, '');

  switch (opts.custom_item_source.replace(/^ext:/, '')) {
    case 'ia_db':
      return `/foods/${encodeURIComponent(sourceId)}`;
    case 'usda_api':
    case 'usda':
      return `https://fdc.nal.usda.gov/fdc-app.html#/food-details/${sourceId}/nutrients`;
    case 'nutrx':
    case 'nutritionix':
      return `https://www.nutritionix.com/i/x/x/${sourceId}`;
    case 'lobl':
      return `https://www.loblaws.ca/x/p/${sourceId}`;
    default:
      return null;
  }
}

/**
 * Converts a meal item's custom_item_source to a Food Engine foodId, or null if
 * there is no custom_item_source or custom_item_source_id.
 */
export const itemCustomItemFoodId = (item: CustomItemSource | null) => {
  if (!item) {
    return null;
  }

  if (!(item.custom_item_source && item.custom_item_source_id)) {
    return null;
  }

  if (item.custom_item_source == 'ia_db') {
    return `ia_db:${item.custom_item_source_id}`;
  }

  if (item.custom_item_source_id.includes(':')) {
    return item.custom_item_source_id;
  }

  switch (item.custom_item_source) {
    case 'ext:nutritionix':
      return `nutrx:${item.custom_item_source_id}`;
    case 'ext:usda':
    case 'ext:usda_api':
      return `usda:${item.custom_item_source_id}`;
  }

  console.error('Unknown custom_item_source:', item.custom_item_source, item.custom_item_source_id);
  return null;
};

export const foodSearchSelectionFoodId = (item: FoodSearchSelection) => {
  return `ia_db:${item.name}`;
};

export const externalFoodSearchResultFoodId = (item: ExternalFoodSearchResult) => {
  if (item.externalId.includes(':')) {
    return item.externalId;
  }

  return `${item.sourceIdAbbr}:${item.externalId}`;
};

/**
 * Converts a food engine foodId to a meal item's custom_item_source, or null if
 * the foodId is null.
 */
export function foodIdToCustomItemSource(foodId: string | null): CustomItemSource | null {
  if (!foodId) {
    return null;
  }

  const [source, id] = foodIdSplit(foodId);
  switch (source) {
    case 'ia_db':
      return { custom_item_source: 'ia_db', custom_item_source_id: id };
    case 'nutrx':
      return { custom_item_source: 'ext:nutritionix', custom_item_source_id: foodId };
    case 'usda':
    case 'usda_api':
      return { custom_item_source: 'ext:usda', custom_item_source_id: foodId };
    case 'lobl':
      return { custom_item_source: 'ext:lobl', custom_item_source_id: foodId };
    case 'kr':
      return { custom_item_source: 'ext:kr', custom_item_source_id: foodId };
    default:
      console.error('Unknown foodId:', foodId);
      return null;
  }
}

export function foodIdSplit(foodId: string | null): [string, string] | [null, null] {
  const parts = (foodId ?? '').split(':');
  if (!parts[0] || !parts[1]) {
    return [null, null];
  }
  return [parts[0], foodId!];
}
