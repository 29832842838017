import { MealItem } from 'apiClients/mpq';
import { FoodComponent } from 'food-editor/types';
import _ from 'lodash';
import { SummaryTable } from 'pages/QueueItem/meal-builder/MealSummary';
import { useMemo } from 'react';
import { DraftItem } from 'types/DraftItem';
import { NutrientDef, nutrientGetDef } from 'utils/mealItems';

export const FoodComponentSummaryTable = (props: {
  foodComponents: FoodComponent[],
}) => {
  const draftItems = useMemo(() => props.foodComponents.map(convertFoodComponentToDraftItem), [props.foodComponents]);

  const relevantNutrients = [
    'carbohydrate_g',
    'fiber_g',
    'protein_g',
    'fat_g',
    'alcohol_g',
    'energy_kcal',
  ].map(n => ({
    ...nutrientGetDef(n as any),
    showIf: true,
  })) as (NutrientDef & { showIf: boolean })[];

  return (
    <SummaryTable
      draftItems={draftItems}
      relevantNutrients={relevantNutrients}
      disableSizingColWrap
      smallerText
    />
  );
};

const convertFoodComponentToDraftItem = (component: FoodComponent): DraftItem => {
  const addOns: string[] = component.component_addons?.map(addon => addon.food_name!) || [];
  const customAddons = component.component_addons?.map(addon => ({
    food_name: addon.food_name!,
    food_name_translations: null,
    percent_eaten: 1,
    serving_unit_amount: addon.serving_unit_amount!,
    serving_unit_label: addon.serving_unit_label!,
    serving_unit_label_translations: null,
    servings: addon.servings!,
    food_image_url: null,
  }));

  const mealItem: MealItem = {
    addons: addOns,
    custom_item: false,
    custom_usda_id: null,
    custom_item_source: null,
    custom_item_source_id: null,
    food_name: component.food_name!,
    percent_eaten: 1,
    serving_unit_amount: component.serving_unit_amount!,
    serving_unit_label: component.serving_unit_label!,
    servings: component.servings!,
    custom_addons: customAddons,
    custom_tip: component.custom_tip,
  };

  return {
    id: null,
    item: mealItem,
    searchItem: component.searchItem || null,
    queryText: component.food_name!,
    foodMatchDetails: null,
  };
};
