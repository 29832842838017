import type {
  CreateMealItemFoodMatchDetailsRequest,
  FoodNlpPhraseMatchResult,
  FoodResponse,
  MealItemCustomAddonResponse,
  MPQImLblMatchResult,
  QA2ChangeReason,
  UpdateMealPushQuestionRequest,
} from 'api/generated/MNT';
import type { MealItem } from 'apiClients/mpq';

export const formatDraftItemFoodName = (draftItem: DraftItem | null) => {
  if (!draftItem?.item) {
    return '–';
  }
  const { item, queryText, searchItem } = draftItem;
  if (item.food_name_alias) {
    return `${item.food_name_alias} (${item.food_name})`;
  }
  return (
    item.food_name
    || queryText
    || searchItem?.name
    || '–'
  );
};

export type DraftItem = {
  id: number | null,
  item: MealItem,
  searchItem: FoodResponse | null,
  queryText: string | null,
  foodMatchDetails: CreateMealItemFoodMatchDetailsRequest | null,
  imLblMatchResult?: MPQImLblMatchResult,
  pushQuestionUpdate?: UpdateMealPushQuestionRequest | null,
};

export type MealItemFoodMatchDetailsSearch = {
  user_type: 'internal',
  source_name: 'search',
  source_details: {
    mixpanel: {
      Type: string,
      Source: MixpanelMealItemSource,
      'Food name': string,
      'Search text': string,
      Index: number,
      'Add or update': 'add' | 'update',
      Editor: string,
      'Custom item name'?: string,
      'Custom item source'?: string | null,
      'Engine sources'?: string,
    },
    nlp_selection?: FoodNlpPhraseMatchResult,
    nutrition_source?: {
      nutrition_source: string,
      nutrition_source_id: string | number,
    },
  },
};

export type MealItemFoodMatchDetailsNLP = {
  user_type: 'internal',
  source_name: 'nlp_note',
  source_details: {
    nlp_selection: FoodNlpPhraseMatchResult,
  },
};

export type MixpanelMealItemSource =
  | 'Search'
  | 'ElasticSearch'
  | 'ClientSearch'
  | 'Similar photo'
  | 'Clip similar'
  | 'Image rec'
  | 'OCR'
  | 'Meal notes'
  | 'User recent items'
  | 'User meal item history'
  | 'Recent meals'
  | 'User favorites'
  | 'Custom: baseless'
  | 'Custom: internal'
  | 'Custom: external'
  | 'External';

export type QueueDiff = {
  itemId: number,
  parentMealItem?: MealItem,
  itemType: 'mealItem' | 'addon',
  changeType: 'create' | 'update' | 'updateSizing' | 'updateAddonsOnly' | 'delete',
  before?: MealItem | MealItemCustomAddonResponse,
  after?: MealItem | MealItemCustomAddonResponse,
};

export type QueueDiffWithReason = QueueDiff & {
  reason: QA2ChangeReason,
};
