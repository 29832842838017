import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import { ChangeCircle, Dangerous, Delete, DragIndicator, Edit } from '@mui/icons-material';
import {
  Avatar,
  Button,
  CardContent,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { Droppable } from 'components/DragAndDrop';
import MainCard from 'components/MainCard';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import { useTable } from 'react-table';
import { DraftItem } from 'types/DraftItem';
import { useQueueItemEditor, useRelevantNutrients } from '../services/QueueItemEditorService';
import { FoodDrawer, useFoodDrawerState } from './FoodDrawer';
import { draftItemGetWarnings, MealItemEditor, MealItemEditorActions, useDragAndDropMealItems } from './MealBuilder';
import { ImLblMatchDetailsIcon } from './MealPhotoQueueImageAutomaticLabels';
import { MealPushQuestions } from './MealPushQuestions';
import { TableDataRow, useMealTable } from './MealSummary';
import { usePatientContext } from './usePatientContext';

export const InteractiveMealTable = React.memo((props: {
  onEditCallback?: () => void,
  onDeleteCallback?: (item: DraftItem) => void,
}) => {
  const editor = useQueueItemEditor();
  const { disabledDroppables, handleDragStart, handleDragEnd } = useDragAndDropMealItems();

  const patientContext = usePatientContext(editor.queueItem);
  const relevantNutrients = useRelevantNutrients({
    context: 'summary',
    patientContext: patientContext,
  });

  const {
    columns,
    tableData,
    summaryRows,
    getCellProps,
  } = useMealTable({
    draftItems: editor.draftItems,
    relevantNutrients,
    hideNameLink: true,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
  });

  const maxNameWidth = useMemo(() => {
    const longestName = rows.reduce((maxLength, row) => {
      const maxNameChars = Math.max(
        row.original.item.food_name.length,
        'food_name_alias' in row.original.item && row.original.item.food_name_alias?.length || 0,
      ) || 'draftItem' in row.original && (row.original.draftItem as any).queryText?.length || 0;
      return Math.max(maxLength, maxNameChars + 2); // add 2 to accommodate font weight
    }, 0);

    return Math.min(50, longestName);
  }, [rows]);

  if (editor.selectedItem) {
    return (
      <MainCard content={false} sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
        <FoodDrawer />
        <CardContent>
          <Stack spacing={2}>
            <MealItemEditor
              queueItem={editor.queueItem}
              draftItem={editor.selectedItem}
              preventWindowScroll
            />
          </Stack>
        </CardContent>
        <Divider />
        <MealItemEditorActions />
      </MainCard>
    );
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      autoScroll={{ layoutShiftCompensation: false, enabled: false }}
    >
      <MainCard content={false}>
        <TableContainer sx={{ maxHeight: '60vh', position: 'relative' }}>
          <Table {...getTableProps()} stickyHeader>
            <TableHead>
              {headerGroups.map((headerGroup: any) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column: any) => (
                    <TableCell
                      {...column.getHeaderProps([
                        { className: column.className },
                      ])}
                      key={column.getHeaderProps([
                        { className: column.className },
                      ]).key}
                      sx={{ position: 'sticky !important' }}
                    >
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} className="striped">
              {rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <DraggableRow
                    key={`drag${i}`}
                    cells={row.cells}
                    getCellProps={getCellProps}
                    disabledDroppables={disabledDroppables}
                    maxNameWidth={maxNameWidth}
                    {...props}
                  />
                );
              })}
              {summaryRows}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </DndContext>
  );
});

const DraggableRow = React.memo((props: {
  // draftItem: DraftItem,
  // dragAndDropIndex: number,
  disabledDroppables: string[],
  dragIsDisabled?: boolean,
  cells: any[],
  maxNameWidth: number,
  getCellProps: (ell: any, cellProps: any) => any,
  onEditCallback?: () => void,
  onDeleteCallback?: (item: DraftItem) => void,
}) => {
  const { disabledDroppables, dragIsDisabled, cells, getCellProps } = props;
  const editor = useQueueItemEditor();

  const rowData: TableDataRow = cells[0].row.original;
  const [draggableId, dragData, rowDragIsDisabled] = rowData.type === 'item'
    ? [
      `${rowData.draftItem.id || rowData.dragAndDropIndex}-draggable`,
      { item: rowData.draftItem },
      rowData.draftItem.item?.custom_item || !rowData.draftItem.item.food_name || dragIsDisabled,
    ]
    : rowData.type === 'addon'
    ? [`${rowData.item.food_name}-draggable`, {
      item: rowData.item,
      mealItem: rowData.mealItem,
      mealItemIndex: rowData.mealItemIndex,
    }, dragIsDisabled]
    : ['empty-row', {}, true];

  const [droppableAddonId, droppableAddonData, addonDroppablesIsDisabled] = rowData.type === 'item'
    ? rowData.draftItem
      ? [
        `${rowData.draftItem.id || rowData.dragAndDropIndex}-addon`,
        { mealItem: rowData.draftItem, mealItemIndex: rowData.dragAndDropIndex },
        disabledDroppables.includes('addon')
        || disabledDroppables.includes(`addonOf:${rowData.dragAndDropIndex}`)
        || disabledDroppables.includes(draggableId),
      ]
      : ['empty-row', {}, true]
    : ['not-a-meal-item', {}, true];

  const draggable = useDraggable({
    id: draggableId,
    data: dragData,
    disabled: rowDragIsDisabled,
  });

  const dragStyle = {
    transform: draggable.transform
      ? `translate3d(${draggable.transform.x}px, ${draggable.transform.y}px, 0)`
      : 'inherit',
    opacity: draggable.isDragging ? 0.5 : 1,
  };

  const droppable = useDroppable({
    id: droppableAddonId,
    data: droppableAddonData,
    disabled: addonDroppablesIsDisabled,
  });

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [actionButtonsTop, setActionButtonsTop] = useState<number>(0);

  const rowClickHandler = () => {
    handleEditDraftItem();
  };
  const rowEnterHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const row = e.currentTarget;
    const rowRect = row.getBoundingClientRect();
    setActionButtonsTop(rowRect.top);
    setShowActionButtons(true);
  };
  const rowLeaveHandler = useCallback(() => {
    setShowActionButtons(false);
  }, []);

  const handleEditDraftItem = () => {
    if (rowData.type === 'empty') {
      return;
    }
    editor.selectedItemSet(rowData.type === 'item' ? rowData.draftItem : rowData.mealItem);
    if (props.onEditCallback) {
      props.onEditCallback();
    }
  };

  const foodDrawer = useFoodDrawerState();
  const handleSwapDraftItem = () => {
    if (rowData.type === 'empty') {
      return;
    }
    if (rowData.type === 'item') {
      mixpanel.track('Meal item swap clicked', {
        'Food name': rowData.draftItem.item.food_name || rowData.draftItem.searchItem?.name
          || rowData.draftItem.queryText || '',
      });
      foodDrawer.setIsSwap(true);
      handleEditDraftItem();
    }
  };

  const handleDeleteDraftItem = () => {
    if (rowData.type === 'item') {
      editor.removeDraftItem(rowData.draftItem);
      if (props.onDeleteCallback) {
        props.onDeleteCallback(rowData.draftItem);
      }
    }
  };

  const relevantNutrients = useRelevantNutrients({
    context: 'item',
  });

  const itemWarnings = useMemo(() => {
    if (rowData.type === 'item') {
      return draftItemGetWarnings({ draftItem: rowData.draftItem, relevantNutrients });
    }
  }, [rowData, relevantNutrients]);

  const { foodLookupName, foodHistorySuggestions } = rowData.type === 'item'
    ? editor.getFoodHistorySuggestions(rowData.draftItem)
    : {
      foodLookupName: null,
      foodHistorySuggestions: null,
    };
  const { palette } = useTheme();

  // the empty row between food and beverage
  if (rowData.type == 'item' && !rowData.draftItem) {
    return (
      <TableRow>
        <TableCell colSpan={props.cells.length} />
      </TableRow>
    );
  }

  return (
    <>
      <TableRow
        ref={draggable.setNodeRef}
        style={{
          ...dragStyle,
        }}
        onClick={() => rowClickHandler()}
        onMouseEnter={(e) => rowEnterHandler(e)}
        onMouseLeave={() => rowLeaveHandler()}
      >
        {props.cells.map((cell: any, i) => {
          const _getCellProps = (cell: any, cellProps: any) => {
            return getCellProps ? getCellProps(cell, cellProps) : cellProps;
          };
          const cellProps = _getCellProps(cell, cell.getCellProps([{ className: cell.column.className }]));
          if (cell.column.id == 'Name') {
            return (
              <TableCell
                {...cellProps}
                key={cellProps.key}
                sx={{
                  minWidth: `${props.maxNameWidth}ch`,
                  position: 'relative',
                  boxSizing: 'content-box',
                }}
              >
                {foodHistorySuggestions && foodHistorySuggestions.length > 0 && (
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      width: 20,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: '2px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Avatar
                      sx={{
                        fontSize: '12px',
                        width: '16px',
                        height: '16px',
                        bgcolor: foodHistorySuggestions.some(mi => mi.inDrafts)
                          ? palette.success.main
                          : undefined,
                      }}
                      title="has recent history matches"
                    >
                      {Math.min(foodHistorySuggestions.length, 5)}
                    </Avatar>
                  </Paper>
                )}
                {!rowDragIsDisabled && !editor.mealDeleted && showActionButtons && (
                  <Paper
                    elevation={0}
                    square
                    sx={{
                      border: '1px solid #d9d9d9',
                      width: 20,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DragIndicator
                      {...draggable.listeners}
                      {...draggable.attributes}
                      sx={{ display: 'flex', alignItems: 'center', height: '100%', width: 20 }}
                    />
                  </Paper>
                )}
                <Paper
                  elevation={0}
                  square
                  ref={droppable.setNodeRef}
                  sx={{
                    background: addonDroppablesIsDisabled
                      ? 'transparent'
                      : droppable.isOver
                      ? 'lightgreen'
                      : 'lightgrey',
                    border: `1px ${
                      addonDroppablesIsDisabled ? 'solid transparent' : droppable.isOver ? 'solid green' : 'dotted grey'
                    }`,
                    fontWeight: rowData.type === 'item' ? 'bold' : 'inherit',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleEditDraftItem()}
                >
                  {!!itemWarnings && (
                    <Tooltip title={itemWarnings.message} arrow>
                      <Dangerous color="error" />
                    </Tooltip>
                  )}
                  {rowData.type !== 'empty'
                    && ((rowData.type === 'item' && rowData.draftItem?.item.food_name) || rowData.item?.food_name
                      ? cell.render('Cell')
                      : <em>{rowData.type === 'item' && rowData.draftItem?.queryText || '!!! no food !!!'}</em>)}
                  {rowData.type === 'item' && rowData.draftItem?.imLblMatchResult
                    && (
                      <Stack paddingLeft={0.5}>
                        <ImLblMatchDetailsIcon match={rowData.draftItem.imLblMatchResult} />
                      </Stack>
                    )}
                </Paper>
                {rowData.type === 'item' && !editor.mealDeleted && showActionButtons && (
                  <Paper
                    sx={{
                      position: 'fixed',
                      top: actionButtonsTop,
                      right: 55,
                      padding: '0.5rem',
                      zIndex: 10,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Stack spacing={2} direction="row">
                      <MealPushQuestions
                        queueItem={editor.queueItem}
                        draftItem={rowData.draftItem}
                      />
                      <Button
                        disabled={editor.mealDeleted}
                        variant="contained"
                        size="small"
                        onClick={() => handleEditDraftItem()}
                        startIcon={<Edit />}
                        sx={{ height: 24 }}
                      >
                        Edit
                      </Button>
                      <Button
                        disabled={editor.mealDeleted}
                        variant="contained"
                        size="small"
                        onClick={() => handleSwapDraftItem()}
                        startIcon={<ChangeCircle />}
                        sx={{ height: 24 }}
                      >
                        Swap
                      </Button>
                      <IconButton
                        disabled={editor.mealDeleted}
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteDraftItem()}
                        title="Remove"
                        sx={{ height: 24 }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Paper>
                )}
              </TableCell>
            );
          }
          if (cell.column.id == 'Sizing') {
            return (
              <TableCell {...cellProps} key={cellProps.key}>
                <Paper
                  elevation={0}
                  sx={{
                    background: 'transparent',
                    maxWidth: 100,
                  }}
                >
                  {cell.render('Cell')}
                </Paper>
              </TableCell>
            );
          }
          return (
            <TableCell {...cellProps} key={cellProps.key}>
              {cell.render('Cell')}
            </TableCell>
          );
        })}
      </TableRow>
      {rowData.type === 'item' && (
        <TableRow>
          <TableCell colSpan={props.cells.length} sx={{ padding: '0 !important' }}>
            <Droppable
              id={`${rowData.draftItem.id || rowData.dragAndDropIndex}-after`}
              data={{ dropBefore: rowData.draftItem }}
              disabled={disabledDroppables.includes('mealItem')}
              style={{ marginTop: -1, marginBottom: -1 }}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
});
