import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { NutrientValue } from 'utils/mealItems';

/**
 * Displays the value of a nutrient, with a warning tooltip if there are any warnings.
 *
 * For use with `mealItemGetNutrientValue`.
 *
 * Example::
 *
 *   const ShowNutrientValue = (props: {
 *     item: MealItem | MealItemCustomAddonResponse,
 *     nutrient: keyof UsdaNutritionResponse,
 *   }) => {
 *     const details = useFoodDetails(props.item.food_name);
 *     const value = details.isLoading ? LOADING_NUTRIENT_VALUE : mealItemGetNutrientValue({
 *       item: props.item,
 *       foodNutrients: details.usda_nutrition,
 *       nutrient: props.nutrient,
 *     });
 *
 *     console.log(`The value of ${value.def.label}: is ${value.valueStr} (warnings: ${value.warnings.join(', ')})`);
 *
 *     return <MealItemNutrientValue value={value} />;
 *   };
 */
export const MealItemNutrientValue = (props: {
  value: NutrientValue,
  suppressWarnings?: boolean,
}) => {
  const { value, suppressWarnings } = props;
  if ((!value.warnings.length || suppressWarnings) && !value.info.length) {
    return <span>{value.valueStr ?? '–'}</span>;
  }

  // Include warnings first, then info
  if (value.warnings.length && !suppressWarnings) {
    return (
      <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{value.warnings.concat(value.info).join('\n')}</span>}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {value?.valueStr ?? '–'} ⚠️
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{value.info.join('\n')}</span>}>
      <span style={{ whiteSpace: 'nowrap' }}>
        {value?.valueStr ?? '–'} <Info color="info" fontSize="small" />
      </span>
    </Tooltip>
  );
};
