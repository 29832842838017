import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { FoodSearchInput } from 'services/foodSearch/FoodSearchInput';
import {
  FOOD_ENGINE_MAX_RESULTS,
  FoodSearchContext,
  useExternalFoodEngineAvailableSources,
  useFoodSearch,
} from '../services/foodSearch/useFoodSearch';
import { FoodSearchResults } from './QueueItem/meal-builder/FoodDrawer';

export const FoodEnginePage = () => {
  const [searchContext, setSearchContext] = useState<FoodSearchContext>({});
  const availableSources = useExternalFoodEngineAvailableSources();
  const foodSearch = useFoodSearch({
    context: searchContext,
    limit: FOOD_ENGINE_MAX_RESULTS,
  });

  return (
    <MainCard>
      <Stack direction="column" spacing={2} flexGrow={1}>
        <Stack direction="row" spacing={2} flexGrow={1}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Meal</InputLabel>
            <Select
              value={searchContext.context_meal_name}
              label="Meal"
              onChange={(event) => {
                setSearchContext({
                  ...searchContext,
                  context_meal_name: event.target.value as string,
                });
              }}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value="breakfast">Breakfast</MenuItem>
              <MenuItem value="lunch">Lunch</MenuItem>
              <MenuItem value="dinner">Dinner</MenuItem>
              <MenuItem value="snack">Snack</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Addon of (food name)</InputLabel>
            <OutlinedInput
              value={searchContext.context_addon_of}
              label="Addon of (food name)"
              onChange={(event) => {
                setSearchContext({
                  ...searchContext,
                  context_addon_of: event.target.value as string,
                });
              }}
            />
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Patient ID</InputLabel>
            <OutlinedInput
              value={searchContext.context_user_id}
              label="Patient ID"
              onChange={(event) => {
                setSearchContext({
                  ...searchContext,
                  context_addon_of: event.target.value as string,
                });
              }}
            />
          </FormControl>

          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Source (FE)</InputLabel>
            <Select
              value={searchContext.fe_sources ?? []}
              renderValue={(selected) => selected.join(', ')}
              label="Source (FE)"
              multiple
              onChange={(event) => {
                const value = typeof event.target.value === 'string'
                  ? event.target.value.split(',')
                  : event.target.value;
                setSearchContext({
                  ...searchContext,
                  fe_sources: value,
                });
              }}
            >
              {availableSources.data?.map(source => (
                <MenuItem
                  key={source.id}
                  value={source.id}
                >
                  <Checkbox checked={searchContext.fe_sources?.includes(source.id)} />
                  <ListItemText primary={source.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <FoodSearchInput foodSearch={foodSearch} fullWidth />
      </Stack>
      <Box sx={{ mt: 1 }} />
      <FoodSearchResults
        foodSearch={foodSearch}
        onFoodSelect={() => {}}
        onExternalSelect={() => {}}
      />
    </MainCard>
  );
};
