import { Alert, AlertTitle, Box, Button, Drawer, Stack, TextField, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { foodApi, mealApi } from 'api';
import { FoodDatabaseTable } from 'components/FoodDatabaseTable';
import MainCard from 'components/MainCard';
import { useSearchParamsDebounce } from 'components/RxTable';
import { SpellCheck } from 'components/spell-check';
import { logTrackedError } from 'errorTracking';
import apiClient, { apiFoodResponseToFoodResponse } from 'food-editor/api-client';
import {
  useFoodEditorWhiteboardService,
  UsersOnCurrentPageWarning,
  Whiteboard,
} from 'food-editor/components/food-editor';
import { apiRenameFood } from 'food-editor/hooks/use-rename-food-mutation';
import { FoodEditorFoodRequest, FoodEditorValue, QueryKey } from 'food-editor/types';
import emptyFoodEditorValue from 'food-editor/utils/empty-food-editor-value';
import foodEditorValueToFoodRequest from 'food-editor/utils/food-editor-value-to-food-request';
import foodResponseToFoodEditorValue from 'food-editor/utils/food-response-to-food-editor-value';
import { maybe } from 'food-editor/utils/utils';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { getFoodDetailsQueryKey, useFoodDetails } from 'services/FoodDetailsService';
import { parseQueryError } from 'utils';
import { telemetrySend } from 'utils/telemetry';
import { FocusedViewHeader } from './TriageFoodTablePage';

const ScrapeFocusedViewEditor = (props: {
  originalName: string,
  foodEditorValue: FoodEditorValue,
  setFoodEditorValue: (value: FoodEditorValue) => void,
  handleSubmit: () => void,
  submitMutation: Pick<UseMutationResult, 'isLoading' | 'isSuccess' | 'isError'>,
  whiteboardService: ReturnType<typeof useFoodEditorWhiteboardService>,
  onSelectBaseItemFoodId: (baseItem: string | null) => void,
}) => {
  const { originalName, foodEditorValue } = props;
  const [newFoodName, setNewFoodName] = useState(originalName);
  const [isDuplicate, setDuplicateError] = useState(false);

  const relevantQueues = useQuery(['relevant_queues', originalName], async () => {
    if (!originalName) {
      return {
        queues: [],
      };
    }

    const queueRes = await foodApi.appApiFoodFoodWhiteboardGetRelevantQueuesQuery({
      food_name: originalName,
    }).then(res => res.data);

    return {
      queues: queueRes,
    };
  }, { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false });

  const checkDuplicate = useQuery(
    ['check-duplicate', newFoodName],
    async () => {
      const res = await foodApi.appApiFoodFoodSearchGetFoodQuery({
        food_name: newFoodName,
      });
      setDuplicateError(res.data.name == newFoodName);
      return res;
    },
    {
      enabled: !!foodEditorValue.term,
    },
  );

  const mealItemsQuery = useQuery(['meal_items', originalName], async () => {
    if (!originalName) {
      return {
        items: [],
      };
    }

    const itemRes = await mealApi.appApiMealGetMealItemsByFoodName({
      food_name: originalName,
    }).then(res => res.data);

    return {
      items: itemRes || [],
    };
  });

  const saveFoodNameOnBlur = (foodName: string) => {
    props.setFoodEditorValue({
      ...foodEditorValue,
      term: foodName,
    });
    setDuplicateError(false);
    checkDuplicate.refetch();
  };

  const foodDidRename = foodEditorValue.term && originalName != foodEditorValue.term;
  const numMealItems = mealItemsQuery.data?.items.length;

  if (!originalName) {
    return <span>Loading...</span>;
  }

  return (
    <Box padding="5%">
      <Stack direction="row" gap={1} alignItems="center">
        <UsersOnCurrentPageWarning />
        <Typography variant="h2" marginBottom="5%">Scrape: {originalName}</Typography>
      </Stack>
      <FocusedViewHeader
        relevantQueuesQuery={relevantQueues}
        originalName={originalName}
        onSelectBaseItemFoodId={props.onSelectBaseItemFoodId}
      />
      <Box paddingTop="5%">
        <Stack>
          <Typography variant="h4" marginBottom="5px">
            Confirm food name
          </Typography>
          <TextField
            value={newFoodName}
            onBlur={(evt) => saveFoodNameOnBlur(evt.target.value)}
            onChange={(evt) => setNewFoodName(evt.target.value)}
            inputProps={{ maxLength: 255 }}
          />
          <SpellCheck
            term={foodEditorValue.term}
            updateSpelling={(correctSpelling) => {
              setNewFoodName(correctSpelling);
              saveFoodNameOnBlur(correctSpelling);
            }}
          />
          <Typography variant="h4" paddingTop="5%" marginBottom="5px">
            Whiteboard
          </Typography>
          <Box justifyContent="center">
            <Whiteboard
              whiteboardService={props.whiteboardService}
              mode="update"
              onComparisonItemSelected={() => {}}
              onProductImageSelected={() => {}}
              onProductUrlSelected={() => {}}
              onlyShowCopyPaste
            />
          </Box>
          <Stack style={{ paddingTop: 50 }} spacing={2}>
            {foodDidRename && (
              <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                This will rename {numMealItems} meal item(s) from <strong>{originalName}</strong> to{' '}
                <strong>{foodEditorValue.term}</strong>
              </Alert>
            )}
            {!foodEditorValue.term && <Alert severity="error">Missing Food Name</Alert>}
            {isDuplicate && <Alert severity="error">Duplicate Term</Alert>}
            <Button
              variant="contained"
              type="submit"
              style={{ height: 50 }}
              onClick={props.handleSubmit}
              disabled={props.submitMutation.isLoading || isDuplicate || !foodEditorValue.term}
            >
              Finish Scraping
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

const useFoodScrapeProcess = () => {
  const queryClient = useQueryClient();
  const [q, setQ] = useSearchParamsDebounce();
  const currentFood = q['scrape-food-name'] ?? '';
  const [foodEditorValue, setEditorValue] = useState<FoodEditorValue>(emptyFoodEditorValue());

  const getFoodQuery = useFoodDetails(currentFood, {
    refetchOnMount: true,
  });

  useEffect(() => {
    if (getFoodQuery.query.isSuccess) {
      setEditorValue(foodResponseToFoodEditorValue(apiFoodResponseToFoodResponse(getFoodQuery)));
    }
  }, [getFoodQuery.query.isSuccess]);

  const telemetryRef = React.useRef({
    startTime: 0,
    key: '',
  });

  useEffect(() => {
    const now = Date.now();
    telemetryRef.current = {
      startTime: now,
      key: `${currentFood}:${now}`,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFood]);

  const setCurrentFood = (foodName: string) => {
    if (!foodName) {
      setQ(prev => (
        {
          ...(Object.keys(prev).filter(param => param != 'scrape-food-name').reduce(
            (acc, key) => ({ ...acc, [key]: prev[key] }),
            {},
          )),
        }
      ));
      return;
    }

    setQ(prev => ({ ...prev, 'scrape-food-name': foodName }));
  };

  const whiteboardService = useFoodEditorWhiteboardService({
    term: currentFood,
    ndbNumber: foodEditorValue.ndbNumber,
  });

  const sendTelemetryResult = () => {
    const durationSeconds = (Date.now() - telemetryRef.current.startTime) / 1000;
    const whiteboardItemTypes = whiteboardService.whiteboardItems
      .filter(item => item.text || item.imageDataUri || item.imageUri)
      .map(item => item.type);
    mixpanel.track('CPG Food Scrape: submit', {
      'Duration (s)': durationSeconds,
      'Food name': foodEditorValue.term,
      'Food name (original)': currentFood,
      'Food name changed?': currentFood != foodEditorValue.term,
      'Whiteboard item count': whiteboardItemTypes.length,
    });
    telemetrySend({
      name: 'CPGFoodScraped',
      key: telemetryRef.current.key,
      value: durationSeconds,
      properties: {
        duration: durationSeconds,
        food_name: foodEditorValue.term,
        food_name_original: currentFood,
        whiteboard_item_count: whiteboardItemTypes.length,
        whiteboard_items: whiteboardItemTypes,
      },
    });
  };

  const submitMutation = useMutation({
    mutationFn: async (args: {
      term: string,
      foodRequest: FoodEditorFoodRequest,
    }) => {
      const updateRes = await apiClient.updateFood(args.term, args.foodRequest);
      if (currentFood && currentFood != foodEditorValue.term) {
        const [_, err] = await maybe(
          apiRenameFood({ oldFoodName: currentFood, newFoodName: foodEditorValue.term.toLowerCase() }),
        );
        if (err) {
          logTrackedError({
            sourceName: 'FoodEditor.submit:apiRenameFood',
            origin: err,
            stackError: new Error(),
            context: { currentFood, newFoodName: foodEditorValue.term },
            userMessage: `Food created but error renaming existing custom items.`,
          });
        }
      }
      return updateRes;
    },
    onSuccess: foodResponse => {
      queryClient.setQueryData([QueryKey.Food, foodResponse.term], foodResponse);
      queryClient.resetQueries(getFoodDetailsQueryKey(foodResponse.term), { exact: true });
      setCurrentFood('');
      queryClient.refetchQueries(['food-database-table']);
    },
  });

  const handleSubmit = () => {
    sendTelemetryResult();
    submitMutation.mutate({
      term: currentFood ? currentFood.toLowerCase() : foodEditorValue.term.toLowerCase(),
      foodRequest: foodEditorValueToFoodRequest({ ...foodEditorValue, status: 'draft' }),
    });
  };

  return {
    component: (
      <>
        <ScrapeFocusedViewEditor
          whiteboardService={whiteboardService}
          originalName={currentFood}
          foodEditorValue={foodEditorValue}
          setFoodEditorValue={setEditorValue}
          handleSubmit={handleSubmit}
          submitMutation={submitMutation}
          onSelectBaseItemFoodId={() => {/* XXX TODO XXX */}}
        />
        {submitMutation.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(submitMutation.error)}
          </Alert>
        )}
      </>
    ),
    currentFood,
    setCurrentFood,
  };
};

export const ScrapeFoodTablePage = () => {
  const foodScrapeProcess = useFoodScrapeProcess();
  const openDrawer = !!foodScrapeProcess.currentFood;

  const handleDrawerClose = () => {
    if (!window.confirm('Are you sure you want to exit? Changes will not be saved')) {
      return;
    }
    foodScrapeProcess.setCurrentFood('');
  };

  const drawerStyle = {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '75vw',
      minWidth: 700,
      borderRight: `1px solid #e0e0e0`,
      backgroundImage: 'none',
      borderLeft: `1px solid #e0e0e0`,
      boxShadow: '8',
    },
  };

  return (
    <MainCard>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerClose}
        hideBackdrop={false}
        transitionDuration={100}
        sx={drawerStyle}
      >
        {foodScrapeProcess.component}
      </Drawer>
      <FoodDatabaseTable onFoodClick={foodScrapeProcess.setCurrentFood} />
    </MainCard>
  );
};
