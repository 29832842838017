import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { PropsOf } from '@emotion/react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Launch } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { dataReviewApi, foodApi, mealApi } from 'api';
import {
  FoodDetailsUpdateRequest,
  FoodOptionCollection,
  MealItemResponse,
  MealPhotoQueueResponse,
} from 'api/generated/MNT';
import { MealItem } from 'apiClients/mpq';
import { FoodDatabaseTable } from 'components/FoodDatabaseTable';
import { FoodOptionEditor } from 'components/FoodOptions';
import MainCard from 'components/MainCard';
import { useSearchParamsDebounce } from 'components/RxTable';
import { SpellCheck } from 'components/spell-check';
import { useAuth } from 'context/appContext';
import { logTrackedError } from 'errorTracking';
import apiClient, { apiFoodResponseToFoodResponse, foodRequestToApiFoodRequest } from 'food-editor/api-client';
import {
  getEmptyWhiteboardItem,
  searchLinkData,
  useFoodEditorWhiteboardService,
  UsersOnCurrentPageWarning,
  Whiteboard,
  WhiteboardItemData,
} from 'food-editor/components/food-editor';
import OntologyPicker, { useOntology } from 'food-editor/components/ontology-picker';
import { apiRenameFood } from 'food-editor/hooks/use-rename-food-mutation';
import { FoodEditorFoodRequest, FoodEditorValue, QueryKey } from 'food-editor/types';
import emptyFoodEditorValue from 'food-editor/utils/empty-food-editor-value';
import foodEditorValueToFoodRequest from 'food-editor/utils/food-editor-value-to-food-request';
import { maybe } from 'food-editor/utils/utils';
import mixpanel from 'mixpanel-browser';
import { MealPhoto } from 'pages/QueueItem/meal-builder/MealPhoto';
import { MealSummary } from 'pages/QueueItem/meal-builder/MealSummary';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFoodDetailsQueryKey, useFoodDetails } from 'services/FoodDetailsService';
import {
  CustomItemSource,
  externalFoodSearchResultFoodId,
  foodIdSplit,
  foodIdToCustomItemSource,
  foodSearchSelectionFoodId,
  getCustomItemSourceLink,
  itemCustomItemFoodId,
  useFoodEngineFood,
} from 'services/FoodEngineService';
import { FoodSearchInput } from 'services/foodSearch/FoodSearchInput';
import { useFoodSearch } from 'services/foodSearch/useFoodSearch';
import { isExternal } from 'util/types';
import { parseQueryError } from 'utils';
import { round } from 'utils/numerical';
import { telemetrySend } from 'utils/telemetry';
import { FoodDrawer, FoodSearchResults } from './QueueItem/meal-builder/FoodDrawer';
import { AddonScaleTextField, MealItemAddonsEditor, MealItemEditor } from './QueueItem/meal-builder/MealBuilder';
import { usePatientContext } from './QueueItem/meal-builder/usePatientContext';
import { QueueItemEditorProvider } from './QueueItem/QueueItemPage';
import { useQueueItemEditor } from './QueueItem/services/QueueItemEditorService';

const TRIAGE_MEAL_ITEM_FLAGS = {
  exist: false,
  alias: false,
  misid: false,
  other: false,
};

const TRIAGE_MEAL_ITEM_FLAG_LABEL = {
  'exist': 'Item exists in database',
  'alias': 'Item should have been aliased',
  'misid': 'Item was misidentified',
  'other': 'Other',
};

type TriageMealItemFlags = typeof TRIAGE_MEAL_ITEM_FLAGS;

type TriageMealItemResolution = {
  resolution?: 'flag' | 'rename',
  mealItem?: MealItem,
  flags?: TriageMealItemFlags,
  note?: string,
};

// Embedding <em /> can make the text appear slightly too close to the adjacent
// text (maybe because of the font?). This fixes that.
const EMPAD: React.CSSProperties = {
  marginLeft: 2,
  marginRight: 3,
};

const TriageFocusedViewEditor = (props: {
  triage: UseTriageCustomItem,
}) => {
  const { triage } = props;
  const { originalName, foodEditorValue } = triage;
  const [newFoodName, setNewFoodName] = useState(originalName);
  const [isDuplicate, setDuplicateError] = useState(false);
  const ontology = useOntology(triage.foodEditorValue.ontologyLevels);

  const relevantQueues = useQuery(['relevant_queues', originalName], async () => {
    if (!originalName) {
      return {
        queues: [],
      };
    }

    const queueRes = await foodApi.appApiFoodFoodWhiteboardGetRelevantQueuesQuery({
      food_name: originalName,
    }).then(res => res.data.reverse());

    return {
      queues: queueRes,
    };
  }, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const items = useTriageRelevantMealItems({
    relevantQueuesQuery: relevantQueues,
    originalName: triage.originalName,
  });

  const mealItemsQuery = useQuery(['meal_items', originalName], async () => {
    if (!originalName) {
      return {
        items: [],
      };
    }

    const itemRes = await mealApi.appApiMealGetMealItemsByFoodName({
      food_name: originalName,
    }).then(res => res.data);

    return {
      items: itemRes || [],
    };
  });

  const checkDuplicate = useQuery(
    ['check-duplicate', foodEditorValue.term],
    async () => {
      const res = await foodApi.appApiFoodFoodSearchGetFoodQuery({
        food_name: foodEditorValue.term,
      });
      setDuplicateError(res.data.name == newFoodName);
      return res;
    },
    {
      enabled: !!foodEditorValue.term,
    },
  );

  const saveFoodNameOnBlur = (foodName: string) => {
    triage.setFoodEditorValue({
      ...foodEditorValue,
      term: foodName,
    });
    setDuplicateError(false);
    checkDuplicate.refetch();
  };

  const foodOptionEditorOnChange = useCallback((optionId: string, value: string) => {
    const newOptionValuesRaw = { ...foodEditorValue.optionValuesRaw };
    newOptionValuesRaw[optionId] = value;
    triage.setFoodEditorValue({
      ...foodEditorValue,
      optionValuesRaw: newOptionValuesRaw,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodEditorValue, props]);

  useEffect(() => {
    triage.setFoodEditorValue({
      ...foodEditorValue,
      adultServingSizeG: '' + ontology?.adult_serving_size_g || '',
      ghgEquivalentKg: '' + ontology?.ghg_equivalent_kg || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ontology]);

  const foodDidRename = originalName != foodEditorValue.term;
  const numMealItems = mealItemsQuery.data?.items.length;

  return (
    <Stack style={{ padding: 20 }} spacing={3}>
      <Stack direction="row" gap={1} alignItems="center">
        <UsersOnCurrentPageWarning />
        <Typography variant="h2">Triage: {originalName}</Typography>
      </Stack>
      <FocusedViewHeader
        relevantQueuesQuery={relevantQueues}
        originalName={originalName}
        onSelectBaseItemFoodId={(foodId) => {
          triage.setBaseItemFoodId(foodId);
        }}
      />
      {items.length > 0 && (
        <Stack>
          {items.map(({ queue, item }, index) => (
            <Alert severity="info" key={item.id}>
              <strong>Source:</strong> queue #{queue.id}:{' '}
              <FocusedViewCustomItemSourceLink
                item={item}
                onClick={() => triage.setBaseItemFoodId(itemCustomItemFoodId(item))}
              />
            </Alert>
          ))}
        </Stack>
      )}
      {triage.baseItemFoodId && (
        <Alert
          severity="info"
          action={
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                triage.setBaseItemFoodId(null);
                triage.resetWhiteboardItems();
              }}
            >
              Clear
            </Button>
          }
        >
          <strong>Selected:</strong>&nbsp;
          <FocusedViewSourceItemLink source={triage.baseItem} />
        </Alert>
      )}
      <Stack direction="row" spacing={2}>
        <TriageToggleButton
          color="success"
          selected={triage.selectedResolution == 'accepted'}
          fullWidth
          style={{ height: 50 }}
          onClick={() => {
            triage.setSelectedResolution('accepted');
          }}
        >
          Approve Custom
        </TriageToggleButton>
        <TriageToggleButton
          color="warning"
          selected={triage.selectedResolution == 'flagged'}
          fullWidth
          style={{ height: 50 }}
          onClick={() => {
            triage.setSelectedResolution('flagged');
          }}
        >
          Flag Custom
        </TriageToggleButton>
      </Stack>
      <Box>
        {triage.selectedResolution == 'accepted' && (
          <Stack spacing={1}>
            <Typography variant="h4" marginBottom="5px">
              Confirm food name
            </Typography>
            <TextField
              value={newFoodName}
              onBlur={(evt) => saveFoodNameOnBlur(evt.target.value)}
              label="Food Name"
              variant="standard"
              onChange={(evt) => setNewFoodName(evt.target.value)}
              inputProps={{ maxLength: 255 }}
              error={!foodEditorValue.term}
              helperText={!foodEditorValue.term ? 'Food name is required' : ''}
            />
            <SpellCheck
              term={foodEditorValue.term}
              updateSpelling={(correctSpelling) => {
                setNewFoodName(correctSpelling);
                saveFoodNameOnBlur(correctSpelling);
              }}
            />
            <OntologyPicker
              value={foodEditorValue.ontologyLevels}
              onChangeValue={(ontology) => {
                triage.setFoodEditorValue({ ...foodEditorValue, ontologyLevels: ontology });
              }}
              reducePopperHeight
            />
            {!!+foodEditorValue.adultServingSizeG && (
              <Typography>Adult serving size: {foodEditorValue.adultServingSizeG} g</Typography>
            )}
            {!!+foodEditorValue.ghgEquivalentKg && (
              <Typography>GHG equivalent: {foodEditorValue.ghgEquivalentKg} kg</Typography>
            )}
            {foodEditorValue.optionCollection
              ? (
                <FoodOptionEditor
                  schema={foodEditorValue.optionCollection}
                  values={foodEditorValue.optionValuesRaw || {}}
                  onChange={foodOptionEditorOnChange}
                  hideDebugOption
                />
              )
              : <div>Options not available (no collection included)</div>}
            <Typography variant="h4" marginBottom="5px">
              Whiteboard
            </Typography>
            <Box justifyContent="center">
              <Whiteboard
                whiteboardService={triage.whiteboardService}
                foodResponseCallbackRef={triage.foodResponseCallbackRef}
                mode="create"
                onComparisonItemSelected={() => {}}
                onProductImageSelected={() => {}}
                onProductUrlSelected={() => {}}
                onlyShowCopyPaste
              />
            </Box>
            <Stack style={{ paddingTop: 20 }} spacing={2}>
              {foodDidRename && (
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  This will rename {numMealItems} meal item(s) from <strong>{originalName}</strong> to{' '}
                  <strong>{foodEditorValue.term}</strong>
                </Alert>
              )}
              {!foodEditorValue.term && <Alert severity="error">Missing Food Name</Alert>}
              {!(foodEditorValue.optionValuesRaw && 'preparation.method' in foodEditorValue.optionValuesRaw) && (
                <Alert severity="error">Missing Preparation Method</Alert>
              )}
              {isDuplicate && <Alert severity="error">Duplicate Term</Alert>}
              {foodEditorValue.ontologyLevels.length == 0 && <Alert severity="error">Missing Ontology</Alert>}
              <Button
                variant="contained"
                type="submit"
                style={{ height: 50 }}
                onClick={triage.handleSubmitApprove}
                disabled={triage.approveMutation.isLoading || !foodEditorValue.term || isDuplicate
                  || foodEditorValue.ontologyLevels.length == 0
                  || !(foodEditorValue.optionValuesRaw && 'preparation.method' in foodEditorValue.optionValuesRaw)}
              >
                Create food
              </Button>
            </Stack>
          </Stack>
        )}
        {triage.selectedResolution == 'flagged' && (
          <Box>
            <Stack style={{ width: '100%' }}>
              {relevantQueues.isLoading && <Typography>Loading...</Typography>}
              {relevantQueues.isError && (
                <Alert severity="error" style={{ maxWidth: '50%' }}>
                  {parseQueryError(relevantQueues.error)}
                </Alert>
              )}
              {relevantQueues.data?.queues.length == 0 && (
                <Typography>
                  No relevant queues found, cannot flag custom
                </Typography>
              )}
              {relevantQueues.data?.queues?.map((q, idx) => (
                <div key={idx} style={{ marginBottom: 50 }}>
                  <QueueItemEditorProvider queueItemId={'' + q.id}>
                    <FlagMealItem
                      triage={triage}
                      queue={q}
                    />
                  </QueueItemEditorProvider>
                </div>
              ))}
              {triage.flagForQaMutation.isError && (
                <Alert severity="error" style={{ maxWidth: '50%' }}>
                  {parseQueryError(triage.flagForQaMutation.error)}
                </Alert>
              )}
              <Button
                variant="contained"
                type="submit"
                style={{ height: 50, width: '100%' }}
                onClick={triage.handleSubmit}
                disabled={triage.flagForQaMutation.isLoading
                  || Object.keys(triage.queueCallbacksRef.current || {}).length == 0}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const FocusedViewFoodSearch = (props: {
  originalName: string,
  onSelectFoodId: (foodId: string) => void,
}) => {
  const { originalName } = props;

  const foodSearch = useFoodSearch({ context: {}, limit: 30 });
  useEffect(() => {
    foodSearch.setActiveSearch({ type: 'external', text: originalName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalName]);

  return (
    <Stack width="60%" flex={1} minHeight="25vh" spacing={1}>
      <FoodSearchInput foodSearch={foodSearch} />
      <Stack direction="row" spacing={1}>
        {searchLinkData.map(linkData => (
          <Button
            key={linkData.label}
            component="a"
            variant="outlined"
            href={linkData.url(foodSearch.activeSearch.text)}
            target="_blank"
            onClick={() => {
              mixpanel.track('Food editor: external search clicked', { Engine: linkData.label });
            }}
            endIcon={<Launch fontSize="small" />}
          >
            {linkData.label}
          </Button>
        ))}
      </Stack>
      <Box sx={{ position: 'relative', flex: 1 }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflowY: 'scroll' }}>
          <FoodSearchResults
            foodSearch={foodSearch}
            onFoodSelect={(result) => {
              props.onSelectFoodId(foodSearchSelectionFoodId(result));
            }}
            onExternalSelect={(result) => {
              props.onSelectFoodId(externalFoodSearchResultFoodId(result));
            }}
            showFoodLinks
          />
        </div>
      </Box>
    </Stack>
  );
};

const TriageToggleButton = (
  props: {
    selected: boolean,
  } & PropsOf<typeof Button>,
) => {
  const { selected, children, ...rest } = props;
  return (
    <Button
      variant={props.selected ? 'contained' : 'outlined'}
      fullWidth
      {...rest}
    >
      {children}
    </Button>
  );
};

const FlagMealItem = (props: {
  triage: UseTriageCustomItem,
  queue: MealPhotoQueueResponse,
}) => {
  const { queue, triage } = props;
  const [resolution, setResolution] = triage.useQueueResolution(queue.id);

  const {
    query,
    queueItem,
    draftItems,
    selectedItemSet,
    saveChanges,
    selectedItem,
    updateDraftItemMealItem,
  } = useQueueItemEditor();

  useEffect(() => {
    const draftItemMatch = draftItems.find(item => item.item.food_name == triage.originalName) ?? null;
    if (draftItemMatch) {
      selectedItemSet(draftItemMatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftItems, triage.originalName]);

  useEffect(() => {
    if (selectedItem) {
      setResolution({
        ...(resolution ?? {}),
        mealItem: selectedItem.item,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const patientContext = usePatientContext(queueItem);

  triage.queueCallbacksRef.current[queue.id] = async () => {
    await saveChanges();
  };
  useEffect(() => {
    return () => {
      triage.queueCallbacksRef.current[queue.id] = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubmitButtonChangeText = () => (
    <>
      <span style={{ whiteSpace: 'nowrap' }}>
        <em style={EMPAD}>{triage.originalName}</em>
      </span>{' '}
      {!!selectedItem?.item && !selectedItem?.item?.custom_item && (
        <>
          &rarr;{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            <em style={EMPAD}>{selectedItem?.item?.food_name}</em>
          </span>
        </>
      )}
    </>
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h3">
          Queue #{queue.id}
        </Typography>
        <Link
          style={{ cursor: 'pointer' }}
          to={`/queue-item/${queue.id}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            color="black"
            style={{ width: '1.5rem', height: '1.5rem' }}
          />
        </Link>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack width="40%">
          <MealPhoto queueItem={queue} />
          <Typography>
            <b>Meal Note:</b> {queue.patient_note ?? 'No patient note provided'}
          </Typography>
        </Stack>
        {(query.isLoading || !selectedItem) && <Typography>Loading meal items...</Typography>}
        {query.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(query.error)}
          </Alert>
        )}
        {(query.isSuccess && !!selectedItem) && (
          <div style={{ width: '60%' }}>
            <FoodDrawer
              {
                /* Note: to prevent the food drawer from adding items to the meal
                instead of replacing the current item, disable adding from user recents.
                This is not ideal - ideally there would be a flag which ensured the
                selection will only replace the current item - but that took more time
                than I have right now. */
                ...{ disableUserRecents: true }
              }
            />
            <MealItemEditor
              queueItem={queueItem}
              draftItem={selectedItem}
              preventWindowScroll
            />
          </div>
        )}
      </Stack>
      {query.isSuccess && !!selectedItem && (
        <MealSummary patientContext={patientContext} queue={queueItem} draftItems={[selectedItem]} />
      )}
      <Box>
        <Typography>
          <b>Flags:</b>
        </Typography>
        <FormGroup>
          {Object.entries(TRIAGE_MEAL_ITEM_FLAG_LABEL).map(([flag, text]) => {
            return (
              <FormControlLabel
                key={flag}
                control={
                  <Checkbox
                    checked={!!resolution?.flags?.[flag as keyof TriageMealItemFlags]}
                    onClick={() =>
                      setResolution({
                        ...resolution,
                        resolution: resolution?.resolution ?? (
                          triage.originalName == selectedItem?.item?.food_name ? 'flag' : undefined
                        ),
                        flags: {
                          ...TRIAGE_MEAL_ITEM_FLAGS,
                          ...resolution?.flags ?? {},
                          [flag as keyof TriageMealItemFlags]: !resolution?.flags?.[flag as keyof TriageMealItemFlags],
                        },
                      })}
                  />
                }
                label={text}
              />
            );
          })}
        </FormGroup>
        {resolution?.flags?.other && (
          <TextField
            value={resolution?.note ?? ''}
            placeholder="Leave a note for QA"
            fullWidth
            multiline
            minRows={3}
            onChange={(evt) =>
              setResolution({
                ...resolution,
                note: evt.target.value,
              })}
          />
        )}
      </Box>

      <Stack direction="row" spacing={2}>
        <TriageToggleButton
          color="success"
          selected={resolution?.resolution == 'rename'}
          disabled={!selectedItem?.item || !!selectedItem?.item?.custom_item}
          onClick={() => setResolution({ ...resolution, resolution: 'rename' })}
        >
          <span>Change {getSubmitButtonChangeText()}</span>
        </TriageToggleButton>
        <TriageToggleButton
          color="warning"
          selected={resolution?.resolution == 'flag'}
          disabled={!selectedItem?.item || !Object.values(resolution?.flags ?? {}).some(flag => flag)}
          onClick={() => setResolution({ ...resolution, resolution: 'flag' })}
        >
          <span>
            Flag {!!selectedItem?.item && (
              <em style={{ whiteSpace: 'nowrap', ...EMPAD }}>
                {selectedItem?.item?.food_name}
              </em>
            )} for QA 2{!!selectedItem?.item?.food_name
              && selectedItem?.item?.food_name != triage.originalName && (
              <>
                , and<br />
                change {getSubmitButtonChangeText()}
              </>
            )}
          </span>
        </TriageToggleButton>
      </Stack>
    </Stack>
  );
};

const useTriageRelevantMealItems = (props: {
  relevantQueuesQuery: UseQueryResult<{
    queues: MealPhotoQueueResponse[],
  }, unknown>,
  originalName: string,
}) => {
  return React.useMemo(() => {
    const mealItems = [] as {
      queue: MealPhotoQueueResponse,
      item: MealItemResponse,
    }[];

    props.relevantQueuesQuery.data?.queues.forEach(queue => {
      const items = queue.existing_items?.filter(item => item.food_name == props.originalName) ?? [];
      items.forEach(item => {
        mealItems.push({ queue, item });
      });
    });
    return mealItems;
  }, [props.relevantQueuesQuery.data, props.originalName]);
};

const useCustomItemSource = (item: CustomItemSource | null) => {
  const isIaItem = item?.custom_item_source == 'ia_db';
  const iaFood = useFoodDetails(
    isIaItem ? item?.custom_item_source_id : null,
  );

  const foodEngineFood = useFoodEngineFood(
    !isIaItem ? itemCustomItemFoodId(item) : null,
  );

  return {
    query: (isIaItem ? iaFood : foodEngineFood).query,
    name: (item?.custom_item_source ?? 'baseless').replace(/ext:/, ''),
    id: item?.custom_item_source_id,
    foodName: isIaItem ? iaFood.food?.name : foodEngineFood.full_name,
    productImage: isIaItem ? iaFood.food?.food_image_url : foodEngineFood.photos?.[0]?.url,
    foodEngineFood: isIaItem ? null : foodEngineFood,
    isExternal: !isIaItem,
    link: foodEngineFood.source_details_page_url ?? getCustomItemSourceLink(item),
  };
};

const FocusedViewCustomItemSourceLink = (props: {
  item: MealItemResponse,
  onClick?: () => void,
}) => {
  const source = useCustomItemSource(props.item);
  return (
    <FocusedViewSourceItemLink
      source={source}
      onClick={props.onClick}
      postNameElem={
        <>
          (<span style={{ textDecoration: 'underline' }}>fill</span>)
        </>
      }
    />
  );
};

const FocusedViewSourceItemLink = (props: {
  source: ReturnType<typeof useCustomItemSource>,
  onClick?: () => void,
  postNameElem?: ReactElement,
}) => {
  const { source } = props;
  if (source.name == 'baseless') {
    return <span>baseless</span>;
  }

  if (source.query.isLoading) {
    return <span>(loading...)</span>;
  }

  if (source.query.isError) {
    return <span>(error: {parseQueryError(source.query.error)})</span>;
  }

  return (
    <>
      <span
        style={{ cursor: 'pointer', fontStyle: 'italic' }}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick?.();
        }}
      >
        {source.name}: {source.foodName} {props.postNameElem}
      </span>
      &nbsp;
      {source.link && (
        <Link to={source.link} target="_blank">
          <OpenInNewIcon
            className="on-hover"
            sx={{
              fontSize: '1rem',
              verticalAlign: 'middle',
            }}
            onClick={event => {
              event.stopPropagation();
            }}
          />
        </Link>
      )}
    </>
  );
};

export const FocusedViewHeader = (props: {
  relevantQueuesQuery: UseQueryResult<{
    queues: MealPhotoQueueResponse[],
  }, unknown>,
  originalName: string,
  onSelectBaseItemFoodId: (baseItem: string | null) => void,
}) => {
  return (
    <Stack direction="row" spacing={2} style={{ minHeight: 400 }}>
      <Box style={{ width: '40%', maxWidth: 350 }}>
        <RelevantQueuesImageViewer relevantQueuesQuery={props.relevantQueuesQuery} />
      </Box>
      <FocusedViewFoodSearch originalName={props.originalName} onSelectFoodId={props.onSelectBaseItemFoodId} />
    </Stack>
  );
};

const RelevantQueuesImageViewer = (props: {
  relevantQueuesQuery: UseQueryResult<{
    queues: MealPhotoQueueResponse[],
  }, unknown>,
}) => {
  const { relevantQueuesQuery: relevantQueues } = props;
  const [imageInFocus, setImageInFocus] = useState<number>(0);

  if (relevantQueues.isLoading) {
    return <Typography>Loading relevant queues</Typography>;
  }

  if (relevantQueues.isError) {
    return <Typography>Error loading relevant queues</Typography>;
  }

  if (relevantQueues.data.queues.length == 0) {
    return <Typography>No relevant queues found</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4">Relevant Queues</Typography>
      <span style={{ whiteSpace: 'nowrap' }}>
        Queue #{relevantQueues.data.queues[imageInFocus].id}{' '}
        <Link
          style={{ cursor: 'pointer' }}
          to={`/queue-item/${relevantQueues.data.queues[imageInFocus].id}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            size="sm"
            color="black"
          />
        </Link>
      </span>
      <Box>
        <MealPhoto queueItem={relevantQueues.data.queues[imageInFocus]} />
        <Typography>
          <b>Meal Note:</b> {relevantQueues.data.queues[imageInFocus].patient_note ?? 'No patient note provided'}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between" style={{ marginTop: 10 }} alignContent="flex-end">
        <Button
          variant="contained"
          disabled={imageInFocus == 0}
          onClick={() => setImageInFocus(imageInFocus - 1)}
          style={{ width: '25%' }}
        >
          Previous
        </Button>
        <Typography style={{ alignContent: 'center' }}>
          {imageInFocus + 1} / {relevantQueues.data.queues.length}
        </Typography>
        <Button
          variant="contained"
          disabled={imageInFocus == relevantQueues.data.queues.length - 1}
          onClick={() => setImageInFocus(imageInFocus + 1)}
          style={{ width: '25%' }}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
};

const useTriageCustomItem = () => {
  const queryClient = useQueryClient();
  const [q, setQ] = useSearchParamsDebounce();
  const currentFood = q['triage-food-name'] ?? '';
  const authInfo = useAuth();

  const [baseItemFoodId, setBaseItemFoodId] = useState<string | null>(null);
  const baseItem = useCustomItemSource(foodIdToCustomItemSource(baseItemFoodId));

  const newFoodEditorValue = useMemo(() => {
    return {
      ...emptyFoodEditorValue(),
      status: 'triaged',
      term: currentFood,
    };
  }, [currentFood]);
  const [foodEditorValue, setFoodEditorValue] = useState<FoodEditorValue>(newFoodEditorValue);
  const [selectedResolution, setSelectedResolution] = useState<'accepted' | 'flagged' | null>(null);

  const queueCallbacksRef = useRef<{ [queueId: number]: null | (() => Promise<void>) }>({});
  const [queueResolutions, setQueueResolutions] = useState<{ [queueId: number]: TriageMealItemResolution }>({});
  const useQueueResolution = (queueId: number) => {
    return [
      queueResolutions[queueId] as TriageMealItemResolution | undefined,
      (resolution: TriageMealItemResolution) => {
        setQueueResolutions({
          ...queueResolutions,
          [queueId]: resolution,
        });
      },
    ] as const;
  };

  const foodOptionsCollection = useQuery(['foodOptionsCollections'], async () => {
    const res = await foodApi.appApiFoodFoodDetailsGetOptionsCollection({ scope: 'food' });
    const foodOpts = res.data;
    return {
      prepMethodOptions: {
        id: foodOpts.id,
        categories: foodOpts.categories.filter(c => c.id == 'preparation'),
        options: foodOpts.options.filter(o => o.category_id == 'preparation'),
        values: foodOpts.values.filter(v => v.option_id == 'preparation.method'),
      } as FoodOptionCollection,
    };
  }, { staleTime: Infinity });

  useEffect(() => {
    if (!foodEditorValue.optionCollection) {
      setFoodEditorValue({
        ...foodEditorValue,
        optionCollection: foodOptionsCollection.data?.prepMethodOptions ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodOptionsCollection.isSuccess]);

  const telemetryRef = React.useRef({
    startTime: 0,
    key: '',
  });

  // Reseat the state
  useEffect(() => {
    flagForQaMutation.reset();
    approveMutation.reset();
    foodResponseCallbackRef.current = null;
    queueCallbacksRef.current = {};
    setFoodEditorValue({
      ...newFoodEditorValue,
      optionCollection: foodOptionsCollection.data?.prepMethodOptions ?? null,
    });
    setQueueResolutions({});
    setSelectedResolution(null);
    const now = Date.now();
    telemetryRef.current = {
      startTime: now,
      key: `${currentFood}:${now}`,
    };
    setBaseItemFoodId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFood]);

  const setCurrentFood = (foodName: string) => {
    setQ(prev => {
      console.log('setting q', prev);
      return ({ ...prev, 'triage-food-name': foodName ?? '' });
    });

    /*
      This should not be necessary because in newFoodEditorValue, the term should have been set properly
      and in the above useEffect, the foodEditorValue should have been reset to newFoodEditorValue

      However it does not work like that, so this is a workaround (unsure why)
    */
    setFoodEditorValue({
      ...foodEditorValue,
      term: foodName,
    });
  };

  const foodResponseCallbackRef = useRef<null | (() => Promise<void>)>(null);

  const whiteboardService = useFoodEditorWhiteboardService({
    term: currentFood,
    ndbNumber: foodEditorValue.ndbNumber,
  });

  const resetWhiteboardItems = () => {
    const initItems = whiteboardService.getInitWhiteboardItems();
    initItems.forEach((initItem) => {
      const itemsToRemove = whiteboardService.whiteboardItems.filter((i) => i.type == initItem.type);
      itemsToRemove.forEach((item) => {
        whiteboardService.removeWhiteboardItem(item);
      });
      const currItem = getEmptyWhiteboardItem();
      whiteboardService.saveWhiteboardItem(currItem, initItem);
    });
  };

  useEffect(() => {
    if (!baseItem) {
      return;
    }

    const image = baseItem?.productImage;
    if (image) {
      const curProductImage = whiteboardService.whiteboardItems.find(item => item.type == 'productImage')
        ?? getEmptyWhiteboardItem();
      const newProductImage: WhiteboardItemData = {
        id: curProductImage.id,
        type: 'productImage',
        order: curProductImage.order,
        imageDataUri: null,
        imageUri: image,
        text: '',
        metadata: {},
      };
      whiteboardService.saveWhiteboardItem(curProductImage, newProductImage);
    }

    const ingredients = baseItem?.foodEngineFood?.ingredients_str;
    if (ingredients) {
      const curIngredients = whiteboardService.whiteboardItems.find(item => item.type == 'ingredients')
        ?? getEmptyWhiteboardItem();
      const newIngredients: WhiteboardItemData = {
        id: curIngredients.id,
        type: 'ingredients',
        order: curIngredients.order,
        text: ingredients,
        imageDataUri: null,
        imageUri: null,
        metadata: {},
      };
      whiteboardService.saveWhiteboardItem(curIngredients, newIngredients);
    }

    if (baseItem.isExternal) {
      const curUrl = whiteboardService.whiteboardItems.find(item => item.type == 'productUrl')
        ?? getEmptyWhiteboardItem();
      const newUrl: WhiteboardItemData = {
        id: curUrl.id,
        type: 'productUrl',
        order: curUrl.order,
        text: baseItem.link ?? '',
        imageDataUri: null,
        imageUri: null,
        metadata: {},
      };
      whiteboardService.saveWhiteboardItem(curUrl, newUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseItem.isExternal,
    baseItem.productImage,
    baseItem.foodEngineFood?.ingredients_str,
    baseItem.link,
  ]);

  const sendTelemetryResult = () => {
    const durationSeconds = (Date.now() - telemetryRef.current.startTime) / 1000;
    const whiteboardItemTypes = whiteboardService.whiteboardItems
      .filter(item => item.text || item.imageDataUri || item.imageUri)
      .map(item => item.type);

    const getExtraFlags = (): Record<string, number> => {
      if (selectedResolution == 'accepted') {
        return {};
      }
      const res = {} as Record<string, number>;
      Object.values(queueResolutions).forEach(qRes => {
        res['Item Renamed'] = (res['Item Renamed'] ?? 0) + (qRes.resolution == 'rename' ? 1 : 0);
        res['Item Flagged'] = (res['Item Flagged'] ?? 0) + (qRes.resolution == 'flag' ? 1 : 0);
        Object.entries(qRes.flags ?? {}).forEach(([flag, value]) => {
          const key = `Item flag: ${flag}`;
          res[key] = (res[key] ?? 0) + (value ? 1 : 0);
        });
      });
      return res;
    };

    const [baseItemSource, baseItemId] = foodIdSplit(baseItemFoodId);

    const extraFlags = getExtraFlags();
    Object.values(queueResolutions).map(res => res.flags).filter(Boolean);
    mixpanel.track('Food Custom Triage: submit', {
      'Result': selectedResolution,
      'Duration (s)': durationSeconds,
      'Food name': foodEditorValue.term,
      'Food name (original)': currentFood,
      'Food name changed?': currentFood != foodEditorValue.term,
      'Food prep method': foodEditorValue.optionValuesRaw?.['preparation.method'],
      'Base item source': baseItemSource,
      'Base item FoodID': baseItemId,
      'Whiteboard item count': whiteboardItemTypes.length,
      ...extraFlags,
    });

    const renameNewNames = [] as string[];
    Object.entries(queueResolutions).forEach(([queueId, resolution]) => {
      if (resolution.resolution == 'rename') {
        renameNewNames.push(resolution.mealItem?.food_name ?? '(unknown)');
      }
    });

    telemetrySend({
      name: 'FoodCustomItemTriaged',
      key: telemetryRef.current.key,
      value: durationSeconds,
      properties: {
        result: selectedResolution,
        duration: durationSeconds,
        food_name: foodEditorValue.term,
        food_name_original: currentFood,
        food_prep_method: foodEditorValue.optionValuesRaw?.['preparation.method'],
        whiteboard_item_count: whiteboardItemTypes.length,
        whiteboard_items: whiteboardItemTypes,
        base_item_source: baseItemSource,
        base_item_food_id: baseItemId,
        ...Object.fromEntries(
          Object.entries(extraFlags).map(([k, v]) => {
            const kSnake = k.replace(/[^a-zA-Z0-9]+/g, '_').toLowerCase();
            return [kSnake, v];
          }),
        ),
        rename_new_names: renameNewNames,
      },
    });
  };

  const approveMutation = useMutation({
    mutationFn: async (args: {
      term: string,
      foodRequest: FoodEditorFoodRequest,
    }) => {
      const details: FoodDetailsUpdateRequest = foodRequestToApiFoodRequest(args.term, args.foodRequest);

      const productImage = whiteboardService.whiteboardItems.find(item => item.type == 'productImage');
      details.food.food_image_url = productImage?.imageUri ?? productImage?.imageDataUri ?? null;
      details.food.brand = baseItem.foodEngineFood?.brand_name;

      const sugServing = baseItem.foodEngineFood?.serving_units?.[0];
      details.food.suggested_serving_count = sugServing?.servings;
      details.food.suggested_serving_unit_label = sugServing?.unit_label;
      details.food.suggested_serving_amount_g = sugServing?.serving_amount_g;

      details.food.nutrition_source_url = baseItem.isExternal ? baseItem.link : null;
      details.usda_nutrition = {
        ...(details.usda_nutrition ?? {}),
        ...(baseItem.foodEngineFood?.nutrient_map ?? {}),
      };
      details.usda_measures = baseItem.foodEngineFood?.serving_units?.map(unit => ({
        label: unit.unit_label,
        eqv: unit.serving_amount_g,
        qty: unit.servings,
        root_label: unit.unit_label,
        ranking: unit.ranking,
        addon: unit.scope == 'addon',
      })) ?? [];

      const res = await foodApi.appApiFoodFoodDetailsPutFoodDetailsQuery({
        food_name: args.term,
        FoodDetailsUpdateRequest: details,
      });
      const updateRes = apiFoodResponseToFoodResponse(res.data);
      await foodResponseCallbackRef.current?.();
      if (currentFood && currentFood != foodEditorValue.term) {
        const [_, err] = await maybe(
          apiRenameFood({ oldFoodName: currentFood, newFoodName: foodEditorValue.term.toLowerCase() }),
        );
        if (err) {
          logTrackedError({
            sourceName: 'TriageCustomItem.approveMutation',
            origin: err,
            stackError: new Error(),
            context: { currentFood, newFoodName: foodEditorValue.term },
            userMessage: `Food created but error renaming existing custom items.`,
          });
        }
      }
      return updateRes;
    },
    onSuccess: foodResponse => {
      queryClient.setQueryData([QueryKey.Food, foodResponse.term], foodResponse);
      queryClient.resetQueries(getFoodDetailsQueryKey(foodResponse.term), { exact: true });
      setCurrentFood('');
      queryClient.refetchQueries(['food-database-table']);
    },
  });

  const flagForQaMutation = useMutation({
    mutationFn: async () => {
      const getQaNote = (resolution: TriageMealItemResolution) => {
        const flags = resolution.flags ?? TRIAGE_MEAL_ITEM_FLAGS;
        return [
          `Reviewed '${currentFood}' during custom item triage.`,
          Object.values(flags).some(Boolean) && '\nFlags:',
          ...Object.entries(flags).map(([flag, value]) =>
            value && `- ${TRIAGE_MEAL_ITEM_FLAG_LABEL[flag as keyof TriageMealItemFlags]}`
          ),
          resolution.note && `- Note: ${resolution.note.trim()}`,
        ].filter(Boolean).join('\n');
      };

      const applyResolution = async (queueId: number, resolution: TriageMealItemResolution) => {
        // Note: resolution == 'rename' is handled by the queueCallbacksRef
        const flags = resolution.flags ?? TRIAGE_MEAL_ITEM_FLAGS;
        await dataReviewApi.appApiQueueReviewPostQualityAssuranceLog({
          data_reviewer_id: authInfo.authInfo!.id,
          meal_photo_queue_id: queueId,
          CreateQualityAssuranceLogRequest: {
            is_reviewed: false,
            is_escalated: resolution.resolution == 'flag',
            is_approved: false,
            note: getQaNote(resolution),
            questionnaire: {
              'customs:unnecessary_custom': flags.exist || flags.alias,
              'customs:incorrect_name': flags.misid,
            },
          },
        });
      };

      await Promise.all([
        ...Object.entries(queueResolutions).map(async ([queueId, resolution]) => {
          return applyResolution(+queueId, resolution);
        }),
        ...Object.values(queueCallbacksRef.current || {}).map(async (callback) => {
          if (callback) {
            await callback();
          }
        }),
      ]);
    },
    onSuccess: () => {
      setCurrentFood('');
      queryClient.refetchQueries(['food-database-table']);
    },
  });
  const handleSubmitApprove = () => {
    sendTelemetryResult();
    approveMutation.mutate({
      term: currentFood ? currentFood : foodEditorValue.term,
      foodRequest: foodEditorValueToFoodRequest(foodEditorValue),
    });
  };

  const handleSubmit = () => {
    sendTelemetryResult();
    flagForQaMutation.mutate();
  };

  return {
    currentFood,
    setCurrentFood,

    baseItemFoodId,
    setBaseItemFoodId,
    baseItem,
    whiteboardService,
    resetWhiteboardItems,

    originalName: currentFood,

    foodEditorValue,
    setFoodEditorValue,

    selectedResolution,
    setSelectedResolution,

    handleSubmitApprove,
    approveMutation,

    foodResponseCallbackRef,

    useQueueResolution,

    handleSubmit,
    flagForQaMutation,

    queueCallbacksRef,
  };
};

type UseTriageCustomItem = ReturnType<typeof useTriageCustomItem>;

export const TriageFoodTablePage = () => {
  const triage = useTriageCustomItem();
  const openDrawer = !!triage.currentFood;

  const handleDrawerClose = () => {
    if (!window.confirm('Are you sure you want to exit? Changes will not be saved')) {
      return;
    }
    triage.setCurrentFood('');
  };

  const drawerStyle = {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '75vw',
      minWidth: 700,
      borderRight: `1px solid #e0e0e0`,
      backgroundImage: 'none',
      borderLeft: `1px solid #e0e0e0`,
      boxShadow: '8',
    },
  };

  return (
    <MainCard>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerClose}
        hideBackdrop={false}
        transitionDuration={100}
        sx={drawerStyle}
      >
        <TriageFocusedViewEditor triage={triage} />
        {
          // TODO PUT THIS BACK
          /*
        {triage.submitMutation.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(triage.submitMutation.error)}
          </Alert>
        )}
        {triage.submitMutation.isError && (
          <Alert severity="error" style={{ maxWidth: '50%' }}>
            {parseQueryError(triage.submitMutation.error)}
          </Alert>
        )}
      */
        }
      </Drawer>
      <FoodDatabaseTable onFoodClick={triage.setCurrentFood} />
    </MainCard>
  );
};
