import { Info } from '@mui/icons-material';
import { Button, FormLabel, Grid, ThemeProvider, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { EDITOR_BUTTON_PROPS, EDITOR_BUTTON_THEME } from './food-editor';

type RankingValues = '' | '0' | '1' | '2' | '3';

export default function RankingPicker({
  label,
  onChangeValue,
  value,
  onBlur,
  isAutomated,
}: {
  label: string,
  onChangeValue: (newValue: RankingValues) => void,
  value: RankingValues,
  onBlur?: () => void,
  isAutomated?: boolean,
}) {
  const labelId = _.uniqueId();

  const RANKING_OPTIONS: { value: RankingValues, label: string }[] = [
    { value: '0', label: 'N/A' },
    { value: '3', label: '3 - Low' },
    { value: '2', label: '2 - Medium' },
    { value: '1', label: '1 - High' },
  ];

  return (
    <>
      <FormLabel id={labelId}>
        {label}
        {isAutomated
          ? (
            <Tooltip title="Automated from nutrients/ontology">
              <Typography display="inline" marginLeft="4px">
                <Info color="inherit" fontSize="small" />
              </Typography>
            </Tooltip>
          )
          : null}
      </FormLabel>
      <ThemeProvider theme={EDITOR_BUTTON_THEME}>
        <Grid container spacing={2}>
          {RANKING_OPTIONS.map(option => {
            return (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="outlined"
                  onClick={() => onChangeValue(option.value as any)}
                  color={value !== option.value ? 'primary' : 'success'}
                  {...EDITOR_BUTTON_PROPS}
                  sx={{ ...EDITOR_BUTTON_PROPS.sx, borderWidth: 2 }}
                  disabled={isAutomated}
                >
                  {option.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </>
  );
}
