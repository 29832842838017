import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { InteractionOutlined } from '@ant-design/icons';
import { Button, CardContent, Chip, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { MealQueueItem } from 'apiClients/mpq';
import { Capabilities } from 'auth-capabilities';
import LoadingButton from 'components/@extended/LoadingButton';
import MainCard from 'components/MainCard';
import { PreviewImage } from 'components/queue/PreviewImage';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { useAuth } from 'context/appContext';
import { useAsyncResult } from 'react-use-async-result';
import { useGetNextQueue } from 'services/MealQueueNotificationService';
import { useMealQueueService } from 'services/MealQueueService';

export const MyItems = (props: {
  items: MealQueueItem[],
  loading: boolean,
}) => {
  const { items } = props;
  const { getNextQueue, disableUntilRefetch } = useGetNextQueue();
  const getNextItemRes = useAsyncResult<unknown>();
  const mpq = useMealQueueService();
  const isItemAvailable = mpq.ownedCount > 0 || mpq.availableCount > 0;
  const { authInfo, hasAuth } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickItem = (item: MealQueueItem) => {
    mixpanel.track('Clicked owned meal item', item);
    navigate(`/queue-item/${item.id}?utm_source=owned-queue-table`, {
      state: {
        queueItem: item,
      },
    });
  };

  const handleClickGetNext = (button: string) => {
    getNextItemRes.bind(getNextQueue({
      source: `get-next:${button}`,
      medium: 'button',
    }));
  };

  const getNextButton = isItemAvailable || getNextItemRes.isPending || disableUntilRefetch
    ? (
      <LoadingButton
        loadingPosition="start"
        loading={getNextItemRes.isPending || disableUntilRefetch}
        color="error"
        variant="contained"
        onClick={() => handleClickGetNext('has-next')}
        startIcon={<InteractionOutlined />}
      >
        Get Next
      </LoadingButton>
    )
    : (
      <Button variant="outlined" color="secondary" onClick={() => handleClickGetNext('waiting')}>
        Waiting for meals...
      </Button>
    );

  return (
    <MainCard
      title="My Items"
      content={false}
      secondary={hasAuth(Capabilities.mpqItemLabel) && items.length > 0 && getNextButton}
    >
      {items.length === 0
        ? (
          <CardContent sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={3}>
                <Stack spacing={1} alignItems="center">
                  {hasAuth(Capabilities.mpqItemLabel) && getNextButton}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        )
        : (
          <CardContent>
            <Grid container spacing={3}>
              {items.map(item => (
                <Grid
                  item
                  xs={12}
                  onClick={() => handleClickItem(item)}
                  key={item.id}
                  sx={{
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter',
                    },
                    pb: 3,
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <PreviewImage queueItem={item} size="resized" />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <QueueSummaryIcons queue={item} />{' '}
                          <Typography align="left" variant="caption" color="secondary">
                            {item.queue_type === 'nlp_queue' ? 'nlp item' : 'photo item'} |{' '}
                            {moment.utc(item.created_time).fromNow()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        )}
    </MainCard>
  );
};
