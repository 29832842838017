import { FoodOptionCollection } from 'api/generated/MNT';
import type { FoodComponentResponse, FoodResponse as ApiFoodresponse, FoodVTO, UsdaMeasure } from 'api/generated/MNT';
import { FoodRowMixedDishComponents } from 'api/generated/MNT';
import { SearchItem } from 'apiClients/search';
import { FoodEditorNutrientFields } from './food-editor-nutrient-fields';

export enum QueryKey {
  Auth = 'auth',
  Food = 'food',
  FoodSearch = 'food-search',
  Ontologies = 'ontologies',
  ClientSearch = 'ClientSearch',
  RecentFoods = 'RecentFoods',
}

export enum LocalStorageKey {
  AccessToken = 'ACCESS_TOKEN',
}

export type AuthState = {
  authenticated: boolean,
};

// The intersection of FoodVTO and ApiFoodResponse, which is useful
// because the "search" on the home screen can use either the "recent foods"
// endpoint - which returns a FoodVTO - or the "search" endpoint - which
// returns an ApiFoodResponse.
export type LimitedFoodResponse = FoodVTO | ApiFoodresponse;

export type FoodSearchResponse = {
  items: LimitedFoodResponse[],
  hasNextPage: boolean,
};

export type FoodEditorFoodRequest = {
  definitionType: 'atomic' | 'composite',
  foodImageUrl: string | null,
  ndbNumber: string | null,
  frenchTerm: string,
  nutritionSourceId: number | null,
  status: string | null,
  ontologyLevels: string[],
  baseFoodName: string | null,
  rootFoodName: string | null,
  usdaNutrientName: string | null,
  otherNames: string[],
  synonyms: string[],
  singularTerm: string | null,
  pluralTerm: string | null,
  customTip: string | null,
  nutritionSourceUrl: string | null,
  fdcid: number | null,
  cnFoodId: number | null,
  notes: string | null,
  preferredAddOn: boolean,
  wholeFood: boolean,
  wholeGrains: boolean,
  grains: boolean,
  beverage: boolean,
  sugaryBeverage: boolean,
  redMeat: boolean,
  meat: boolean,
  poultry: boolean,
  pork: boolean,
  protein: boolean,
  nonMeatAnimalProtein: boolean,
  fish: boolean,
  shellfish: boolean,
  processed: boolean,
  ultraProcessed: boolean,
  vegetable: boolean,
  fruit: boolean,
  fried: boolean,
  legume: boolean,
  highCalcium: boolean,
  highFibre: boolean,
  complexCarbs: boolean,
  simpleCarbs: boolean,
  supplement: boolean,
  unhealthyFat: boolean,
  healthyFat: boolean,
  plantProtein: boolean,
  nutsOrSeeds: boolean,
  plantBased: boolean,
  plantBasedWhole: boolean,
  raw: boolean,
  cooked: boolean,
  additives: boolean,
  glutenFree: boolean,
  omegaThree: boolean,
  suggestedItem: boolean,
  rankingUp: number | null,
  rankingPb: number | null,
  rankingHealthyFat: number | null,
  rankingUnhealthyFat: number | null,
  rankingBothFat: number | null,
  rankingWholeGrains: number | null,
  rankingRefinedGrains: number | null,
  rankingFruit: number | null,
  rankingVegetable: number | null,
  rankingLowGi: number | null,
  rankingHighGi: number | null,
  rankingLowK: number | null,
  rankingHighK: number | null,
  similarItems: string[],
  connectedItems: string[],
  compoundingWords: string[],
  dataSource: string | null,
  giLevel: 'low' | 'medium' | 'high' | null,
  giComments: string | null,
  potassiumLevel: 'low' | 'medium' | 'high' | null,
  potassiumComments: string | null,
  adultServingSizeG: number | null,
  suggestedServingCount: number | null,
  suggestedServingUnitLabel: string | null,
  suggestedServingAmountG: number | null,
  ghgEquivalentKg: number | null,
  manufacturer: string | null,
  measures: UsdaMeasure[],
  components: FoodComponentResponse[] | null,
  overrideValues: Record<string, any> | null,
  mixedDishComponents: FoodRowMixedDishComponents,
  disabled: boolean,
  optionCollectionId: number | null,
  optionValuesRaw: Record<string, any> | null,
  listedServingCount: number | null,
  listedServingUnitLabel: string | null,
  listedServingAmountG: number | null,
} & FoodEditorNutrientFields<number | null>;

export type FoodEditorFoodResponse = FoodEditorFoodRequest & {
  term: string,
  ndbNumber: string,
  createdAt: string | null,
  lastUpdatedAt: string | null,
  optionCollection: FoodOptionCollection,
};

export const proteinTypeNames = [
  'non_meat_animal',
  'seafood',
  'meat',
  'poultry',
  'plant',
  'fish',
  'red_meat',
  'pork',
  'legume',
  'shellfish',
] as const;

export type ProteinTypeName = typeof proteinTypeNames[number];

export type FoodComponent = FoodComponentResponse & {
  searchItem?: SearchItem,
};

export type FoodEditorAttributions = {
  createdTime?: string,
  createdByUserId?: number | null,
  updatedTime?: string,
  lastUpdatedByUserId?: number | null,
  triagedTime?: string | null,
  triagedByUserId?: number | null,
  scrapedTime?: string | null,
  scrapedByUserId?: number | null,
  reviewedTime?: string | null,
  reviewedByUserId?: number | null,
};

export type FoodEditorValue = {
  term: string,
  definitionType: 'atomic' | 'composite',
  frenchTerm: string,
  ndbNumber: string,
  nutritionSourceId: number | null,
  status: string,
  foodImageUrl: string | null,
  ontologyLevels: string[],
  baseFoodName: string,
  rootFoodName: string,
  usdaNutrientName: string,
  otherNames: string[],
  synonyms: string[],
  singularTerm: string,
  pluralTerm: string,
  customTip: string,
  nutritionSourceUrl: string | null,
  fdcid: string | null,
  cnFoodId: string | null,
  notes: string | null,
  // TODO: replacementId
  fruitAndVegetableType: 'fruit' | 'vegetable' | 'both' | '',
  carbohydrateType: 'complex' | 'simple' | '',
  proteinType: Array<ProteinTypeName>,
  beverageType: 'sugary' | 'non_sugary' | '',
  fatType: 'healthy' | 'unhealthy' | 'both' | '',
  grainType: 'whole' | 'refined' | '',
  processedType: 'whole_food' | 'processed' | 'ultra_processed' | '',
  preparationType: 'raw' | 'cooked' | '',
  preferredAddOn: boolean,
  wholeFood: boolean,
  fried: boolean,
  highCalcium: boolean,
  highFibre: boolean,
  supplement: boolean,
  nutsOrSeeds: boolean,
  plantBased: boolean,
  plantBasedWhole: boolean,
  additives: boolean,
  glutenFree: boolean,
  omegaThree: boolean,
  suggestedItem: boolean,
  rankingUp: '1' | '2' | '3' | '',
  rankingPb: '1' | '2' | '3' | '',
  rankingHealthyFat: '1' | '2' | '3' | '',
  rankingUnhealthyFat: '1' | '2' | '3' | '',
  rankingBothFat: '1' | '2' | '3' | '',
  rankingWholeGrains: '1' | '2' | '3' | '',
  rankingRefinedGrains: '1' | '2' | '3' | '',
  rankingFruit: '1' | '2' | '3' | '',
  rankingVegetable: '1' | '2' | '3' | '',
  rankingLowGi: '1' | '2' | '3' | '',
  rankingHighGi: '1' | '2' | '3' | '',
  rankingLowK: '1' | '2' | '3' | '',
  rankingHighK: '1' | '2' | '3' | '',
  similarItems: string[],
  connectedItems: string[],
  compoundingWords: string[],
  dataSource: string,
  giLevel: 'low' | 'medium' | 'high' | '',
  giComments: string,
  potassiumLevel: 'low' | 'medium' | 'high' | '',
  potassiumComments: string,
  mixedDishComponents: FoodRowMixedDishComponents,
  adultServingSizeG: string,
  suggestedServingCount: string,
  suggestedServingUnitLabel: string,
  suggestedServingAmountG: string,
  ghgEquivalentKg: string,
  manufacturer: string,
  measures: UsdaMeasure[],
  components: FoodComponent[],
  disabled: boolean,

  overrideValues: Partial<FoodEditorValue> | null,

  optionCollection: FoodOptionCollection | null,
  optionValuesRaw: Record<string, any> | null,

  ephemeralNutrientValueDisplayScale: '' | 'sug' | '100g' | 'adult serving',

  listedServingCount: string,
  listedServingUnitLabel: string,
  listedServingGPerServing: string,
} & FoodEditorNutrientFields<number | null>;

export type Ontology = {
  id: number,
  name: string,
  children: Ontology[],
};

export type LoginRequest = {
  email: string,
  password: string,
};

export type LoginResponse = {
  access_token: string,
  token_type: string,
  expires_in: string,
};
