import { CardContent, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { pluralize } from 'async-result/utils';
import { ListMealHistoryView } from 'components/ListMealHistoryView';
// import { MealSummary } from 'components/MealSummary';

import type { DraftItem } from '../types/DraftItem';

import type { MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner, MealPhotoQueueResponse } from 'api/generated/MNT';
import type { MealQueueItem } from 'apiClients/mpq';

import 'App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import { useQuery } from '@tanstack/react-query';
import { dataReviewApi } from 'api';
import { FilterSortTable } from 'components/FilterSortTable';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { MealChangeLogsTable } from 'food-editor/components/FoodChangeLogs';
import _ from 'lodash';
import { useQueryNeverRefetch } from 'utils';
import { useMealChangeLogs } from './QueueItem/meal-builder/useMealChangeLogs';

export const MealChangeLogs = (props: {
  queueItem: MealQueueItem,
  draftItems: DraftItem[],
  hideTitle?: boolean,
  mainCardStyle?: React.CSSProperties,
}) => {
  const mealChangeLogsRes = useMealChangeLogs(props.queueItem);

  return (
    <MainCard
      style={{ minHeight: 200, maxHeight: 300, overflowY: 'auto', ...(props.mainCardStyle || {}) }}
      title={props.hideTitle ? null : 'Meal History'}
    >
      {mealChangeLogsRes.query.isError && <div>{'' + mealChangeLogsRes.query.error}</div>}
      {mealChangeLogsRes.query.isLoading && <div>Loading...</div>}
      {mealChangeLogsRes.query.isSuccess && (mealChangeLogsRes.data
        ? (
          <MealChangeLogsTable
            changeLogs={mealChangeLogsRes.data}
            firstReviewerID={props.queueItem.first_reviewer_user_id}
          />
        )
        : <div>No Changes</div>)}
    </MainCard>
  );
};

export const HistoryView = (props: {
  queueItem: MealPhotoQueueResponse,
  draftItems: DraftItem[],
  asCard?: boolean,
  mainCardStyle?: React.CSSProperties,
}) => {
  const { queueItem, draftItems } = props;

  return (
    <span>
      {props.asCard
        ? (
          <MainCard
            className="section"
            style={{ paddingTop: 0, height: 200, overflow: 'scroll', ...(props.mainCardStyle || {}) }}
          >
            <ListMealHistoryView
              queueItem={queueItem}
              draftItems={draftItems}
            />
          </MainCard>
        )
        : (
          <div className="section" style={{ paddingTop: 0 }}>
            <ListMealHistoryView
              queueItem={queueItem}
              draftItems={draftItems}
            />
          </div>
        )}
    </span>
  );
};

export const MealQoSDetails = (props: {
  item: MealQueueItem,
  mainCardStyle?: React.CSSProperties,
}) => {
  const qosDetailsQuery = useQuery(['mealQoSDetails', props.item.id], async () => {
    const r = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueItemQosDetails({
      meal_photo_queue_id: props.item.id,
    });
    const res = {
      otherItemCount: 0,
      reviewerItems: [{
        item: props.item,
        overlap_type: 'self',
        same_reviewer: true,
      }] as MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner[],
    };
    r.data.overlapping_queues.forEach(q => {
      if (!q.same_reviewer) {
        res.otherItemCount += 1;
      }
      res.reviewerItems.push(q);
    });
    res.reviewerItems = _.sortBy(res.reviewerItems, q => q.item.reviewed_time);
    return res;
  }, {
    ...useQueryNeverRefetch,
  });

  const [refTime, setRefTime] = useState(null);
  const relTime = (_target: 'created_time' | 'reviewed_time' | string, time: string | null | undefined) => {
    if (!time) {
      return '–';
    }
    const m = moment(time);
    const target = refTime ?? _target;
    const diffNeg = target != 'created_time' && target != 'reviewed_time'
      ? m.diff(target, 'seconds')
      : m.diff(props.item[target], 'seconds');
    const diff = Math.abs(diffNeg);
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff - hours * 3600) / 60);
    const seconds = diff - hours * 3600 - minutes * 60;
    const before = diffNeg < 0 ? ' before' : '';
    return `${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? minutes + 'm' : ''}${
      ('' + seconds).padStart(2, '0')
    }s${before}`;
  };

  const abbreviateOverlapType = {
    'created_before_finished_before': 'CBFB',
    'created_before_finished_after': 'CBFA',
    'created_after_finished_before': 'CAFB',
    'created_after_finished_after': 'CAFA',
    'other': 'other',
    'self': 'self',
  };

  const columns = [
    {
      Header: 'QID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => (
        row.overlap_type != 'self' && (
          <Link to={`/queue-item/${row.item.id}`} target="_blank">
            {row.item.id}
          </Link>
        )
      ),
    },
    {
      Header: 'UID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => row.item.patient_id,
    },
    {
      Header: 'Type',
      Cell: ({ row }: any) => {
        const overlap_type: keyof typeof abbreviateOverlapType = row?.original?.overlap_type;
        if (!overlap_type) {
          return null;
        }
        return <p title={overlap_type}>{abbreviateOverlapType[overlap_type]}</p>;
      },
    },
    {
      Header: 'Created',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.created_time;
      },
      Cell: (props: any) => {
        return relTime('created_time', props.value);
      },
      _cellTimeField: 'created_time',
    },
    {
      Header: 'Accessed',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.first_reviewer_access_time;
      },
      Cell: (props: any) => {
        const row = props.row.original;
        return relTime(row.item.created_time, props.value);
      },

      _cellTimeField: 'first_reviewer_access_time',
    },
    {
      Header: 'Reviewed',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return row.item.reviewed_time;
      },
      Cell: (props: any) => {
        return relTime('created_time', props.value);
      },

      _cellTimeField: 'reviewed_time',
    },
    {
      Header: 'Reviewer ID',
      accessor: (row: MealPhotoQueueQoSDetailsResponseOverlappingQueuesInner) => {
        return `${row.item.data_reviewer_id} ${row.same_reviewer ? ' (Current)' : ''}`;
      },
    },
  ];

  const getCellProps = (cellInfo: any) => {
    if (!cellInfo.column._cellTimeField) {
      return {};
    }
    const timeValue = cellInfo.row.original?.item?.[cellInfo.column._cellTimeField];
    if (!timeValue) {
      return {};
    }
    return {
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 125,
        minWidth: 125,
      } satisfies React.CSSProperties,
      onMouseEnter: () => {
        setRefTime(timeValue);
      },
      onMouseLeave: () => {
        setRefTime(null);
      },
    };
  };

  return (
    <MainCard
      title="QoS Details"
      content={false}
      style={props.mainCardStyle}
    >
      {qosDetailsQuery.isLoading && <CardContent>Loading...</CardContent>}
      {qosDetailsQuery.isError && <CardContent>Error: {'' + qosDetailsQuery.error}</CardContent>}
      {qosDetailsQuery.data && (
        <ScrollX>
          <Stack>
            <CardContent>
              <Typography style={{ marginBottom: 10 }}>
                While this queue was active,{' '}
                <strong>{pluralize(qosDetailsQuery.data?.otherItemCount, 'queue was', 'queues were')}</strong>{' '}
                processed by other reviewers.
              </Typography>
              <Typography>
                <strong>
                  {pluralize(
                    qosDetailsQuery.data?.reviewerItems.filter(item => item.same_reviewer).length - 1,
                    'queue was',
                    'queues were',
                  )}
                </strong>{' '}
                processed by the same reviewer (note: created/reviewed times are relative to this queue's creation time,
                a queue's accessed time is relative to its own creation time):
              </Typography>
            </CardContent>
            <FilterSortTable
              columns={columns}
              data={qosDetailsQuery.data.reviewerItems}
              striped={true}
              numRows={qosDetailsQuery.data.reviewerItems.length}
              getCellProps={getCellProps}
              showColumnSort
            />
          </Stack>
        </ScrollX>
      )}
    </MainCard>
  );
};
