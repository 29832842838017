import { LoadingOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { FoodSearchItem, FoodSearchResult } from 'client-search/food-search-index';
import { useFoodSearchIndex } from 'client-search/useFoodSearchIndex';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAsyncResult } from 'react-use-async-result';
import { FOOD_ENGINE_MAX_RESULTS, UseFoodSearchType } from './useFoodSearch';

export const FoodSearchInput = (props: {
  foodSearch: UseFoodSearchType,
  inputRef?: React.RefObject<HTMLInputElement>,
  autoFocus?: boolean,
  fullWidth?: boolean,
  onKeyUp?: (evt: React.KeyboardEvent<any>) => void,
  onFocus?: (evt: React.FocusEvent<any>) => void,
  onChange?: (evt: React.ChangeEvent<any>) => void,
}) => {
  const { foodSearch } = props;
  const foodDetailsPopup = useFoodDetailsPopup();

  return (
    <OutlinedInput
      autoComplete="off"
      placeholder="Search for food"
      autoFocus={props.autoFocus}
      fullWidth={props.fullWidth}
      value={foodSearch.activeSearch.text}
      inputRef={props.inputRef}
      onChange={(evt) => {
        foodSearch.setActiveSearch({
          type: 'db',
          text: evt.target.value,
        });
        foodDetailsPopup.forceClear();
        props.onChange?.(evt);
      }}
      onKeyUp={(evt: React.KeyboardEvent<any>) => {
        if (evt.code == 'Enter') {
          foodSearch.setActiveSearch({
            type: 'external',
            text: foodSearch.activeSearch.text,
          });
        }
        props.onKeyUp?.(evt);
      }}
      onFocus={props.onFocus}
      endAdornment={
        <InputAdornment position="end">
          {foodSearch.indexStatusStr}
          <IconButton
            color="secondary"
            edge="end"
            disabled={foodSearch.loading}
            onClick={() => {
              foodSearch.setActiveSearch({
                type: 'external',
                text: foodSearch.activeSearch.text,
              });
            }}
          >
            {foodSearch.loading ? <LoadingOutlined /> : <SearchOutlined />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export const FoodSearchTypeahead = (props: {
  value: string,
  onSelect: (item: string) => void,
  defaultOptions?: string[],
  exclude?: string[],
  placeholder: string,
}) => {
  const foodIndex = useFoodSearchIndex();
  const searchIndexRes = useAsyncResult<FoodSearchResult>();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const searchInputRef = React.useRef<any>();

  React.useEffect(() => {
    const handler = (e: any) => {
      if (e.target != searchInputRef.current?.inputNode) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handler, false);
    return () => document.removeEventListener('click', handler, false);
  }, []);

  React.useEffect(() => {
    if (!foodIndex.isDone) {
      return;
    }
    const req = foodIndex.result.search('flexi', searchText);
    searchIndexRes.bind(req);
  }, [foodIndex, searchText]);

  React.useEffect(() => {
    if (!props.value) {
      searchInputRef.current?.clear();
    }
  }, [props.value]);

  const dedupe = (xs: { name: string }[]) => {
    const seen = {} as any;
    return xs.filter(x => {
      if (seen[x.name]) {
        return false;
      }
      seen[x.name] = true;
      return true;
    });
  };

  const options = React.useMemo(() => {
    return (
      dedupe(
        (props.defaultOptions || [])
          .filter(name => !searchText || name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
          .map(name => ({ name }))
          .concat(searchIndexRes.isDone ? searchIndexRes.result.items : [])
          .filter(item => !(props.exclude || []).includes(item.name))
          .slice(0, FOOD_ENGINE_MAX_RESULTS),
      )
    ).map(item => ({ ...item, id: item.name }));
  }, [props.defaultOptions, props.exclude, searchIndexRes, searchText]);

  return (
    <Typeahead
      id="addon-search-typeahead"
      ref={searchInputRef}
      labelKey="name"
      selected={props.value ? [{ name: props.value }] : []}
      filterBy={['name']}
      onFocus={() => setOpen(true)}
      open={open}
      onChange={s => {
        props.onSelect(s[0] ? (s[0] as FoodSearchItem).name : '');
      }}
      options={options}
      isLoading={searchIndexRes.isPending}
      onInputChange={value => {
        setOpen(true);
        setSearchText(value);
      }}
      onKeyDown={() => setOpen(true)}
      placeholder={props.placeholder}
    />
  );
};
