import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  FoodApiAppApiFoodFoodSearchGetFoodDatabaseTableRequest,
  FoodDatabaseTableResponseRow,
  FoodDatabaseTableResponseRowRelatedPatientDraftReportsInner,
  RecentMeal,
} from 'api/generated/MNT';
import { useAuth } from 'context/appContext';
import { FOOD_STATUS_OPTIONS } from 'food-editor/components/food-editor';
import { HumanTime } from 'food-editor/components/HumanTime';
import { useUsersOnFood } from 'layout/MainLayout/Header/HeaderContent';
import { omit } from 'lodash';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { parseQueryError } from 'utils';
import { dataReviewApi, foodApi } from '../api';
import { PatientID } from './PatientID';
import { RxTable, RxTableColumn, RxTableFilter, RxTableSort, useRxTableData } from './RxTable';

const RecentMealsCol = (props: {
  recent_meals?: RecentMeal[],
}) => {
  const recent_meals = props.recent_meals;

  if (!recent_meals) {
    return <div />;
  }

  return (
    <div style={{ width: 'auto' }}>
      {recent_meals.map((meal, index) => {
        return (
          <Grid container key={index} style={{ paddingBottom: 2, paddingTop: 2 }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} style={{ alignItems: 'center' }}>
                <Link to={`/queue-item/${meal.queue_id}`}>
                  <Typography>
                    {`${meal.queue_id}`}
                  </Typography>
                </Link>
                <Typography>
                  {`(${meal.meal_id}/${meal.patient_id})`}
                </Typography>
                {meal.patient_is_priority && <Chip label="P" size="small" color="error" title="Priority patient" />}
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

const PatientReportsCol = (props: {
  patient_reports?: FoodDatabaseTableResponseRowRelatedPatientDraftReportsInner[],
}) => {
  const patient_reports = props.patient_reports;

  if (!patient_reports) {
    return <div />;
  }

  return (
    <div style={{ width: 'auto' }}>
      {patient_reports.map((report, index) => {
        return (
          <Grid container key={index} style={{ paddingBottom: 2, paddingTop: 2 }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} style={{ alignItems: 'center' }}>
                <Link to={`/patient-reports/${report.patient_report_id}`}>
                  <Typography>
                    {`${report.patient_report_id}`}
                  </Typography>
                </Link>
                <Typography>
                  {`(${report.patient_id})`}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

const FoodNameCol = (props: {
  row: FoodDatabaseTableResponseRow,
  onFoodClick?: (foodName: string) => void,
}) => {
  const { row, onFoodClick } = props;
  const rowUrl = getRowUrl(row);
  const usersOnFoodPage = useUsersOnFood({
    url: rowUrl,
    matchParams: { 'triage-food-name': row.food_name, 'scrape-food-name': row.food_name },
  });

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      {!onFoodClick
        ? (
          <Link to={rowUrl}>
            {row.food_name}
          </Link>
        )
        : (
          <Typography
            sx={{
              color: '#3D74F4',
              textAlign: 'left',
              justifyContent: 'flex-start',
              '&:hover': { color: '#1C5AC5', cursor: 'pointer' },
            }}
            onClick={() => onFoodClick(row.food_name)}
          >
            <u>{row.food_name}</u>
          </Typography>
        )}
      {usersOnFoodPage.length > 0
        && (
          <div>
            <div
              style={{
                // marginLeft: '0.15rem',
                display: 'inline-block',
                width: '0.8rem',
                height: '0.8rem',
                borderRadius: '50%',
                // marginBottom: '0.05rem',
                backgroundColor: '#9c27b0',
              }}
              title={usersOnFoodPage.map(u => u.uid).join(', ') + (usersOnFoodPage.length > 1 ? ' are ' : ' is ')
                + 'viewing this food'}
            />
          </div>
        )}
    </Stack>
  );
};

const getRowUrl = (row: FoodDatabaseTableResponseRow) => {
  return row.is_custom_item
    ? `/foods/new?initial-name=${encodeURIComponent(row.food_name)}`
    : `/foods/${encodeURIComponent(row.food_name)}`;
};

const getColumns = (onFoodClick?: (foodName: string) => void) => {
  const columns: Array<RxTableColumn<FoodDatabaseTableResponseRow>> = [
    {
      field: 'food_name',
      header: 'Food Name',
      sortable: true,
      filter: {
        type: 'string',
        defaultCmp: 'contains',
      },
      cellRenderer: ({ row }) => <FoodNameCol row={row} onFoodClick={onFoodClick} />,
    },
    {
      field: 'recent_meals',
      header: 'Recent Meals',
      sortable: false,
      cellRenderer: ({ row }) => <RecentMealsCol recent_meals={row.recent_meals} />,
    },
    {
      field: 'related_patient_draft_reports',
      header: 'Draft Reports',
      sortable: true,
      cellRenderer: ({ row }) => <PatientReportsCol patient_reports={row.related_patient_draft_reports} />,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: {
        type: 'enum',
        values: [{
          label: 'Custom Item',
          value: 'custom_meal_item',
        }, ...FOOD_STATUS_OPTIONS],
      },
    },
    {
      header: 'Definition Type',
      field: 'definition_type',
      getValue: (row) => row.definition_type ?? '',
      sortable: true,
      filter: {
        type: 'enum',
        values: [
          { label: 'Atomic', value: 'atomic' },
          { label: 'Composite', value: 'composite' },
        ],
      },
    },
    {
      header: 'Prep',
      field: 'option_value_preparation_method',
      getValue: (row) => row.option_value_preparation_method ?? '',
      filter: {
        type: 'enum',
        values: [
          { label: '(None)', value: '__null__' },
          { label: 'CPG', value: 'cpg' },
          { label: 'Recipe', value: 'recipe' },
          { label: 'Restaurant', value: 'restaurant' },
          { label: 'Unknown', value: 'unknown' },
        ],
      },
    },
    {
      field: 'created_time',
      header: 'Created',
      sortable: true,
      cellRenderer: ({ row }) => <HumanTime value={row.created_time} />,
    },
    {
      field: 'triaged_by_user_id',
      header: 'Triager',
      filter: {
        type: 'string',
        defaultCmp: 'eq',
      },
      cellRenderer: ({ row }) => <PatientID userId={row.triaged_by_user_id} hideOffline isPriority={false} />,
    },
    {
      field: 'triaged_time',
      header: 'Triaged',
      sortable: true,
      cellRenderer: ({ row }) => <HumanTime value={row.triaged_time} />,
    },

    {
      field: 'scraped_by_user_id',
      header: 'Scraper',
      filter: {
        type: 'string',
        defaultCmp: 'eq',
      },
      cellRenderer: ({ row }) => (
        <PatientIdOrAssignTask row={row} field="scraped_by_user_id" userId={row.scraped_by_user_id} />
      ),
    },
    {
      field: 'scraped_time',
      header: 'Scraped',
      sortable: true,
      cellRenderer: ({ row }) => <HumanTime value={row.scraped_time} />,
    },

    {
      field: 'reviewed_by_user_id',
      header: 'Reviewer',
      filter: {
        type: 'string',
        defaultCmp: 'eq',
      },
      cellRenderer: ({ row }) => (
        <PatientIdOrAssignTask row={row} field="reviewed_by_user_id" userId={row.reviewed_by_user_id} />
      ),
    },
    {
      field: 'reviewed_time',
      header: 'Reviewed',
      sortable: true,
      cellRenderer: ({ row }) => <HumanTime value={row.reviewed_time} />,
    },

    {
      field: 'updated_time',
      header: 'Last Updated',
      sortable: true,
      cellRenderer: ({ row }) => <HumanTime value={row.updated_time} />,
    },
  ];

  return columns;
};

const PatientIdOrAssignTask = (props: { row: FoodDatabaseTableResponseRow, field: string, userId?: number }) => {
  const { row, field, userId } = props;
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { authInfo } = useAuth();
  const queryClient = useQueryClient();

  const showChip = (field === 'scraped_by_user_id' && row.status === 'triaged'
    && row.scraped_by_user_id === authInfo?.reviewer_id)
    || (field === 'reviewed_by_user_id' && row.status === 'under_review'
      && row.reviewed_by_user_id === authInfo?.reviewer_id);

  const showAssignButton = !userId && !showChip
    && ((field === 'scraped_by_user_id' && row.status === 'triaged' && row.scraped_by_user_id === null)
      || (field === 'reviewed_by_user_id' && row.status === 'under_review' && row.reviewed_by_user_id === null));

  const assignFoodMutation = useMutation(
    {
      mutationFn: async (assigned_to_user_id: number) => {
        return await foodApi.appApiFoodFoodDetailsPatchFoodAssignTaskQuery({
          food_name: row.food_name,
          FoodAssignmentRequest: {
            scraped_by_user_id: field === 'scraped_by_user_id' ? assigned_to_user_id : null,
            reviewed_by_user_id: field === 'reviewed_by_user_id' ? assigned_to_user_id : null,
          },
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['food-database-table'],
        });
      },
    },
  );

  const handleAssignSubmit = (assignedToUserId: number | null) => {
    if (!assignedToUserId) {
      return;
    }

    if (!window.confirm(`Please confirm assigning food "${row.food_name} to user "${assignedToUserId}"`)) {
      return;
    }

    assignFoodMutation.mutate(assignedToUserId);
  };

  useEffect(() => {
    if (assignFoodMutation.isLoading) {
      setSubmitting(true);
    }
  }, [assignFoodMutation.isLoading]);

  useEffect(() => {
    if (assignFoodMutation.isError) {
      setSubmitting(false);
    }
  }, [assignFoodMutation.isError]);

  useEffect(() => {
    if (assignFoodMutation.isSuccess) {
      setShowAssignModal(false);
      setSubmitting(false);

      mixpanel.track('Food editor: assigned food', {
        'Food name': assignFoodMutation.data.data.food,
        'Task': assignFoodMutation.data.data.next_status,
        'Assigned to': assignFoodMutation.data.data.assigned_to_user_id,
      });
    }
  }, [assignFoodMutation.isSuccess]);

  return (
    <>
      <AssignTaskDialog
        row={row}
        open={showAssignModal}
        submitting={submitting}
        error={assignFoodMutation.error}
        onClose={() => setShowAssignModal(false)}
        onSubmit={(assigned_to_user_id) => handleAssignSubmit(Number(assigned_to_user_id))}
      />
      {showAssignButton
        ? (
          <Button
            disabled={showAssignModal}
            variant="contained"
            disableElevation
            onClick={() => setShowAssignModal(true)}
          >
            Assign
          </Button>
        )
        : showChip
        ? <Chip label="To Do" color="info" />
        : <PatientID userId={userId} hideOffline isPriority={false} />}
    </>
  );
};

const AssignTaskDialog = (props: {
  row: FoodDatabaseTableResponseRow,
  open: boolean,
  submitting: boolean,
  error?: unknown,
  onClose: () => void,
  onSubmit: (assignedTo: string) => void,
}) => {
  const { row, open, submitting, error } = props;
  const [assignedTo, setAssignedTo] = useState('');

  const query = useQuery(['list-data-reviewers'], async () => {
    const res = await dataReviewApi.appApiDataReviewerGetDataReviewers();
    return res.data;
  });

  const reviewersToShow = _(query.data || [])
    .filter(dr => dr.is_active && !dr.is_admin)
    .sortBy('id')
    .value();

  const handleRadioChange = (event: SelectChangeEvent) => {
    setAssignedTo(event.target.value);
  };

  const handleSubmit = () => {
    props.onSubmit(assignedTo);
  };

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      fullWidth
      disableScrollLock
      hideBackdrop={true}
      PaperProps={{
        elevation: 24,
        sx: {
          position: 'fixed',
          right: '20%',
          width: '40%',
          maxWidth: 600,
          height: '70%',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h3">
          Assign <i>{row.food_name}</i> to:
        </Typography>
      </DialogTitle>
      <DialogContent>
        {query.isError
          ? <DialogContentText>Error: {parseQueryError(query.error)}</DialogContentText>
          : query.isLoading
          ? <DialogContentText>Loading...</DialogContentText>
          : (
            <FormControl>
              <FormLabel>Reviewers</FormLabel>
              <RadioGroup
                onChange={handleRadioChange}
              >
                {reviewersToShow.map(
                  dr => {
                    return (
                      <FormControlLabel key={dr.id} value={dr.id} control={<Radio />} label={`${dr.id}: ${dr.email}`} />
                    );
                  },
                )}
              </RadioGroup>
            </FormControl>
          )}
      </DialogContent>
      <DialogActions>
        {!!error && <Typography>{parseQueryError(error)}</Typography>}
        <Button
          color="secondary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!query.isSuccess || submitting || !assignedTo}
          onClick={handleSubmit}
        >
          Confirm and Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FoodDatabaseTable = (props: {
  defaults?: any,
  onFoodClick?: (foodName: string) => void,
}) => {
  const columns = getColumns(props.onFoodClick);

  const table = useFoodDatabaseTable();

  return (
    <div style={{ overflowX: 'scroll' }}>
      {
        /*
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              id="food_name_input"
              name="food_name"
              fullWidth
              label="Food Name"
              InputLabelProps={{ sx: { overflow: 'visible' } }}
              value={foodSearch}
              variant="outlined"
              onChange={e => inputChanged(e, e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ overflow: 'visible' }} id="multiple-status-select-label">Status</InputLabel>
            <Select
              id="multiple-status-select"
              name="status"
              fullWidth
              multiple
              value={status}
              onChange={e => statusChanged(e)}
              input={<OutlinedInput />}
            >
              <MenuItem value="custom_meal_item">
                Custom meal item
              </MenuItem>
              <MenuItem value="custom_item_pending_refresh">
                Custom meal item (pending refresh)
              </MenuItem>
              {FOOD_STATUS_OPTIONS.map(s => (
                <MenuItem key={s.value} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
              */
      }

      <RxTable
        columns={columns}
        data={table}
        allowColumnSelect
        allowFilters
        allowDownload
      />
    </div>
  );
};

export const MyFoodTablePage = () => {
  const columns = getColumns();
  const { authInfo } = useAuth();

  const myFoodTable = useFoodDatabaseTable();

  const [toDoTable, historyTable] = useMemo(() => {
    const toDo: FoodDatabaseTableResponseRow[] = [], history: FoodDatabaseTableResponseRow[] = [];

    myFoodTable.rows.filter(r => !r.status.includes('custom')).forEach(r =>
      (r.reviewed_by_user_id === authInfo?.reviewer_id && r.reviewed_time === null
        ? toDo
        : r.scraped_by_user_id === authInfo?.reviewer_id && r.scraped_time === null
        ? toDo
        : history).push(r)
    );

    return [toDo, history];
  }, [myFoodTable.rows, authInfo?.reviewer_id]);

  if (myFoodTable.query.isLoading) {
    return <span>loading...</span>;
  }

  if (myFoodTable.query.isError) {
    return <span>Error: {'' + myFoodTable.query.error}</span>;
  }

  return (
    <Stack>
      <Typography variant="h3">To Do</Typography>
      <div style={{ overflowX: 'scroll' }}>
        <RxTable
          columns={columns}
          data={{ ...myFoodTable, rows: toDoTable }}
        />
      </div>
      <Typography variant="h3" style={{ marginTop: '32px' }}>Past Foods</Typography>
      <div style={{ overflowX: 'scroll' }}>
        <RxTable
          columns={columns}
          data={{ ...myFoodTable, rows: historyTable }}
        />
      </div>
    </Stack>
  );
};

export const useFoodDatabaseTable = (opts?: {
  tableFilter?: RxTableFilter<FoodDatabaseTableResponseRow>,
  tableSort?: RxTableSort<FoodDatabaseTableResponseRow>,
  useSearchParams?: boolean,
}) => {
  const foodTable = useRxTableData({
    queryKey: 'food-database-table',
    apiEndpoint: foodApi.appApiFoodFoodSearchGetFoodDatabaseTable.bind(foodApi),
    useSearchParams: opts?.useSearchParams ?? true,
    queryOptions: {
      staleTime: 60 * 1000,
      cacheTime: 15 * 1000,
      keepPreviousData: true,
    },
    initialFilter: opts?.tableFilter,
    initialSort: opts?.tableSort,
    queryLimit: 200,
  });

  return foodTable;
};
