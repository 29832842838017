export const QA_CONFIDENCE_LABELS = [
  { label: 'Very low', value: 1 },
  { label: 'Low', value: 2 },
  { label: 'Medium', value: 3 },
  { label: 'High', value: 4 },
  { label: 'Very high', value: 5 },
];

export const QA_QUESTIONNAIRE_QUESTIONS = [
  {
    id: 'ident:missing_items',
    text: (
      <span>
        Were any <strong>singular items</strong> missed? (e.g. granola bar, chocolate, ice cream)
      </span>
    ),
    description: <span>Singular food items missed</span>,
  },

  {
    id: 'ident:missing_base_items',
    text: (
      <span>
        Were any <strong>base items</strong> missed?
      </span>
    ),
    description: <span>Base food items missed</span>,
  },

  {
    id: 'ident:missing_sauces',
    text: (
      <span>
        Were any <strong>sauces or seasonings</strong> missed?
      </span>
    ),
    description: <span>Sauces or seasonings missed</span>,
  },

  {
    id: 'ident:missing_addons',
    text: (
      <span>
        Were any other toppings missed <strong>(not sauces or seasonings)</strong>?
      </span>
    ),
    description: <span>Add-ons or toppings missed</span>,
  },

  {
    id: 'ident:mislabeled_items',
    text: (
      <span>
        Were any items <strong>mislabeled</strong>? (i.e. had to be replaced with a different item)
      </span>
    ),
    description: <span>Food items mislabeled</span>,
  },

  {
    id: 'ident:extra_items',
    text: (
      <span>
        Were any items added that were <strong>absent from the photo</strong> and not mistaken for something else?
      </span>
    ),
    description: <span>Extra items logged</span>,
  },

  {
    id: 'ident:dupe_items',
    text: (
      <span>
        Was an item added to the meal <strong>more than once (incorrectly)</strong>?
      </span>
    ),
    description: <span>Duplicate items logged</span>,
  },

  {
    id: 'sizing:inappropriate_serving_labels',
    text: (
      <span>
        Were <strong>inappropriate serving labels</strong> assigned to: customs/branded items/addons?
      </span>
    ),
    description: <span>Inappropriate serving labels</span>,
  },

  {
    id: 'sizing:incorrect_sizes_major',
    text: (
      <span>
        Were there any items with <strong>significant sizing errors</strong>? (sizing adjustments &plusmn;2x)
      </span>
    ),
    description: <span>Major incorrectly sized items</span>,
  },

  {
    id: 'sizing:incorrect_sizes_minor',
    text: (
      <span>
        Were there any <strong>minor sizing errors</strong> (small adjustments) for any items?
      </span>
    ),
    description: <span>Minor incorrectly sized items</span>,
  },

  {
    id: 'nutrients:high_carb_change',
    text: (
      <span>
        Were there any items that had a <strong>high carb value (&gt;70g)</strong> that required a change?
      </span>
    ),
    description: <span>High carb value (&gt;70g) items required a change</span>,
  },

  {
    id: 'nutrients:cooking_method_change',
    text: (
      <span>
        Were any items changed to reflect a more <strong>appropriate cooking method</strong>?
      </span>
    ),
    description: <span>Cooking method required a change</span>,
  },

  {
    id: 'nutrients:product_version_change',
    text: (
      <span>
        Were any items changed to reflect a more{' '}
        <strong>specific version of the product</strong>? (e.g. flavour, cut of meat, frozen canned vs fresh, etc.)
      </span>
    ),
    description: <span>Product version required a change</span>,
  },

  {
    id: 'customs:unnecessary_custom',
    text: (
      <span>
        Were any custom items added that were <strong>already in the database</strong>?
      </span>
    ),
    description: <span>Unnecessary custom items created</span>,
  },

  {
    id: 'customs:needed_custom_item',
    text: (
      <span>
        Were there any items that should have <strong>gone in as a custom</strong>?
      </span>
    ),
    description: <span>Custom items should have been created</span>,
  },

  {
    id: 'customs:incorrect_manual_amount',
    text: (
      <span>
        Were any custom items entered that were given an <strong>incorrect manual carb or fibre amount</strong>?
      </span>
    ),
    description: <span>Incorrect manual carb or fibre amount</span>,
  },

  {
    id: 'customs:incorrect_name',
    text: (
      <span>
        Were any custom items added with an <strong>incorrect name</strong>?
      </span>
    ),
    description: <span>Custom items with incorrect name</span>,
  },

  {
    id: 'customs:incorrect_log',
    text: (
      <span>
        Were any custom items added that were <strong>incorrect compared to the user log</strong>?
      </span>
    ),
    description: <span>Custom items does not match user log</span>,
  },

  {
    id: 'context:item_does_not_match_note',
    text: (
      <span>
        Did the food items added to the log <strong>not match the meal note</strong>?
      </span>
    ),
    description: <span>Meal note or NLP entry did not match the items labeled</span>,
  },

  {
    id: 'context:item_in_history_and_still_incorrect',
    text: (
      <span>
        Did the food items <strong>exist in the user history</strong> but was still labeled incorrectly?
      </span>
    ),
    description: <span>Item already existed in the user history but was labeled incorrectly</span>,
  },

  {
    id: 'context:dietary_restrictions_not_followed',
    text: (
      <span>
        Were any adjustments made to better reflect <strong>user dietary restrictions or motivations</strong>?
      </span>
    ),
    description: <span>Items were adjusted based on dietary restrictions or motivations</span>,
  },
];
