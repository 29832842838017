import React, { useContext, useEffect, useState } from 'react';

import { FormOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  Box,
  ClickAwayListener,
  InputAdornment,
  OutlinedInput,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { getFoodByQuery, searchByTextWithContext, SearchItem } from 'apiClients/search';
import { assertUnreachable } from 'async-result/utils';
import { FoodSearchItem, FoodSearchResult, stripPunctuation } from 'client-search/food-search-index';
import IconButton from 'components/@extended/IconButton';
import { useAuth } from 'context/appContext';
import { FoodSearchInput } from 'services/foodSearch/FoodSearchInput';
import { MixpanelMealItemSource } from 'types/DraftItem';
import { FoodSearchContext, useFoodSearch } from '../../../services/foodSearch/useFoodSearch';
import { FoodSearchResults } from './FoodDrawer';

export const FoodSearchDropdownInput = (props: {
  initialSearchText?: string,
  context: FoodSearchContext,
  autoFocus?: boolean,
  onCancel?: () => void,
  onItemSelect: (item: SearchItem, index: number, source: MixpanelMealItemSource) => void,
  onCustomSelect?: (item: SearchItem, index: number) => void,
  onSubmitSearch?: (searchText: string, promise: Promise<unknown>) => void,
}) => {
  const { authInfo } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchEfficiencyMetrics, setSearchEfficiencyMetrics] = React.useState({
    firstKeypressTimestamp: 0,
    keyPressCount: 0,
    searchCount: 0,
  });
  const foodSearch = useFoodSearch({
    context: props.context,
  });
  const [selection, setSelection] = useState(
    null as null | {
      idx: number,
      source: MixpanelMealItemSource,
      name: string,
      selectionType: 'select' | 'edit',
    },
  );
  const selectedFoodQuery = useQuery(['food-get-food', selection], async () => {
    if (!selection) {
      return null;
    }
    setAnchorEl(null);
    return getFoodByQuery(selection.name, authInfo!.access_token);
  });

  useEffect(() => {
    foodSearch.setActiveSearch({
      type: 'db',
      text: props.initialSearchText || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialSearchText]);

  useEffect(() => {
    if (!selectedFoodQuery.data || !selection) {
      return;
    }

    mixpanel.track('Food search: efficiency', {
      Source: selection.source,
      'Time to select': Date.now() - searchEfficiencyMetrics.firstKeypressTimestamp,
      'Keypress count': searchEfficiencyMetrics.keyPressCount,
      'Search count': searchEfficiencyMetrics.searchCount,
      'Query length': foodSearch.activeSearch.text.length,
      'Result length': foodSearch.activeSearch.text.length,
      'Query': foodSearch.activeSearch.text,
      'Result': selection.name,
      Index: selection.idx,
    });

    setSearchEfficiencyMetrics({
      firstKeypressTimestamp: 0,
      keyPressCount: 0,
      searchCount: 0,
    });

    setSelection(null);

    if (selection.selectionType === 'select') {
      props.onItemSelect(selectedFoodQuery.data, selection.idx, selection.source);
    } else if (selection.selectionType === 'edit') {
      props.onCustomSelect?.(selectedFoodQuery.data, selection.idx);
    } else {
      assertUnreachable(selection.selectionType, `Unexpected selection type: ${selection.selectionType}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchEfficiencyMetrics,
    foodSearch.activeSearch,
    selection,
    selectedFoodQuery.data,
  ]);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={0}>
          <Stack direction="row" spacing={0.25}>
            <FoodSearchInput
              foodSearch={foodSearch}
              autoFocus={props.autoFocus !== false}
              fullWidth
              onKeyUp={(e) => {
                setAnchorEl(e.currentTarget);
                if (e.code == 'Escape') {
                  foodSearch.setActiveSearch({
                    type: 'db',
                    text: props.initialSearchText || '',
                  });
                  setAnchorEl(null);
                }
              }}
              onFocus={(e) => {
                if (e.target.value) {
                  foodSearch.setActiveSearch({
                    type: 'db',
                    text: e.target.value,
                  });
                  setAnchorEl(e.currentTarget);
                }
              }}
              onChange={(evt: any) => {
                if (!searchEfficiencyMetrics.firstKeypressTimestamp) {
                  setSearchEfficiencyMetrics({
                    ...searchEfficiencyMetrics,
                    firstKeypressTimestamp: Date.now(),
                  });
                }
                setAnchorEl(evt.currentTarget);
              }}
            />
          </Stack>
          <Popper
            id="simple-popper"
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement="bottom-start"
            popperOptions={{
              modifiers: [
                { name: 'flip', enabled: false },
              ],
            }}
            sx={{
              width: anchorEl ? anchorEl.clientWidth + 45 : 0,
              backgroundColor: '#fff',
              zIndex: 9999,
              borderRadius: 1,
              borderColor: '#ddd',
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
              minWidth: 500,
            }}
          >
            <FoodSearchResults
              foodSearch={foodSearch}
              onCustomSelect={props.onCustomSelect && ((selection, idx, evt) => {
                setSelection({
                  idx,
                  source: selection.source,
                  name: selection.item.name,
                  selectionType: 'edit',
                });
                evt.preventDefault();
                evt.stopPropagation();
              })}
              onFoodSelect={(option, idx, evt) => {
                setSelection({
                  idx,
                  source: option.source,
                  name: option.item.name,
                  selectionType: 'select',
                });
              }}
            />
          </Popper>
        </Stack>
      </div>
    </ClickAwayListener>
  );
};
