/* eslint-disable react/destructuring-assignment */

import { DownOutlined, LoadingOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import {
  Add,
  ArrowDropDown,
  Cancel,
  ChevronRight,
  Delete,
  DragHandle,
  ExpandMore,
  FileUpload,
  Info,
  Launch,
  OpenInNew,
  Save,
  WarningOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  ButtonProps,
  Card,
  CardActionArea,
  Checkbox,
  ClickAwayListener,
  Collapse,
  Container,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQueries, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { foodApi, imageDetectionApi, translationsApi } from 'api';
import {
  FoodDetailsWarning,
  FoodOption,
  FoodWhiteboardCreateUpdateRequest,
  FoodWhiteboardItemResponse,
  ImageDetectionResultOcrNftParsedResultNftParsedNutrient,
  ImageDetectionResultOcrNftParsedResultNftParsedServing,
  ImageDetectionResultResponse,
  MealItemResponse,
  MealPhotoQueueResponse,
  TranslatedStringResponse,
  UsdaMeasure,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import { FoodOptionEditor } from 'components/FoodOptions';
import { PatientID } from 'components/PatientID';
import { PreviewImage } from 'components/queue/PreviewImage';
import { SpellCheck } from 'components/spell-check';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import { scaleLinear } from 'd3-scale';
import {
  apiFoodResponseToFoodResponse,
  ccFoodEditorValues,
  foodEditorValueToPartialFoodDetails,
} from 'food-editor/api-client';
import {
  applyDerivationsToFoodEditorValue,
  AUTOMATED_CC_RANKING_FIELD_NAMES,
  foodDetailsToDerivations,
} from 'food-editor/food-classification-derivation';
import { useFoodEditorFieldRelationship } from 'food-editor/food-editor-field-relationship';
import {
  FOOD_EDITOR_NUTRIENT_FIELDS,
  FOOD_EDITOR_NUTRIENTS_BY_EDITOR_FIELD,
  FoodEditorNutrientFieldDefinition,
  FoodEditorNutrientValue,
} from 'food-editor/food-editor-nutrient-fields';
import useUpdateWarnings from 'food-editor/hooks/use-update-warnings';
import foodEditorValueToFoodRequest from 'food-editor/utils/food-editor-value-to-food-request';
import { foodEditorGetErrors } from 'food-editor/utils/is-food-editor-valid';
import {
  NUTRIENT_RDA_VALUES,
  nutrientRdaPctToValue,
  nutrientValueIsRdaPct,
  nutrientValueToRdaPct,
} from 'food-editor/utils/NutrientRDAValues';
import { formatNumber } from 'food-editor/utils/utils';
import useLocalStorage from 'hooks/useLocalStorage';
import { useUsersOnPage } from 'layout/MainLayout/Header/HeaderContent';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { FoodSearchResults } from 'pages/QueueItem/meal-builder/FoodDrawer';
import { floatOrNull } from 'pages/QueueItem/meal-builder/MealBuilder';
import { getFoodEditorUrl } from 'pages/QueueItem/meal-builder/MealSummary';
import {
  nutritionxIdToUsdaNameMap,
  nutritionxNutrientsToUsdaNutrients,
} from 'pages/QueueItem/meal-builder/nutritionix-nutrients';
import React, { FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';
import { useFoodDetails } from 'services/FoodDetailsService';
import { FoodSearchInput } from 'services/foodSearch/FoodSearchInput';
import {
  _usdaResultGetMeasure,
  ExternalFoodSearchResult,
  externalFoodSearchResultToFoodEditorValue,
  useFoodSearch,
} from 'services/foodSearch/useFoodSearch';
import { encodeQueryParams, parseQueryError } from 'utils';
import { round, safeDivNull } from 'utils/numerical';
import { create } from 'zustand';
import useErrorState from '../hooks/use-error-state';
import { FoodSearchView } from '../screens/food-search-screen';
import { FoodEditorAttributions, FoodEditorValue, proteinTypeNames } from '../types';
import { assertUnreachable } from '../utils/assert-unreachable';
import foodResponseToFoodEditorValue from '../utils/food-response-to-food-editor-value';
import { useUpdatingState } from '../utils/useUpdatingState';
import termRequired from '../validations/term-required';
import FoodPicker from './food-picker';
import { FoodChangeLogs } from './FoodChangeLogs';
import {
  _compositeFoodLastCalculatedDetailsHack,
  FoodComponentBuilder,
  getComponentsTotalWeight,
} from './FoodComponentBuilder';
import { FoodComponentSummaryTable } from './FoodComponentSummaryTable';
import { FoodEditorNFTIngestionModal } from './FoodEditorNFTIngestionModal';
import { HumanTime } from './HumanTime';
import LowMediumHighPicker from './low-medium-high-picker';
import MixedDishComponentsEditor from './mixed-dish-components-editor';
import OntologyPicker, { useOntology } from './ontology-picker';
import RankingPicker from './ranking-picker';

type FoodEditorMode = 'create' | 'update';

export const ALCOHOL_PERCENT_TO_G_RATIO = 0.789;

function assertUnreachableUnsafe(x: never, message?: string): never {
  throw new Error(
    (message ? message + ' ' : '') + 'Reached unreachable statement: ' + JSON.stringify(x),
  );
}

export interface FoodStatusOption {
  value: string;
  label: string;
  disabled?: (state: FoodEditorState) => boolean;
}

export const FOOD_STATUS_OPTIONS: FoodStatusOption[] = [
  { value: 'triaged', label: 'Triaged' },
  { value: 'draft', label: 'Draft' },
  { value: 'under_review', label: 'Under review', disabled: state => state.mode == 'create' },
  { value: 'active', label: 'Active', disabled: state => state.mode == 'create' },
  { value: 'inactive', label: 'Inactive', disabled: state => state.mode == 'create' },
  { value: 'deleted', label: 'Delete', disabled: state => state.mode == 'create' },
];

export const FOOD_STATUS_TRANSITIONS = {
  created: ['triaged'],
  triaged: ['draft', 'deleted'],
  draft: ['under_review', 'deleted'],
  under_review: ['active', 'deleted'],
  active: ['inactive', 'under_review', 'deleted'],
  deleted: ['draft', 'under_review'],
} satisfies Record<string, string[]>;

const listToggle = (list: string[], value: string) => {
  if (list.includes(value)) {
    return list.filter(v => v !== value);
  }
  return [...list, value];
};

export interface FoodEditorProps {
  attributions?: FoodEditorAttributions;
  mode: FoodEditorMode;
  onChangeValue: (cb: (o: FoodEditorValue) => FoodEditorValue) => void;
  showErrors?: boolean;
  initialName: string | null;
  value: FoodEditorValue;
  foodResponseCallbackRef?: FoodResponseCallbackRef;
}

export type FoodEditorState = {
  hasAuth: (capability: Capabilities) => boolean,
  onFieldChange: (newValues: Partial<FoodEditorValue>) => void,
  termState: ReturnType<typeof useErrorState>,
  value: FoodEditorValue,
  initialName: string | null,
  showErrors?: boolean,
  mode: FoodEditorMode,
  warnings: FoodDetailsWarning[],
  updateWarnings: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement | HTMLButtonElement>,
  showWarnings: boolean,
  toggleWarnings: () => void,
  isDuplicate: boolean,
  termInputRef: React.RefObject<HTMLInputElement>,
  nfts?: ReturnType<typeof useFoodEditorWhiteboardNftImageDetection>,
};

export type FoodEditorField = {
  label: string,
  field: keyof FoodEditorValue,
  useEffect?: (state: FoodEditorState) => void,
  input:
    | ((state: FoodEditorState, field: FoodEditorField, isCompareTo: boolean) => JSX.Element | null)
    | 'text'
    | 'TextDiffLang'
    | 'multiline'
    | 'switch'
    | 'FoodPicker'
    | 'RankingPicker'
    | 'LowMediumHighPicker'
    | 'MixedDishComponentsEditor'
    | 'MeasuresEditor'
    | 'FoodComponentBuilder'
    | 'FoodImage'
    | 'AddRecipeServingToMeasures'
    | 'Divider'
    | ({ type: 'numeric', min: number })
    | ({ type: 'select', options: Array<{ value: string, label: string, disabled?: boolean }> })
    | ({ type: 'multiSelect', options: Array<{ value: string, label: string, disabled?: boolean }> })
    | ({ type: 'autocomplete', options?: string[] })
    | ({ type: '__UNKNOWN_TYPE_PLACEHOLDER__' }),
  viewOnly?: boolean,
  size?: number,
  derived?: (state: FoodEditorValue) => string | number | null,
  doNotCopy?: boolean,
  variant?: string,
  copyFrom?: (state: FoodEditorValue) => Partial<FoodEditorValue>,
};

type FoodEditorSection = {
  id: string,
  label: string | ((state: FoodEditorState) => string),
  SubHeaderComponent?: (props: { state: FoodEditorState, isCompareTo: boolean }) => JSX.Element | null,
  showForComposite?: boolean,
  columns?: number,
  fields: FoodEditorField[],
};

function nutrientFields(nutrients: FoodEditorNutrientFieldDefinition[]): FoodEditorField[] {
  return nutrients.map(n => ({
    label: n.label,
    field: n.editorField,
    viewOnly: !!n.derived,
    derived: n.derived,
    input: (state, field, isCompareTo) => {
      return (
        <NutrientInput
          field={field}
          state={state}
          isCompareTo={isCompareTo}
          boldLabel={n.boldLabel}
          indentLabel={n.indentLabel}
        />
      );
    },
  }));
}

function calculateNutrientWarningFlagNftComparison(
  field: FoodEditorField,
  state: FoodEditorState,
) {
  const nfts = state.nfts;
  const nftValueInputStr = nfts?.editorNutrientsPer100G?.[field.field] as string | undefined;
  const nftValue = floatOrNull(nftValueInputStr);
  if (nftValue === null) {
    return null;
  }

  const displayScaleValue = getDisplayScaleValue(state);
  const fieldValueStr = nutrientValueToDisplayValue(state.value[field.field] as any, displayScaleValue)[0];
  const nftValueStr = nutrientValueToDisplayValue(nftValue, displayScaleValue)[0];
  if (round(+fieldValueStr, 2) === round(+nftValueStr, 2)) {
    return null;
  }

  return (
    <span>
      Nutrient value ({fieldValueStr || <i>empty</i>} per {displayScaleValue}g){' '}
      is different from NFT value ({nftValueStr} per {displayScaleValue}g)
    </span>
  );
}

const FoodTermTextbox = (props: { state: FoodEditorState }) => {
  const { state } = props;
  const [foodName, setFoodName] = useState(state.value.term);

  useEffect(() => {
    if (state.value.term == foodName) {
      return;
    }
    setFoodName(state.value.term);
  }, [state.value.term]);

  return (
    <>
      {state.isDuplicate && state.mode == 'create' && <Alert severity="error">Duplicate Term</Alert>}
      <TextField
        label="Term"
        disabled={state.mode !== 'create'}
        error={state.termState.showError}
        helperText={state.termState.error}
        fullWidth
        onChange={evt => setFoodName(evt.target.value)}
        value={foodName}
        onBlur={() => {
          state.onFieldChange({ term: foodName });
          state.termState.onBlur();
        }}
        variant="standard"
        inputProps={{ maxLength: 255, ref: state.termInputRef }}
      />
      {state.mode === 'create' && (
        <SpellCheck
          term={state.value.term}
          updateSpelling={(correctSpelling) => {
            state.onFieldChange({ 'term': correctSpelling });
          }}
        />
      )}
      {state.initialName && state.initialName !== state.value.term && (
        <em style={{ margin: '1rem 0', color: '#777' }}>
          All custom items named '{state.initialName}' will be renamed to '{state.value.term}'
        </em>
      )}
    </>
  );
};

function calculateNutrientWarningFlag(
  field: FoodEditorField,
  state: FoodEditorState,
) {
  if (field.field != 'energyKcal') {
    return calculateNutrientWarningFlagNftComparison(field, state);
  }

  const values = state.value;
  const expectedVal = +(values.proteinG || 0) * 4
    + +(values.fatG || 0) * 9
    + +(values.carbohydrateG || 0) * 4
    + +(values.alcoholG || 0) * 7;

  const actualVal = values.energyKcal ?? 0;
  const diff = Math.abs(expectedVal - actualVal);
  const percentDiff = diff / (expectedVal || 1);
  if (percentDiff < 0.1) {
    return calculateNutrientWarningFlagNftComparison(field, state);
  }

  const formatNum = (num: number | string | null | undefined) => {
    const val = +(num as any);
    if (isNaN(val)) {
      return '-';
    }
    return val.toFixed(2);
  };

  return (
    <span>
      Energy value ({formatNum(actualVal)}) is more than 10% off from expected value: {formatNum(expectedVal)} (diff:
      {' '}
      {formatNum(diff)}, {formatNum(percentDiff * 100)}%)
      <br />
      Protein: {formatNum(values.proteinG)}g * 4 = {formatNum(values.proteinG ?? 0 * 4)}
      <br />
      Fat: {formatNum(values.fatG)}g * 9 = {formatNum(values.fatG ?? 0 * 9)}
      <br />
      Carbs: {formatNum(values.carbohydrateG)}g * 4 = {formatNum(values.carbohydrateG ?? 0 * 4)}
      <br />
      Alcohol: {formatNum(values.alcoholG)}g * 7 = {formatNum(values.alcoholG ?? 0 * 7)}
    </span>
  );
}

const EDITOR_SECTIONS: FoodEditorSection[] = [
  {
    id: 'basic',
    label: 'Basic',
    fields: [
      {
        label: 'Term',
        field: 'term',
        viewOnly: true,
        input: (state: FoodEditorState) => <FoodTermTextbox state={state} />,
      },
      {
        label: 'French Term',
        field: 'frenchTerm',
        input: 'TextDiffLang',
        doNotCopy: true,
      },
      {
        label: 'NDBNO',
        field: 'nutritionSourceId',
        viewOnly: true,
        input: {
          type: 'numeric',
          min: 0,
        },
      },

      {
        label: 'Food ID',
        field: 'ndbNumber',
        input: 'text',
        viewOnly: true,
      },

      {
        label: 'Status',
        field: 'status',
        input: FoodStatusInput,
        doNotCopy: true,
      },

      {
        label: 'Image',
        field: 'foodImageUrl',
        input: 'FoodImage',
      },

      {
        label: 'Nutrition Source URL',
        field: 'nutritionSourceUrl',
        input: 'text',
      },

      {
        label: 'Ontology',
        field: 'ontologyLevels',
        input: (state: FoodEditorState) => (
          <OntologyPicker
            onChangeValue={value => {
              state.onFieldChange({ 'ontologyLevels': value });
              if (!value.length) {
                return;
              }
              const isSupplement = value.length > 2
                ? value[0] == 'supplements' || value[1] == 'supplements'
                : value[0] == 'supplements';
              state.onFieldChange({ supplement: isSupplement });
            }}
            showErrors={state.showErrors}
            value={state.value.ontologyLevels || []}
          />
        ),
      },

      {
        label: 'Base Food Name',
        field: 'baseFoodName',
        input: 'text',
        doNotCopy: true,
      },

      {
        label: 'Root Food Name',
        field: 'rootFoodName',
        input: 'text',
        doNotCopy: true,
      },

      /*
      note: hidden for now because it was deffered until v1
      (see comments in 'apiFoodResponseToFoodResponse')
      {
        label: "USDA Nutrient Name",
        field: "usdaNutrientName",
        input: "text",
      },
      */

      {
        label: 'Other names',
        field: 'otherNames',
        input: {
          type: 'autocomplete',
        },
      },

      {
        label: 'Synonyms',
        field: 'synonyms',
        input: {
          type: 'autocomplete',
        },
        doNotCopy: true,
      },

      // {
      //   label: "Compounding Words",
      //   field: "compoundingWords",
      //   input: "autocomplete",
      // },

      // {
      //   label: 'Singular Term',
      //   field: 'singularTerm',
      //   input: 'text',
      // },

      // {
      //   label: 'Plural Term',
      //   field: 'pluralTerm',
      //   input: 'text',
      // },

      {
        label: 'Custom Tip',
        field: 'customTip',
        input: 'text',
        doNotCopy: true,
      },

      {
        label: 'Manufacturer',
        field: 'manufacturer',
        input: 'text',
      },

      {
        label: 'Similar Items',
        field: 'similarItems',
        input: 'FoodPicker',
      },

      // {
      //   label: "Connected Items",
      //   field: "connectedItems",
      //   input: "FoodPicker",
      // },

      {
        label: 'Adult Serving Size (g)',
        field: 'adultServingSizeG',
        useEffect: (state: FoodEditorState) => {
          const ontology = useOntology(state.value.ontologyLevels);
          const [prevOntology, setPrevOntology] = useState(ontology);

          useEffect(() => {
            if (!ontology || ontology == prevOntology) {
              return;
            }

            setPrevOntology(ontology);
            const shouldUpdate = (!!ontology && !!prevOntology) || state.mode == 'create';

            if (!shouldUpdate) {
              return;
            }

            const currentServingSize = floatOrNull(state.value.adultServingSizeG);
            const hasCustomServingSize = currentServingSize !== null
              && ontology?.adult_serving_size_g != currentServingSize;

            if (hasCustomServingSize) {
              if (
                !window.confirm(
                  `This food had a custom adult serving size. Do you want to update it to ${
                    ontology!.adult_serving_size_g
                  }g to match the new ontology?`,
                )
              ) {
                return;
              }
            }

            state.onFieldChange({
              adultServingSizeG: '' + (ontology!.adult_serving_size_g) || '',
              ghgEquivalentKg: '' + (ontology!.ghg_equivalent_kg) || '',
            });
          }, [ontology, prevOntology, state]);
        },
        input: {
          type: 'numeric',
          min: 0.01,
        },
      },

      {
        label: 'GHG Equivalent (kg CO2)',
        field: 'ghgEquivalentKg',
        // Note: the `useEffect` function on `adultServingSizeG` will set this field
        input: {
          type: 'numeric',
          min: 0.01,
        },
      },
    ],
  },

  {
    id: 'measures',
    label: 'Measures',
    fields: [
      {
        label: 'Measures',
        field: 'measures',
        input: 'MeasuresEditor',
      },
    ],
  },

  {
    id: 'components',
    label: 'Components',
    showForComposite: true,
    columns: 3,
    fields: [
      {
        label: 'Recipe Srv Unit',
        field: 'listedServingUnitLabel',
        input: 'text',
        size: 3,
      },
      {
        label: 'Recipe Srv Count',
        field: 'listedServingCount',
        input: {
          type: 'numeric',
          min: 0,
        },
        size: 3,
      },
      {
        label: 'Recipe (g/Srv)',
        field: 'listedServingGPerServing',
        input: {
          type: 'numeric',
          min: 0,
        },
        useEffect: (state: FoodEditorState) => {
          useEffect(() => {
            const servings = +state.value.listedServingCount;
            if (isNaN(servings) || servings <= 0) {
              return;
            }
            const gramPerServing = formatNumber(
              getComponentsTotalWeight(state.value.components)
                / servings,
              4,
            );
            state.onFieldChange({
              listedServingGPerServing: gramPerServing,
            });
          }, [state.value.listedServingCount, state.value.components]);
        },
        viewOnly: true,
        size: 3,
      },
      {
        label: 'Add to Measures',
        field: 'measures',
        input: 'AddRecipeServingToMeasures',
        size: 3,
      },
      {
        label: 'Components',
        field: 'components',
        doNotCopy: true,
        input: 'FoodComponentBuilder',
        size: 12,
      },
    ],
  },
  {
    id: 'nutrients',
    label: state => `Nutrients (per ${getDisplayScaleValue(state)}g)`,
    SubHeaderComponent: (props) => {
      const { state, isCompareTo } = props;
      return <NutrientSectionNftSubheader state={state} isCompareTo={isCompareTo} />;
    },
    columns: 3,
    fields: [
      {
        label: 'Sug Srv Count',
        field: 'suggestedServingCount',
        input: {
          type: 'numeric',
          min: 1,
        },
        size: 3,
      },
      {
        label: 'Sug Srv Unit',
        field: 'suggestedServingUnitLabel',
        input: 'text',
        size: 3,
      },
      {
        label: 'Sug Srv Amount (g)',
        field: 'suggestedServingAmountG',
        input: (state, field, isCompareTo) => {
          return <ServingAmountInput state={state} isCompareTo={isCompareTo} />;
        },
        size: 6,
      },
      {
        label: 'Edit values per',
        field: 'ephemeralNutrientValueDisplayScale',
        input: (state, field, isCompareTo) => {
          const invalidSugServ = !state.value.suggestedServingAmountG;
          const invalidAdultServ = !state.value.adultServingSizeG;
          if (invalidSugServ && invalidAdultServ) {
            return null;
          }
          const displayScaleValue = getDisplayScaleValue(state);
          const value = state.value.ephemeralNutrientValueDisplayScale
            || (displayScaleValue == NUTRIENT_100G
              ? '100g'
              : !!(+state.value.suggestedServingAmountG) && displayScaleValue == +state.value.suggestedServingAmountG
              ? 'sug'
              : 'adult serving');
          return (
            <Stack sx={{ mb: 3 }}>
              <ToggleButtonGroup
                id="#ephemeralNutrientValueDisplayScale"
                exclusive
                value={value}
                onChange={(evt, newValue) => {
                  if (newValue) {
                    state.onFieldChange({ ephemeralNutrientValueDisplayScale: newValue });
                  }
                }}
                color="primary"
                fullWidth
              >
                <ToggleButton value="100g">Per 100g</ToggleButton>
                {!invalidAdultServ && (
                  <ToggleButton value="adult serving">
                    Per {state.value.adultServingSizeG}g (adult serv. size)
                  </ToggleButton>
                )}
                {!invalidSugServ && (
                  <ToggleButton value="sug">Per {state.value.suggestedServingAmountG}g (sug serv. size)</ToggleButton>
                )}
              </ToggleButtonGroup>
            </Stack>
          );
        },
        size: 12,
      },
      ...nutrientFields(FOOD_EDITOR_NUTRIENT_FIELDS),
    ],
  },

  {
    id: 'classifications / rankings',
    label: 'Classifications / Rankings',
    SubHeaderComponent: (props) => {
      const { state } = props;
      const estimates = useFoodEditorFieldValueEstimates(state, '__ignored__' as any);
      const queryClient = useQueryClient();
      const flags = useFeatures();

      if (!flags.food_editor_estimates) {
        return null;
      }

      return (
        <div>
          <Typography variant="body2" style={{ marginBottom: 10 }}>
            Estimates: {estimates.query.isLoading && 'loading...'}
            {estimates.query.isError && `error: ${parseQueryError(estimates.query.error)}`}
            {estimates.query.data?.error && `error: ${estimates.query.data.error}`}
            {estimates.query.data?.fields && `loaded ${estimates.query.data.fields.length} fields`}
            {!estimates.query.isLoading && (
              <>
                {' '}
                (
                <a
                  href="#"
                  onClick={() => {
                    queryClient.resetQueries({ queryKey: ['food-editor-estimates'] });
                  }}
                >
                  refresh
                </a>
                )
              </>
            )}
          </Typography>
        </div>
      );
    },
    fields: [
      {
        label: 'Fat Type',
        field: 'fatType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'healthy', label: 'Unsaturated' },
            { value: 'unhealthy', label: 'Saturated' },
            { value: 'both', label: 'Both' },
          ],
        },
      },
      {
        label: 'Unsaturated Fat Ranking',
        field: 'rankingHealthyFat',
        input: 'RankingPicker',
      },
      {
        label: 'Saturated Fat Ranking',
        field: 'rankingUnhealthyFat',
        input: 'RankingPicker',
      },
      {
        label: 'Both Fat Ranking',
        field: 'rankingBothFat',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'fatType',
        input: 'Divider',
      },

      {
        label: 'Carbohydrate Type',
        field: 'carbohydrateType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'simple', label: 'Simple' },
            { value: 'complex', label: 'Complex' },
          ],
        },
      },
      {
        label: '',
        field: 'carbohydrateType',
        input: 'Divider',
      },

      {
        label: 'Potassium Level',
        field: 'potassiumLevel',
        input: 'LowMediumHighPicker',
      },
      {
        label: 'Low Potassium Ranking',
        field: 'rankingLowK',
        input: 'RankingPicker',
      },
      {
        label: 'High Potassium Ranking',
        field: 'rankingHighK',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'potassiumLevel',
        input: 'Divider',
      },

      {
        label: 'Protein Type',
        field: 'proteinType',
        input: {
          type: 'multiSelect',
          options: [
            ...(proteinTypeNames.map((name) => {
              return { value: name, label: name };
            })),
          ],
        },
      },
      {
        label: '',
        field: 'proteinType',
        input: 'Divider',
      },

      {
        label: 'Fruit & Vegetable Type',
        field: 'fruitAndVegetableType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'fruit', label: 'Fruit' },
            { value: 'vegetable', label: 'Vegetable' },
            { value: 'both', label: 'Both' },
          ],
        },
      },
      {
        label: 'Fruit Ranking',
        field: 'rankingFruit',
        input: 'RankingPicker',
      },
      {
        label: 'Vegetable Ranking',
        field: 'rankingVegetable',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'fruitAndVegetableType',
        input: 'Divider',
      },

      {
        label: 'Grain Type',
        field: 'grainType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'whole', label: 'Whole' },
            { value: 'refined', label: 'Refined' },
          ],
        },
      },
      {
        label: 'Whole Grains Ranking',
        field: 'rankingWholeGrains',
        input: 'RankingPicker',
      },
      {
        label: 'Refined Grains Ranking',
        field: 'rankingRefinedGrains',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'grainType',
        input: 'Divider',
      },

      {
        label: 'Processing Type',
        field: 'processedType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'whole_food', label: 'Whole Food' },
            { value: 'processed', label: 'Processed' },
            { value: 'ultra_processed', label: 'Ultra-Processed' },
          ],
        },
      },
      {
        label: 'Ultra-Processed Ranking',
        field: 'rankingUp',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'processedType',
        input: 'Divider',
      },

      {
        label: 'Preparation Type',
        field: 'preparationType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'raw', label: 'Raw' },
            { value: 'cooked', label: 'Cooked' },
          ],
        },
      },
      {
        label: '',
        field: 'preparationType',
        input: 'Divider',
      },

      {
        label: 'Glycemic Index Level',
        field: 'giLevel',
        input: 'LowMediumHighPicker',
      },
      {
        label: 'Low GI Ranking',
        field: 'rankingLowGi',
        input: 'RankingPicker',
      },
      {
        label: 'High GI Ranking',
        field: 'rankingHighGi',
        input: 'RankingPicker',
      },
      {
        label: '',
        field: 'giLevel',
        input: 'Divider',
      },

      {
        label: 'Beverage Type',
        field: 'beverageType',
        input: {
          type: 'select',
          options: [
            { value: '', label: '' },
            { value: 'sugary', label: 'Sugary' },
            { value: 'non_sugary', label: 'Non-Sugary' },
          ],
        },
      },
      {
        label: '',
        field: 'beverageType',
        input: 'Divider',
      },

      {
        label: 'Plant-Based',
        field: 'plantBased',
        input: 'switch',
      },
      {
        label: 'Plant-Based Ranking',
        field: 'rankingPb',
        input: 'RankingPicker',
      },

      {
        label: 'Nuts & Seeds',
        field: 'nutsOrSeeds',
        input: 'switch',
      },
      {
        label: 'High Calcium',
        field: 'highCalcium',
        input: 'switch',
      },

      {
        label: 'High Fibre',
        field: 'highFibre',
        input: 'switch',
      },

      {
        label: 'Fried',
        field: 'fried',
        input: 'switch',
      },

      {
        label: 'Gluten Free',
        field: 'glutenFree',
        input: 'switch',
      },

      {
        label: 'Preferred Add-On',
        field: 'preferredAddOn',
        input: 'switch',
      },

      {
        label: 'Has Additives',
        field: 'additives',
        input: 'switch',
      },
      {
        label: 'Source of Omega-3',
        field: 'omegaThree',
        input: 'switch',
      },
      {
        label: 'Supplement',
        field: 'supplement',
        input: 'switch',
      },
    ],
  },

  {
    id: 'options',
    label: 'Options',
    fields: [
      {
        label: 'Options',
        field: 'optionValuesRaw',
        input: (state: FoodEditorState) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const onChange = React.useCallback((optionId: string, value: string) => {
            const { optionCollection, optionValuesRaw } = state.value;
            const option = optionCollection?.options?.find((o: FoodOption) => o.id === optionId);
            if (!option) {
              return;
            }
            const setOptionValue = (value: string | string[] | undefined) => {
              console.log('setOptionValue:', optionId, optionValuesRaw?.[optionId], '->', value);
              const newOptionValuesRaw = { ...optionValuesRaw };
              if (value !== undefined) {
                newOptionValuesRaw[optionId] = value;
              } else {
                delete newOptionValuesRaw[optionId];
              }
              state.onFieldChange({
                optionValuesRaw: newOptionValuesRaw,
              });
            };

            if (option.cardinality === 'single') {
              setOptionValue(value == optionValuesRaw?.[optionId] ? undefined : value);
            } else {
              const currentValue = optionValuesRaw?.[optionId] as string[] || [];
              setOptionValue(listToggle(currentValue, value));
            }
          }, [state]);

          if (!state.value.optionCollection) {
            return <div>Options not available (no collection included)</div>;
          }

          return (
            <FoodOptionEditor
              schema={state.value.optionCollection}
              values={state.value.optionValuesRaw || {}}
              onChange={onChange}
            />
          );
        },
      },
    ],
  },

  {
    id: 'mixed-dish-percent',
    label: 'Mixed Dish Percent',
    fields: [
      {
        label: 'Mixed Dish Components',
        field: 'mixedDishComponents',
        input: 'MixedDishComponentsEditor',
      },
    ],
  },

  {
    id: 'misc',
    label: 'Misc',
    fields: [
      {
        label: 'USDA FDCID',
        field: 'fdcid',
        input: 'text',
      },
      {
        label: 'CAD Nutrition Facts ID',
        field: 'cnFoodId',
        input: 'text',
      },
      {
        label: 'Notes',
        field: 'notes',
        input: 'multiline',
      },
      /*
      Disabled for now until I figure out how to handle it.
      {
        label: "Suggested Item",
        field: "suggestedItem",
        input: "switch",
      },
      */

      /*
      Ignored per comments in apiFoodResponseToFoodResponse
      {
        label: "Disabled",
        field: "disabled",
        input: "switch",
      },
      */
      {
        label: 'Status',
        field: 'status',
        input: FoodStatusInput,
        doNotCopy: true,
      },
    ],
  },
];

const OverrideInputWrapper = (props: {
  state: FoodEditorState,
  isCompareTo: boolean,
  field: FoodEditorField,
}) => {
  const { field, state, isCompareTo } = props;
  const hasBeenOverridden = field.field in (state.value.overrideValues || {});
  const fieldValue = state.value[field.field];

  const handleOverride = () => {
    if (!hasBeenOverridden) {
      state.onFieldChange({
        overrideValues: {
          ...state.value.overrideValues,
          [field.field]: state.value[field.field],
        },
      });
    } else {
      const newOverrideValues = Object.fromEntries(
        Object.entries(state.value.overrideValues || {}).filter(([key, value]) => key != field.field),
      );
      const newVals: Partial<FoodEditorValue> = {
        overrideValues: _.isEmpty(newOverrideValues) ? null : newOverrideValues,
      };
      if (_compositeFoodLastCalculatedDetailsHack?.[field.field] !== undefined) {
        newVals[field.field] = _compositeFoodLastCalculatedDetailsHack[field.field] as any;
      }
      state.onFieldChange(newVals);
    }
  };

  useEffect(() => {
    if (hasBeenOverridden) {
      state.onFieldChange({
        overrideValues: {
          ...state.value.overrideValues,
          [field.field]: state.value[field.field],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, hasBeenOverridden]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={11}>
        <FoodEditorFieldViewInner
          field={{ ...field, viewOnly: !hasBeenOverridden || isCompareTo }}
          state={state}
          isCompareTo={isCompareTo}
        />
      </Grid>
      <Tooltip title="Override Value?" placement="left">
        <Grid item xs={1}>
          <span>
            <Checkbox
              disabled={isCompareTo}
              checked={hasBeenOverridden}
              onChange={handleOverride}
            />
          </span>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

const ServingAmountInput = (props: {
  state: FoodEditorState,
  isCompareTo: boolean,
}) => {
  const { state, isCompareTo } = props;
  const updateNutrientsPerGramValue = (
    buttonOption: 'update' | 'updateWithoutScaling' | 'scaleWithoutUpdate' | 'scaleAndMatch' | 'scaleAndMatchServing',
  ) => {
    const newValue = +servingAmountValue;
    if (isNaN(newValue) || newValue <= 0) {
      return;
    }

    if (state.value.definitionType == 'composite') {
      const measures: UsdaMeasure[] = state.value.measures.map((m, idx) => ({
        ...m,
        ranking: m.ranking ? +m.ranking : null,
        addon: m.addon ? m.addon : false,
      }));
      const measureLabel = (!state.value.suggestedServingCount || !state.value.suggestedServingUnitLabel)
        ? 'serving'
        : 'serving (' + state.value.suggestedServingCount + ' ' + state.value.suggestedServingUnitLabel + ')';
      const newSuggestedMeasure = {
        label: measureLabel,
        eqv: newValue,
        qty: 1,
        root_label: 'suggested serving',
      } as UsdaMeasure;

      if (!measures.some(m => m.root_label == 'suggested serving')) {
        measures.push(newSuggestedMeasure);
      } else {
        measures[measures.findIndex(m => m.root_label == 'suggested serving')] = newSuggestedMeasure;
      }
      state.onFieldChange({ measures: measures });
    }

    const fieldsToChange: Partial<FoodEditorValue> = {};
    const hasExistingValues = FOOD_EDITOR_NUTRIENT_FIELDS
      .map(f => state.value[f.editorField])
      .filter(value => !!value)
      .length > 0;

    if (buttonOption == 'update' || buttonOption == 'updateWithoutScaling') {
      mixpanel.track('Food editor: update nutrients per N grams', {
        Value: newValue,
      });

      fieldsToChange.suggestedServingAmountG = servingAmountValue;

      const updateValue = confirm(
        `Change suggested serving amount to ${newValue}g without re-calculating nutrients per 100g?`
          + `\nNutrients will be displayed per ${newValue}g.`,
      );

      if (updateValue) {
        state.onFieldChange(fieldsToChange);
      }

      setButtonOptions({
        ...buttonOptions,
        canEdit: false,
      });
      return;
    }

    const scale = getScaleFactor({
      inverse: buttonOption == 'scaleAndMatch',
      serving: buttonOption == 'scaleAndMatchServing',
    });
    if (buttonOption == 'scaleAndMatch' || buttonOption == 'scaleAndMatchServing') {
      fieldsToChange.suggestedServingAmountG = servingAmountValue;
      const updateValue = confirm(
        `Scale nutrients by ${scale.toFixed(2)}x and change suggested serving amount to ${newValue}g?`,
      );
      if (!updateValue) {
        return;
      }
    } else if (buttonOption == 'scaleWithoutUpdate') {
      const shouldUpdateExistingValues = hasExistingValues
        && confirm(`Scale nutrients by ${scale.toFixed(2)}x without updating suggested serving amount?`);

      if (!shouldUpdateExistingValues) {
        return;
      }
    } else {
      assertUnreachableUnsafe(buttonOption);
    }

    FOOD_EDITOR_NUTRIENT_FIELDS.forEach((f) => {
      const existingValue = state.value[f.editorField] as FoodEditorNutrientValue;
      if (existingValue) {
        fieldsToChange[f.editorField] = existingValue * scale;
      }
    });

    if ('suggestedServingAmountG' in fieldsToChange) {
      fieldsToChange.ephemeralNutrientValueDisplayScale = 'sug';
    }

    state.onFieldChange(fieldsToChange);
    setButtonOptions({
      ...buttonOptions,
      canEdit: false,
    });
  };

  const clearZeros = () => {
    if (!confirm('Clear all nutrient values that are 0?')) {
      return;
    }
    const fieldsToChange: any = {};
    FOOD_EDITOR_NUTRIENT_FIELDS.forEach(f => {
      const existingValue = state.value[f.editorField] as number;
      if (existingValue === 0) {
        fieldsToChange[f.editorField] = null;
      }
    });
    state.onFieldChange(fieldsToChange);
  };

  const handleClearClick = () => {
    const res = prompt(
      `Clear what?\n`
        + `- zeros: clear all nutrient values that are 0\n`
        + `- all: clear all nutrient values\n`,
    );
    switch (res) {
      case 'zeros':
        clearZeros();
        break;
      case 'all':
        if (!confirm('Clear all nutrient values?')) {
          return;
        }
        state.onFieldChange(Object.fromEntries(FOOD_EDITOR_NUTRIENT_FIELDS.map(f => [f.editorField, null])));
        break;
      default:
        alert("Neither 'zeros' or 'all' selected; doing nothing.");
    }
  };

  const [buttonOptions, setButtonOptions] = useState({
    canEdit: false,
    open: false,
  });
  const [servingAmountValue, setServingAmountValue] = useState(state.value.suggestedServingAmountG);
  const getScaleFactor = (opts: { inverse?: boolean, serving?: boolean }) => {
    const denominator = opts.serving ? +state.value.suggestedServingAmountG : NUTRIENT_100G;
    let res = +servingAmountValue / denominator;
    if (opts.inverse || opts.serving) {
      res = 1 / res;
    }
    return res;
  };
  const firstNutDef = FOOD_EDITOR_NUTRIENT_FIELDS.find(f => {
    const existingValue = state.value[f.editorField] as FoodEditorNutrientValue;
    if (existingValue) {
      return f;
    }
  }) || null;

  const firstNutVal = firstNutDef && +(state.value[firstNutDef.editorField] ?? 0);

  const scaleExample = (opts: { inverse?: boolean, serving?: boolean }) => {
    return !!firstNutDef && !!firstNutVal && (
      <span>
        For example, <i>{firstNutDef.label}</i> will be changed<br />
        <i>{(firstNutVal).toFixed(2)}</i> =&gt; <i>{(firstNutVal * getScaleFactor(opts)).toFixed(2)}</i> per 100g.<br />
      </span>
    );
  };

  const anchorRef = useRef<HTMLDivElement>(null);
  const options = ([
    {
      label: 'Change suggested serving amount only',
      subheading: (
        <span>
          Suggested serving amount is changed to <i>{servingAmountValue}g</i>.<br />
          Nutrient values per 100g remain unchanged.<br />
          Per-serving nutrient values will change.
        </span>
      ),
      action: 'updateWithoutScaling',
    },

    {
      label: `Change amount and scale nutrents per serving`,
      hidden: !state.value.suggestedServingAmountG,
      subheading: (
        <span>
          Suggested serving amount is changed to <i>{servingAmountValue}g</i>.<br />
          Nutrient values per 100g by are scaled by <i>{(getScaleFactor({ serving: true })).toFixed(2)}x</i>.<br />
          Per-serving nutrient remain unchanged.
        </span>
      ),
      action: 'scaleAndMatchServing',
    },

    {
      label: `Change amount and scale nutrents per 100g`,
      hidden: !!state.value.suggestedServingAmountG,
      subheading: (
        <span>
          Suggested serving amount is changed to {servingAmountValue}g.<br />
          Nutrient values per 100g by are scaled by <i>{(getScaleFactor({ inverse: true })).toFixed(2)}x</i>.<br />
          Per-serving nutrient values will change.<br />
          {scaleExample({ inverse: true })}
          Note: use this option when nutrients were entered<br />per{' '}
          {servingAmountValue}g, but a serving amount was not previously<br />
          defined.
        </span>
      ),
      action: 'scaleAndMatch',
    },

    {
      label: 'Re-calculate nutrients without changing amount',
      hidden: true, // note: this option doesn't seem to be useful, so hide it for now
      subheading: (
        <span>
          Suggested serving amount remains unchanged.<br />
          Nutrient values per 100g are scaled by <i>{getScaleFactor({ inverse: false }).toFixed(2)}x</i>.<br />
          Per-serving nutrient values will change.<br />
          {scaleExample({ inverse: false })}
        </span>
      ),
      action: 'scaleWithoutUpdate',
    },

    {
      label: 'Cancel',
      action: 'cancel',
      subheading: null,
    },
  ] as const).filter(o => 'hidden' in o ? !o.hidden : true);

  useEffect(() => {
    setServingAmountValue('' + state.value.suggestedServingAmountG);
  }, [state.value]);

  const handleClose = (event: Event) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setButtonOptions({
      ...buttonOptions,
      open: false,
    });
  };

  const handleMenuItemClick = (action: typeof options[number]['action']) => {
    if (action == 'cancel') {
      setServingAmountValue('' + state.value.suggestedServingAmountG);
      setButtonOptions({
        ...buttonOptions,
        canEdit: false,
        open: false,
      });
      return;
    }
    updateNutrientsPerGramValue(action);
  };

  const hasLabelOrCount = !!(state.value.suggestedServingUnitLabel || state.value.suggestedServingCount);
  const inputError = hasLabelOrCount && !servingAmountValue ? 'Serving amount is required' : null;

  const hasZeroValues = FOOD_EDITOR_NUTRIENT_FIELDS
    .map(f => state.value[f.editorField])
    .filter(value => value === 0)
    .length > 0;

  const canEdit = !isCompareTo && (
    !state.value.suggestedServingAmountG
    || buttonOptions.canEdit
  );

  return (
    <Stack spacing={0}>
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          id={isCompareTo ? 'suggestedServingAmountG-compare' : 'suggestedServingAmountG'}
          disabled={!canEdit}
          inputProps={{ step: 'any', min: 0 }}
          name="suggestedServingAmountG"
          label="Sug Srv Amount (g)"
          onChange={evt => {
            evt.target.focus();
            setServingAmountValue(evt.target.value);
          }}
          type="number"
          error={!!inputError}
          value={canEdit
            ? servingAmountValue?.toString() || ''
            : servingAmountValue?.toString() || state.value.suggestedServingAmountG}
          variant="standard"
          style={{
            minWidth: 75,
          }}
          onBlur={(evt) => {
            state.updateWarnings(evt);
            // saveUponInputBlur(evt, servingAmountValue);
          }}
        />
        {!isCompareTo && (
          <>
            {!canEdit && (
              <Button
                style={{ height: 55 }}
                onClick={() =>
                  setButtonOptions({
                    ...buttonOptions,
                    canEdit: true,
                  })}
              >
                Edit
              </Button>
            )}
            {canEdit && (
              <>
                <ButtonGroup style={{ height: 45 }} ref={anchorRef}>
                  <Button
                    onClick={() => updateNutrientsPerGramValue('update')}
                    size="small"
                    disabled={!servingAmountValue}
                  >
                    Update
                  </Button>
                  <Button
                    size="small"
                    aria-controls={buttonOptions.open ? 'split-button-menu' : undefined}
                    aria-expanded={buttonOptions.open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    style={{ padding: 0 }}
                    disabled={!servingAmountValue}
                    onClick={() => {
                      if (!servingAmountValue) {
                        return;
                      }
                      setButtonOptions({
                        ...buttonOptions,
                        open: !buttonOptions.open,
                      });
                    }}
                  >
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={buttonOptions.open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option) => (
                              <MenuItem
                                key={option.action}
                                onClick={() => handleMenuItemClick(option.action)}
                              >
                                <ListItemText primary={option.label} secondary={option.subheading} />
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            <Button color="warning" onClick={handleClearClick}>Clear</Button>
          </>
        )}
      </Stack>
      {inputError && <FormHelperText error>{inputError}</FormHelperText>}
      {state.value.suggestedServingCount && +state.value.suggestedServingCount != 1 && servingAmountValue && (
        <FormHelperText>
          <i>
            {`1 ${state.value.suggestedServingUnitLabel} is ${
              parseFloat((+servingAmountValue / +state.value.suggestedServingCount).toFixed(2))
            }g`}
          </i>
        </FormHelperText>
      )}
    </Stack>
  );
};

export const NUTRIENT_100G = 100;
export const nutrientScale = (opts: {
  value: number | string | null | undefined,
  from: number | string | null | undefined,
  to: number | string | null | undefined,
}) => {
  const value = floatOrNull(opts.value);
  if (value === null) {
    return null;
  }
  const from = floatOrNull(opts.from);
  if (!from) {
    return null;
  }
  const to = floatOrNull(opts.to);
  if (to === null || !to) {
    return null;
  }

  if (from === to) {
    return value;
  }

  const newValue = (value / from) * to;

  if (isNaN(newValue)) {
    return null;
  }

  return newValue;
};

export const nutrientValueToStrOrEmpty = (value: number | string | null | undefined): string => {
  const valueFloat = floatOrNull(value);
  if (valueFloat === null) {
    return '';
  }
  return round(valueFloat, 4).toString();
};

const nutrientValueToDisplayValue = (input: FoodEditorNutrientValue, scaleG: number): [string, string | null] => {
  if (typeof input === 'string') {
    // if (config.IS_DEV) {
    //   throw new Error('Invalid input: nutrient input should be number or null; got: ' + JSON.stringify(input));
    // }
    input = +input;
  }

  if (typeof input !== 'number') {
    return ['', null];
  }

  const displayValue = nutrientScale({
    value: input,
    from: NUTRIENT_100G,
    to: scaleG,
  });
  return [nutrientValueToStrOrEmpty(displayValue), null];
};

const nutrientDisplayValueToValue = (displayValue: string, scaleG: number): [string, string | null] => {
  if (displayValue === '' || displayValue === undefined || displayValue === null) {
    return ['', null];
  }

  const value = +displayValue;
  if (isNaN(value)) {
    return [displayValue, `Invalid number: ${displayValue}`];
  }

  const resValue = nutrientScale({
    value,
    from: scaleG,
    to: NUTRIENT_100G,
  });
  return [nutrientValueToStrOrEmpty(resValue), null];
};

const getDisplayScaleValue = (state: FoodEditorState) => {
  if (!state.value.ephemeralNutrientValueDisplayScale) {
    if (state.value.suggestedServingAmountG) {
      return +state.value.suggestedServingAmountG;
    }
    return NUTRIENT_100G;
  }

  return state.value.ephemeralNutrientValueDisplayScale == 'adult serving'
    ? +state.value.adultServingSizeG
    : state.value.ephemeralNutrientValueDisplayScale == 'sug'
    ? +state.value.suggestedServingAmountG
    : NUTRIENT_100G;
};

const NutrientInput = (props: {
  field: FoodEditorField,
  state: FoodEditorState,
  isCompareTo: boolean,
  boldLabel?: boolean,
  indentLabel?: 1 | 2,
}) => {
  const { field, state, isCompareTo } = props;
  const theme = useTheme();
  const isCompositeFood = state.value.definitionType == 'composite';
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const displayScaleG = getDisplayScaleValue(state);

  const [displayValue, setDisplayValue] = useState('');
  const [displayValueError, setDisplayValueError] = useState(null as null | string);
  useEffect(() => {
    const [dv, error] = nutrientValueToDisplayValue(
      state.value[field.field] as FoodEditorNutrientValue,
      field.field == 'alcoholPercent' ? 100 : displayScaleG,
    );
    setDisplayValue(dv);
    setDisplayValueError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value[field.field], displayScaleG]);

  const nutrientRDAValues: Partial<FoodEditorValue> = NUTRIENT_RDA_VALUES;
  const hasRDA = !!nutrientRDAValues[field.field];

  const handleFieldChange = (evt: any) => {
    if (
      evt.target.validity.patternMismatch
      || (!hasRDA && evt.target.value.includes('%'))
    ) {
      return;
    }
    setDisplayValue(evt.target.value);
  };

  const saveUponInputBlur = () => {
    if (hasRDA && nutrientValueIsRdaPct(field.field, displayValue)) {
      const nutrientVal = nutrientRdaPctToValue(field.field, displayValue);
      if (typeof nutrientVal !== 'number') {
        setDisplayValueError(`Invalid percentage: ${displayValue}`);
        return;
      }
      state.onFieldChange({ [field.field]: nutrientVal / getDisplayScaleValue(state) * NUTRIENT_100G });
      return;
    }

    if (displayValue === '') {
      state.onFieldChange({ [field.field]: null });
      return;
    }

    const value = field.field == 'alcoholPercent' ? +displayValue : nutrientScale({
      value: displayValue,
      from: displayScaleG,
      to: NUTRIENT_100G,
    });
    if (typeof value !== 'number') {
      setDisplayValueError(`Invalid number: ${displayValue}`);
      return;
    }
    state.onFieldChange({ [field.field]: value });
    if (field.field == 'alcoholG') {
      state.onFieldChange({ alcoholPercent: value / ALCOHOL_PERCENT_TO_G_RATIO });
      return;
    }
    if (field.field == 'alcoholPercent') {
      state.onFieldChange({ alcoholG: value * ALCOHOL_PERCENT_TO_G_RATIO });
    }
  };

  const warningFlag = displayValueError || calculateNutrientWarningFlag(field, state);

  const [previewValueUnrounded, previewValueError] = nutrientDisplayValueToValue(displayValue, displayScaleG);
  const previewValue = previewValueUnrounded ? parseFloat(previewValueUnrounded).toFixed(2) : '';
  const fieldLabel = FOOD_EDITOR_NUTRIENTS_BY_EDITOR_FIELD[field.field]?.label ?? '';
  const unit = /.*\((.*)\)/.exec(fieldLabel)?.[1] ?? '';

  return (
    <div style={{ position: 'relative' }}>
      {inputHasFocus && (
        <div
          style={{
            position: 'absolute',
            top: -10,
            left: -10,
            right: -10,
            border: '1px solid #ccc',
            zIndex: 100,
            boxShadow: theme.shadows[3],
            background: 'white',
          }}
        >
          <div
            style={{
              marginTop: 60,
              padding: 10,
            }}
          >
            {displayScaleG != NUTRIENT_100G && field.field != 'alcoholPercent' && (
              <Typography>
                <i>
                  {previewValue
                    ? `≈ ${previewValue}${unit} per ${NUTRIENT_100G}g`
                    : previewValueError}
                </i>
              </Typography>
            )}
            {displayScaleG == NUTRIENT_100G && field.field != 'alcoholPercent' && (
              <Typography>
                <i>per {NUTRIENT_100G}g</i>
              </Typography>
            )}
            {warningFlag && <Alert severity="error">{warningFlag}</Alert>}
          </div>
        </div>
      )}
      <TextField
        fullWidth
        id={isCompareTo ? field.field + '-compare' : field.field}
        inputProps={{ pattern: '[0-9]*\\.?[0-9]*%?' }}
        InputLabelProps={{
          shrink: props.boldLabel ? undefined : true,
          style: {
            color: isNaN(+displayValue) || displayValue === '' ? 'rgb(242, 82, 82)' : undefined,
            fontWeight: props.boldLabel ? 'bold' : undefined,
            paddingLeft: `${(props.indentLabel ?? 0) * 15}px`,
          },
        }}
        InputProps={{
          endAdornment: (
            hasRDA || (warningFlag && !isCompareTo)
              ? (
                <InputAdornment
                  style={field.viewOnly || isCompositeFood || isCompareTo
                    ? { color: theme.palette.text.disabled }
                    : { cursor: 'default' }}
                  position="end"
                >
                  {!!warningFlag && !isCompareTo && (
                    <Tooltip title={warningFlag}>
                      <WarningOutlined style={{ color: theme.palette.warning.main }} />
                    </Tooltip>
                  )}
                  {hasRDA && nutrientValueToRdaPct(field.field, displayValue)}
                </InputAdornment>
              )
              : undefined
          ),
        }}
        label={field.label}
        disabled={field.viewOnly || isCompositeFood || isCompareTo}
        onChange={evt => {
          evt.target.focus();
          handleFieldChange(evt);
        }}
        value={displayValue?.toString() || ''}
        variant="standard"
        onFocus={() => setInputHasFocus(true)}
        onBlur={(evt) => {
          state.updateWarnings(evt);
          setInputHasFocus(false);
          saveUponInputBlur();
        }}
        style={{ zIndex: inputHasFocus ? 200 : undefined }}
      />
    </div>
  );
};

export type OcrImageValue<T> = T & { image: ImageDetectionResultResponse };

const useFoodEditorWhiteboardNftImageDetection = (state: FoodEditorState) => {
  const flags = useFeatures();
  const whiteboard = useFoodEditorWhiteboardService({ term: state.value.term, ndbNumber: state.value.ndbNumber });
  const nfts = !flags.food_editor_nft_parser || state.mode == 'create'
    ? []
    : whiteboard.whiteboardItems.filter(item => {
      return item.type == 'nft' && !!item.imageUri;
    });

  const queries = useQueries({
    queries: nfts.map(nft => ({
      queryKey: ['image-detection-result', 'ocr_nft', 'food_whiteboard_item', nft.id],
      queryFn: async () => {
        const res = await imageDetectionApi.appApiImageDetectionGetImageDetectionResult({
          detect_type: 'ocr_nft',
          image_source: 'food_whiteboard_item',
          image_id: '' + nft.id,
        });

        return res.data;
      },
    })),
  });

  const countValues = (obj: Record<string, any>) => {
    return Object.values(obj).filter(v => typeof v !== 'undefined' && v !== null).length;
  };

  const suggestedServing = useMemo(() => {
    // Find the parsed serving with the most values filled
    const servings: OcrImageValue<ImageDetectionResultOcrNftParsedResultNftParsedServing>[] = [];
    queries.forEach(q => {
      q.data?.result?.values?.forEach(v => {
        if (v.type == 'NftParsedServing') {
          servings.push({
            image: q.data!,
            ...v,
          });
        }
      });
    });

    return servings.sort((a, b) => -(countValues(a.value) - countValues(b.value)))[0] ?? null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.map(q => q.isSuccess).join(',')]);

  const nutrients = useMemo(() => {
    // For each nutrient, find the nutrient with the most values filled
    const nutrients: OcrImageValue<ImageDetectionResultOcrNftParsedResultNftParsedNutrient>[] = [];
    queries.forEach(q => {
      q.data?.result?.values?.forEach(v => {
        if (v.type == 'NftParsedNutrient') {
          nutrients.push({
            image: q.data!,
            ...v,
          });
        }
      });
    });

    const res = _(nutrients)
      .filter(n => !!n?.value?.nutrient)
      .groupBy(n => n.value.nutrient)
      .map(values => {
        return values.sort((a, b) => -(countValues(a.value) - countValues(b.value)))[0] ?? null;
      })
      .value();

    // { 'energy_kcal': { nutrient: 'energy_kcal', value: 100, unit: 'kcal' }, ... }
    return Object.fromEntries(res.map(n => [n.value.nutrient!, n]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.map(q => q.isSuccess).join(',')]);

  const editorNutrientsPer100G = useMemo(() => {
    const res: Partial<FoodEditorValue> = {};

    const servingAmountG = floatOrNull(state.value.suggestedServingAmountG ?? '')
      ?? suggestedServing?.value?.serving_amount_g;
    if (!servingAmountG) {
      return res;
    }

    FOOD_EDITOR_NUTRIENT_FIELDS.forEach(f => {
      const nutrient = nutrients[f.mntField];
      const value = nutrient?.value?.amount;
      if (typeof value !== 'number') {
        return;
      }

      res[f.editorField] = nutrientScale({
        value,
        from: servingAmountG,
        to: NUTRIENT_100G,
      });
    });
    return res;
  }, [nutrients, state.value.suggestedServingAmountG, suggestedServing?.value?.serving_amount_g]);

  return {
    queries,
    nfts,
    suggestedServing,
    nutrients,
    editorNutrientsPer100G,
  };
};

const NutrientSectionNftSubheader = (props: {
  state: FoodEditorState,
  isCompareTo: boolean,
}) => {
  const nfts = props.state.nfts;
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (props.state.mode == 'create') {
    return (
      <Alert severity="info">
        Note: NFT parser is available after the food is created.
      </Alert>
    );
  }

  if (!nfts?.nfts?.length) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" spacing={1} style={{ width: '100%', overflowX: 'scroll', marginBottom: '1em' }}>
        {nfts.nfts.map(nft => (
          !!nft.imageUri && (
            <Stack key={nft.id} direction="column" spacing={1}>
              <img
                src={nft.imageUri}
                style={{ maxHeight: 200, cursor: 'pointer' }}
                onClick={() => setIsModalOpen(true)}
              />
              <Button
                onClick={() => setIsModalOpen(true)}
                disabled={nfts.queries.some(q => q.isLoading)}
              >
                Fill
              </Button>
            </Stack>
          )
        ))}
      </Stack>
      <FoodEditorNFTIngestionModal
        state={props.state}
        nfts={nfts.nfts}
        suggestedServing={nfts.suggestedServing}
        nutrients={nfts.nutrients}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(values) => {
          props.state.onFieldChange(values);
          setIsModalOpen(false);
        }}
      />
    </Stack>
  );
};

function FoodStatusInput(state: FoodEditorState, field: FoodEditorField, isCompareTo: boolean) {
  const validTransitions = FOOD_STATUS_TRANSITIONS[state.value.status as keyof typeof FOOD_STATUS_TRANSITIONS]
    ?? Object.keys(FOOD_STATUS_TRANSITIONS);
  const isStatusEnabled = (o: FoodStatusOption) => {
    if (state.hasAuth(Capabilities.foodDbAdmin)) {
      return true;
    }
    if (o.value == state.value.status) {
      return true;
    }
    return !o.disabled?.(state) && validTransitions.includes(o.value);
  };

  return (
    <FoodEditorFieldView
      state={state}
      field={{
        ...field,
        input: {
          type: 'select',
          options: FOOD_STATUS_OPTIONS.map(o => ({
            ...o,
            disabled: !isStatusEnabled(o),
          })),
        },
      }}
      isCompareTo={false}
    />
  );
}

function FoodEditorFieldView(props: {
  field: FoodEditorField,
  state: FoodEditorState,
  isCompareTo: boolean,
}) {
  const { field, state, isCompareTo } = props;
  const fieldsWithOverrides = Object.keys(ccFoodEditorValues);

  // This is to hide unneeded checkboxes for composite foods
  const isFieldValid = useFoodEditorFieldRelationship(field.field, state.value) && field.input != 'Divider';

  const shouldUseOverride = fieldsWithOverrides.includes(field.field) && isFieldValid
    && state.value.definitionType == 'composite';

  return (
    <FoodEditorInputSuggestValue state={state} field={field} isCompareTo={isCompareTo}>
      {shouldUseOverride
        ? <OverrideInputWrapper state={state} field={field} isCompareTo={isCompareTo} />
        : <FoodEditorFieldViewInner state={state} field={field} isCompareTo={isCompareTo} />}
    </FoodEditorInputSuggestValue>
  );
}

type FieldEstimateTemp = {
  field_name: string,
  field_value: string,
  is_set: boolean,
  is_suggested: boolean,
  is_negated: boolean,
  sources: {
    group_name: string,
    question: string,
  }[],
};

const useFoodEditorFieldValueEstimates = (
  state: FoodEditorState,
  field: FoodEditorField,
) => {
  const flags = useFeatures();
  const query = useQuery({
    queryKey: ['food-editor-estimates', state.value.term],
    queryFn: async () => {
      const res = await foodApi.appApiFoodFoodDetailsGetFoodDetailsEstimatesQuery({
        food_name: state.value.term,
      });
      return res.data;
    },
    enabled: flags.food_editor_estimates && !!state.value.term,
  });

  const estimates = useMemo(() => {
    const estimateFields: Array<FieldEstimateTemp> = (query.data?.fields as any) ?? [];
    const fieldDef = ccFoodEditorValues[field.field as keyof typeof ccFoodEditorValues];
    if (!fieldDef) {
      return [];
    }
    const estimates = estimateFields?.filter(f => fieldDef.ccCols.includes(f.field_name));
    if (!estimates) {
      return [];
    }

    return estimates;
  }, [query.data, field.field]);

  return {
    query,
    estimates,
  };
};

const FoodEditorInputSuggestValue = (props: {
  field: FoodEditorField,
  state: FoodEditorState,
  isCompareTo: boolean,
  children: React.ReactNode,
}) => {
  const estimates = useFoodEditorFieldValueEstimates(props.state, props.field);
  const flags = useFeatures();

  const handleFieldClick = (e: FieldEstimateTemp) => {
    const ccField = ccFoodEditorValues[props.field.field as keyof typeof ccFoodEditorValues];
    const curCcVals = ccField.toApiRequest(props.state.value) as any;
    curCcVals[e.field_name] = !curCcVals[e.field_name];
    props.state.onFieldChange({
      [props.field.field]: ccField.toFoodEditor(curCcVals),
    });
  };

  if (!flags.food_editor_estimates) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  }

  if (props.isCompareTo) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  }

  return (
    <Stack>
      {props.children}
      {!!estimates.estimates?.length && (
        <Stack>
          <Typography variant="caption" color="text.secondary">
            {estimates.estimates.map((e, idx) => {
              if (e.is_negated) {
                return;
              }

              return (
                <span
                  key={e.field_name}
                  title={e.sources.map(s => `${s.question}`).join('\n')}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleFieldClick(e)}
                >
                  {e.field_name}
                  {e.is_suggested ? '?' : ''}
                  {idx < estimates.estimates.length - 1 ? ', ' : ''}
                </span>
              );
            })}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export const EDITOR_BUTTON_COLOUR_DARK_GREY = '#404040';
export const EDITOR_BUTTON_COLOUR_GREEN = '#66bb6a';

export const EDITOR_BUTTON_THEME = createTheme({
  palette: {
    primary: {
      main: EDITOR_BUTTON_COLOUR_DARK_GREY,
      contrastText: '#fff',
    },
    success: {
      main: EDITOR_BUTTON_COLOUR_GREEN,
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      textTransform: 'initial',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&.Mui-disabled': {
            backgroundColor: EDITOR_BUTTON_COLOUR_DARK_GREY,
            color: '#fff',
            opacity: 0.5,
          },
        },
        containedSuccess: {
          '&.Mui-disabled': {
            backgroundColor: EDITOR_BUTTON_COLOUR_GREEN,
            color: '#fff',
            opacity: 0.5,
          },
        },
        outlinedPrimary: {
          '&.Mui-disabled': {
            borderColor: EDITOR_BUTTON_COLOUR_DARK_GREY,
            backgroundColor: 'transparent',
            color: EDITOR_BUTTON_COLOUR_DARK_GREY,
            opacity: 0.5,
          },
        },
        outlinedSuccess: {
          '&.Mui-disabled': {
            borderColor: EDITOR_BUTTON_COLOUR_GREEN,
            backgroundColor: 'transparent',
            color: EDITOR_BUTTON_COLOUR_GREEN,
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export const EDITOR_BUTTON_PROPS: ButtonProps = {
  sx: { width: '75%' },
  size: 'small',
  disableElevation: true,
  disableRipple: true,
};

function FoodEditorFieldViewInner(props: {
  field: FoodEditorField,
  state: FoodEditorState,
  isCompareTo: boolean,
}) {
  const { field, state, isCompareTo } = props;
  const [value, setValue] = useState(state.value[field.field]);
  const foodOntology = useOntology(state.value.ontologyLevels);
  const showField = useFoodEditorFieldRelationship(field.field, state.value);

  // use when a component's value and inputValue are different types
  // e.g. Autocomplete from MUI with 'multiple' prop has value as an array and inputValue as a string
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(state.value[field.field]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value[field.field]]);

  const saveUponInputBlur = () => {
    state.onFieldChange({ [field.field]: value });
  };

  // const handleTranslateText = async () => {
  //   const termEN = state.value.term;
  //   const res = await translationsApi.appApiTranslationGetTranslation({
  //     scope: "food.name",
  //     key: termEN,
  //     lang: "fr",
  //   });
  //   state.onFieldChange({frenchTerm: res.data.value});
  // };

  const translateTextRes = useAsyncResult<TranslatedStringResponse>();
  const getTranslation = async () => {
    const res = await translationsApi.appApiTranslationGetTranslation({
      scope: 'food.name',
      key: state.value.term,
      lang: 'fr',
    });
    return res.data;
  };

  const translateText = () => {
    translateTextRes.bind(getTranslation());
  };

  useEffect(() => {
    if (translateTextRes.isDone) {
      state.onFieldChange({ frenchTerm: translateTextRes.result.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateTextRes.isDone]);

  const fieldIsAutomated = AUTOMATED_CC_RANKING_FIELD_NAMES.includes(field.field);

  if (typeof field.input === 'function') {
    return field.input(state, field, props.isCompareTo);
  }

  if (field.input === 'text') {
    return (
      <TextField
        fullWidth
        id={isCompareTo ? field.field + '-compare' : field.field}
        disabled={field.viewOnly}
        label={field.label}
        onChange={evt => setValue(evt.target.value)}
        onBlur={saveUponInputBlur}
        value={value ?? ''}
        variant="standard"
        inputProps={{ maxLength: 255 }}
        style={{ height: 70 }}
      />
    );
  }

  if (field.input === 'TextDiffLang') {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            id={isCompareTo ? field.field + '-compare' : field.field}
            disabled={field.viewOnly}
            label={field.label}
            onChange={evt => setValue(evt.target.value)}
            onBlur={saveUponInputBlur}
            value={value}
            variant="standard"
            inputProps={{ maxLength: 255 }}
            style={{ height: 70 }}
          />

          <Button
            variant="outlined"
            disabled={field.viewOnly || translateTextRes.isPending}
            style={{ height: 45 }}
            onClick={translateText}
          >
            Translate
          </Button>
        </Stack>
        {translateTextRes.isPending && <Typography>Translation Loading...</Typography>}
        {translateTextRes.isError && <Typography>Translation Error: {'' + translateTextRes.error}</Typography>}
      </>
    );
  }

  if (field.input === 'multiline') {
    return (
      <TextField
        multiline
        fullWidth
        id={isCompareTo ? field.field + '-compare' : field.field}
        disabled={field.viewOnly}
        label={field.label}
        onChange={evt => setValue(evt.target.value)}
        onBlur={saveUponInputBlur}
        value={value}
        variant="standard"
        inputProps={{ maxLength: 255 }}
        rows={5}
      />
    );
  }

  if (field.input === 'switch') {
    return (
      <FormControlLabel
        id={isCompareTo ? field.field + '-compare' : field.field}
        control={
          <Switch
            disabled={field.viewOnly || fieldIsAutomated}
            checked={value as boolean}
            onChange={evt => {
              setValue(evt.target.checked);
              state.onFieldChange({ [field.field]: evt.target.checked });
            }}
            // onBlur={saveUponInputBlur}
          />
        }
        label={
          <Typography>
            {field.label}
            {fieldIsAutomated
              ? (
                <Tooltip title="Automated from nutrients/ontology">
                  <Typography display="inline" marginLeft="4px">
                    <Info color="inherit" fontSize="small" />
                  </Typography>
                </Tooltip>
              )
              : null}
          </Typography>
        }
      />
    );
  }

  if (field.input === 'FoodPicker') {
    return (
      <FoodPicker
        excludeTerms={state.mode === 'update' ? [state.value.term] : []}
        label={field.label}
        onChangeValue={newValue => setValue(newValue)}
        onBlur={saveUponInputBlur}
        value={value as any}
      />
    );
  }

  if (field.input === 'RankingPicker') {
    if (!showField) {
      return null;
    }

    return (
      <RankingPicker
        label={field.label}
        onChangeValue={newValue => {
          setValue(newValue);
          state.onFieldChange({ [field.field]: newValue });
        }}
        // onBlur={saveUponInputBlur}
        value={value as any}
        isAutomated={fieldIsAutomated}
      />
    );
  }

  if (field.input === 'LowMediumHighPicker') {
    return (
      <LowMediumHighPicker
        label={field.label}
        onChangeValue={newValue => {
          setValue(newValue);
          state.onFieldChange({ [field.field]: newValue });
        }}
        value={value as any}
        isAutomated={fieldIsAutomated}
      />
    );
  }

  if (field.input === 'MixedDishComponentsEditor') {
    return (
      <MixedDishComponentsEditor
        onChangeValue={newValue => setValue(newValue)}
        onBlur={saveUponInputBlur}
        showErrors={state.showErrors}
        value={value as any}
        state={state}
      />
    );
  }

  if (field.input === 'FoodImage') {
    const url = state.value[field.field] as string;
    return <FoodImage state={state} field={field} url={url} />;
  }

  if (field.input === 'MeasuresEditor') {
    return <MeasuresEditor state={state} field={field} />;
  }

  if (field.input === 'FoodComponentBuilder') {
    return <FoodComponentBuilder state={state} field={field} isCompareTo={isCompareTo} />;
  }

  if (field.input === 'AddRecipeServingToMeasures') {
    return <AddRecipeServingToMeasures state={state} />;
  }

  if (field.input === 'Divider') {
    // Only dynamic divider is for Beverage Types, so hardcoding it here
    if (field.field === 'beverageType' && !showField) {
      return null;
    }

    return <Divider sx={{ borderBottomWidth: 1, borderBottomColor: 'black', marginTop: 1.5, marginBottom: 1.5 }} />;
  }

  if (field.input.type === 'autocomplete') {
    return (
      <Autocomplete
        id={isCompareTo ? field.field + '-compare' : field.field}
        multiple
        freeSolo
        options={field.input.options || []}
        disabled={field.viewOnly}
        onChange={(_, values) => {
          setValue(values);
          state.onFieldChange({ [field.field]: values });
        }}
        value={value as any}
        style={{ paddingBottom: 20 }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        onBlur={() => {
          if (inputValue) {
            state.onFieldChange({ [field.field]: [...((value ?? []) as string[]), inputValue] });
            setInputValue('');
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={field.label}
            onBlur={state.updateWarnings}
          />
        )}
      />
    );
  }

  if (field.input.type === 'numeric') {
    const showOntologyText: boolean = field.field == 'adultServingSizeG'
      && !!state.value.adultServingSizeG
      && foodOntology?.adult_serving_size_g != floatOrNull(state.value.adultServingSizeG);
    const ontologyText = foodOntology
      ? `This is a custom value. Ontology's default adult serving size is ${foodOntology?.adult_serving_size_g ?? 0}g`
      : '';

    const fieldLabel = field.field == 'adultServingSizeG' && foodOntology?.adult_serving_size_g
      ? `Adult Serving Size (default: ${foodOntology.adult_serving_size_g}g)`
      : field.field == 'ghgEquivalentKg' && foodOntology?.ghg_equivalent_kg
      ? `GHG Equivalent (default: ${foodOntology.ghg_equivalent_kg}kg CO2)`
      : field.label;

    return (
      <TextField
        fullWidth
        id={isCompareTo ? field.field + '-compare' : field.field}
        inputProps={{ step: 'any', min: field.input.min }}
        label={fieldLabel}
        disabled={field.viewOnly}
        onChange={evt => {
          evt.target.focus();
          setValue(evt.target.value);
        }}
        type="number"
        value={value?.toString() || ''}
        variant="standard"
        onBlur={(evt) => {
          state.updateWarnings(evt);
          saveUponInputBlur();
        }}
        helperText={showOntologyText ? ontologyText : ''}
        FormHelperTextProps={{ style: { color: showOntologyText ? '#ab47bc' : undefined } }}
        InputLabelProps={{ style: { color: showOntologyText ? '#ab47bc' : undefined } }}
      />
    );
  }

  if (field.input.type === 'select') {
    if (!showField) {
      return null;
    }

    if (field.field !== 'status') {
      return (
        <>
          <FormLabel id={`${field.field}-label`}>
            {field.label}
            {fieldIsAutomated
              ? (
                <Tooltip title="Automated from nutrients/ontology">
                  <Typography display="inline" marginLeft="4px">
                    <Info color="inherit" fontSize="small" />
                  </Typography>
                </Tooltip>
              )
              : null}
          </FormLabel>
          <ThemeProvider theme={EDITOR_BUTTON_THEME}>
            <Grid container spacing={2}>
              {field.input.options.map(option => {
                return (
                  <Grid item key={option.value} xs={3}>
                    <Button
                      variant="contained"
                      color={value === option.value ? 'success' : 'primary'}
                      onClick={() => {
                        setValue(option.value);
                        state.onFieldChange({ [field.field]: option.value });
                      }}
                      {...EDITOR_BUTTON_PROPS}
                      disabled={fieldIsAutomated}
                    >
                      {option.label || 'N/A'}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </ThemeProvider>
        </>
      );
    }

    return (
      <FormControl variant="standard" fullWidth>
        <FormLabel id={`${field.field}-label`}>{field.label}</FormLabel>
        <RadioGroup
          id={isCompareTo ? field.field + '-compare' : field.field}
          name={`${field.field}-label`}
          value={value}
          onChange={evt => {
            setValue(evt.target.value);
            state.onFieldChange({ [field.field]: evt.target.value });
          }}
          onBlur={(evt) => {
            state.updateWarnings(evt);
          }}
          row
        >
          <Grid container>
            {field.input.options.map(option => (
              <Grid key={option.value} item xs={field.field == 'status' ? undefined : 3}>
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label || 'N/A'}
                  disabled={option.disabled}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    );
  }

  if (field.input.type === 'multiSelect') {
    return (
      <FormControl variant="standard" fullWidth>
        <FormLabel id={`${field.field}-label`}>{field.label}</FormLabel>
        <FormGroup row>
          <Grid container>
            {field.input.options.map(option => (
              <Grid key={option.value} item xs={field.field == 'status' ? undefined : 3}>
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={((value ?? []) as string[]).includes(option.value)}
                      onClick={() => {
                        const currValue = (value ?? []) as string[];
                        if (currValue.includes(option.value)) {
                          setValue(currValue.filter(v => v != option.value));
                          return;
                        }
                        setValue([...currValue, option.value]);
                      }}
                      onBlur={(evt) => {
                        saveUponInputBlur();
                        state.updateWarnings(evt);
                      }}
                    />
                  }
                  label={option.label || 'N/A'}
                  disabled={option.disabled}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    );
  }

  if (field.input.type === '__UNKNOWN_TYPE_PLACEHOLDER__') {
    return <div>Unknown field type</div>;
  }

  assertUnreachable(field.input, 'Unknown input type');
}

const AddRecipeServingToMeasures = (props: {
  state: FoodEditorState,
}) => {
  const { state } = props;
  const [added, setAdded] = useState(state.value.measures.some(m => m.root_label == 'recipe serving'));

  const handleClick = () => {
    const servings = +state.value.listedServingCount;
    const totalComponentWeight = getComponentsTotalWeight(state.value.components);
    if (isNaN(servings) || servings <= 0 || totalComponentWeight <= 0) {
      return;
    }

    const measures: UsdaMeasure[] = state.value.measures.map((m, idx) => ({
      ...m,
      ranking: m.ranking ? +m.ranking : null,
      addon: m.addon ? m.addon : false,
    }));
    const measureLabel = state.value.listedServingUnitLabel
      ? 'serving (' + state.value.listedServingUnitLabel + ')'
      : 'serving';
    const newRecipeMeasure = {
      addon: false,
      eqv: round(totalComponentWeight / servings, 4),
      label: measureLabel,
      label_translations: null,
      qty: 1,
      ranking: null,
      root_label: 'recipe serving',
    } as UsdaMeasure;

    if (!measures.some(m => m.root_label == 'recipe serving')) {
      measures.push(newRecipeMeasure);
    } else {
      measures[measures.findIndex(m => m.root_label == 'recipe serving')] = newRecipeMeasure;
    }
    state.onFieldChange({ measures: measures });
  };

  useEffect(() => {
    if (state.value.measures.some(m => m.root_label == 'recipe serving')) {
      setAdded(true);
      return;
    }
    setAdded(false);
  }, [state.value.measures]);

  return (
    <Button onClick={handleClick} variant="contained" style={{ verticalAlign: 'middle' }}>
      {added ? 'Update Measure' : 'Add to Measures'}
    </Button>
  );
};

const MeasureNumericInput = (props: {
  measure: UsdaMeasure,
  label: string,
  field: keyof UsdaMeasure,
  viewOnly?: boolean,
  updateMeasure: (measure: UsdaMeasure, newValue: Partial<UsdaMeasure>) => void,
}) => {
  const errState = useErrorState('' + props.measure[props.field], false, (v: string) => {
    return props.field === 'eqv'
      ? parseFloat(v) === 0
        ? "Gram equivalent can't be 0"
        : (props.measure.label === 'gram' && props.measure.qty / parseFloat(v) !== 1
          ? 'Gram equivalent must be 1'
          : null)
      : null;
  });

  return (
    <TextField
      label={props.label}
      inputProps={{ step: 'any', min: 0 }}
      disabled={props.viewOnly}
      onChange={evt => props.updateMeasure(props.measure, { [props.field]: +evt.target.value })}
      type="number"
      size="small"
      value={props.measure[props.field]}
      error={errState.showError}
      helperText={errState.error}
      onBlur={errState.onBlur()}
    />
  );
};

export const searchLinkData: Array<{ label: string, url: (q: string) => string }> = [
  {
    label: 'USDA',
    url: q => 'https://fdc.nal.usda.gov/fdc-app.html#/?query=' + encodeURIComponent(q),
  },
  {
    label: 'Google',
    url: q => 'https://google.ca/search?q=' + encodeURIComponent(q),
  },
  {
    label: 'Bing',
    url: q => 'https://bing.ca/search?q=' + encodeURIComponent(q),
  },
  {
    label: 'Walmart',
    url: q => 'https://www.walmart.ca/en/search?q=' + encodeURIComponent(q),
  },
];

const FoodSearchPanel = (props: {
  term: string,
  showImmediateResults: boolean,
  onComparisonItemSelected: (compare: FoodEditorValue | null) => void,
}) => {
  const foodSearch = useFoodSearch({ context: {}, limit: 20 });
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    foodSearch.setActiveSearch({
      type: 'db',
      text: props.term,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.term]);

  const setCompareQueryParam = (compare: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('compare', compare);
    setSearchParams(params);
  };

  return (
    <Stack spacing={1}>
      <FoodSearchInput foodSearch={foodSearch} />
      <Stack direction="row" spacing={1}>
        {searchLinkData.map(linkData => (
          <Button
            key={linkData.label}
            component="a"
            variant="outlined"
            href={linkData.url(foodSearch.activeSearch.text)}
            target="_blank"
            onClick={() => {
              mixpanel.track('Food editor: external search clicked', { Engine: linkData.label });
            }}
            endIcon={<Launch fontSize="small" />}
          >
            {linkData.label}
          </Button>
        ))}
      </Stack>
      <FoodSearchResults
        foodSearch={foodSearch}
        onCustomSelect={(option, _, evt) => {
          evt.preventDefault();
          window.open(getFoodEditorUrl(option.name), '_blank');
        }}
        onFoodSelect={(option, _, evt) => {
          evt.preventDefault();
          props.onComparisonItemSelected(null);
          setCompareQueryParam(option.name);
        }}
        onExternalSelect={(externalFood, _, evt) => {
          evt.preventDefault();
          props.onComparisonItemSelected(externalFoodSearchResultToFoodEditorValue(externalFood));
          setCompareQueryParam(
            [externalFood.brand_name, externalFood.item_name].filter(Boolean).join(', ').toLocaleLowerCase(),
          );
          if (!externalFood.serving_unit_amount) {
            alert('WARNING: NO SERVING SIZE (g) FOUND! DOUBLE CHECK NUTRIENTS PER 100g!');
          }
        }}
      />
    </Stack>
  );
};

const MeasureTextInput = (props: {
  measure: UsdaMeasure,
  label: string,
  field: keyof UsdaMeasure,
  viewOnly?: boolean,
  updateMeasure: (measure: UsdaMeasure, newValue: Partial<UsdaMeasure>) => void,
}) => {
  return (
    <TextField
      fullWidth
      label={props.label}
      disabled={props.viewOnly}
      defaultValue={props.measure[props.field]}
      size="small"
      onChange={evt => props.updateMeasure(props.measure, { [props.field]: evt.target.value })}
    />
  );
};

const MeasureBooleanInput = (props: {
  measure: UsdaMeasure,
  label: string,
  field: keyof UsdaMeasure,
  viewOnly?: boolean,
  updateMeasure: (measure: UsdaMeasure, newValue: Partial<UsdaMeasure>) => void,
}) => {
  return (
    <FormControlLabel
      labelPlacement="start"
      label={props.label}
      control={
        <Switch
          disabled={props.viewOnly}
          checked={props.measure[props.field] as any}
          onChange={evt => props.updateMeasure(props.measure, { [props.field]: evt.target.checked })}
        />
      }
    />
  );
};

let _measureIdCounter = 1;

type MeasureDef = {
  dimension: 'mass' | 'volume',
  label: string,
  root_label: string,
  eqv: number,
  gramEquiv?: number,
};

const MEASURE_DEFS: MeasureDef[] = [
  // Mass-based measures
  {
    dimension: 'mass',
    label: 'gram',
    root_label: 'gram',
    eqv: 1,
    gramEquiv: 1,
  },

  {
    dimension: 'mass',
    label: 'oz',
    root_label: 'ounce',
    eqv: 28.3495,
  },

  // Volume-based measures
  {
    dimension: 'volume',
    label: 'ml',
    root_label: 'milliliter',
    eqv: 1,
  },

  {
    dimension: 'volume',
    label: 'tsp',
    root_label: 'teaspoon',
    eqv: 4.92892,
  },

  {
    dimension: 'volume',
    label: 'tbsp',
    root_label: 'tablespoon',
    eqv: 14.7868,
  },

  {
    dimension: 'volume',
    label: 'fl oz',
    root_label: 'fluid ounce',
    eqv: 29.5735,
  },

  {
    dimension: 'volume',
    label: 'cup',
    root_label: 'cup',
    eqv: 236.588,
  },
];

const MeasuresEditor = (props: {
  state: FoodEditorState,
  field: FoodEditorField,
}) => {
  const { viewOnly } = props.field;
  const [measures, setMeasures] = useUpdatingState(props.state.value.measures, []);
  const [didSave, setDidSave] = useState(false);

  const measureId = (measure: UsdaMeasure) => {
    const m: any = measure;
    if (!m._id) {
      m._id = _measureIdCounter++;
    }
    return m._id;
  };

  const updateMeasure = (measure: UsdaMeasure, newValues: Partial<UsdaMeasure>) => {
    setMeasures(measures.map(m => m === measure ? { ...measure, ...newValues } : m));
  };

  const handleAddMeasure = () => {
    mixpanel.track('Food editor: add measure');
    setMeasures([...measures, {
      label: '',
      eqv: 0,
      qty: 1,
      root_label: '',
      addon: false,
    }]);
  };

  const convertMeasureEquiv = (src: UsdaMeasure, dst: MeasureDef) => {
    const srcDef = MEASURE_DEFS.find(def => def.label == src.label);
    if (!srcDef) {
      return null;
    }

    return +(((src.eqv / src.qty) / srcDef.eqv) * dst.eqv).toFixed(2);
  };

  const handleAddMeasureFromDef = (def: MeasureDef) => {
    const existingMeasure = measures.find(m =>
      MEASURE_DEFS.find(def2 => def2.label == m.label && def.dimension == def2.dimension)
    );
    const equiv = def.gramEquiv
      ? def.gramEquiv
      : !existingMeasure
      ? 0
      : convertMeasureEquiv(existingMeasure, def) || 0;

    setMeasures([...measures, {
      label: def.label,
      eqv: equiv,
      qty: 1,
      root_label: def.root_label,
      addon: false,
    }]);
  };

  const handleRemoveMeasure = (measure: UsdaMeasure) => {
    setMeasures(measures.filter(m => m !== measure));
  };

  const handleSaveMeasures = () => {
    props.state.onFieldChange({
      measures: measures.map((m, idx) => ({
        ...m,
        ranking: m.ranking ? +m.ranking : null,
        addon: m.addon ? m.addon : false,
      })),
    });
    setDidSave(true);
  };

  const getDefForMeasure = (measure: UsdaMeasure) => {
    return MEASURE_DEFS.find(def => (
      def.label == measure.label
      || def.root_label == measure.root_label
      || def.label == measure.root_label
      || def.root_label == measure.label
    ));
  };

  const [definedDimensions, definedMeasureLabels] = React.useMemo(() => {
    const definedDimensions = new Set<string>();
    const definedMeasureLabels = new Set<string>();
    measures.forEach(measure => {
      definedMeasureLabels.add(measure.label);
      if (measure.root_label) {
        definedMeasureLabels.add(measure.root_label);
      }

      if (!measure.eqv || !measure.qty) {
        return;
      }
      const measureDef = getDefForMeasure(measure);
      if (!measureDef) {
        return;
      }
      definedDimensions.add(measureDef.dimension);
    });
    return [
      definedDimensions,
      definedMeasureLabels,
    ];
  }, [measures]);

  const allMeasuresSaved = measures.every(m => props.state.value.measures.find(m2 => m2 === m))
    && measures.length === props.state.value.measures.length;
  const allMeasuresValid = measures.every(m => (
    m.label
    && m.eqv > 0
    && m.qty > 0
  ));

  const adultServingSizeG = floatOrNull(props.state.value.adultServingSizeG);

  return (
    <Stack style={{ marginTop: 15 }} spacing={3} divider={<Divider />}>
      <Stack direction="row" spacing={1}>
        {MEASURE_DEFS.map(def => {
          if (definedMeasureLabels.has(def.label) || definedMeasureLabels.has(def.root_label)) {
            return null;
          }
          return (
            <Button
              key={def.label}
              variant="outlined"
              color={definedDimensions.has(def.dimension) ? 'success' : 'primary'}
              onClick={() => handleAddMeasureFromDef(def)}
            >
              {def.label}
            </Button>
          );
        })}
      </Stack>
      {measures.map(measure => (
        <Stack key={measureId(measure)} spacing={2}>
          {(() => {
            const measureDef = getDefForMeasure(measure);
            if (!measureDef) {
              return null;
            }

            const compareMeasure = measures.find(m => {
              if (m === measure) {
                return false;
              }

              if (!m.eqv || !m.qty) {
                return false;
              }

              const compareDef = getDefForMeasure(m);
              if (!compareDef) {
                return false;
              }

              return compareDef.dimension == measureDef.dimension;
            });
            const compareMeasureDef = compareMeasure && getDefForMeasure(compareMeasure);
            if (!compareMeasureDef) {
              return null;
            }

            const compareExpectedEquiv = convertMeasureEquiv(compareMeasure, measureDef);
            if (!compareExpectedEquiv) {
              return null;
            }

            const diff = Math.abs(compareExpectedEquiv - (measure.eqv / measure.qty));
            const diffPct = diff / measure.eqv;
            if (diffPct < 0.1) {
              return null;
            }

            return (
              <Alert severity="warning">
                <kbd>{measure.label}</kbd> differs from <kbd>{compareMeasure.label}</kbd> equivalent by{' '}
                {(diffPct * 100).toFixed(0)}% (expected: <i>{compareExpectedEquiv * measure.qty}</i> gram equiv)
              </Alert>
            );
          })()}
          <Stack>
            <Stack direction="row" spacing={1}>
              <MeasureTextInput
                measure={measure}
                label="Label"
                field="label"
                viewOnly={viewOnly}
                updateMeasure={updateMeasure}
              />
              <MeasureNumericInput
                measure={measure}
                label="Quantity"
                field="qty"
                viewOnly={viewOnly}
                updateMeasure={updateMeasure}
              />
              <MeasureNumericInput
                measure={measure}
                label="Gram Equiv"
                field="eqv"
                viewOnly={viewOnly}
                updateMeasure={updateMeasure}
              />
              {!viewOnly && (
                <IconButton onClick={() => handleRemoveMeasure(measure)}>
                  <Delete />
                </IconButton>
              )}
            </Stack>
            {!!adultServingSizeG && (
              <Typography fontSize={12}>
                {measure.qty} {measure.label} is equivalent to{' '}
                {adultServingSizeG && formatNumber(measure.eqv / adultServingSizeG, 1)} adult servings
              </Typography>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <MeasureTextInput
              measure={measure}
              label="Root label"
              field="root_label"
              viewOnly={viewOnly}
              updateMeasure={updateMeasure}
            />
            <MeasureNumericInput
              measure={measure}
              label="Ranking"
              field="ranking"
              viewOnly={viewOnly}
              updateMeasure={updateMeasure}
            />
            <MeasureBooleanInput
              measure={measure}
              label="Addon?"
              field="addon"
              viewOnly={viewOnly}
              updateMeasure={updateMeasure}
            />
          </Stack>
        </Stack>
      ))}

      {!viewOnly && (
        <Button
          startIcon={<Add />}
          onClick={handleAddMeasure}
        >
          Add measure
        </Button>
      )}

      {!viewOnly && !allMeasuresSaved && (
        <Stack direction="row" spacing={1}>
          <Button
            fullWidth
            startIcon={<Save />}
            onClick={handleSaveMeasures}
            color="success"
            variant="contained"
            disabled={!allMeasuresValid}
          >
            Save measures
          </Button>
          <Button
            startIcon={<Cancel />}
            onClick={() => setMeasures(props.state.value.measures)}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      )}

      {didSave && (
        <Alert severity="info">
          Remember to save this food to persist the measures!
        </Alert>
      )}
    </Stack>
  );
};

const imageUrlToBase64 = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
    reader.onerror = reject;
  });
};

const FoodImage = (props: {
  state: FoodEditorState,
  field: FoodEditorField,
  url: string,
}) => {
  const { field } = props;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const newUrlMutation = useMutation({
    mutationFn: async (newUrl: string | File | null) => {
      if (!newUrl) {
        return null;
      }

      if (typeof newUrl === 'string') {
        return imageUrlToBase64(newUrl);
      }

      const objUrl = URL.createObjectURL(newUrl);
      try {
        return await imageUrlToBase64(objUrl);
      } finally {
        URL.revokeObjectURL(objUrl);
      }
    },
    onSuccess: (newUrl) => {
      props.state.onFieldChange({ [field.field]: newUrl });
    },
  });

  const onFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    newUrlMutation.mutate(evt.target.files?.[0] ?? null);
  };

  // Oof this is a bit of a hack, but ... it's a hack that works, so we'll roll
  // with it until there's a better option.
  const pleaseLoadUrl = props.url?.indexOf('x-please-load:') == 0
    ? props.url.slice('x-please-load:'.length)
    : null;

  useEffect(() => {
    if (pleaseLoadUrl) {
      newUrlMutation.mutate(pleaseLoadUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pleaseLoadUrl]);

  const url = newUrlMutation.isLoading || pleaseLoadUrl ? null : props.url;

  return (
    <FormControl variant="standard" fullWidth>
      <FormLabel>
        Image
      </FormLabel>
      <Grid container spacing={1}>
        {!url && field.viewOnly && (
          <Grid item>
            <i>No food image</i>
          </Grid>
        )}
        {url && (
          <Grid item>
            <img
              src={url}
              style={{ width: 130, height: 130 }}
            />
          </Grid>
        )}
        {!field.viewOnly && (
          <Grid item flex={1}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} flex={1}>
                <Button variant="outlined" onClick={() => fileInputRef.current?.click()} endIcon={<FileUpload />}>
                  Upload File
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newUrl = prompt('Enter image URL:');
                    newUrl && newUrlMutation.mutate(newUrl);
                  }}
                  endIcon={<Launch />}
                >
                  Enter URL
                </Button>
                {url && (
                  <Button
                    variant="outlined"
                    onClick={() => confirm('Clear image?') && newUrlMutation.mutate(null)}
                    endIcon={<Cancel />}
                  >
                    Clear
                  </Button>
                )}
              </Stack>

              {newUrlMutation.isError && (
                <Alert severity="error">
                  {'' + newUrlMutation.error}
                </Alert>
              )}

              {newUrlMutation.isLoading && <LoadingOutlined />}

              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onFileSelect}
              />
            </Stack>
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
};

function FoodItemHeader(props: {
  heading: string,
  headingChildren?: JSX.Element,
}) {
  return (
    <Typography
      variant="h4"
      color="inherit"
      title={props.heading}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        paddingRight: props.headingChildren ? 60 : 0,
      }}
    >
      {props.heading}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        {props.headingChildren}
      </div>
    </Typography>
  );
}

function FoodEditorSectionView(props: {
  section: FoodEditorSection,
  state: FoodEditorState,
  compareTo: FoodEditorValue | null,
}) {
  const { compareTo } = props;
  const state: FoodEditorState = !compareTo ? props.state : {
    ...props.state,
    value: compareTo as FoodEditorValue,
  };

  const [valuesToUndo, setValuesToUndo] = useState<Partial<FoodEditorValue> | null>(null);
  const [contentHidden, setContentHidden] = useLocalStorage(
    `food-editor-section:${props.section.id}${compareTo ?? '-compare'}:hidden`,
    !!compareTo,
  );
  const [highlighted, setHighlighted] = useState(false);

  const highlightDifference = () => {
    const copyFrom = compareTo;
    if (!copyFrom) {
      return;
    }
    props.section.fields.filter(field => !field.doNotCopy).forEach((field) => {
      const compareInput = document.getElementById(field.field + '-compare');
      const originalValue = props.state.value[field.field] ?? '';
      const compareValue = copyFrom[field.field] ?? '';

      if (
        compareInput && !isNaN(+compareValue) && !isNaN(+originalValue) && originalValue != '' && compareValue != ''
      ) {
        if (highlighted && +compareValue != +originalValue) {
          const colorScale = scaleLinear([(+originalValue + 0.00001) * 0.8, (+originalValue + 0.00001) * 1.2], [
            'red',
            'green',
          ]).clamp(true);
          compareInput.style.webkitTextFillColor = colorScale(+compareValue);
        } else {
          compareInput.style.webkitTextFillColor = 'unset';
        }
      } else if (compareInput && originalValue != compareValue) {
        if (highlighted) {
          compareInput.style.webkitTextFillColor = 'red';
        } else {
          compareInput.style.webkitTextFillColor = 'unset';
        }
      } else if (compareInput) {
        compareInput.style.webkitTextFillColor = 'unset';
      }
    });
  };

  useEffect(() => {
    highlightDifference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.value, highlighted]);

  const copySection = () => {
    const copyFrom = compareTo;
    if (!copyFrom) {
      return;
    }

    mixpanel.track('Food editor: compare with copy', {
      Section: props.section.label,
    });

    if (!valuesToUndo) {
      const oldValues: Partial<FoodEditorValue> = {};
      props.section.fields.forEach(field => {
        if (field.doNotCopy || field.viewOnly) {
          return;
        }
        const fieldToCopy = props.state.value[field.field];
        const fieldValues = props.section.id == 'nutrients'
          ? { [field.field]: fieldToCopy === null ? null : isNaN(+fieldToCopy) ? null : +fieldToCopy }
          : field.copyFrom
          ? field.copyFrom(props.state.value)
          : { [field.field]: fieldToCopy };
        Object.assign(oldValues, fieldValues);
      });
      setValuesToUndo(oldValues);
    }

    const newValues: Partial<FoodEditorValue> = {};
    props.section.fields.forEach(field => {
      if (field.doNotCopy || field.viewOnly) {
        return;
      }
      const fieldToCopy = copyFrom[field.field];
      const fieldValues = props.section.id == 'nutrients'
        ? { [field.field]: fieldToCopy === null ? null : isNaN(+fieldToCopy) ? null : +fieldToCopy }
        : field.copyFrom
        ? field.copyFrom(copyFrom)
        : { [field.field]: fieldToCopy };
      Object.assign(newValues, fieldValues);
    });
    props.state.onFieldChange(newValues);
  };

  const undoCopyValues = () => {
    if (!valuesToUndo) {
      return;
    }

    mixpanel.track('Food editor: compare with undo', {
      Section: props.section.label,
    });

    props.state.onFieldChange(valuesToUndo);
    setValuesToUndo(null);
  };

  const headingStyle: React.CSSProperties = {
    backgroundColor: '#1976d2',
    color: 'white',
    paddingLeft: 15,
    height: 40,
    lineHeight: '40px',
    marginBottom: 10,
    marginTop: 20,
    flex: 1,
    paddingRight: 10,
  };

  const SectionButton: React.CSSProperties = {
    cursor: 'pointer',
  };

  const label = (state: FoodEditorState) => {
    return typeof props.section.label === 'string'
      ? props.section.label
      : props.section.label(state);
  };

  return (
    <Stack width={1} style={{ marginTop: 10 }}>
      <Typography
        variant="h6"
        style={headingStyle}
        sx={{ cursor: 'pointer' }}
        onClick={() => setContentHidden(!contentHidden)}
      >
        {!compareTo && (
          <Stack direction="row" alignItems="center" flex={1} justifyContent="space-between">
            <div>{label(props.state)}</div>
            {!contentHidden && <ExpandMore style={SectionButton} />}
            {contentHidden && <ChevronRight style={SectionButton} />}
          </Stack>
        )}
        {compareTo && (
          <Stack direction="row" alignItems="center" flex={1} justifyContent="flex-end">
            <div style={{ marginRight: 'auto' }}>{label(state)}</div>{' '}
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setHighlighted(!highlighted);
              }}
              style={{ backgroundColor: 'white', height: 24, marginTop: -3, marginRight: 5 }}
            >
              Highlight Difference
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                copySection();
              }}
              style={{ backgroundColor: 'white', height: 24, marginTop: -3, marginRight: 5 }}
            >
              Copy
            </Button>
            {valuesToUndo && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  undoCopyValues();
                }}
                color="warning"
                variant="contained"
                style={{ height: 24, marginTop: -3, marginRight: 5 }}
              >
                Undo
              </Button>
            )}
            {!contentHidden && <ExpandMore style={SectionButton} onClick={() => setContentHidden(!contentHidden)} />}
            {contentHidden && <ChevronRight style={SectionButton} onClick={() => setContentHidden(!contentHidden)} />}
          </Stack>
        )}
      </Typography>
      {!contentHidden && (
        <>
          {props.section.SubHeaderComponent && (
            <props.section.SubHeaderComponent state={state} isCompareTo={!!compareTo} />
          )}
          {(props.section.columns || 1) == 1
            && props.section.fields.map(field => (
              <FormGroup key={field.label + field.field}>
                <FoodEditorFieldView
                  field={{ ...field, viewOnly: field.viewOnly ?? !!compareTo }}
                  state={state}
                  isCompareTo={compareTo != null}
                />
              </FormGroup>
            ))}
          {(props.section.columns || 1) > 1 && (
            <Grid container spacing={1}>
              {props.section.fields.map(field => (
                <Grid item key={field.label} xs={field.size ? field.size : Math.floor(12 / props.section.columns!)}>
                  <FoodEditorFieldView
                    field={{ ...field, viewOnly: field.viewOnly ?? !!compareTo }}
                    state={state}
                    isCompareTo={compareTo != null}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Stack>
  );
}

const CompareView = (props: {
  state: FoodEditorState,
  compareTo?: FoodEditorValue | null,
}) => {
  const { state } = props;
  const [compareTo, setCompareTo] = useState<FoodEditorValue | null>(props.compareTo || null);
  const [searchParams, setSearchParams] = useSearchParams();
  const flags = useFeatures();
  const compareToName = searchParams.get('compare');
  useEffect(() => {
    const setOrClear = compareToName ? 'set' : 'cleared';
    mixpanel.track(`Food editor: compare with ${setOrClear}`, {
      'Current food name': state.value.term,
      'Comparison food name': compareToName,
    });
  }, [compareToName, state.value.term]);

  const getFoodQuery = useFoodDetails(!compareTo ? compareToName : null);
  const setCompareQueryParam = useCallback((compare: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('compare', compare);
    setSearchParams(params);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setCompareTo(props.compareTo || null);
  }, [props.compareTo]);

  useEffect(() => {
    if (getFoodQuery.query.isSuccess && getFoodQuery.food) {
      setCompareTo(foodResponseToFoodEditorValue(apiFoodResponseToFoodResponse(getFoodQuery)));
    }
  }, [getFoodQuery]);

  const onCompareViewClear = () => {
    setCompareQueryParam('');
    setCompareTo(null);
  };

  return (
    <>
      {compareTo
        ? (
          <>
            <FoodItemHeader
              heading={compareTo.term}
              headingChildren={<Button onClick={onCompareViewClear}>Back</Button>}
            />
            {flags.food_editor_composite_foods && (
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={compareTo.definitionType}
                exclusive
                disabled
                style={{ paddingTop: 10, marginBottom: -10 }}
              >
                <ToggleButton value="atomic">
                  Atomic
                </ToggleButton>
                <ToggleButton value="composite">
                  Composite
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {EDITOR_SECTIONS.filter(section => {
              if (compareTo.definitionType == 'composite') {
                return section.showForComposite ?? true;
              }
              return !(section.showForComposite ?? false);
            }).map(section => (
              <FoodEditorSectionView
                key={section.id + '-compare'}
                section={section}
                state={state}
                compareTo={compareTo}
              />
            ))}
          </>
        )
        : <FoodItemHeader heading="Compare with..." />}
      {!compareToName && (
        <FoodSearchView
          queryParamName="compare-search"
          onFoodSelect={foodName => setCompareQueryParam(foodName)}
        />
      )}
      {!!compareToName && getFoodQuery.query.isLoading && !compareTo && <div>Loading...</div>}
      {!!compareToName && getFoodQuery.query.isSuccess && !getFoodQuery.food && !compareTo && <div>Not Found</div>}
    </>
  );
};

const WHITEBOARD_ITEM_TYPES = {
  productUrl: {
    label: 'Product URL',
    matches: /^https?:/,
    inputType: 'url',
  },

  otherText: {
    label: 'Other Text',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  ingredients: {
    label: 'Ingredients (text)',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  ingredientsPhoto: {
    label: 'Ingredients (photo)',
    matches: /$a/,
    inputType: 'image',
  },

  otherImage: {
    label: 'Other Image',
    matches: /$a/,
    inputType: 'image',
  },

  nft: {
    label: 'NFT',
    matches: /$a/,
    inputType: 'image',
  },

  productImage: {
    label: 'Product Image',
    matches: /$a/,
    inputType: 'image',
  },

  brand: {
    label: 'Brand',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  region: {
    label: 'Region',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  nutrientSourceEst: {
    label: 'Nutrient Estimates Source',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  measureSourceEst: {
    label: 'Measure Estimates Source',
    matches: /^(?!\s*$).+/,
    inputType: 'textarea',
  },

  none: {
    label: '',
    matches: /[\s\S]+/,
    inputType: 'none',
  },
};
type WhiteboardItemTypeName = keyof typeof WHITEBOARD_ITEM_TYPES;

export type WhiteboardItemData = {
  id: number, // 0 for new notes
  type: WhiteboardItemTypeName,
  order: number,
  imageDataUri: string | null,
  imageUri: string | null,
  text: string,
  metadata: object, // change later, no metadata for now
};

const FoodEditorWhiteboardNote = (props: {
  noteData: WhiteboardItemData,
  isDraggedOver: boolean,
  saveData: (newNote: WhiteboardItemData) => void,
  handleNoteDelete: () => void,
  handleDragStart: () => void,
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void,
  handleDragEnd: () => void,
  onSelectProductImage: (imageUri: string) => void,
  onSelectProductUrl: (url: string) => void,
}) => {
  const { saveData } = props;
  const [noteData, _setNoteData] = useState<WhiteboardItemData>({ ...props.noteData });

  const setNoteData = (newValue: WhiteboardItemData) => {
    saveData({ ...newValue });
    _setNoteData({ ...newValue });
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    const images: DataTransferItem[] = [].slice.call(evt.clipboardData.items).filter((item: DataTransferItem) => {
      // Filter the image items only
      return /^image\//.test(item.type);
    });
    if (!images.length) {
      const textValue = evt.clipboardData.getData('text');
      setNoteData({ ...noteData, type: detectTextType(textValue), text: textValue });
      return;
    }
    const imageAsFile = images[0].getAsFile();
    if (!imageAsFile) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageAsFile);
    reader.onload = () => {
      if (reader.result) {
        setNoteData({
          ...noteData,
          imageUri: reader.result as string,
          type: noteData.type != 'productImage' && noteData.type != 'nft' ? 'otherImage' : noteData.type,
        });
      }
    };
  };

  const detectTextType = (value: string): WhiteboardItemTypeName => {
    for (const [typename, typeDef] of Object.entries(WHITEBOARD_ITEM_TYPES)) {
      if (value.match(typeDef.matches)) {
        return typename as WhiteboardItemTypeName;
      }
    }
    return 'none';
  };

  useEffect(() => {
    _setNoteData({ ...props.noteData });
  }, [props.noteData]);

  const itemDef = WHITEBOARD_ITEM_TYPES[noteData.type] || WHITEBOARD_ITEM_TYPES['none'];

  return (
    <Stack
      style={{ marginTop: 10, marginBottom: 10 }}
      key={noteData.order}
      spacing={1}
      onDragOver={props.handleDragOver}
      onDragEnd={props.handleDragEnd}
    >
      {props.isDraggedOver && <Divider sx={{ borderBottomColor: '#039dfc', borderBottomWidth: 5 }} />}
      {itemDef.inputType != 'none' && (
        <Stack style={{ width: '100%' }} direction="row" justifyContent="space-between">
          <Select
            style={{ width: '25%', maxHeight: 41 }}
            value={noteData.type}
            onChange={(evt) => {
              setNoteData({
                ...noteData,
                type: evt.target.value as WhiteboardItemTypeName,
              });
            }}
          >
            {Object.entries(WHITEBOARD_ITEM_TYPES).filter(([key, value]) => value.label).map(([key, value]) => (
              <MenuItem
                key={key}
                value={key}
              >
                {value.label}
              </MenuItem>
            ))}
          </Select>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0.5}
          >
            {itemDef.label == 'Product Image' && (
              <Button
                variant="outlined"
                onClick={() => {
                  const imageUrl = noteData.imageUri || noteData.imageDataUri;
                  imageUrl && props.onSelectProductImage(imageUrl);
                }}
              >
                Use as food image
              </Button>
            )}
            {itemDef.label == 'Product URL' && (
              <Button
                variant="outlined"
                onClick={() => {
                  const sourceUrl = noteData.text;
                  sourceUrl && props.onSelectProductUrl(sourceUrl);
                }}
              >
                Use as nutrition source URL
              </Button>
            )}
            <IconButton onClick={props.handleNoteDelete}>
              <Delete color="primary" />
            </IconButton>
            <div
              onDragStart={props.handleDragStart}
              draggable
            >
              <DragHandle />
            </div>
          </Stack>
        </Stack>
      )}
      {itemDef.inputType == 'url'
        ? (
          <Stack direction="row" spacing={1}>
            <OutlinedInput
              style={{ width: '100%' }}
              placeholder="https://"
              onChange={evt => _setNoteData({ ...noteData, text: evt.target.value })}
              onBlur={() => setNoteData(noteData)}
              value={noteData.text}
              // autoFocus // do we need this?
            />
            <IconButton
              href={noteData.text}
              target="_blank"
              rel="norefferer noopener"
              style={{ alignSelf: 'center' }}
            >
              <OpenInNew />
            </IconButton>
          </Stack>
        )
        : itemDef.inputType == 'textarea'
        ? (
          <TextField
            style={{ width: '100%' }}
            onChange={evt => _setNoteData({ ...noteData, text: evt.target.value })}
            onBlur={() => setNoteData(noteData)}
            value={noteData.text}
            minRows={3}
            multiline
          />
        )
        : itemDef.inputType == 'image'
        ? (
          <Stack>
            {noteData.imageUri
              ? (
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: 300,
                    maxHeight: itemDef.label == 'NFT' ? undefined : 400,
                    cursor: 'pointer',
                    border: '1px solid #ddd',
                  }}
                  onClick={() => {
                    window.open(noteData.imageUri!, '_blank');
                  }}
                  src={noteData.imageUri}
                />
              )
              : (
                <TextField
                  style={{ width: '100%' }}
                  placeholder="Paste image here"
                  value=""
                  onPaste={handlePaste}
                  minRows={1}
                />
              )}
          </Stack>
        )
        : itemDef.inputType == 'none'
        ? (
          <TextField
            style={{ width: '100%' }}
            onChange={evt => _setNoteData(prev => ({ ...prev, text: evt.target.value }))}
            value={noteData.text}
            onPaste={handlePaste}
            onBlur={() => {
              setNoteData({ ...noteData, type: detectTextType(noteData.text) });
            }}
            minRows={3}
            multiline
          />
        )
        : <div>Unknown Input Type: {itemDef.inputType}</div>}
    </Stack>
  );
};

const whiteboardItemDataToApiRequest = (note: WhiteboardItemData): FoodWhiteboardCreateUpdateRequest => {
  return {
    id: note.id || null,
    type: note.type,
    order: note.order,
    text: note.text,
    image_uri: note.imageUri,
    image_hash: note.imageDataUri ?? null,
    whiteboard_metadata: note.metadata,
  };
};

const apiDataToWhiteboardItems = (apiWhiteboardNotes: FoodWhiteboardItemResponse[]): WhiteboardItemData[] => {
  return apiWhiteboardNotes.map(note => ({
    id: note.id as number,
    type: note.type as WhiteboardItemTypeName,
    order: note.order,
    text: note.text ?? '',
    imageUri: note.image_uri,
    imageDataUri: note.image_hash ?? null,
    metadata: note.whiteboard_metadata,
  }));
};

const _useWhiteboardItemStore = create<Record<string, WhiteboardItemData[]>>(() => ({}));

const useFoodEditorWhiteboardWIPItems = (term: string) => {
  return _useWhiteboardItemStore(state => state[term] || []);
};

const INIT_WHITEBOARD_ITEMS_TYPES: WhiteboardItemTypeName[] = [
  'productImage',
  'nft',
  'ingredients',
  'productUrl',
];

export const getEmptyWhiteboardItem = (order: number = 100) => ({
  id: 0,
  type: 'none' as WhiteboardItemTypeName,
  order,
  imageUri: null,
  imageDataUri: null,
  text: '',
  metadata: {},
});

const getInitWhiteboardItemResponses = () => {
  const initItems: FoodWhiteboardItemResponse[] = INIT_WHITEBOARD_ITEMS_TYPES.map((type, idx) => {
    return {
      ...whiteboardItemDataToApiRequest(getEmptyWhiteboardItem(-idx)),
      type: type,
    } as FoodWhiteboardItemResponse;
  });
  return initItems;
};

export const useFoodEditorWhiteboardService = (opts: { term: string, ndbNumber: string }) => {
  const { term, ndbNumber } = opts;
  const emptyItem = getEmptyWhiteboardItem;

  const query = useQuery(['food-whiteboard-items', term, ndbNumber], async () => {
    if (!ndbNumber) {
      return getInitWhiteboardItemResponses();
    }

    const res = await foodApi.appApiFoodFoodWhiteboardGetWhiteboardItemsQuery({ food_name: term });
    const apiItems = res.data.sort((a, b) => a.order - b.order);
    let initItemOrder = (apiItems[0]?.order ?? 1) - 1;

    INIT_WHITEBOARD_ITEMS_TYPES.forEach(type => {
      if (apiItems.some(i => i.type == type)) {
        return;
      }

      apiItems.push(
        { ...whiteboardItemDataToApiRequest(emptyItem(initItemOrder)), type: type } as FoodWhiteboardItemResponse,
      );
      --initItemOrder;
    });

    return apiItems;
  }, {
    enabled: !!term,
  });

  const whiteboardItems = useFoodEditorWhiteboardWIPItems(term);

  const setWhiteboardItems = (callback: (items: WhiteboardItemData[]) => WhiteboardItemData[]) => {
    _useWhiteboardItemStore.setState(prevFull => {
      const prev = prevFull[term] || [];
      const newItems = callback(prev);
      newItems.sort((a, b) => a.order - b.order);
      if (!newItems.some(i => i.id == 0 && i.type == 'none')) {
        newItems.splice(0, 0, emptyItem((newItems[0]?.order ?? 1) - 1));
      }
      return {
        ...prevFull,
        [term]: newItems,
      };
    });
  };

  useEffect(() => {
    if (!query.isSuccess) {
      return;
    }
    setWhiteboardItems(items => {
      return [
        ...apiDataToWhiteboardItems(query.data),
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess]);

  const removeWhiteboardItem = async (item: WhiteboardItemData) => {
    setWhiteboardItems(items => {
      return items.filter(i => i !== item);
    });
    if (!ndbNumber) {
      return;
    }
    await foodApi.appApiFoodFoodWhiteboardDeleteWhiteboardItem({
      food_name: term,
      id: item.id,
    });
  };

  const _saveWhiteboardItems = (oldVal: WhiteboardItemData, newVal: WhiteboardItemData) => {
    setWhiteboardItems(items => {
      if (items.includes(oldVal)) {
        return items.map(i => i === oldVal ? newVal : i);
      }
      return [...items, newVal];
    });
  };

  const saveWhiteboardItem = async (oldVal: WhiteboardItemData, newVal: WhiteboardItemData) => {
    if (newVal.type == 'none') {
      return;
    }

    const allDataFieldsEmpty = checkEmptyWhiteboardItem(oldVal) && checkEmptyWhiteboardItem(newVal);

    if (!ndbNumber || allDataFieldsEmpty) {
      _saveWhiteboardItems(oldVal, newVal);
      return;
    }

    if (newVal.id) {
      await foodApi.appApiFoodFoodWhiteboardPutWhiteboardItemQuery({
        food_name: term,
        id: newVal.id,
        FoodWhiteboardCreateUpdateRequest: whiteboardItemDataToApiRequest(newVal),
      });
    } else {
      const res = await foodApi.appApiFoodFoodWhiteboardPostWhiteboardItemQuery({
        food_name: term,
        FoodWhiteboardCreateUpdateRequest: whiteboardItemDataToApiRequest(newVal),
      });
      newVal.id = res.data.id;
    }

    _saveWhiteboardItems(oldVal, newVal);
  };

  const clearWhiteboardItems = (food: string) => {
    _useWhiteboardItemStore.setState(prevFull => {
      const { [food]: _, ...rest } = prevFull;
      return rest;
    });
  };

  const getInitWhiteboardItems = () => {
    return apiDataToWhiteboardItems(getInitWhiteboardItemResponses());
  };

  return {
    term,
    whiteboardItems,
    removeWhiteboardItem,
    saveWhiteboardItem,
    query,
    clearWhiteboardItems,
    getInitWhiteboardItems,
  };
};

const checkEmptyWhiteboardItem = (data: WhiteboardItemData) => {
  return !!data && !data.text && !data.imageUri && !data.imageDataUri;
};

export type FoodResponseCallbackRef = React.MutableRefObject<null | (() => Promise<void>)>;

export const Whiteboard = (props: {
  whiteboardService: ReturnType<typeof useFoodEditorWhiteboardService>,
  foodResponseCallbackRef?: FoodResponseCallbackRef,
  mode: string,
  onComparisonItemSelected: (compare: FoodEditorValue | null) => void,
  onProductImageSelected: (imageUri: string) => void,
  onProductUrlSelected: (url: string) => void,
  onlyShowCopyPaste?: boolean,
}) => {
  const { whiteboardService } = props;
  const term = whiteboardService.term;

  const [dragElement, setDragElement] = useState<number>(-1);
  const [dragOverElement, setDragOverElement] = useState<number>(-1);

  const headingStyle: React.CSSProperties = {
    backgroundColor: '#1976d2',
    color: 'white',
    height: 40,
    lineHeight: '40px',
    flex: 1,
    marginBottom: 10,
    marginLeft: -10,
    marginRight: -10,
    paddingLeft: 15,
    paddingRight: 10,
  };

  const sectionStyle: React.CSSProperties = {
    marginBottom: 25,
    paddingLeft: 10,
    paddingRight: 10,
  };

  const handleNoteChange = (i: number, newNote: WhiteboardItemData) => {
    const oldNote = whiteboardService.whiteboardItems[i];
    whiteboardService.saveWhiteboardItem(oldNote, newNote);
  };

  const handleNoteDelete = (note: WhiteboardItemData) => {
    whiteboardService.removeWhiteboardItem(note);
  };

  const handleDragStart = (i: number) => {
    setDragElement(i);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    e.preventDefault();
    setDragOverElement(i);
  };

  const handleDragEnd = () => {
    if (dragElement == dragOverElement || !dragOverElement) {
      setDragElement(-1);
      setDragOverElement(-1);
      return;
    }
    const items = whiteboardService.whiteboardItems;
    const newOrder = dragElement < dragOverElement
      ? (
        items.length - 1 == dragOverElement
          ? items[dragOverElement].order + 1
          : (items[dragOverElement].order + items[dragOverElement + 1].order) / 2.0
      )
      : (items[dragOverElement - 1].order + items[dragOverElement].order) / 2.0;
    whiteboardService.saveWhiteboardItem(
      items[dragElement],
      { ...items[dragElement], order: newOrder },
    );
    setDragElement(-1);
    setDragOverElement(-1);
  };

  const [searchParams] = useSearchParams();
  const relevantQueueTerm = searchParams.get('initial-name') ?? term;

  const relevantQueuesQuery = useQuery(['relevant_queues', relevantQueueTerm], async () => {
    if (!relevantQueueTerm) {
      return {
        queues: [],
      };
    }

    const queueRes = await foodApi.appApiFoodFoodWhiteboardGetRelevantQueuesQuery({
      food_name: relevantQueueTerm,
    }).then(res => res.data);

    return {
      queues: queueRes,
    };
  });

  (props.foodResponseCallbackRef || { current: null }).current = async () => {
    if (props.mode != 'create') {
      return;
    }

    const whiteboardItemsToSubmit = whiteboardService.whiteboardItems;
    const promises = Promise.all(
      whiteboardItemsToSubmit.map((note: WhiteboardItemData) => {
        if (note.type == 'none' || checkEmptyWhiteboardItem(note)) {
          return;
        }
        return foodApi.appApiFoodFoodWhiteboardPostWhiteboardItem({
          food_name: term,
          FoodWhiteboardCreateUpdateRequest: whiteboardItemDataToApiRequest(note),
        });
      }),
    );
    await promises;
    whiteboardService.clearWhiteboardItems(term);
  };

  return (
    <Stack>
      {!props.onlyShowCopyPaste && (
        <>
          <Stack style={sectionStyle} divider={<Divider />}>
            <Typography variant="h6" style={headingStyle}>
              Search
            </Typography>
            <FoodSearchPanel
              term={term}
              showImmediateResults={props.mode == 'create'}
              onComparisonItemSelected={props.onComparisonItemSelected}
            />
          </Stack>
          <Stack style={{ ...sectionStyle, marginBottom: '0' }} divider={<Divider />}>
            <Typography variant="h6" style={headingStyle}>
              Relevant Queues
            </Typography>
            <RelevantQueuesTileView relevantQueuesQuery={relevantQueuesQuery} />
          </Stack>
        </>
      )}
      <Stack style={sectionStyle}>
        <Typography variant="h6" style={headingStyle}>
          Copy/Paste
        </Typography>
        {whiteboardService.whiteboardItems.map((note, i) => {
          if (i > 0 && (whiteboardService.query.isError || whiteboardService.query.isLoading)) {
            return;
          }
          return (
            <FoodEditorWhiteboardNote
              key={note.order}
              noteData={note}
              isDraggedOver={dragOverElement == i && !!i}
              saveData={handleNoteChange.bind(this, i)}
              handleNoteDelete={() => handleNoteDelete(note)}
              handleDragStart={handleDragStart.bind(this, i)}
              handleDragOver={(e) => handleDragOver(e, i)}
              handleDragEnd={handleDragEnd}
              onSelectProductImage={(imageUrl) => {
                props.onProductImageSelected(imageUrl);
              }}
              onSelectProductUrl={(url) => {
                props.onProductUrlSelected(url);
              }}
            />
          );
        })}
        {whiteboardService.query.isLoading && <div>Loading...</div>}
        {whiteboardService.query.isError && (
          <div>
            Could not load existing whiteboard notes: {'' + whiteboardService.query.error}
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export const RelevantQueuesTileView = (props: {
  relevantQueuesQuery: UseQueryResult<{
    queues: MealPhotoQueueResponse[],
  }, unknown>,
}) => {
  const { relevantQueuesQuery } = props;
  const handleQueueClick = (queueItem: any) => {
    // navigate(`/queue-item/${queueItem.id}`);
    window.open(`${window.location.origin}/queue-item/${queueItem.id}`, '_blank', 'rel=noopener noreferrer');
  };

  return (
    <>
      {relevantQueuesQuery.isSuccess && (
        <Stack direction="row" sx={{ maxWidth: '100%', overflowX: 'auto' }} spacing={1}>
          {relevantQueuesQuery.data?.queues.map((queueItem, index) => {
            return (
              <Card
                sx={{ overflow: 'unset' }}
                elevation={0}
                key={index}
              >
                <CardActionArea
                  sx={{ padding: '10px' }}
                  onClick={() => {
                    handleQueueClick(queueItem);
                  }}
                >
                  <Stack
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <div>
                      <PreviewImage queueItem={queueItem} size="resized" />
                    </div>
                    <Typography variant="h6">
                      <strong>#{queueItem.id}</strong>
                    </Typography>
                    <Typography variant="h6">
                      <span style={{ fontWeight: 'normal' }}>Patient</span>{' '}
                      <PatientID userId={queueItem.patient_id} isPriority={!!queueItem.is_priority_patient} />
                    </Typography>
                    <Typography variant="h6">
                      <HumanTime value={queueItem.reviewed_time} />
                    </Typography>
                  </Stack>
                </CardActionArea>
              </Card>
            );
          })}
          {!relevantQueuesQuery.data?.queues.length && (
            <Typography variant="h6" style={{ marginBottom: '25px' }}>
              There are no relevant queues
            </Typography>
          )}
        </Stack>
      )}
      {relevantQueuesQuery.isFetching && (
        <Typography variant="h6" style={{ marginBottom: '25px' }}>
          Loading Queues...
        </Typography>
      )}
      {relevantQueuesQuery.isError && (
        <Typography variant="h6" style={{ marginBottom: '25px' }}>
          No relevant queues for the given food name
        </Typography>
      )}
    </>
  );
};

export const UsersOnCurrentPageWarning = () => {
  const location = useLocation();
  const usersOnCurrentPage = useUsersOnPage(location.pathname + location.search);

  if (window.location.href.includes('utm_source=food-search')) {
    return (null);
  }

  return (
    usersOnCurrentPage.length > 0
      ? (
        <div
          style={{
            display: 'inline-block',
            width: '1.0rem',
            height: '1.0rem',
            borderRadius: '50%',
            backgroundColor: '#9c27b0',
          }}
          title={usersOnCurrentPage.map(u => u.uid).join(', ') + (usersOnCurrentPage.length > 1 ? ' are ' : ' is ')
            + 'also viewing this food'}
        />
      )
      : null
  );
};

function FoodItemEditor(props: FoodEditorProps) {
  const termState = useErrorState(props.value.term, props.showErrors, termRequired);

  const { hasAuth } = useAuth();
  const [isDuplicate, setDuplicateError] = useState(false);
  const [selectedTab, setSelectedTab] = useLocalStorage<string>('food-editor-selected-tab', 'whiteboard');
  const [compareTo, setCompareTo] = useState<FoodEditorValue | null>(null);
  const { attributions } = props;
  const flags = useFeatures();
  const foodDefinitionType = props.value.definitionType || 'atomic';
  const termInputRef = useRef<HTMLInputElement>(null);
  const [mealItemFromExt, setMealItemFromExt] = useState<MealItemResponse[]>([]);

  useEffect(() => {
    if (props.initialName) {
      const termName = props.initialName;
      props.onChangeValue(currentValue => ({
        ...currentValue,
        term: termName,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialName]);

  const [searchParams] = useSearchParams();
  const relevantQueueTerm = searchParams.get('initial-name') ?? props.initialName ?? props.value.term;

  const relevantQueuesQuery = useQuery(['relevant_queues', relevantQueueTerm], async () => {
    if (!relevantQueueTerm) {
      return {
        queues: [],
      };
    }

    const queueRes = await foodApi.appApiFoodFoodWhiteboardGetRelevantQueuesQuery({
      food_name: relevantQueueTerm,
    }).then(res => res.data);

    return {
      queues: queueRes,
    };
  });

  useEffect(() => {
    const relevantMealItems: MealItemResponse[] = [];

    relevantQueuesQuery.data?.queues.forEach(q => {
      if (!q.existing_items) {
        return;
      }

      for (const mealItem of q.existing_items) {
        if (mealItem.food_name == relevantQueueTerm) {
          relevantMealItems.push(mealItem);
          return;
        }
      }
    });

    setMealItemFromExt(relevantMealItems.filter(i => i.custom_item_source && i.custom_item_source.startsWith('ext:')));
  }, [relevantQueuesQuery.data?.queues]);

  const [warnings, setWarnings] = useState<FoodDetailsWarning[]>([]);

  const [showWarnings, setShowWarnings] = useState(true);
  const toggleWarnings = () => {
    setShowWarnings(!showWarnings);
  };

  useEffect(() => {
    updateWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWarningsInfo = useUpdateWarnings();
  // Updates the state prop warnings with the current nutrient values, used onBlur of numeric text fields
  // Does not update database
  const updateWarnings = async () => {
    if (foodEditorGetErrors(state.value).length) {
      return;
    }
    const currentFoodInfo = await updateWarningsInfo.mutateAsync({
      term: state.value.term,
      foodRequest: foodEditorValueToFoodRequest(state.value),
    });
    setWarnings(currentFoodInfo.warnings);
  };

  const state: FoodEditorState = {
    hasAuth,
    onFieldChange(updates: Partial<FoodEditorValue>) {
      props.onChangeValue(currentValue => {
        const hasChanges = Object.keys(updates).some(key => {
          return (currentValue as any)[key] != (updates as any)[key];
        });
        if (!hasChanges) {
          return currentValue;
        }
        const changesObj = Object.keys(updates).reduce((acc, key) => {
          return {
            ...acc,
            [`${key}: old`]: (currentValue as any)[key] ?? 'undefined',
            [`${key}: new`]: (updates as any)[key] ?? 'undefined',
          };
        }, {
          'Food name': currentValue.term,
        } as Record<string, any>);
        mixpanel.track('Food editor: field changed', changesObj);
        const newValue: FoodEditorValue = {
          ...currentValue,
          ...updates,
        };
        const newValueAsFoodDetails = foodEditorValueToPartialFoodDetails(newValue);
        const newDerivations = foodDetailsToDerivations(newValueAsFoodDetails);
        const newValueWithDerivations = applyDerivationsToFoodEditorValue(newValue, newDerivations);
        EDITOR_SECTIONS.forEach(section => {
          section.fields.forEach(field => {
            if (field.derived) {
              (newValueWithDerivations[field.field] as any) = field.derived(newValueWithDerivations);
            }
          });
        });
        return newValueWithDerivations;
      });
    },
    termState,
    initialName: props.initialName,
    value: props.value,
    showErrors: props.showErrors,
    mode: props.mode,
    warnings,
    updateWarnings,
    showWarnings,
    toggleWarnings,
    isDuplicate,
    termInputRef,
  };

  const whiteboardService = useFoodEditorWhiteboardService({
    term: props.initialName ?? state.value.term,
    ndbNumber: props.value.ndbNumber,
  });

  const checkDuplicate = useQuery(
    ['check-duplicate', state.termState.blurred, state.termState.onBlur],
    async () => {
      const res = await foodApi.appApiFoodFoodSearchGetFoodQuery({
        food_name: props.value.term,
      });
      setDuplicateError(res.data.name === props.value.term);
      return res;
    },
    {
      enabled: !!state.value.term,
    },
  );

  useEffect(() => {
    const termInput = termInputRef.current;
    const handleBlur = () => {
      setDuplicateError(false);
      checkDuplicate.refetch();
    };
    termInput?.addEventListener('blur', handleBlur);

    return () => {
      termInput?.removeEventListener('blur', handleBlur);
    };
  }, [checkDuplicate]);

  useEffect(() => {
    if (state.value.definitionType == 'composite') {
      return;
    }

    if (selectedTab == 'summary') {
      setSelectedTab('whiteboard');
    }
  }, [selectedTab, setSelectedTab, state.value.definitionType]);

  const nfts = useFoodEditorWhiteboardNftImageDetection(state);
  state.nfts = nfts;

  EDITOR_SECTIONS.forEach(section => {
    section.fields.forEach(field => {
      if (field.useEffect) {
        field.useEffect(state);
      }
    });
  });

  const stickySidePanelStyle: React.CSSProperties = {
    position: 'sticky',
    top: 150,
    left: '50%',
    height: '88vh',
  };

  const sidePanelViewStyle: React.CSSProperties = {
    position: 'relative',
    top: -71,
    height: '100%',
    overflow: 'scroll',
    borderRadius: 3,
    boxShadow: '1px 1px 1px 1px grey',
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const onComparisonItemSelected = (compare: FoodEditorValue | null) => {
    if (compare) {
      setCompareTo(compare);
    }
    setSelectedTab('compare');
  };

  const isCompositeFood = state.value.definitionType == 'composite';

  const handleFoodDefinitionTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newFoodType: 'atomic' | 'composite',
  ) => {
    if (newFoodType == state.value.definitionType || !newFoodType) {
      return;
    }

    if (!window.confirm(`Please confirm that you want to change the food type to ${newFoodType}`)) {
      return;
    }

    state.onFieldChange({
      definitionType: newFoodType,
    });
  };

  const getExternalApiUrl = (source: string, sourceId: string | null): { apiUrl: string, apiHeader: HeadersInit } => {
    if (source == 'ext:usda_api' && sourceId) {
      return {
        apiUrl: `https://api.nal.usda.gov/fdc/v1/food/${sourceId}?${
          encodeQueryParams({ api_key: 'c8QLZVgVhamZMOe4idVrnfDgGeF5ZI568JXa3Mym' })
        }`,
        apiHeader: {},
      };
    }

    if (source == 'ext:nutritionix' && sourceId) {
      return {
        apiUrl: `https://trackapi.nutritionix.com/v2/search/item/?nix_item_id=${sourceId}`,
        apiHeader: {
          'x-app-id': 'bea9ffb2',
          'x-app-key': '2ae289d25cc7fb2585e7c448427133c0',
          'x-remote-user-id': '0',
        },
      };
    }

    return {
      apiUrl: '',
      apiHeader: {},
    };
  };

  const onExtSourceImportClick = async (mealItem: MealItemResponse) => {
    if (!mealItem.custom_item_source || !mealItem.custom_item_source_id) {
      return;
    }

    const { apiUrl, apiHeader } = getExternalApiUrl(mealItem.custom_item_source, mealItem.custom_item_source_id);

    if (!apiUrl) {
      return;
    }

    const res = await fetch(apiUrl, {
      method: 'GET',
      headers: apiHeader,
    });
    const resJson = await res.json();

    const measure = mealItem.custom_item_source == 'ext:usda_api' ? _usdaResultGetMeasure(resJson) : null;
    if (!measure && mealItem.custom_item_source == 'ext:usda_api') {
      return null;
    }

    const usdaApiToUsdaNutrients = (nxNutrients: any[]) => {
      const usdaNutrients: Partial<UsdaNutritionResponse> = {};
      nxNutrients.forEach((nxNutrient) => {
        const usdaField = nutritionxIdToUsdaNameMap[nxNutrient.nutrient.number];
        if (!usdaField) {
          return;
        }

        if (usdaField == 'omega_three_fatty_acids_g') {
          usdaNutrients[usdaField] = (usdaNutrients[usdaField] || 0) + nxNutrient.value;
          return;
        }

        usdaNutrients[usdaField] = nxNutrient.amount;
      });

      usdaNutrients.netcarb_g = typeof usdaNutrients.carbohydrate_g == 'number'
          && typeof usdaNutrients.fiber_g == 'number'
        ? usdaNutrients.carbohydrate_g - usdaNutrients.fiber_g
        : null;

      return usdaNutrients;
    };

    const externalItem = mealItem.custom_item_source == 'ext:usda_api'
      ? resJson
      : mealItem.custom_item_source == 'ext:nutritionix'
      ? resJson.foods[0]
      : null;

    const result: ExternalFoodSearchResult | null = mealItem.custom_item_source == 'ext:usda_api'
      ? {
        id: '' + externalItem.fdcId,
        externalId: '' + externalItem.fdcId,
        sourceId: 'ext:usda_api',
        sourceIdAbbr: 'usda',
        item_name: externalItem.description.toLowerCase(),
        full_name: externalItem.description.toLowerCase(),
        brand_name: 'brandOwner' in externalItem ? externalItem.brandOwner.toLowerCase() as string : null,
        serving_unit_label: measure?.label,
        servings: measure?.servings || 1,
        serving_unit_amount: safeDivNull(measure!.eqv, measure!.servings || 1),
        nutrients: usdaApiToUsdaNutrients(externalItem.foodNutrients || []),
        thumbUrl: null,
      }
      : mealItem.custom_item_source == 'ext:nutritionix'
      ? {
        id: externalItem.nix_item_id,
        externalId: externalItem.nix_item_id,
        sourceId: 'ext:nutritionix',
        sourceIdAbbr: 'nutrx',
        item_name: externalItem.food_name,
        full_name: externalItem.brand_name_item_name,
        brand_name: externalItem.brand_name,
        serving_unit_label: externalItem.serving_unit.toLowerCase(),
        servings: externalItem.serving_qty,
        serving_unit_amount: safeDivNull(externalItem.serving_weight_grams, externalItem.serving_qty),
        nutrients: nutritionxNutrientsToUsdaNutrients(externalItem.full_nutrients || []),
        thumbUrl: externalItem.photo?.thumb,
      }
      : null;

    if (!result) {
      return;
    }

    const newFoodValue = externalFoodSearchResultToFoodEditorValue(result);
    onComparisonItemSelected({
      ...newFoodValue,
      term: newFoodValue.term + ` (from ${mealItem.custom_item_source.slice(4)}) `,
    });
  };

  return (
    <Container maxWidth={false} style={{ padding: 0 }}>
      {mealItemFromExt.length > 0 && (
        <Alert severity="info" sx={{ maxWidth: '50%' }}>
          This meal item is from an external source
          {mealItemFromExt.map((item, idx) => {
            return (
              <div key={idx} style={{ minHeight: '1rem' }}>
                Source: {item.custom_item_source?.slice(4)}&nbsp;
                <Button
                  variant="contained"
                  sx={{ padding: 0.25, height: '1rem' }}
                  onClick={() => {
                    onExtSourceImportClick(item);
                  }}
                >
                  Compare Item
                </Button>
              </div>
            );
          })}
        </Alert>
      )}
      {(state.warnings?.length !== 0) && (
        <div style={{ position: 'fixed', top: 60, right: 10, zIndex: 100 }}>
          <List sx={{ p: 0, background: '#ffe8a5', borderRadius: '2%' }}>
            <ListItem disablePadding divider>
              <ListItemButton onClick={state.toggleWarnings}>
                <ListItemIcon>
                  <WarningOutlined />
                </ListItemIcon>
                <ListItemText primary="Warnings" primaryTypographyProps={{ fontWeight: 700, padding: '0 7px' }} />
                {state.showWarnings ? <DownOutlined /> : <UpOutlined />}
              </ListItemButton>
            </ListItem>
            <Collapse in={state.showWarnings} timeout="auto" unmountOnExit>
              {state.warnings && state.warnings.map((warning, idx) => (
                <Alert
                  key={idx}
                  variant="border"
                  color="warning"
                  icon={<WarningOutlined />}
                  // Will re add this when dismissing function is added
                  // action={
                  //   <Button color="warning" size="small">
                  //     Remove
                  //   </Button>
                  // }
                  sx={{
                    '& .MuiAlert-message': {
                      padding: '8px 0',
                    },
                  }}
                >
                  {warning.message}
                </Alert>
              ))}
            </Collapse>
          </List>
        </div>
      )}
      <Stack direction="row" style={{ height: '100%' }} gap={2}>
        <Stack width={1} maxWidth="50%" style={{ height: '100%', minWidth: 400 }}>
          <Stack direction="row" gap={1} alignItems="center">
            <FoodItemHeader heading={props.value.term} />
            <UsersOnCurrentPageWarning />
          </Stack>
          {flags.food_editor_composite_foods && (
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={foodDefinitionType}
              exclusive
              onChange={handleFoodDefinitionTypeChange}
              style={{ paddingTop: 10, marginBottom: -10 }}
            >
              <ToggleButton value="atomic">
                Atomic
              </ToggleButton>
              <ToggleButton value="composite">
                Composite
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {EDITOR_SECTIONS.filter(section => {
            if (isCompositeFood) {
              return section.showForComposite ?? true;
            }
            return !(section.showForComposite ?? false);
          }).map(section => (
            <FoodEditorSectionView
              key={section.id}
              section={section}
              state={state}
              compareTo={null}
            />
          ))}
          {!!attributions && (
            <>
              {attributions.createdTime && (
                <>
                  <Typography>
                    Created{!!attributions.createdByUserId && ' By User ' + attributions.createdByUserId}:
                  </Typography>
                  <HumanTime value={attributions.updatedTime} isUTC={true} />
                </>
              )}
              {attributions.triagedTime && (
                <>
                  <Typography>
                    Triaged{!!attributions.triagedByUserId && ' By User ' + attributions.triagedByUserId}:
                  </Typography>
                  <HumanTime value={attributions.triagedTime} isUTC={true} />
                </>
              )}
              {attributions.scrapedTime && (
                <>
                  <Typography>
                    Scraped{!!attributions.scrapedByUserId && ' By User ' + attributions.scrapedByUserId}:
                  </Typography>
                  <HumanTime value={attributions.scrapedTime} isUTC={true} />
                </>
              )}
              {attributions.reviewedTime && (
                <>
                  <Typography>
                    Reviewed{!!attributions.reviewedByUserId && ' By User ' + attributions.reviewedByUserId}:
                  </Typography>
                  <HumanTime value={attributions.reviewedTime} isUTC={true} />
                </>
              )}
              {attributions.updatedTime && (
                <>
                  <Typography>
                    Last Updated{!!attributions.lastUpdatedByUserId && ' By User ' + attributions.lastUpdatedByUserId}:
                  </Typography>
                  <HumanTime value={attributions.updatedTime} isUTC={true} />
                </>
              )}
            </>
          )}
        </Stack>
        <Stack
          width={1}
          maxWidth="50%"
          style={{
            ...(selectedTab != 'compare' ? stickySidePanelStyle : {}),
            minWidth: 400,
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            style={{ marginBottom: 25, position: 'relative', top: -69 }}
          >
            <Tabs variant="fullWidth" value={selectedTab} onChange={handleTabChange} style={{ width: '100%' }}>
              <Tab label="Compare Items" value="compare" />
              <Tab label="Whiteboard" value="whiteboard" />
              <Tab label="History" value="history" />
              {state.value.definitionType == 'composite' && <Tab label="Components Summary" value="summary" />}
            </Tabs>
          </Stack>
          <Stack style={{ display: selectedTab == 'compare' ? 'block' : 'none', position: 'relative', top: -73 }}>
            <CompareView
              state={state}
              compareTo={compareTo}
            />
          </Stack>
          <Stack style={{ ...sidePanelViewStyle, display: selectedTab == 'whiteboard' ? 'block' : 'none' }}>
            <Whiteboard
              whiteboardService={whiteboardService}
              foodResponseCallbackRef={props.foodResponseCallbackRef}
              mode={props.mode}
              onComparisonItemSelected={onComparisonItemSelected}
              onProductImageSelected={(imageUrl) => {
                const foodImageUrl = !imageUrl.startsWith('data:') ? imageUrl : 'x-please-load:' + imageUrl;
                state.onFieldChange({
                  foodImageUrl: foodImageUrl,
                });
              }}
              onProductUrlSelected={(url) => {
                state.onFieldChange({
                  nutritionSourceUrl: url,
                });
              }}
            />
          </Stack>
          <Stack style={{ ...sidePanelViewStyle, display: selectedTab == 'history' ? 'block' : 'none' }}>
            <FoodChangeLogs foodName={state.value.term} />
          </Stack>
          <Stack style={{ ...sidePanelViewStyle, display: selectedTab == 'summary' ? 'block' : 'none' }}>
            <FoodComponentSummaryTable foodComponents={state.value.components} />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}

export default function FoodEditor(props: FoodEditorProps) {
  return <FoodItemEditor {...props} />;
}
