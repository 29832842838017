import { FoodDetailsResponse, FoodVTOKLevelEnum } from 'api/generated/MNT';
import { apiFoodResponseToFoodResponse, foodEditorValueToPartialFoodDetails } from './api-client';
import { NUTRIENT_100G, nutrientScale } from './components/food-editor';
import { FoodEditorValue } from './types';
import foodResponseToFoodEditorValue from './utils/food-response-to-food-editor-value';
import { nutrientValueToRdaPct } from './utils/NutrientRDAValues';

type FDR<T extends keyof FoodDetailsResponse> = NonNullable<FoodDetailsResponse[T]>;

type FDRDottedFields<T extends keyof FoodDetailsResponse> = {
  [K in keyof FDR<T> & string as `${T}.${K}`]: FDR<T>[K];
};

type FoodDerivationFields =
  & FDRDottedFields<'food'>
  & FDRDottedFields<'usda_nutrition'>
  & FDRDottedFields<'cc'>
  & FDRDottedFields<'ranking'>
  & FDRDottedFields<'ontology'>;

export type FoodDerivationFieldDefinition<
  Name extends keyof FoodDerivationFields = keyof FoodDerivationFields,
  Fields extends keyof FoodDerivationFields = keyof FoodDerivationFields,
> = {
  name: Name,
  derivation: {
    dependsOn: Fields[],
    calculate: (inputs: Pick<FoodDerivationFields, Fields>) => FoodDerivation<Name>,
  },
};

export type FoodDerivation<Field extends keyof FoodDerivationFields = keyof FoodDerivationFields> = {
  fieldVisible: boolean,
  needsReview: boolean,
  field: Field,
  value: FoodDerivationFields[Field],
};

function d<
  Name extends keyof FoodDerivationFields,
  Fields extends keyof FoodDerivationFields,
>(inputs: FoodDerivationFieldDefinition<Name, Fields>): FoodDerivationFieldDefinition<Name, Fields> {
  return inputs;
}

function fdrFieldGet<F extends keyof FoodDerivationFields>(
  fdr: FoodDetailsResponse,
  field: F,
): FoodDerivationFields[F] {
  const [a, b] = field.split('.');
  return (fdr as any)[a][b];
}

function fdrFieldSet<F extends keyof FoodDerivationFields>(
  fdr: FoodDetailsResponse,
  field: F,
  value: FoodDerivationFields[F],
): FoodDetailsResponse {
  const [a, b] = field.split('.');
  return {
    ...fdr,
    [a]: {
      ...(fdr as any)[a],
      [b]: value,
    },
  };
}

const FOOD_EDITOR_CC_RANKING_DERIVATION_DEFINITIONS = [
  d({
    name: 'food.k_level',
    derivation: {
      dependsOn: ['usda_nutrition.potassium_mg', 'food.suggested_serving_amount_g', 'food.adult_serving_size_g'],
      calculate: (inputs) => {
        const potassiumMg = nutrientScale({
          value: inputs['usda_nutrition.potassium_mg'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const potassiumLevel: FoodVTOKLevelEnum = !potassiumMg || potassiumMg <= 0
          ? ''
          : potassiumMg >= 200
          ? 'high'
          : potassiumMg < 100
          ? 'low'
          : 'medium';
        return {
          fieldVisible: !!potassiumLevel,
          needsReview: false,
          field: 'food.k_level',
          value: potassiumLevel,
        };
      },
    },
  }),

  d({
    name: 'ranking.low_k',
    derivation: {
      dependsOn: [
        'food.k_level',
        'usda_nutrition.potassium_mg',
        'food.suggested_serving_amount_g',
        'food.adult_serving_size_g',
      ],
      calculate: (inputs) => {
        const kLevel = inputs['food.k_level'] || '';
        const potassiumMg = nutrientScale({
          value: inputs['usda_nutrition.potassium_mg'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const ranking = kLevel !== 'low' || !potassiumMg
          ? 0
          : potassiumMg < 20
          ? 1
          : potassiumMg >= 60
          ? 3
          : 2;
        return {
          fieldVisible: kLevel === 'low',
          needsReview: false,
          field: 'ranking.low_k',
          value: ranking,
        };
      },
    },
  }),

  d({
    name: 'ranking.high_k',
    derivation: {
      dependsOn: [
        'food.k_level',
        'usda_nutrition.potassium_mg',
        'food.suggested_serving_amount_g',
        'food.adult_serving_size_g',
      ],
      calculate: (inputs) => {
        const kLevel = inputs['food.k_level'];
        const potassiumMg = nutrientScale({
          value: inputs['usda_nutrition.potassium_mg'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const ranking = kLevel !== 'high' || !potassiumMg
          ? 0
          : potassiumMg >= 450
          ? 1
          : potassiumMg < 300
          ? 3
          : 2;
        return {
          fieldVisible: kLevel === 'high',
          needsReview: false,
          field: 'ranking.high_k',
          value: ranking,
        };
      },
    },
  }),

  d({
    name: 'cc.cc_high_fiber',
    derivation: {
      dependsOn: ['usda_nutrition.fiber_g', 'food.suggested_serving_amount_g', 'food.adult_serving_size_g'],
      calculate: (inputs) => {
        const fibreG = nutrientScale({
          value: inputs['usda_nutrition.fiber_g'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const isHighFiber = !!(fibreG >= 3
          || parseInt(
              nutrientValueToRdaPct(
                FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING['usda_nutrition.fiber_g'],
                fibreG.toString(),
              ) || '0',
            )
            >= 10);
        return {
          fieldVisible: !!isHighFiber,
          needsReview: false,
          field: 'cc.cc_high_fiber',
          value: isHighFiber,
        };
      },
    },
  }),

  d({
    name: 'cc.cc_calcium',
    derivation: {
      dependsOn: ['usda_nutrition.calcium_mg', 'food.suggested_serving_amount_g', 'food.adult_serving_size_g'],
      calculate: (inputs) => {
        const calciumMg = nutrientScale({
          value: inputs['usda_nutrition.calcium_mg'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const isHighCalcium = !!(parseInt(
          nutrientValueToRdaPct(
            FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING['usda_nutrition.calcium_mg'],
            calciumMg.toString(),
          ) || '0',
        )
          >= 15);
        return {
          fieldVisible: !!isHighCalcium,
          needsReview: false,
          field: 'cc.cc_calcium',
          value: isHighCalcium,
        };
      },
    },
  }),

  d({
    name: 'cc.cc_simplecarbs',
    derivation: {
      dependsOn: [
        'usda_nutrition.netcarb_g',
        'usda_nutrition.sugars_g',
        'food.suggested_serving_amount_g',
        'food.adult_serving_size_g',
      ],
      calculate: (inputs) => {
        const netcarbG = nutrientScale({
          value: inputs['usda_nutrition.netcarb_g'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const totalSugarsG = nutrientScale({
          value: inputs['usda_nutrition.sugars_g'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const isSimpleCarbs = netcarbG > 10 && totalSugarsG / netcarbG >= 0.5;
        return {
          fieldVisible: !!isSimpleCarbs,
          needsReview: false,
          field: 'cc.cc_simplecarbs',
          value: isSimpleCarbs,
        };
      },
    },
  }),

  d({
    name: 'cc.cc_complexcarbs',
    derivation: {
      dependsOn: [
        'usda_nutrition.netcarb_g',
        'usda_nutrition.sugars_g',
        'food.suggested_serving_amount_g',
        'food.adult_serving_size_g',
      ],
      calculate: (inputs) => {
        const netcarbG = nutrientScale({
          value: inputs['usda_nutrition.netcarb_g'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const totalSugarsG = nutrientScale({
          value: inputs['usda_nutrition.sugars_g'],
          from: NUTRIENT_100G,
          to: inputs['food.suggested_serving_amount_g'] || inputs['food.adult_serving_size_g'] || NUTRIENT_100G,
        }) || 0;
        const isComplexCarbs = netcarbG > 10 && totalSugarsG / netcarbG < 0.5;
        return {
          fieldVisible: !!isComplexCarbs,
          needsReview: false,
          field: 'cc.cc_complexcarbs',
          value: isComplexCarbs,
        };
      },
    },
  }),

  d({
    name: 'cc.cc_supplement',
    derivation: {
      dependsOn: [
        'ontology.levels',
      ],
      calculate: (inputs) => {
        const ontologyLevels = inputs['ontology.levels'] || [];

        const isSupplement = ontologyLevels[0] === 'supplements';

        return {
          fieldVisible: !!isSupplement,
          needsReview: false,
          field: 'cc.cc_supplement',
          value: isSupplement,
        };
      },
    },
  }),
] satisfies FoodDerivationFieldDefinition<keyof FoodDerivationFields>[];

const _DERIVATION_DEFINITION_LOOKUP = FOOD_EDITOR_CC_RANKING_DERIVATION_DEFINITIONS.reduce((acc, def) => {
  acc[def.name] = def;
  return acc;
}, {} as Record<keyof FoodDerivationFields, FoodDerivationFieldDefinition<keyof FoodDerivationFields>>);

const orderFields = (
  fieldDefs: Record<keyof FoodDerivationFields, FoodDerivationFieldDefinition<keyof FoodDerivationFields>>,
): (keyof FoodDerivationFields)[] => {
  // Order derived fields so dependencies are derived prior to their dependents
  const visited = new Set<keyof FoodDerivationFields>();
  const visiting = new Set<keyof FoodDerivationFields>();
  const sortedFields: (keyof FoodDerivationFields)[] = [];
  const fieldDefNames = Object.keys(fieldDefs);

  // Recursive DFS function to visit fields and their dependencies
  const visit = (field: keyof FoodDerivationFields) => {
    if (visited.has(field)) {
      return;
    }
    if (visiting.has(field)) {
      throw new Error(`Circular dependency detected on field: ${field}`);
    }
    visiting.add(field);
    const dependencies = fieldDefs[field].derivation.dependsOn;
    for (const dep of dependencies) {
      // We only need to sort derived fields, any other dependencies can be ignored
      if (fieldDefNames.includes(dep)) {
        visit(dep);
      }
    }
    visiting.delete(field);
    visited.add(field);
    sortedFields.push(field);
  };

  fieldDefNames.forEach((fieldName, index) => {
    if (!visited.has(fieldName as keyof FoodDerivationFields)) {
      visit(fieldName as keyof FoodDerivationFields);
    }
  });

  return sortedFields;
};

const _DERIVED_FIELD_ORDER = orderFields(_DERIVATION_DEFINITION_LOOKUP);

const FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING: { [key: string]: keyof FoodEditorValue } = {
  'cc.cc_calcium': 'highCalcium',
  'cc.cc_high_fiber': 'highFibre',
  'cc.cc_simplecarbs': 'carbohydrateType',
  'cc.cc_complexcarbs': 'carbohydrateType',
  'cc.cc_healthy_fat': 'fatType',
  'cc.cc_unhealthy_fat': 'fatType',
  'cc.cc_both_fat': 'fatType',
  'cc.cc_supplement': 'supplement',
  'cc.cc_beverage': 'beverageType',
  'cc.cc_sugary_beverage': 'beverageType',
  'food.k_level': 'potassiumLevel',
  'ranking.low_k': 'rankingLowK',
  'ranking.high_k': 'rankingHighK',
  'ranking.healthy_fat': 'rankingHealthyFat',
  'ranking.unhealthy_fat': 'rankingUnhealthyFat',
  'ranking.both_fat': 'rankingBothFat',
  'usda_nutrition.calcium_mg': 'calciumMg',
  'usda_nutrition.fiber_g': 'fibreG',
  'usda_nutrition.fat_g': 'fatG',
  'usda_nutrition.fattyacids_totalsaturated_g': 'satFatG',
} as const;

export const AUTOMATED_CC_RANKING_FIELD_NAMES = FOOD_EDITOR_CC_RANKING_DERIVATION_DEFINITIONS.map(def =>
  FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING[def.name]
);

export const foodDetailsToDerivations = (foodDetails: Partial<FoodDetailsResponse>): FoodDerivation[] => {
  const cachedDerivationValues: Partial<FoodDerivationFields> = {};
  const derivations = _DERIVED_FIELD_ORDER.map(fieldName => {
    const def = _DERIVATION_DEFINITION_LOOKUP[fieldName];
    const inputs = def.derivation.dependsOn.reduce<Partial<FoodDerivationFields>>((inputAcc, inputFieldName) => {
      if (!cachedDerivationValues[inputFieldName]) {
        const value = fdrFieldGet(foodDetails, inputFieldName);
        cachedDerivationValues[inputFieldName] = value as any;
      }
      inputAcc[inputFieldName] = cachedDerivationValues[inputFieldName] as any;
      return inputAcc;
    }, {} as FoodDerivationFields);
    const derivation = def.derivation.calculate(inputs as any);
    cachedDerivationValues[def.name] = derivation.value as any;
    return derivation;
  });
  return derivations;
};

export const applyDerivationsToFoodDetails = (
  foodDetails: FoodDetailsResponse,
  derivations: FoodDerivation[],
): FoodDetailsResponse => {
  return derivations.reduce(
    (updatedFoodDetails, derivation) => ({
      ...updatedFoodDetails,
      ...fdrFieldSet(updatedFoodDetails, derivation.field, derivation.value),
    }),
    foodDetails,
  );
};

export const applyDerivationsToFoodEditorValue = (
  foodEditorValue: FoodEditorValue,
  derivations: FoodDerivation[],
): FoodEditorValue => {
  const foodDetails = foodEditorValueToPartialFoodDetails(foodEditorValue);
  const foodDetailsWithDerivations = applyDerivationsToFoodDetails(foodDetails, derivations);
  const foodEditorValueWithPartialDerivations = foodResponseToFoodEditorValue(
    apiFoodResponseToFoodResponse(foodDetailsWithDerivations),
  );
  const partial: Partial<FoodEditorValue> = {};
  derivations.forEach((derivation) => {
    const foodEditorValueFieldName = FOOD_DETAILS_TO_FOOD_EDITOR_VALUE_MAPPING[derivation.field];
    if (['carbohydrateType', 'fatType', 'beverageType'].includes(foodEditorValueFieldName)) {
      (partial as Record<string, unknown>)[foodEditorValueFieldName] =
        foodEditorValueWithPartialDerivations[foodEditorValueFieldName];
    } else {
      (partial as Record<string, unknown>)[foodEditorValueFieldName] =
        typeof foodEditorValue[foodEditorValueFieldName] == 'string' ? derivation.value?.toString() : derivation.value;
    }
  });
  return { ...foodEditorValue, ...partial };
};
