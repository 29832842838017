import { Info } from '@mui/icons-material';
import { Button, FormLabel, Grid, ThemeProvider, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { EDITOR_BUTTON_PROPS, EDITOR_BUTTON_THEME } from './food-editor';

type LowMediumHighValues = '' | 'low' | 'medium' | 'high';

export default function LowMediumHighPicker({
  label,
  onChangeValue,
  value,
  onBlur,
  isAutomated,
}: {
  label: string,
  onChangeValue: (newValue: LowMediumHighValues) => void,
  value: LowMediumHighValues,
  onBlur?: () => void,
  isAutomated?: boolean,
}) {
  const labelId = _.uniqueId();

  const BUTTON_OPTIONS: { value: LowMediumHighValues, label: string }[] = [
    { value: '', label: 'N/A' },
    { value: 'low', label: '3 - Low' },
    { value: 'medium', label: '2 - Medium' },
    { value: 'high', label: '1 - High' },
  ];

  return (
    <>
      <FormLabel id={labelId}>
        {label}
        {isAutomated
          ? (
            <Tooltip title="Automated from nutrients/ontology">
              <Typography display="inline" marginLeft="4px">
                <Info color="inherit" fontSize="small" />
              </Typography>
            </Tooltip>
          )
          : null}
      </FormLabel>
      <ThemeProvider theme={EDITOR_BUTTON_THEME}>
        <Grid container spacing={2}>
          {BUTTON_OPTIONS.map(option => {
            return (
              <Grid item key={option.value} xs={3}>
                <Button
                  variant="contained"
                  onClick={() => onChangeValue(option.value as any)}
                  color={value !== option.value ? 'primary' : 'success'}
                  {...EDITOR_BUTTON_PROPS}
                  disabled={isAutomated}
                >
                  {option.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </>
  );
}
