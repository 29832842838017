import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { foodApi } from 'api';
import { FoodDetailsResponse, FoodResponse } from 'api/generated/MNT';
import { AxiosError, AxiosPromise } from 'axios';
import React, { useMemo } from 'react';
import { useQueryNeverRefetch } from 'utils';

export type UseFoodRes<T> = {
  query: UseQueryResult<T>,
} & T;

export type UseFoodDetailsOpts = {
  refetchOnMount?: boolean,
};

export function _useFoodQuery<T>(
  foodNames: string[] | string | null | undefined,
  opts: UseFoodDetailsOpts,
  getFood: (foodName: string) => AxiosPromise<T>,
  getQueryKey: (foodName: string) => string[],
) {
  const queryFn = async (foodName: string | null) => {
    if (!foodName) {
      return null;
    }

    const res = await (
      getFood(foodName).catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          return null;
        }
        throw err;
      })
    );
    return res?.data ?? null;
  };

  const [_foodNames, isSingular]: [(string | null)[], boolean] = React.useMemo(() => {
    if (!foodNames) {
      return [[null], true];
    }
    if (typeof foodNames === 'string') {
      return [[foodNames], true];
    }
    return [foodNames, false];
  }, [foodNames]);

  const queries = useQueries({
    queries: _foodNames.map((foodName) => ({
      queryKey: getQueryKey(foodName ?? '<null>'),
      queryFn: () => queryFn(foodName),
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
      ...useQueryNeverRefetch,
      refetchOnMount: opts?.refetchOnMount ?? false,
    })),
  });

  return useMemo(() => {
    if (isSingular) {
      return {
        query: queries[0],
        ...(queries[0].data || {}),
      };
    }

    const res = _foodNames
      .map((foodName, i) => {
        if (!foodName) {
          return null;
        }

        const query = queries[i];
        return [
          foodName,
          {
            query,
            ...(query.data || {}),
          },
        ];
      })
      .filter(x => !!x);
    return Object.fromEntries(res as any);
    // queries itself is not stable, we only want to know when the query statuses have changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingular, _foodNames, queries.map(q => q.isSuccess).join(',')]);
}

/**
 * `useFoodDetails(foodName | foodNames[])` is a hook which fetches (with
 * caching) the complete nutritional details for a single food, or multiple foods.
 *
 * `useFoodDetails(null)` will return an empty object.
 *
 * > useFoodDetails(null)
 * { query: { isLoading: false, isDone: true } }
 * > useFoodDetails('apple')
 * { query: { isLoading: ..., ... }, food: { ... }, usda_nutrition: { ... }, ... }
 * > useFoodDetails(['apple', 'banana'])
 * { apple: { query: ..., food: ..., ... }, banana: { query: ..., food: ..., ... } }
 */
export function useFoodDetails(
  foodNames: string | null | undefined,
  opts?: UseFoodDetailsOpts,
): UseFoodRes<FoodDetailsResponse>;
export function useFoodDetails<Name extends string>(
  foodNames: Name[],
  opts?: UseFoodDetailsOpts,
): {
  [key in Name]: UseFoodRes<FoodDetailsResponse>;
};
export function useFoodDetails(
  foodNames: string[] | string | null | undefined,
  opts?: UseFoodDetailsOpts,
) {
  return _useFoodQuery(
    foodNames,
    opts ?? {},
    (foodName) =>
      foodApi.appApiFoodFoodDetailsGetFoodDetailsQuery({
        food_name: foodName,
      }),
    getFoodDetailsQueryKey,
  );
}

export function getFoodDetailsQueryKey(foodName: string) {
  return ['food', foodName, 'details'];
}

/**
 * `useFoodResponse(foodName | foodNames[])` is a hook which fetches (with
 * caching) the FoodREsponse single food, or multiple foods.
 *
 * `useFoodResponse(null)` will return an empty object.
 *
 * > useFoodResponse(null)
 * { query: { isLoading: false, isDone: true } }
 * > useFoodResponse('apple')
 * { query: { isLoading: ..., ... }, name: 'apple', ... }
 * > useFoodResponses(['apple', 'banana'])
 * { apple: { query: ..., name: 'apple', ... }, banana: { query: ..., name: 'banana', ... } }
 */
export function useFoodResponse(
  foodNames: string | null | undefined,
  opts?: UseFoodDetailsOpts,
): UseFoodRes<FoodResponse>;
export function useFoodResponse(
  foodNames: string[] | string | null | undefined,
  opts?: UseFoodDetailsOpts,
) {
  return _useFoodQuery(
    foodNames,
    opts ?? {},
    (foodName) =>
      foodApi.appApiFoodFoodSearchGetFoodQuery({
        food_name: foodName,
      }),
    (foodName) => ['food', foodName, 'response'],
  );
}

export function useFoodResponses<Name extends string>(
  foodNames: Name[],
  opts?: UseFoodDetailsOpts,
): {
  [key in Name]: UseFoodRes<FoodResponse>;
};
export function useFoodResponses(
  foodNames: string[] | string | null | undefined,
  opts?: UseFoodDetailsOpts,
) {
  return _useFoodQuery(
    foodNames,
    opts ?? {},
    (foodName) =>
      foodApi.appApiFoodFoodSearchGetFoodQuery({
        food_name: foodName,
      }),
    (foodName) => ['food', foodName, 'response'],
  );
}
