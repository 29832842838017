import * as querystring from 'query-string';

import { apiConfig, dataReviewApi, oauthApi } from 'api';
import {
  Configuration,
  DataReviewerResponse,
  type DataReviewerResponseReviewGroupsInner,
  OauthApi,
  OAuthToken,
} from 'api/generated/MNT';
import { config } from 'config';

export type ReviewerAuthInfo = Exclude<DataReviewerResponse, 'id'> & {
  access_token: string,
  reviewer_id: number,
};

const _handleAuthReq = async (url: string, data: FormData): Promise<ReviewerAuthInfo> => {
  const response = await fetch(url, {
    method: 'POST',
    body: data,
  });

  if (response.status === 401) {
    throw new Error('Wrong email or password');
  } else if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const text = await response.text();
  const queryParams = querystring.parse(text.substr(1));
  const { access_token } = queryParams;
  if (!access_token || Array.isArray(access_token)) {
    throw new Error(`auth_failed`);
  }

  const res = await dataReviewApi.appApiDataReviewerGetCurrentDataReviewer();
  const reviewerInfo = res.data;

  return {
    access_token: access_token,
    reviewer_id: reviewerInfo.id,
    ...res.data,
    review_groups: reviewerInfo.review_groups?.filter((group: any) => group.is_active) || [],
  };
};

export const authenticateToken = async (token: string): Promise<ReviewerAuthInfo> => {
  const authUrl = `${config.API_URL}/api/oauth/authorize_auditor_token`;

  const formData = new FormData();
  formData.append('token', token);
  formData.append('client_id', '2');
  formData.append('redirect_uri', '/');
  formData.append('response_type', 'token');

  return _handleAuthReq(authUrl, formData);
};

export const authenticate = async (
  email: string,
  password: string,
  oauth: OauthApi = oauthApi,
  apiConf: Configuration = apiConfig,
): Promise<ReviewerAuthInfo> => {
  const authResponse = await oauth.appApiOauthAuthorizeSwagger({
    client_id: '2',
    username: email,
    password: password,
    redirect_uri: '/',
    response_type: 'token',
    user_scope: 'reviewer',
  });

  const parsed = new URLSearchParams(authResponse.data);
  const token = Object.fromEntries(parsed.entries()) as any as OAuthToken;
  apiConf.accessToken = token.access_token;

  const currentUser = await dataReviewApi.appApiDataReviewerGetCurrentDataReviewer();
  const reviewGroups = currentUser.data.review_groups || [];

  return {
    access_token: token.access_token,
    reviewer_id: currentUser.data.id,
    ...currentUser.data,
    review_groups: reviewGroups.filter((group) => group.is_active),
  };
};
