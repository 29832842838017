import {
  ChatBubble,
  Check,
  Close,
  Delete,
  Help,
  KeyOff,
  MoreHoriz,
  PriorityHigh,
  QuestionMark,
  Send,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  DialogActions,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MealQueueItem } from 'apiClients/mpq';
import { useAuthenticatedBlobQuery } from 'components/AuthenticatedImage';
import { HumanTime } from 'food-editor/components/HumanTime';
import { useEffect, useRef, useState } from 'react';
import ReactCrop, { type Crop } from 'react-image-crop';
import './MealPushQuestions.css';
import {
  MealPushQuestionFocusRegionImage,
  MealPushQuestionFocusRegionPatientNote,
  MealPushQuestionResponse,
  MealPushQuestionStatusEnum,
  MealPushQuestionTypeEnum,
  UpdateMealPushQuestionRequest,
} from 'api/generated/MNT';
import { assertUnreachable } from 'food-editor/utils/utils';
import Draggable from 'react-draggable';
import { DraftItem } from 'types/DraftItem';
import { usePushQuestions, useQueueItemEditor } from '../services/QueueItemEditorService';
import { getMealPhotoUrl } from './MealPhoto';

export const getMealPushQuestionColor = (question: MealPushQuestionResponse | UpdateMealPushQuestionRequest | null) => {
  switch (question?.question_status) {
    case 'pending:patient':
      return 'gold';
    case 'pending:system':
      return 'red';
    case 'resolved':
      return 'green';
    default:
      return '#aaa';
  }
};

function isSavedPushQuestion(
  question: MealPushQuestionResponse | UpdateMealPushQuestionRequest | null,
): question is MealPushQuestionResponse {
  return (question as any)?.id != null;
}

export const MealPushQuestions = (props: {
  queueItem: MealQueueItem,
  draftItem: DraftItem,
  size?: 'small' | 'medium' | 'large',
}) => {
  const { queueItem, draftItem } = props;
  const { updateDraftItem } = useQueueItemEditor();
  const mealText = queueItem?.patient_note || queueItem?.meal_search_text || null;
  const img = useAuthenticatedBlobQuery(getMealPhotoUrl(queueItem, 'original'));
  const pushQuestionService = usePushQuestions({
    patientId: queueItem.patient_id,
    mealId: queueItem.created_meal_id,
    itemId: draftItem.item?.id,
  });
  const pqMaybeDel = draftItem.pushQuestionUpdate ?? pushQuestionService.itemQuestion;
  const pushQuestion = pqMaybeDel?.question_status == MealPushQuestionStatusEnum.Deleted
    ? null
    : pqMaybeDel;

  const [pushQuestionPopperOpen, setPushQuestionPopperOpen] = useState(false);
  const questionStatus = pushQuestion?.question_status || null;
  const [imgCrop, setImgCrop] = useState<Crop>();
  const [textCrop, setTextCrop] = useState<{ startIdx: number, endIdx: number, textSpan: string }>({
    startIdx: 0,
    endIdx: 0,
    textSpan: '',
  });
  const nodeRef = useRef(null);
  const defaultPushQuestionType = pushQuestion?.question_type ?? draftItem.item.food_name === 'unknown food'
    ? MealPushQuestionTypeEnum.ClarifyNft
    : MealPushQuestionTypeEnum.DescribeItem;
  const [pushQuestionType, setPushQuestionType] = useState(defaultPushQuestionType);

  useEffect(() => {
    if (pushQuestion?.question_focus_region?.type == 'image') {
      setImgCrop({
        unit: '%',
        x: pushQuestion.question_focus_region.region?.xPct || 0,
        y: pushQuestion.question_focus_region.region?.yPct || 0,
        width: pushQuestion.question_focus_region.region?.widthPct || 0,
        height: pushQuestion.question_focus_region.region?.heightPct || 0,
      });
    } else if (pushQuestion?.question_focus_region?.type == 'patient-note') {
      setTextCrop({
        startIdx: +(pushQuestion.question_focus_region.region?.startIdx || 0),
        endIdx: +(pushQuestion.question_focus_region.region?.endIdx || 0),
        textSpan: pushQuestion.question_focus_region.region?.textSpan || '',
      });
    }
  }, [pushQuestion, draftItem]);

  const handlePushQuestionAction = (action: 'request' | 'discard' | 'resolve') => {
    const focusRegion: MealPushQuestionFocusRegionImage | MealPushQuestionFocusRegionPatientNote = imgCrop
      ? {
        type: 'image',
        region: {
          imageId: queueItem.meal_photo_id + '',
          xPct: imgCrop.x,
          yPct: imgCrop.y,
          widthPct: imgCrop.width,
          heightPct: imgCrop.height,
        },
      }
      : {
        type: 'patient-note',
        region: {
          startIdx: textCrop.startIdx,
          endIdx: textCrop.endIdx,
          textSpan: textCrop.textSpan,
          fullText: mealText || '',
        },
      };

    switch (action) {
      case 'request':
        updateDraftItem(draftItem, {
          pushQuestionUpdate: {
            meal_item_id: draftItem.item?.id,
            question_type: pushQuestionType,
            question_status: MealPushQuestionStatusEnum.Pendingpatient,
            question_focus_region: focusRegion,
          },
        });
        setPushQuestionPopperOpen(false);
        break;
      case 'discard':
        if (!pushQuestion) {
          return;
        }
        if (!confirm('Are you sure you want to discard this push question?')) {
          return;
        }
        updateDraftItem(draftItem, {
          pushQuestionUpdate: {
            ...pushQuestion,
            question_status: MealPushQuestionStatusEnum.Deleted,
          },
        });
        setPushQuestionPopperOpen(false);
        break;
      case 'resolve':
        if (!pushQuestion) {
          return;
        }
        updateDraftItem(draftItem, {
          pushQuestionUpdate: {
            ...pushQuestion,
            question_status: MealPushQuestionStatusEnum.Resolved,
          },
        });
        break;
      default:
        assertUnreachable(action);
    }
  };

  const handleTextSelect = () => {
    const selection = window.getSelection();
    const start = selection?.anchorOffset || 0;
    const end = selection?.focusOffset || 0;
    if (start >= 0 && end >= 0) {
      setTextCrop(
        start < end
          ? { startIdx: start, endIdx: end, textSpan: selection?.toString() || '' }
          : start != end
          ? { startIdx: end, endIdx: start, textSpan: selection?.toString() || '' }
          : { startIdx: 0, endIdx: 0, textSpan: '' },
      );
    }
  };

  const getHighlightText = () => {
    const before = mealText?.substring(0, textCrop.startIdx);
    const highlight = textCrop.textSpan;
    const after = mealText?.substring(textCrop.endIdx);

    return (
      <Paper
        sx={{
          maxWidth: '90%',
          py: 5,
          width: '90%',
          textAlign: 'center',
        }}
      >
        <span>
          <strong>
            {mealText
              ? (
                <span>
                  {before}
                  <span style={{ backgroundColor: 'gold' }}>{highlight}</span>
                  {after}
                </span>
              )
              : <span>No meal photo or text</span>}
          </strong>
        </span>
      </Paper>
    );
  };

  const getIcon = () => {
    const color = getMealPushQuestionColor(pushQuestion);

    const iconSx = { fontSize: 15, color: 'white' } as const;

    const icon = questionStatus == MealPushQuestionStatusEnum.Pendingpatient
      ? <MoreHoriz sx={iconSx} />
      : questionStatus == MealPushQuestionStatusEnum.Pendingsystem
      ? <PriorityHigh sx={iconSx} />
      : questionStatus == MealPushQuestionStatusEnum.Resolved
      ? <Check sx={iconSx} />
      : <QuestionMark sx={iconSx} />;

    return (
      <div style={{ position: 'relative' }}>
        <ChatBubble sx={{ fontSize: 30, color }} />
        <span style={{ position: 'absolute', top: 0, left: 8, height: 25, alignItems: 'center', display: 'flex' }}>
          {icon}
        </span>
      </div>
    );
  };

  const getCardContent = () => {
    const patientResponse = pushQuestion?.question_response?.items?.map(item => item.value as string) || [];

    const getQuestionText = () => {
      switch (pushQuestionType) {
        case MealPushQuestionTypeEnum.DescribeItem:
          return 'Please provide a short description of this item to improve the accuracy of the nutrition analysis';
        case MealPushQuestionTypeEnum.ClarifyNft:
          return `This looks like a nutrition facts table; Please provide the product's name`;
      }
    };

    return (
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography>
              <strong>Question</strong>
              <br /> {getQuestionText()}
              <br />
              <strong>Answer</strong>
              <br />
              {questionStatus == 'pending:patient'
                ? (
                  <span>
                    This push question is pending a patient response
                  </span>
                )
                : (
                  <span>
                    {patientResponse.map((res, idx) => <span key={idx}>{res}</span>)}
                  </span>
                )}
            </Typography>
          </Grid>
          {isSavedPushQuestion(pushQuestion) && (
            <Grid item xs={4}>
              <Typography>
                <strong>Asked by</strong> User {'' + pushQuestion?.created_by_user_id}
                <br />
                <HumanTime value={pushQuestion?.created_time} />
              </Typography>
              {(questionStatus == 'pending:system' || questionStatus == 'resolved') && (
                <Typography>
                  <strong>Answered</strong>
                  <br />
                  {pushQuestion?.question_response_time
                    ? <HumanTime value={pushQuestion?.question_response_time} />
                    : 'No response time'}
                </Typography>
              )}
              {questionStatus == 'resolved' && (
                <Typography>
                  <strong>Resolved by</strong> User {'' + pushQuestion?.resolved_by_user_id}
                  <br />
                  {pushQuestion?.question_resolved_time
                    ? <HumanTime value={pushQuestion?.question_resolved_time} />
                    : 'No resolved time'}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </CardContent>
    );
  };

  const getCardMediaOrText = () => {
    if (!img.isSuccess) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(questionStatus != null && questionStatus != 'deleted') ? getHighlightText() : (
            <Paper
              onMouseUp={handleTextSelect}
              sx={{
                maxWidth: '90%',
                py: 5,
                width: '90%',
                textAlign: 'center',
              }}
            >
              <span>
                <strong>
                  {mealText || 'No meal photo or text'}
                </strong>
              </span>
            </Paper>
          )}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ReactCrop
          disabled={questionStatus != null && questionStatus != 'deleted'}
          crop={imgCrop}
          onChange={(_, c) => setImgCrop(c)}
        >
          <CardMedia
            component="img"
            height="300"
            width="500"
            src={img.src || ''}
            title="Meal Photo"
          />
        </ReactCrop>
      </div>
    );
  };

  const getCard = () => {
    const closeButton = (
      <Button
        onClick={() => {
          setPushQuestionPopperOpen(false);
        }}
        startIcon={<Close />}
        color="secondary"
        sx={{ marginLeft: 'auto' }}
      >
        Close
      </Button>
    );

    const handleQuestionTypeChange = (event: SelectChangeEvent) => {
      switch (event.target.value) {
        case MealPushQuestionTypeEnum.DescribeItem:
          setPushQuestionType(MealPushQuestionTypeEnum.DescribeItem);
          break;
        case MealPushQuestionTypeEnum.ClarifyNft:
          setPushQuestionType(MealPushQuestionTypeEnum.ClarifyNft);
          break;
      }
    };

    switch (questionStatus) {
      case 'pending:patient':
        return (
          <>
            <CardHeader
              className="dragHandle"
              title="Pending Push Question"
              action={
                <IconButton
                  onClick={() => {
                    setPushQuestionPopperOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              }
            />
            {getCardMediaOrText()}
            {getCardContent()}
            <CardActions>
              {closeButton}
              <Button
                onClick={() => {
                  handlePushQuestionAction('discard');
                }}
                startIcon={<Delete />}
                color="error"
              >
                Discard
              </Button>
            </CardActions>
          </>
        );
      case 'pending:system':
        return (
          <>
            <CardHeader
              className="dragHandle"
              title="Resolve Push Question"
              action={
                <IconButton
                  onClick={() => {
                    setPushQuestionPopperOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              }
            />
            {getCardMediaOrText()}
            {getCardContent()}
            <CardActions>
              {closeButton}
              <Button
                onClick={() => {
                  handlePushQuestionAction('discard');
                }}
                startIcon={<Delete />}
                color="error"
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  handlePushQuestionAction('resolve');
                }}
                startIcon={<Check />}
                color="success"
              >
                Resolve
              </Button>
            </CardActions>
          </>
        );
      case 'resolved':
        return (
          <>
            <CardHeader
              className="dragHandle"
              title="Push Question"
              action={
                <IconButton
                  onClick={() => {
                    setPushQuestionPopperOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              }
            />
            {getCardMediaOrText()}
            {getCardContent()}
            <CardActions>
              {closeButton}
            </CardActions>
          </>
        );
      default:
        return (
          <>
            <CardHeader
              className="dragHandle"
              title="Push Question"
              action={
                <IconButton
                  onClick={() => {
                    setPushQuestionPopperOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              }
            />
            {getCardMediaOrText()}
            {!pushQuestion
              ? (
                <CardActions style={{ minHeight: '70px' }}>
                  <FormControl style={{ width: '30%' }}>
                    <InputLabel>Question Type</InputLabel>
                    <Select label="Question Type" onChange={handleQuestionTypeChange} value={pushQuestionType}>
                      <MenuItem value={MealPushQuestionTypeEnum.DescribeItem}>Describe Item</MenuItem>
                      <MenuItem value={MealPushQuestionTypeEnum.ClarifyNft}>Clarify NFT</MenuItem>
                    </Select>
                  </FormControl>
                  {closeButton}
                  <Button
                    onClick={() => {
                      if (imgCrop || textCrop.textSpan) {
                        handlePushQuestionAction('request');
                      } else {
                        alert('You must select a portion of the image/text you want clarification on first!');
                      }
                    }}
                    startIcon={<Help />}
                  >
                    Request Clarification
                  </Button>
                </CardActions>
              )
              : (
                <>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography>
                      <strong>Request will be sent on queue submisson</strong>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {closeButton}
                    <Button
                      onClick={() => {
                        if (!draftItem.id) {
                          handlePushQuestionAction('discard');
                        }
                      }}
                      color="error"
                      startIcon={<Delete />}
                    >
                      Discard
                    </Button>
                  </CardActions>
                </>
              )}
          </>
        );
    }
  };

  return (
    <>
      <Tooltip
        title={'Push Question: ' + (questionStatus ? questionStatus : 'unsent')}
        PopperProps={{ sx: { textTransform: 'capitalize' } }}
      >
        <IconButton
          onClick={() => {
            setPushQuestionPopperOpen(!pushQuestionPopperOpen);
          }}
          sx={{ p: 1, height: 24 }}
        >
          {getIcon()}
        </IconButton>
      </Tooltip>
      {pushQuestionPopperOpen && (
        <Draggable handle=".dragHandle" nodeRef={nodeRef}>
          <Fade in={pushQuestionPopperOpen} timeout={350}>
            <Paper ref={nodeRef} sx={{ zIndex: 1201, position: 'fixed', bottom: 30, right: 30 }}>
              <Card
                sx={{ width: 500, maxWidth: 500 }}
              >
                {getCard()}
              </Card>
            </Paper>
          </Fade>
        </Draggable>
      )}
    </>
  );
};
