import { Menu as RadialMenu } from '@spaceymonk/react-radial-menu';
import React, { useEffect, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { create } from 'zustand';

export {
  Menu as RadialMenu,
  MenuItem as RadialMenuItem,
  SubMenu as RadialSubMenu,
} from '@spaceymonk/react-radial-menu';

export type RadialMenuProps = Parameters<typeof RadialMenu>[0];

const useRxRadialMenuStore = create(() => ({
  onClose: null as (() => void) | null,
  props: null as RadialMenuProps | null,
}));

const doCloseMenu = () => {
  const store = useRxRadialMenuStore.getState();
  store.onClose?.();
  useRxRadialMenuStore.setState({
    onClose: null,
    props: null,
  });
};

export const RxRadialMenuRootView = () => {
  const store = useRxRadialMenuStore();

  useEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      doCloseMenu();
    }
  });

  if (!store.props) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 9999,
      }}
      onClick={() => {
        doCloseMenu();
      }}
    >
      <RadialMenu {...store.props} />
    </div>
  );
};

export const useRxRadialMenu = (opts: {
  onClose?: () => void,
  props: Omit<RadialMenuProps, 'centerX' | 'centerY' | 'show'>,
}) => {
  const [showEvent, setShowEvent] = useState<React.MouseEvent | null>(null);
  const store = useRxRadialMenuStore();
  useEffect(() => {
    if (!showEvent) {
      return;
    }
    const children = opts.props.children as any;
    if (typeof children?.length != 'number' || children.length < 2) {
      alert('Programming error: RadialMenu must have at least 2 children; got: ' + children?.length);
      console.error('Programming error: RadialMenu must have at least 2 children; got:', children);
      return;
    }
    // Convert the event's client x/y to document x/y
    const doc = document.documentElement;
    const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    useRxRadialMenuStore.setState({
      onClose: () => {
        opts.onClose?.();
        setShowEvent(null);
      },

      props: {
        ...opts.props,
        children: children.concat().reverse(),
        centerX: showEvent.clientX + left,
        centerY: showEvent.clientY + top,
        show: true,
      },
    });
    // Note: this is a hack, because if we add "props" to the dependency list, it
    // will cause an infinite loop. Ideally this will be handled ... better ... in
    // the future, but for now it's Good Enough.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEvent]);

  return {
    isOpen: !!store,
    show: (event: React.MouseEvent) => {
      setShowEvent(event);
    },
    close: () => {
      doCloseMenu();
    },
  };
};
