import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { dataReviewApi } from 'api';
import { MealPhotoQueueResponse } from 'api/generated/MNT';
import { useAuth } from 'context/appContext';
import { useInterval } from 'hooks/useInterval';
import mixpanel from 'mixpanel-browser';
import { useSocketIOClient } from 'socketio/SocketIOService';
import { useDocumentVisibility } from 'utils';
import { mkRandId } from 'utils/telemetry';
import { create } from 'zustand';

const filterRecentlyLabelled = (
  recentlyLabelledQueueIDs: number[],
  queues: MealPhotoQueueResponse[],
) => {
  return queues.filter(q => !recentlyLabelledQueueIDs.includes(q.id));
};

const globalRefetchThrottle = {
  call: (func: () => void) => {
    globalRefetchThrottle._lastFunc = func;
    globalRefetchThrottle._run();
  },
  _lastFunc: null as (() => void) | null,
  _run: _.throttle(() => {
    globalRefetchThrottle._lastFunc?.();
  }, 500),
};

let _lastRefetchReason: string | null = null;
const useLastRefetchedAtStore = create(() => ({
  lastRefetchedAt: null as number | null,
}));

const useAvailableMqpsQuery = () => {
  const { authInfo } = useAuth();

  const { lastRefetchedAt } = useLastRefetchedAtStore();
  const isDocumentVisible = useDocumentVisibility();
  const query = useQuery(['available-mpqs', authInfo?.reviewer_id], async () => {
    useLastRefetchedAtStore.setState({ lastRefetchedAt: Date.now() });
    if (!authInfo) {
      return {
        queues: [],
        ownedQueueCount: 0,
        ownedQueues: [],
        availableQueueCount: 0,
      };
    }

    const reason = _lastRefetchReason;
    const startTime = Date.now();
    const res = await dataReviewApi.appApiDataReviewerGetDataReviewerMealPhotoQueuesToBeProcessed({
      data_reviewer_id: authInfo.reviewer_id,
    }, {
      params: {
        'refetch-reason': reason,
      },
    });
    const activeMpqs = res.data;
    const ownedQueues = activeMpqs.filter(i => i.first_reviewer_user_id === authInfo.reviewer_id);
    const availableQueues = activeMpqs.filter(i => !i.first_reviewer_user_id);

    // console.log('Active MPQ refetch:', {
    //   Reason: reason,
    //   'Duration (s)': (Date.now() - startTime) / 1000,
    //   Count: activeMpqs.length,
    //   OwnedCount: ownedQueues.length,
    //   AvailableCount: availableQueues.length,
    // });

    mixpanel.track('Active MPQ Refetch', {
      Count: activeMpqs.length,
      OwnedCount: ownedQueues.length,
      AvailableCount: availableQueues.length,
      'Duration (s)': (Date.now() - startTime) / 1000,
      Reason: reason,
    });

    return {
      queues: activeMpqs || [],
      ownedQueueCount: ownedQueues.length,
      ownedQueues: ownedQueues,
      availableQueueCount: availableQueues.length,
    };
  }, {
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    query,
    lastRefetchedAt,
    refetch: React.useCallback((reason: string) => {
      // If tab is not active and it's been less than 15s since the last refetch, skip refetch.
      const lastRefetch = localStorage.getItem('mpq-refetch:last-refetch');
      const lastRefetchTimestamp = lastRefetch ? +lastRefetch : null;
      const refetchThrottle = lastRefetchTimestamp && Date.now() - lastRefetchTimestamp < 15 * 1000;
      if (refetchThrottle && (!isDocumentVisible || reason == 'initial')) {
        return;
      }
      localStorage.setItem('mpq-refetch:last-refetch', Date.now().toString());
      globalRefetchThrottle.call(() => {
        _lastRefetchReason = reason;
        query.refetch();
      });
    }, [query, isDocumentVisible]),
  };
};

export const useActiveQueuePeriodRefetch = () => {
  const io = useSocketIOClient();
  const { refetch } = useAvailableMqpsQuery();
  const refetchInterval = io?.connected ? 16 * 1000 : 6 * 1000;
  useInterval(() => {
    refetch('interval:' + String(refetchInterval / 1000));
  }, refetchInterval);
};

export const useMealQueueService = () => {
  const { authInfo } = useAuth();
  const routerLocation = useLocation();
  const [recentlyLabelledQueueIDs, setRecentlyLabelledQueueIDs] = useState<number[]>([]);

  const { query, refetch, lastRefetchedAt } = useAvailableMqpsQuery();
  useEffect(() => {
    refetch('initial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIsCurrentlyLabellingOwnQueue = React.useCallback((location: { pathname: string }) => {
    return !!query.data?.queues.some(q => (
      q.first_reviewer_user_id == authInfo?.reviewer_id
      && (location.pathname == `/queue-item/${q.id}`)
    ));
  }, [query, authInfo]);

  return React.useMemo(() => ({
    query,
    refetch,
    lastRefetchedAt,
    allActiveQueues: filterRecentlyLabelled(recentlyLabelledQueueIDs, query.data?.queues || []),
    ownedCount: query.data?.ownedQueueCount || 0,
    ownedQueues: filterRecentlyLabelled(recentlyLabelledQueueIDs, query.data?.ownedQueues || []),
    availableCount: query.data?.availableQueueCount || 0,
    currentlyLabellingOwnQueue: getIsCurrentlyLabellingOwnQueue(routerLocation),
    addRecentlyLabelledQueueId: (queueId: number) => {
      setRecentlyLabelledQueueIDs([queueId, ...recentlyLabelledQueueIDs].slice(0, 5));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [query, getIsCurrentlyLabellingOwnQueue, routerLocation, recentlyLabelledQueueIDs]);
};
