/**
 * Returns the first non-null, non-undefined, non-NaN argument, or NaN if all
 * arguments are null, undefined, or NaN::
 *
 *   > coalesceNaN(1, 2, 3)
 *   1
 *   > coalesceNaN(null, 2, 3)
 *   2
 *   > coalesceNaN(undefined, 2, 3)
 *   2
 *   > coalesceNaN(NaN, 2, 3)
 *   2
 */
export const coalesceNaN = (...args: (number | null | undefined)[]): number => {
  for (const arg of args) {
    if (arg !== null && arg !== undefined && !Number.isNaN(arg)) {
      return arg;
    }
  }
  return NaN;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * Rounds a number to a specified number of decimal places.
 *
 * >>> round(1.2345, 2)
 * 1.23
 */
export function round(n: number, nDecimals: number) {
  const exp = 10 ** (nDecimals || 0);
  return Math.round(n * exp) / exp;
}

/**
 * Round a number to n decimal places, ±1e-n
 *
 * >>> roundPretty(1.023, 2)
 * 1.23
 * >>> roundPretty(1.012, 2)
 * 1.00
 * >>> roundPretty(0.99, 2)
 * 1.00
 */
export function roundPretty(n: number, nDecimals: number) {
  if (!nDecimals) {
    return Math.round(n);
  }
  for (let i = nDecimals; i > 0; i--) {
    const cur = round(n, i);
    const next = round(n, i - 1);
    const delta = 1.1 * 1 / 10 ** i;
    const diff = Math.abs(cur - next);
    console.log({ cur, next, delta, diff });
    if (!diff || diff >= delta) {
      return cur;
    }
  }
  return round(n, 0);
}

/**
 * Divides two numbers, returning null if either number is null.
 *
 * >>> safeDivNull(1, 2)
 * 0.5
 * >>> safeDivNull(null, 2)
 * null
 * >>> safeDivNull(1, null)
 * null
 */
export const safeDivNull = (a: number | null, b: number | null) => {
  if (a == null || b == null) {
    return null;
  }
  return a / b;
};
