import { useQuery } from '@tanstack/react-query';
import { imageDetectionApi } from 'api';
import {
  ImageDetectionResultResponse,
  ImageDetectionTypeEnum,
  MealPhotoQueueResponse,
  MPQImLblMatchResult,
  MPQMetadataPrelblAvailabilityEnum,
} from 'api/generated/MNT';
import { config } from 'config';
import { useFeatures } from 'context/FeatureContext';
import React from 'react';

const mpqImLblIsAvailable = (
  flags: ReturnType<typeof useFeatures>,
  queueItem: MealPhotoQueueResponse | null,
) => {
  return (
    !!queueItem?.id
    && (queueItem.queue_type == 'photo_ffe_queue' || queueItem.queue_type == 'nlp_queue')
    && queueItem?.queue_metadata?.prelbl_availability == MPQMetadataPrelblAvailabilityEnum.Available
  );
};

export const mpqImLblShouldPrefillMeal = (
  flags: ReturnType<typeof useFeatures>,
  queueItem: MealPhotoQueueResponse | null,
) => {
  return (
    queueItem
    && mpqImLblIsAvailable(flags, queueItem)
    && !queueItem.is_processed
  );
};

export type ImageDetectionImLblResultResponse = Awaited<ReturnType<typeof mpqLoadImLblDetectResult>>;

export const mpqLoadImLblDetectResult = async (queueItem: MealPhotoQueueResponse) => {
  const res = await imageDetectionApi.appApiImageDetectionGetImageDetectionResult({
    detect_type: ImageDetectionTypeEnum.MpqImLblMatch,
    image_source: 'meal_photo_queue',
    image_id: '' + queueItem.id,
    cache: 'check',
  });
  return {
    ...res.data,
    result: (res.data.result || []) as MPQImLblMatchResult[],
  };
};

export const mpqImLblQueryKey = (queueItem: MealPhotoQueueResponse | null) => {
  return ['mpq-im-lbl', queueItem?.id];
};

export const mpqImLblDetectResultToDebugContext = (
  detectResult: ImageDetectionImLblResultResponse | null | undefined,
) => {
  return detectResult?.id
    ? {
      image_detection_result_id: detectResult.id,
    }
    : {};
};

export const useMpqImLblService = (opts: {
  queueItem: MealPhotoQueueResponse | null,
}) => {
  const { queueItem } = opts;
  const flags = useFeatures();
  const [removedMatches, setRemovedMatches] = React.useState<MPQImLblMatchResult[]>([]);

  // Note: prelabelling will be enabled for odd-numbered queues as a holdout
  // test. If this goes well, we can enable it for all queues.
  const isAvailable = mpqImLblIsAvailable(flags, queueItem);

  const query = useQuery(mpqImLblQueryKey(queueItem), async () => {
    if (!queueItem?.id) {
      return null;
    }
    const result = await mpqLoadImLblDetectResult(queueItem);
    setRemovedMatches([]);
    return result;
  }, {
    enabled: isAvailable,
  });

  const data = React.useMemo(() => {
    return query.data?.result.filter((match) => !removedMatches.includes(match)) ?? [];
  }, [query.data, removedMatches]);

  return {
    isAvailable,
    prefillMeal: mpqImLblShouldPrefillMeal(flags, queueItem),
    query,
    debugContext: mpqImLblDetectResultToDebugContext(query.data),
    removeMatch: (match: MPQImLblMatchResult) => {
      setRemovedMatches([...removedMatches, match]);
    },
    data,
  };
};
