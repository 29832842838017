import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { config } from 'config';
import React from 'react';

// Note: there have been some crashes coming from the internals of the sentry
// replay module, and we haven't used the replays, so disable them for now.
export const sentryReplay: any = false && new Sentry.Replay();

/**
 * Triggers a Sentry Replay recording session while the component is mounted.
 */
export const useSentryReplay = () => {
  React.useEffect(() => {
    if (!sentryReplay) {
      return;
    }
    sentryReplay.start();
    return () => {
      sentryReplay.stop();
    };
  }, []);
};

export const initAnalytics = () => {
  !config.IS_LOCAL && Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.IS_PREVIEW ? 'preview' : config.ENV,
    release: config.COMMIT_SHA,

    ignoreErrors: [
      // Outlook's Safe Link checker throws these errors:
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'Non-Error promise rejection captured with value: Object Not Found Matching',
    ],

    integrations: [
      new BrowserTracing(),
      sentryReplay,
    ].filter(Boolean),

    tracesSampleRate: 0.01,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.0,
  });
};
