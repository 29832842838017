/* eslint-disable import/no-anonymous-default-export */
import { client, dataManagementApi, foodApi } from 'api';
import {
  CcColumnsRequest,
  FoodDetailsResponse,
  FoodDetailsUpdateRequestTranslationsInner,
  FoodOntologyVTO,
} from 'api/generated/MNT';
import { FoodDetailsUpdateRequest } from 'api/generated/MNT';
import _ from 'lodash';
import { FOOD_EDITOR_NUTRIENT_FIELDS, FoodEditorNutrientFields } from './food-editor-nutrient-fields';
import {
  FoodEditorFoodRequest,
  FoodEditorFoodResponse,
  FoodEditorValue,
  FoodSearchResponse,
  ProteinTypeName,
} from './types';

const numberOrNull = (x: number | null | undefined) => {
  if (x === null || x === undefined) {
    return null;
  }
  return x;
};

/**
 * Removes all null and undefined values from `obj`.
 */
const objRemoveNulls = <T extends object>(obj: T): Partial<T> => {
  const result: any = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val !== null && val !== undefined) {
      result[key] = val;
    }
  });
  return result;
};

const ccToProteinMap: { [K in keyof CcColumnsRequest]?: ProteinTypeName } = {
  cc_redmeat: 'red_meat',
  cc_poultry: 'poultry',
  cc_pork: 'pork',
  cc_non_meat_animal_protein: 'non_meat_animal',
  cc_fish: 'fish',
  cc_shellfish: 'shellfish',
  cc_legume: 'legume',
  cc_plant_protein: 'plant',
  cc_meat: 'meat',
};

type FoodEditorCCFieldDef = {
  ccCols: string[],
  toFoodEditor: (apiVal: CcColumnsRequest) => any,
  toApiRequest: (editorVal: Partial<FoodEditorValue>) => Partial<CcColumnsRequest>,
};

export const ccFoodEditorValues = {
  fruitAndVegetableType: {
    ccCols: ['cc_fruit', 'cc_vegetable'],
    toFoodEditor: (apiVal: Record<string, any>) => {
      if (_.difference(['cc_fruit', 'cc_vegetable'], Object.keys(apiVal)).length > 0) {
        return null;
      } else if (apiVal.cc_fruit) {
        return apiVal.cc_vegetable ? 'both' : 'fruit';
      } else if (apiVal.cc_vegetable) {
        return 'vegetable';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_fruit: ['both', 'fruit'].includes(editorVal.fruitAndVegetableType || ''),
        cc_vegetable: ['both', 'vegetable'].includes(editorVal.fruitAndVegetableType || ''),
      };
    },
  },
  proteinType: {
    ccCols: [
      'cc_protein',
      'cc_redmeat',
      'cc_poultry',
      'cc_pork',
      'cc_non_meat_animal_protein',
      'cc_fish',
      'cc_shellfish',
      'cc_legume',
      'cc_plant_protein',
      'cc_meat',
    ],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return Object.entries(ccToProteinMap)
        .filter(([attr, _]) => !!(apiVal as any)[attr])
        .map(([_, proteinType]) => proteinType as ProteinTypeName);
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_protein: (editorVal.proteinType?.length || 0) > 0,
        cc_redmeat: editorVal.proteinType?.includes('red_meat'),
        cc_poultry: editorVal.proteinType?.includes('poultry'),
        cc_pork: editorVal.proteinType?.includes('pork'),
        cc_non_meat_animal_protein: editorVal.proteinType?.includes('non_meat_animal'),
        cc_fish: editorVal.proteinType?.includes('fish'),
        cc_shellfish: editorVal.proteinType?.includes('shellfish'),
        cc_legume: editorVal.proteinType?.includes('legume'),
        cc_plant_protein: editorVal.proteinType?.includes('plant'),
        cc_meat: editorVal.proteinType?.includes('meat'),
      };
    },
  },
  carbohydrateType: {
    ccCols: ['cc_complexcarbs', 'cc_simplecarbs'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_complexcarbs) {
        return 'complex';
      } else if (apiVal.cc_simplecarbs) {
        return 'simple';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_complexcarbs: editorVal.carbohydrateType == 'complex',
        cc_simplecarbs: editorVal.carbohydrateType == 'simple',
      };
    },
  },
  beverageType: {
    ccCols: ['cc_beverage', 'cc_sugary_beverage'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_sugary_beverage) {
        return 'sugary';
      } else if (apiVal.cc_beverage) {
        return 'non_sugary';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_beverage: editorVal.beverageType !== '',
        cc_sugary_beverage: editorVal.beverageType === 'sugary',
      };
    },
  },
  fatType: {
    ccCols: ['cc_unhealthy_fat', 'cc_healthy_fat'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_unhealthy_fat) {
        return apiVal.cc_healthy_fat ? 'both' : 'unhealthy';
      } else if (apiVal.cc_healthy_fat) {
        return 'healthy';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_unhealthy_fat: ['both', 'unhealthy'].includes(editorVal.fatType || ''),
        cc_healthy_fat: ['both', 'healthy'].includes(editorVal.fatType || ''),
      };
    },
  },
  grainType: {
    ccCols: ['cc_whole', 'cc_grains'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_whole) {
        return 'whole';
      } else if (apiVal.cc_grains) {
        return 'refined';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_whole: editorVal.grainType == 'whole',
        cc_grains: editorVal.grainType == 'refined',
      };
    },
  },
  processedType: {
    ccCols: ['cc_whole_foods', 'cc_ultraprocessed', 'cc_processed'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_whole_foods) {
        return 'whole_food';
      } else if (apiVal.cc_ultraprocessed) {
        return 'ultra_processed';
      } else if (apiVal.cc_processed) {
        return 'processed';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_whole_foods: editorVal.processedType == 'whole_food',
        cc_ultraprocessed: editorVal.processedType == 'ultra_processed',
        cc_processed: editorVal.processedType == 'processed',
      };
    },
  },
  preparationType: {
    ccCols: ['cc_cooked', 'cc_raw'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      if (apiVal.cc_cooked) {
        return 'cooked';
      } else if (apiVal.cc_raw) {
        return 'raw';
      }
      return '';
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_cooked: editorVal.preparationType == 'cooked',
        cc_raw: editorVal.preparationType == 'raw',
      };
    },
  },
  fried: {
    ccCols: ['cc_fried'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_fried;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_fried: editorVal.fried,
      };
    },
  },
  highCalcium: {
    ccCols: ['cc_calcium'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_calcium;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_calcium: editorVal.highCalcium,
      };
    },
  },
  highFibre: {
    ccCols: ['cc_high_fiber'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_high_fiber;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_high_fiber: editorVal.highFibre,
      };
    },
  },
  supplement: {
    ccCols: ['cc_supplement'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_supplement;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_supplement: editorVal.supplement,
      };
    },
  },
  nutsOrSeeds: {
    ccCols: ['cc_nuts_seeds'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_nuts_seeds;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_nuts_seeds: editorVal.nutsOrSeeds,
      };
    },
  },
  plantBased: {
    ccCols: ['cc_plant_based'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_plant_based;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_plant_based: editorVal.plantBased,
      };
    },
  },
  plantBasedWhole: {
    ccCols: ['cc_plant_based_whole'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_plant_based_whole;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_plant_based_whole: editorVal.plantBasedWhole,
      };
    },
  },
  additives: {
    ccCols: ['cc_additives'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_additives;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_additives: editorVal.additives,
      };
    },
  },
  glutenFree: {
    ccCols: ['cc_gluten_free'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_gluten_free;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_gluten_free: editorVal.glutenFree,
      };
    },
  },
  omegaThree: {
    ccCols: ['cc_omega_three'],
    toFoodEditor: (apiVal: CcColumnsRequest) => {
      return apiVal.cc_omega_three;
    },
    toApiRequest: (editorVal: Partial<FoodEditorValue>) => {
      return {
        cc_omega_three: editorVal.omegaThree,
      };
    },
  },
} satisfies Record<string, FoodEditorCCFieldDef>;

export const overrideValuesToFoodEditorValue = (
  overrideValues: Record<string, any> | null,
): Record<string, any> | null => {
  if (!overrideValues) {
    return null;
  }

  const ccOverrides = overrideValues.ccOverrideValues
    ? {
      ...Object.fromEntries(
        Object.entries(ccFoodEditorValues).map(([key, value]) => {
          const editorVal = value.ccCols.every(cc => Object.keys(overrideValues.ccOverrideValues).includes(cc))
            ? value.toFoodEditor(overrideValues.ccOverrideValues)
            : null;
          return [key, editorVal] as [string, any];
        }).filter(([key, value]) => value != null),
      ),
    }
    : {};

  const mdcOverrides = overrideValues.mdcOverrideValues
    ? { mixedDishComponents: overrideValues.mdcOverrideValues }
    : {};

  return {
    ...ccOverrides,
    ...mdcOverrides,
  };
};

export const compositeDetailsOverridesToFoodResponse = (
  composite_details: FoodDetailsResponse['composite_details'],
) => {
  if (!composite_details) {
    return null;
  }

  return {
    ccOverrideValues: composite_details?.food_cc_override_values,
    mdcOverrideValues: composite_details?.food_mixed_dish_components_override_values,
  };
};

export const usdaNutritionToFoodResponse = (
  usda_nutrition: FoodDetailsResponse['usda_nutrition'],
) => {
  const res = Object.fromEntries(FOOD_EDITOR_NUTRIENT_FIELDS.map(f => [
    f.editorField,
    numberOrNull(usda_nutrition?.[f.mntField]),
  ]));
  return res as FoodEditorNutrientFields<number | null>;
};

export const nutrientFieldsToUsdaNutrition = (
  nutrientFields: FoodEditorNutrientFields<number | null>,
): FoodDetailsResponse['usda_nutrition'] => {
  const res = Object.fromEntries(FOOD_EDITOR_NUTRIENT_FIELDS.map(f => [
    f.mntField,
    numberOrNull(nutrientFields?.[f.editorField]),
  ]));
  return res;
};

export const ccToFoodResponse = (
  cc: FoodDetailsResponse['cc'],
) => {
  return {
    wholeFood: cc?.cc_whole_foods || false,
    grains: cc?.cc_grains || false,
    beverage: cc?.cc_beverage || false,
    sugaryBeverage: cc?.cc_sugary_beverage || false,
    meat: cc?.cc_meat || false,
    poultry: cc?.cc_poultry || false,
    pork: cc?.cc_pork || false,
    protein: cc?.cc_protein || false,
    nonMeatAnimalProtein: cc?.cc_non_meat_animal_protein || false,
    fish: cc?.cc_fish || false,
    shellfish: cc?.cc_shellfish || false,
    processed: cc?.cc_processed || false,
    vegetable: cc?.cc_vegetable || false,
    fruit: cc?.cc_fruit || false,
    fried: cc?.cc_fried || false,
    legume: cc?.cc_legume || false,
    supplement: cc?.cc_supplement || false,
    unhealthyFat: cc?.cc_unhealthy_fat || false,
    healthyFat: cc?.cc_healthy_fat || false,
    plantProtein: cc?.cc_plant_protein || false,
    plantBased: cc?.cc_plant_based || false,
    plantBasedWhole: cc?.cc_plant_based_whole || false,
    raw: cc?.cc_raw || false,
    cooked: cc?.cc_cooked || false,
    additives: cc?.cc_additives || false,
    glutenFree: cc?.cc_gluten_free || false,
    omegaThree: cc?.cc_omega_three || false,
    wholeGrains: cc?.cc_whole || false,
    redMeat: cc?.cc_redmeat || false,
    ultraProcessed: cc?.cc_ultraprocessed || false,
    highCalcium: cc?.cc_calcium || false,
    highFibre: cc?.cc_high_fiber || false,
    complexCarbs: cc?.cc_complexcarbs || false,
    simpleCarbs: cc?.cc_simplecarbs || false,
    nutsOrSeeds: cc?.cc_nuts_seeds || false,
  };
};

export const foodEditorValuesToCc = (
  foodEditorValue: FoodEditorValue,
): Partial<CcColumnsRequest> => {
  const res = Object.entries(ccFoodEditorValues).reduce((acc, [key, value]) => {
    const toCc = value.toApiRequest(foodEditorValue);
    return { ...acc, ...toCc };
  }, {} as Partial<CcColumnsRequest>);
  return res;
};

export const apiFoodResponseToFoodResponse = (foodDetailsResponse: FoodDetailsResponse): FoodEditorFoodResponse => {
  const { food, ontology, usda_nutrition, usda_measures, components, cc, ranking, composite_details, translations } =
    foodDetailsResponse;
  return {
    ndbNumber: food?.rxfood_id || '',
    term: food?.name || '',
    definitionType: food?.definition_type || 'atomic',
    frenchTerm: translations?.find(translation => translation.lang === 'fr')?.value || '',
    status: food?.status || '',
    foodImageUrl: food?.food_image_url || null,
    createdAt: food?.created_time || null,
    lastUpdatedAt: food?.updated_time || null,
    ontologyLevels: ontology?.levels || [],
    baseFoodName: food?.base_food_name || '',
    rootFoodName: food?.root_food_name || '',
    dataSource: food?.nutrition_source || '',
    nutritionSourceId: food?.nutrition_source_id || null,
    otherNames: food?.other_names || [],
    synonyms: food?.synonyms || [],
    singularTerm: food?.name_singular || '',
    pluralTerm: food?.name_plural || '',
    customTip: food?.custom_tip || '',

    nutritionSourceUrl: food?.nutrition_source_url || null,
    fdcid: food?.fdcid || null,
    cnFoodId: food?.cn_food_id || null,
    notes: food?.notes || null,

    preferredAddOn: food?.preferred_addon || false,
    similarItems: food?.similar_items || [],
    adultServingSizeG: food?.adult_serving_size_g || null,
    suggestedServingCount: food?.suggested_serving_count || null,
    suggestedServingUnitLabel: food?.suggested_serving_unit_label || null,
    suggestedServingAmountG: food?.suggested_serving_amount_g || null,
    manufacturer: food?.manufacturer || '',

    giLevel: food?.glycemic_load || null,
    potassiumLevel: food?.k_level || null,
    ghgEquivalentKg: food?.ghg_equivalent_kg || null,
    mixedDishComponents: food?.mixed_dish_components || {},

    potassiumComments: null, // safe to ignore
    connectedItems: [], // not here now, in spreadsheet, should add in the future
    compoundingWords: [], // col bp, probably safe to ignore
    giComments: null, // ignore for v0, revisit in v1
    usdaNutrientName: null, // ignore for v0, high priority v1 (detailed description from USDA, column "I" in O1)

    ...ccToFoodResponse(cc),

    suggestedItem: false, // Col CM in O1, used for nutrition suggestions, add for v1

    rankingUp: ranking?.ultra_processed || 0,
    rankingPb: ranking?.plant_based || 0,
    rankingHealthyFat: ranking?.healthy_fat || 0,
    rankingUnhealthyFat: ranking?.unhealthy_fat || 0,
    rankingBothFat: ranking?.both_fat || 0,
    rankingWholeGrains: ranking?.whole_grain || 0,
    rankingRefinedGrains: ranking?.refined_grain || 0,
    rankingFruit: ranking?.fruit || 0,
    rankingVegetable: ranking?.vegetable || 0,
    rankingLowGi: ranking?.low_gi || 0,
    rankingHighGi: ranking?.high_gi || 0,
    rankingLowK: ranking?.low_k || 0,
    rankingHighK: ranking?.high_k || 0,

    ...usdaNutritionToFoodResponse(usda_nutrition),

    measures: usda_measures || [],
    components: components || [],

    overrideValues: compositeDetailsOverridesToFoodResponse(composite_details),

    disabled: false, // In O1, but not important here; will ignore.
    optionCollection: foodDetailsResponse.option_collection!,
    optionCollectionId: foodDetailsResponse.option_collection?.id || null,
    optionValuesRaw: foodDetailsResponse.option_values_raw!,

    listedServingCount: foodDetailsResponse.composite_details?.listed_servings || null,
    listedServingUnitLabel: foodDetailsResponse.composite_details?.listed_serving_unit_label || '',
    listedServingAmountG: foodDetailsResponse.composite_details?.listed_serving_unit_amount || null,
  };
};

// Only currently concerned with those that source the current derivation fields: nutrients
// TODO: eventually ingredients
export const foodEditorValueToPartialFoodDetails = (foodEditorValue: FoodEditorValue): Partial<FoodDetailsResponse> => {
  return {
    cc: foodEditorValuesToCc(foodEditorValue),
    food: {
      name: foodEditorValue.term,
      suggested_serving_amount_g: parseFloat(foodEditorValue.suggestedServingAmountG),
      adult_serving_size_g: parseFloat(foodEditorValue.adultServingSizeG),
    },
    ontology: {
      levels: foodEditorValue.ontologyLevels,
    },
    ranking: {
      name: foodEditorValue.term,
      ultra_processed: parseInt(foodEditorValue.rankingUp),
      plant_based: parseInt(foodEditorValue.rankingPb),
      healthy_fat: parseInt(foodEditorValue.rankingHealthyFat),
      unhealthy_fat: parseInt(foodEditorValue.rankingUnhealthyFat),
      both_fat: parseInt(foodEditorValue.rankingBothFat),
      whole_grain: parseInt(foodEditorValue.rankingWholeGrains),
      refined_grain: parseInt(foodEditorValue.rankingRefinedGrains),
      fruit: parseInt(foodEditorValue.rankingFruit),
      vegetable: parseInt(foodEditorValue.rankingVegetable),
      low_gi: parseInt(foodEditorValue.rankingLowGi),
      high_gi: parseInt(foodEditorValue.rankingHighGi),
      low_k: parseInt(foodEditorValue.rankingLowK),
      high_k: parseInt(foodEditorValue.rankingHighK),
    },
    usda_nutrition: nutrientFieldsToUsdaNutrition(foodEditorValue),
  };
};

const possibleLanguageTranslations = {
  frenchTerm: 'fr',
};

type TranslationTable = typeof possibleLanguageTranslations;

export const foodRequestToApiFoodRequest = (term: string, r: FoodEditorFoodRequest): FoodDetailsUpdateRequest => {
  const translations: FoodDetailsUpdateRequestTranslationsInner[] = [];
  Object.keys(possibleLanguageTranslations).forEach(key => {
    translations.push({
      translation: r[key as keyof FoodEditorFoodRequest],
      lang: possibleLanguageTranslations[key as keyof TranslationTable],
    } as FoodDetailsUpdateRequestTranslationsInner);
  });
  const details: FoodDetailsUpdateRequest = {
    food: {
      rxfood_id: r.ndbNumber,
      definition_type: r.definitionType,
      name: term,
      status: r.status || 'triaged',
      created_time: new Date().toISOString(),
      updated_time: new Date().toISOString(),
      base_food_name: r.baseFoodName,
      root_food_name: r.rootFoodName,
      food_image_url: r.foodImageUrl || null,
      nutrition_source: r.dataSource || '',
      nutrition_source_id: r.nutritionSourceId!,
      other_names: r.otherNames,
      synonyms: r.synonyms,
      name_singular: r.singularTerm,
      name_plural: r.pluralTerm,
      custom_tip: r.customTip,
      nutrition_source_url: r.nutritionSourceUrl!,
      fdcid: r.fdcid!,
      cn_food_id: r.cnFoodId!,
      notes: r.notes!,
      preferred_addon: r.preferredAddOn,
      similar_items: r.similarItems,
      adult_serving_size_g: r.adultServingSizeG,
      suggested_serving_count: r.suggestedServingCount,
      suggested_serving_unit_label: r.suggestedServingUnitLabel,
      suggested_serving_amount_g: r.suggestedServingAmountG,
      manufacturer: r.manufacturer,
      glycemic_load: r.giLevel || undefined,
      k_level: r.potassiumLevel || undefined,
      ghg_equivalent_kg: r.ghgEquivalentKg,
      mixed_dish_components: objRemoveNulls(r.mixedDishComponents || {}),
    },

    ontology: {
      levels: r.ontologyLevels.filter(x => !!x) as string[],
    },

    cc: {
      cc_additives: r.additives,
      cc_beverage: r.beverage,
      cc_calcium: r.highCalcium,
      cc_complexcarbs: r.complexCarbs,
      cc_cooked: r.cooked,
      cc_fish: r.fish,
      cc_fried: r.fried,
      cc_fruit: r.fruit,
      cc_gluten_free: r.glutenFree,
      cc_grains: r.grains,
      cc_healthy_fat: r.healthyFat,
      cc_high_fiber: r.highFibre,
      cc_legume: r.legume,
      cc_meat: r.meat,
      cc_non_meat_animal_protein: r.nonMeatAnimalProtein,
      cc_nuts_seeds: r.nutsOrSeeds,
      cc_omega_three: r.omegaThree,
      cc_plant_based: r.plantBased,
      cc_plant_based_whole: r.plantBasedWhole,
      cc_plant_protein: r.plantProtein,
      cc_pork: r.pork,
      cc_poultry: r.poultry,
      cc_processed: r.processed,
      cc_protein: r.protein,
      cc_raw: r.raw,
      cc_redmeat: r.redMeat,
      cc_shellfish: r.shellfish,
      cc_simplecarbs: r.simpleCarbs,
      cc_sugary_beverage: r.sugaryBeverage,
      cc_supplement: r.supplement,
      cc_ultraprocessed: r.ultraProcessed,
      cc_unhealthy_fat: r.unhealthyFat,
      cc_vegetable: r.vegetable,
      cc_whole: r.wholeGrains,
      cc_whole_foods: r.wholeFood,
    },

    ranking: {
      name: term,
      ultra_processed: r.rankingUp! || 0,
      plant_based: r.rankingPb! || 0,
      healthy_fat: r.rankingHealthyFat! || 0,
      unhealthy_fat: r.rankingUnhealthyFat! || 0,
      both_fat: r.rankingBothFat! || 0,
      whole_grain: r.rankingWholeGrains! || 0,
      refined_grain: r.rankingRefinedGrains! || 0,
      fruit: r.rankingFruit! || 0,
      vegetable: r.rankingVegetable! || 0,
      low_gi: r.rankingLowGi! || 0,
      high_gi: r.rankingHighGi! || 0,
      low_k: r.rankingLowK! || 0,
      high_k: r.rankingHighK! || 0,
    },

    usda_nutrition: {
      usda_id: null,
      ...Object.fromEntries(
        FOOD_EDITOR_NUTRIENT_FIELDS.filter(f => !f.skipConvertToRequest).map(f => [
          f.mntField,
          numberOrNull(r[f.editorField] as any),
        ]),
      ),
    },
    usda_measures: r.measures,
    components: r.components,
    composite_details: {
      food_cc_override_values: r.overrideValues?.ccOverrideValues,
      food_mixed_dish_components_override_values: r.overrideValues?.mdcOverrideValues,
      listed_servings: r.listedServingCount,
      listed_serving_unit_label: r.listedServingUnitLabel,
      listed_serving_unit_amount: r.listedServingAmountG,
    },
    option_collection_id: r.optionCollectionId,
    option_values_raw: r.optionValuesRaw,
    translations,
  };
  return details;
};

export default {
  createFood: async (term: string, foodRequest: FoodEditorFoodRequest) =>
    (
      await client.post<FoodEditorFoodResponse>(
        `foods/${encodeURIComponent(term)}`,
        foodRequest,
      )
    ).data,

  getFood: async (food_name: string): Promise<FoodEditorFoodResponse> => {
    const res = await foodApi.appApiFoodFoodDetailsGetFoodDetailsQuery({ food_name });
    return apiFoodResponseToFoodResponse(res.data);
  },

  getOntologies: async (): Promise<FoodOntologyVTO[]> => {
    const res = await foodApi.appApiFoodOntologyGetAllOntologies();
    return res.data;
  },

  searchFoods: async (
    query: string,
    offset: number,
    limit: number,
    excludeTerms: string[] = [],
  ): Promise<FoodSearchResponse> => {
    if (query.length == 0) {
      return {
        items: [],
        hasNextPage: false,
      };
    }

    const res = await foodApi.appApiFoodFoodSearchSearchByText({
      search_text: query,
    });

    return {
      items: res.data.results,
      hasNextPage: false,
    };
  },

  renameFood: async (old_food_name: string, new_food_name: string): Promise<void> => {
    await dataManagementApi.appApiDataManagementPutRenameFood({
      old_food_name,
      new_food_name,
    });
  },

  updateFood: async (term: string, r: FoodEditorFoodRequest): Promise<FoodEditorFoodResponse> => {
    // Note: some of this logic is duplicated in TriageFoodTablePage
    const details: FoodDetailsUpdateRequest = foodRequestToApiFoodRequest(term, r);
    const res = await foodApi.appApiFoodFoodDetailsPutFoodDetailsQuery({
      food_name: term,
      FoodDetailsUpdateRequest: details,
    });
    return apiFoodResponseToFoodResponse(res.data);
  },

  applyWarnings: async (term: string, r: FoodEditorFoodRequest): Promise<FoodDetailsResponse> => {
    const details: FoodDetailsUpdateRequest = foodRequestToApiFoodRequest(term, r);
    const res = await foodApi.appApiFoodFoodDetailsApplyWarningsQuery({
      food_name: term,
      FoodDetailsUpdateRequest: details,
    });

    return res.data;
  },
};
