import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Collapse,
  FormControl,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { foodApi } from 'api';
import {
  ChangeLogItem,
  FoodChangeLogResponse,
  MealChangeLogResponse,
  NutrientEstimatesRequest,
} from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import { AxiosError } from 'axios';
import { useAuth } from 'context/appContext';
import { useFeatures } from 'context/FeatureContext';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DraftItem } from 'types/DraftItem';
import { useQueryNeverRefetch } from 'utils';
import { HumanTime } from './HumanTime';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(even):hover': {
    backgroundColor: 'unset !important',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const getFieldChangesStr = (change: ChangeLogItem) => {
  const isComponentChange = change.item_type == 'food_component';
  const isMeasureChange = change.item_type == 'usda_measure';

  if (change.change_type != 'update' || !change.old || !change.new) {
    return [null];
  }

  return Object.entries(change.old).map(([key, value]) => {
    const changeKey = isComponentChange
      ? `${change.new?.food_name || change.old?.food_name} ${key}`
      : isMeasureChange
      ? `${change.new?.qty || change.old?.qty} ${change.new?.label || change.old?.label} - ${key}`
      : key;
    if ((value == null && change.new?.[key] == null) || value === change.new?.[key]) {
      return null;
    }

    if (typeof change.new?.[key] === 'object' || typeof value === 'object') {
      return (
        <div key={key}>
          {changeKey}: <i>{JSON.stringify(value)} → {JSON.stringify(change.new?.[key])}</i>
        </div>
      );
    }

    if (moment(value, moment.ISO_8601).isValid() && moment(change.new?.[key], moment.ISO_8601).isValid()) {
      return (
        <div key={key}>
          {changeKey}:{' '}
          <i>
            <HumanTime value={value} isUTC={true} /> → <HumanTime value={change.new?.[key]} isUTC={true} />
          </i>
        </div>
      );
    }

    return (
      <div key={key}>
        {changeKey}: <i>{'' + value} → {'' + change.new?.[key]}</i>
      </div>
    );
  });
};

export const useFoodChangeLogs = (foodName: string) => {
  const { authInfo } = useAuth();
  const query = useQuery(['food-change-logs', foodName], async () => {
    if (!authInfo?.access_token || !foodName) {
      return null;
    }
    const res = await foodApi.appApiFoodFoodDetailsGetFoodDetailsChangeLogsQuery({
      food_name: foodName,
    });
    return res.data;
  }, {
    ...useQueryNeverRefetch,
    cacheTime: 0,
  });

  return {
    query: query as UseQueryResult<unknown>,
    data: query.data,
  };
};

const getItemInfo = (change: ChangeLogItem) => {
  const itemName = change.new?.food_name || change.old?.food_name;
  if (change.item_type == 'food_component' && change.change_type == 'create') {
    return `${itemName} - ${change.new?.servings} ${change.new?.serving_unit_label}`;
  }
  if (change.item_type == 'usda_measure') {
    return `${change.new?.qty ?? change.old?.q} ${change.new?.label ?? change.old?.label}`;
  }
  return itemName;
};

const ChangeLogRow = (props: { change: ChangeLogItem }) => {
  const change = props.change;
  const flags = useFeatures();

  const ts = moment(change.timestamp).format('HH:mm');
  const changeModel = {
    'food': 'Food',
    'food_component': 'Component',
    'food_cc': 'CC',
    'usda_nutrition': 'Nutrition',
    'usda_measure': 'Measure',
  }[change.item_type] || change.item_type;

  const changeType = {
    'create': 'added',
    'update': 'updated',
    'delete': 'deleted',
  }[change.change_type];

  if (changeModel == 'Component' && !flags.food_editor_composite_foods) {
    return <span key="empty" />;
  }

  const itemInfo = getItemInfo(change);

  const fieldChangesStr = getFieldChangesStr(change);
  const filteredFieldChangesStr = fieldChangesStr.filter(change => change);
  if (changeType == 'updated' && !filteredFieldChangesStr.length) {
    // Ignore changes if there's nothing specific we want to show in them
    return <span key="empty" />;
  }

  return (
    <StyledTableRow style={{ pointerEvents: 'none' }} key={change.id}>
      <StyledTableCell>{ts}</StyledTableCell>
      <StyledTableCell>{changeModel} {changeType}</StyledTableCell>
      <StyledTableCell>{change.change_type != 'update' ? itemInfo : filteredFieldChangesStr}</StyledTableCell>
    </StyledTableRow>
  );
};

const CollapseChangeLogRow = (props: { change: ChangeLogItem }) => {
  const change = props.change;
  const [open, setOpen] = useState(false);
  const flags = useFeatures();

  const ts = moment(change.timestamp).format('HH:mm');
  const changeModel = {
    'food': 'Food',
    'food_component': 'Component',
    'food_cc': 'CC',
    'usda_nutrition': 'Nutrition',
    'usda_measure': 'Measure',
  }[change.item_type] || change.item_type;

  const changeType = {
    'create': 'added',
    'update': 'updated',
    'delete': 'deleted',
  }[change.change_type];

  if (changeModel == 'Component' && !flags.food_editor_composite_foods) {
    return <span key="empty" />;
  }

  const itemInfo = getItemInfo(change);

  const fieldChangesStr = getFieldChangesStr(change);
  const filteredFieldChangesStr = fieldChangesStr.filter(change => change);
  if (changeType == 'updated' && !filteredFieldChangesStr.length) {
    // Ignore changes if there's nothing specific we want to show in them
    return <span key="empty" />;
  }

  return (
    <StyledTableRow key={change.id} onClick={() => setOpen(!open)}>
      <StyledTableCell>{ts}</StyledTableCell>
      <StyledTableCell>{changeModel} {changeType}</StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {filteredFieldChangesStr.length} Changes
          <div style={{ width: 10 }} />
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {change.change_type != 'update' ? itemInfo : filteredFieldChangesStr}
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const FoodChangeLogsTable = (props: {
  changeLogs: FoodChangeLogResponse,
}) => {
  const { changeLogs } = props;
  const { hasAuth } = useAuth();
  const [changeSearch, setChangeSearch] = useState('');

  const tableOrChangeIncludesStr = (change: ChangeLogItem, value: string) => {
    const lowercaseValue = value.toLocaleLowerCase();
    const changeModel = {
      'food': 'food',
      'food_component': 'component',
      'food_cc': 'cc',
      'usda_nutrition': 'nutrition',
    }[change.item_type] || change.item_type;
    const oldVal = JSON.stringify(change.old).toLocaleLowerCase();
    const newVal = JSON.stringify(change.new).toLocaleLowerCase();
    if (changeModel.includes(lowercaseValue)) {
      return true;
    }
    if ((oldVal && oldVal.includes(lowercaseValue)) || (newVal && newVal.includes(lowercaseValue))) {
      return true;
    }
    return false;
  };

  const filteredChangesGrouped = useMemo(() => {
    const changes = ([] as ChangeLogItem[])
      .concat(changeLogs.food_changes || []);
    changes.sort((a, b) => b.timestamp > a.timestamp ? 1 : -1);

    const changesGrouped: Array<{ header: string, changes: ChangeLogItem[] }> = [];
    let last: any = null;
    changes.forEach(change => {
      const changeDate = moment(change.timestamp).format('YYYY-MM-DD');
      const headerAttribution = !change.user_id
        ? 'Engine'
        : `User ${change.user_id}${change.user_name ? ' (' + change.user_name + ')' : ''}`;
      const changeHeader = `${changeDate}: ${headerAttribution}`;
      if (last?.header == changeHeader) {
        last.changes.push(change);
        return;
      }
      last = { header: changeHeader, changes: [change] };
      changesGrouped.push(last);
    });

    if (!changeSearch) {
      return changesGrouped;
    }

    const filteredChanges = changesGrouped.map(changeGroup => {
      changeGroup.changes = changeGroup.changes.filter(change => tableOrChangeIncludesStr(change, changeSearch));
      return changeGroup;
    });
    return filteredChanges.filter((g) => !!g.changes.length);
  }, [changeSearch]);

  const getNumChanges = (change: ChangeLogItem) => {
    if (!change.old || change.change_type != 'update') {
      return 0;
    }
    return Object.entries(change.old).filter(([key, value]) => value !== change.new?.[key]).length;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    setChangeSearch(e.target.value);
  };

  return (
    <>
      <FormControl fullWidth>
        <TextField
          name="changes"
          fullWidth
          label="Search Changes"
          InputLabelProps={{ sx: { overflow: 'visible' } }}
          variant="outlined"
          value={changeSearch}
          onChange={e => handleChange(e)}
        />
      </FormControl>
      <TableContainer style={{ maxWidth: '100%' }}>
        {filteredChangesGrouped.map(gc => {
          return (
            <Table key={gc.header} style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={3}>{gc.header}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Time</StyledTableCell>
                  <StyledTableCell>Event</StyledTableCell>
                  <StyledTableCell>Changes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gc.changes.map((change, index) =>
                  getNumChanges(change) > 5
                    ? <CollapseChangeLogRow key={index} change={change} />
                    : <ChangeLogRow key={index} change={change} />
                )}
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
    </>
  );
};

export const FoodChangeLogs = (props: {
  foodName: string,
}) => {
  const foodName = props.foodName;

  const headingStyle: React.CSSProperties = {
    backgroundColor: '#1976d2',
    color: 'white',
    height: 40,
    lineHeight: '40px',
    marginBottom: 10,
    marginLeft: -10,
    marginRight: -10,
    paddingLeft: 15,
    paddingRight: 10,
  };

  const foodChangeLogs = useFoodChangeLogs(foodName);

  const handleError = (e: any) => {
    if (e.response.status == 404) {
      return <span>Food is a custom item or cannot be found</span>;
    }
    return <span>{'' + e}</span>;
  };

  return (
    <Stack
      style={{ minHeight: 200, marginBottom: 25, paddingLeft: 10, paddingRight: 10, overflowY: 'auto' }}
    >
      <Typography variant="h6" style={headingStyle}>
        Food Edit History
      </Typography>
      {foodChangeLogs.query.isError && <div>{handleError(foodChangeLogs.query.error)}</div>}
      {foodChangeLogs.query.isLoading && <div>Loading...</div>}
      {foodChangeLogs.query.isSuccess && foodChangeLogs.data
        ? (
          <FoodChangeLogsTable
            changeLogs={foodChangeLogs.data}
          />
        )
        : <div>No Changes</div>}
    </Stack>
  );
};

const getMealItemFieldChangeStr = (change: ChangeLogItem, customMealItemsDict: CustomItemFoodToFirstLog) => {
  if (change.change_type != 'update' || !change.old || !change.new) {
    return [<span key="empty" />];
  }

  if (change.item_type == 'meal') {
    return getFieldChangesStr(change);
  }

  const newFoodName = getMealItemNameForDisplay(customMealItemsDict, change.id, change.new);
  const oldFoodName = getMealItemNameForDisplay(customMealItemsDict, change.id, change.old);

  const res: any = [];
  if (
    oldFoodName !== undefined && newFoodName !== undefined
    && change.old.food_name != change.new.food_name
  ) {
    res.push(
      <div key="meal_item">
        meal item:{' '}
        <i>
          {oldFoodName} → {newFoodName}
          {' '}
        </i>
      </div>,
    );
  } else if (
    oldFoodName !== undefined && newFoodName !== undefined
    && !change.old.food_name_alias && !!change.new.food_name_alias
  ) {
    res.push(
      <div key="meal_item">
        meal item:{' '}
        <i>
          {oldFoodName} → alias added: {newFoodName}
          {' '}
        </i>
      </div>,
    );
  } else if (
    oldFoodName !== undefined && newFoodName !== undefined
    && change.old.food_name_alias != change.new.food_name_alias
  ) {
    res.push(
      <div key="meal_item">
        meal item:{' '}
        <i>
          {oldFoodName} → alias updated: {newFoodName}
          {' '}
        </i>
      </div>,
    );
  }
  if (
    change.old.servings !== undefined && change.old.serving_unit_label !== undefined
    && newFoodName !== undefined
  ) {
    res.push(
      <div key="portion">
        {newFoodName} portion:{' '}
        <i>
          {change.old.servings} {change.old.serving_unit_label} → {change.new.servings} {change.new.serving_unit_label}
        </i>
      </div>,
    );
  } else if (change.old.servings !== undefined && newFoodName !== undefined) {
    res.push(
      <div key="servings">
        {newFoodName} servings: <i>{change.old.servings} → {change.new.servings}</i>
      </div>,
    );
  } else if (change.old.serving_unit_label !== undefined && newFoodName !== undefined) {
    res.push(
      <div key="serving_unit_label">
        {newFoodName} unit: <i>{change.old.serving_unit_label} → {change.new.serving_unit_label}</i>
      </div>,
    );
  }
  if (change.old.percent_eaten !== undefined && newFoodName !== undefined) {
    const oldPercentEaten = (+change.old.percent_eaten * 100).toString() + '%';
    const newPercentEaten = (+change.new.percent_eaten * 100).toString() + '%';
    res.push(
      <div key="percent_eaten">
        {newFoodName} % eaten: <i>{oldPercentEaten} → {newPercentEaten}</i>
      </div>,
    );
  }
  if (change.old.note !== undefined && newFoodName !== undefined) {
    res.push(
      <div key="note">
        {newFoodName} note: <i>{change.old.note} → {change.new.note}</i>
      </div>,
    );
  }

  // Legacy addons, these have been deprecated in favour of custom_addons
  const oldAddons: string[] = change.old.addons || [];
  if (oldAddons.length > 0) {
    const newAddons: string[] = change.new.addons || [];

    const addedAddons = newAddons.filter(a => !oldAddons.includes(a));
    if (addedAddons.length > 0) {
      res.push(
        <div key="added_addons">
          addons added: <i>{addedAddons.join(', ')}</i>
        </div>,
      );
    }

    const removedAddons = oldAddons.filter(a => !newAddons.includes(a));
    if (removedAddons.length > 0) {
      res.push(
        <div key="removed_addons">
          addons removed: <i>{removedAddons.join(', ')}</i>
        </div>,
      );
    }
  }

  const oldNutrientOverrides: Partial<NutrientEstimatesRequest> = change.old.nutrient_overrides?.aa_patient?.nutrients
    || {};

  const newNutrientOverrides: Partial<NutrientEstimatesRequest> = change.new.nutrient_overrides?.aa_patient?.nutrients
    || {};

  type NutrientDiff = {
    name: string,
    oldValue?: number,
    newValue?: number,
  };
  const editedNutrients: NutrientDiff[] = [];
  const addedNutrients: NutrientDiff[] = [];
  const deletedNutrients: NutrientDiff[] = [];
  [...new Set(Object.keys(oldNutrientOverrides).concat(Object.keys(newNutrientOverrides)))].forEach(n => {
    const oldValue = oldNutrientOverrides[n as keyof NutrientEstimatesRequest];
    const newValue = newNutrientOverrides[n as keyof NutrientEstimatesRequest];

    if (oldValue !== newValue) {
      if (typeof oldValue === 'number' && typeof newValue === 'number') {
        editedNutrients.push({ name: n, oldValue: oldValue, newValue: newValue });
        return;
      }
      if (!oldValue && typeof newValue === 'number') {
        addedNutrients.push({ name: n, newValue: newValue });
        return;
      }
      if (typeof oldValue === 'number' && !newValue) {
        deletedNutrients.push({ name: n, oldValue: oldValue });
      }
    }
  });

  if (editedNutrients.length) {
    res.push(
      <div key="patient_nutrient_overrides_edited_nutrients">
        macros edited:
        <ul>
          {editedNutrients.map((n, i) => (
            <li key={i}>
              {n.name}: <i>{n.oldValue} → {n.newValue}</i>
            </li>
          ))}
        </ul>
      </div>,
    );
  }

  if (addedNutrients.length) {
    res.push(
      <div key="patient_nutrient_overrides_added_nutrients">
        macros added:
        <ul>
          {addedNutrients.map((n, i) => (
            <li key={i}>
              {n.name}: <i>{n.newValue}</i>
            </li>
          ))}
        </ul>
      </div>,
    );
  }

  if (deletedNutrients.length) {
    res.push(
      <div key="patient_nutrient_overrides_deleted_nutrients">
        macros deleted:
        <ul>
          {deletedNutrients.map((n, i) => (
            <li key={i}>
              {n.name}: <i>{n.oldValue}</i>
            </li>
          ))}
        </ul>
      </div>,
    );
  }

  return res;
};

const getMealPushQuestionFieldChangeStr = (
  change: ChangeLogItem,
  pushQuestionIdToMealItem: PushQuestionIdToMealItem,
) => {
  const getQuestionResponse = (changeFields: { [key: string]: any }) => {
    if (!changeFields.question_response || changeFields.question_response.items.length == 0) {
      return '';
    }

    const res = changeFields.question_response.items.map((i: any) => {
      return i.form_item_id + ': ' + i.value;
    });

    return res.join(', ');
  };

  const foodName = pushQuestionIdToMealItem[change.item_id].foodName;
  const res: any = [];

  res.push(
    <div key="associated_meal_item">
      meal item: {foodName}
    </div>,
  );

  if (change.change_type == 'delete') {
    return res;
  }

  if (change.change_type == 'create') {
    res.push(
      <div key="question_type">
        type: {change.new?.question_type}
      </div>,
    );
    return res;
  }

  if (!change.old || !change.new) {
    return [<span key="empty" />];
  }

  if (change.old.question_status != change.new.question_status) {
    res.push(
      <div key="question_status">
        status:{' '}
        <i>
          {change.old.question_status} → {change.new.question_status}
          {' '}
        </i>
      </div>,
    );
  }
  if (!change.old.question_response && !!change.new.question_response) {
    const questionRes = getQuestionResponse(change.new);
    res.push(
      <div key="question_response">
        response received:{' '}
        <i>
          {questionRes}
          {' '}
        </i>
      </div>,
    );
  } else if (!!change.old.question_response && !change.new.question_response) {
    res.push(
      <div key="question_response">
        response removed
      </div>,
    );
  } else if (change.old.question_response != change.new.question_response) {
    const oldQuestionRes = getQuestionResponse(change.old);
    const newQuestionRes = getQuestionResponse(change.new);

    res.push(
      <div key="question_response">
        response updated:{' '}
        <i>
          {oldQuestionRes} → {newQuestionRes}
          {' '}
        </i>
      </div>,
    );
  }
  return res;
};

// The value of this dict is the change log ID which the food name was changed to a non-custom
type CustomItemFoodToFirstLog = {
  [foodName: string]: number | null,
};

type MealItemIdToFoodName = {
  [id: string]: string,
};

type PushQuestionIdToMealItem = {
  [id: string]: {
    mealItemId?: string,
    foodName?: string,
  },
};

export const MealChangeLogsTable = (props: {
  changeLogs: MealChangeLogResponse,
  reviewerID?: number | null | undefined,
  firstReviewerID?: number | null | undefined,
}) => {
  const { changeLogs, reviewerID } = props;
  const { hasAuth } = useAuth();
  const renderChange = (change: ChangeLogItem) => {
    const ts = moment(change.timestamp).format('HH:mm');
    const changeModel = {
      'meal': 'Meal',
      'meal_item': 'Item',
      'meal_item_custom_addon': 'Addon',
      'meal_push_question': 'Question',
      'meal_photo_queue': 'Queue',
    }[change.item_type] || change.item_type;

    const changeType = {
      'create': 'added',
      'update': 'updated',
      'delete': 'deleted',
    }[change.change_type];

    const getItemName = (change: ChangeLogItem) => {
      const changeFields = change.new?.food_name ? change.new : change.old;
      if (change.item_type != 'meal_item_custom_addon') {
        return getMealItemNameForDisplay(customMealItemsDict, change.id, changeFields);
      }

      // Find the meal item that this addon is attached to
      const mealItem = changeLogs?.changes?.filter(
        miChange => (
          miChange.item_type == 'meal_item'
          && miChange.item_id == (change.old?.meal_item_id || change.new?.meal_item_id)
          && !!miChange.new?.food_name
          && miChange.id < change.id
        ),
      ).reverse()[0];
      if (!mealItem) {
        return getMealItemNameForDisplay(customMealItemsDict, change.id, changeFields);
      }

      return (
        <>
          {getMealItemNameForDisplay(customMealItemsDict, change.id, mealItem.new)}:{' '}
          <span>{changeFields?.food_name}</span>
        </>
      );
    };

    if (changeModel == 'Queue') {
      const first_reviewer_user_id = change.new?.first_reviewer_user_id;
      const data_reviewer_id = change.new?.data_reviewer_id;
      if (!first_reviewer_user_id && !data_reviewer_id) {
        return;
      }

      if (first_reviewer_user_id) {
        return (
          <tr key={change.id}>
            <td>{ts}</td>
            <td>{changeModel} {changeType}</td>
            <td>First accesssed by: User {first_reviewer_user_id}</td>
          </tr>
        );
      }

      return (
        <tr key={change.id}>
          <td>{ts}</td>
          <td>{changeModel} {changeType}</td>
          <td>Reviewer: {props.firstReviewerID} → {data_reviewer_id}</td>
        </tr>
      );
    }

    if (change.item_type == 'meal_push_question') {
      const fieldChangeStr = getMealPushQuestionFieldChangeStr(change, pushQuestionIdToMealItem);
      return (
        <tr key={change.id}>
          <td>{ts}</td>
          <td>{changeModel} {changeType}</td>
          <td>{fieldChangeStr}</td>
        </tr>
      );
    }

    const itemName = getItemName(change);

    // remove unnecessary noise
    if (itemName === 'awaiting verification...') {
      return;
    }

    if (reviewerID && reviewerID != change.user_id) {
      return;
    }

    if (change.change_type != 'update') {
      return (
        <tr key={change.id}>
          <td>{ts}</td>
          <td>{changeModel} {changeType}</td>
          <td>{itemName}</td>
        </tr>
      );
    }

    const fieldChangesStr = getMealItemFieldChangeStr(change, customMealItemsDict);
    if (!fieldChangesStr.length) {
      // Ignore changes if there's nothign specific we want to show in them
      return false;
    }

    return (
      <tr key={change.id}>
        <td>{ts}</td>
        <td>{changeModel} {changeType}</td>
        <td>{fieldChangesStr}</td>
      </tr>
    );
  };

  const [changesGrouped, customMealItemsDict, pushQuestionIdToMealItem] = useMemo(() => {
    const changes: ChangeLogItem[] = changeLogs.changes ?? [];
    changes.sort((a, b) => b.timestamp > a.timestamp ? 1 : -1);

    const mealItemLastServingUnit = {} as any;
    const customMealItemsDict: CustomItemFoodToFirstLog = {};
    const mealItemToFoodName: MealItemIdToFoodName = {};
    const pushQuestionIdToMealItem: PushQuestionIdToMealItem = {};
    changes.slice().reverse().forEach(change => {
      if (change.item_type == 'meal_push_question' && change.change_type == 'create') {
        pushQuestionIdToMealItem[change.item_id] = { mealItemId: change.new?.meal_item_id };
        return;
      }

      if (change.item_type != 'meal_item') {
        return;
      }

      mealItemToFoodName[change.item_id] = change.new?.food_name || change.old?.food_name;

      if (change.new?.custom_item) {
        customMealItemsDict[change.new.food_name] = null;
      } else if (change.old?.custom_item && !change.new?.custom_item) {
        customMealItemsDict[change.old.food_name] = change.id;
      }

      if (change.new?.servings) {
        mealItemLastServingUnit[change.item_id + '-servings'] = change.new.servings;
      }

      if (change.new?.serving_unit_label) {
        mealItemLastServingUnit[change.item_id + '-units'] = change.new.serving_unit_label;
      }

      if (change.change_type != 'update' || !change.old || !change.new) {
        return;
      }

      const shouldAdd = !!(change.old.servings || change.old.serving_unit_label);

      if (shouldAdd && !change.old.servings) {
        change.old.servings = mealItemLastServingUnit[change.item_id + '-servings'];
        change.new.servings = mealItemLastServingUnit[change.item_id + '-servings'];
      }

      if (shouldAdd && !change.old.serving_unit_label) {
        change.old.serving_unit_label = mealItemLastServingUnit[change.item_id + '-units'];
        change.new.serving_unit_label = mealItemLastServingUnit[change.item_id + '-units'];
      }
    });

    Object.entries(pushQuestionIdToMealItem).forEach(([key, value]) => {
      if (!value.mealItemId) {
        return;
      }
      const mealItemName = mealItemToFoodName[value.mealItemId];
      pushQuestionIdToMealItem[key].foodName = mealItemName;
    });

    const changesGrouped: Array<{ header: string, changes: ChangeLogItem[] }> = [];
    let last: any = null;
    changes.forEach(change => {
      const changeDate = moment(change.timestamp).format('YYYY-MM-DD');
      const headerAttribution = !change.user_id
        ? 'Engine'
        : hasAuth(Capabilities.mpqChangeLogsFullAttributionView)
        ? `User ${change.user_id}${change.user_name ? ' (' + change.user_name + ')' : ''}`
        : hasAuth(Capabilities.mpqChangeLogsPartAttributionView)
        ? `Source: ${change.user_name}`
        : `Algorithm`;
      const changeHeader = `${changeDate}: ${headerAttribution}`;
      if (last?.header == changeHeader) {
        last.changes.push(change);
        return;
      }
      last = { header: changeHeader, changes: [change] };
      changesGrouped.push(last);
    });
    return [changesGrouped, customMealItemsDict, pushQuestionIdToMealItem];
  }, [JSON.stringify(changeLogs.changes)]);

  return (
    <table className="table-black-border" style={{ width: '100%' }}>
      {changesGrouped.map(gc => {
        const renderedChanges = gc.changes.map(renderChange).filter(x => !!x);
        if (!renderedChanges.length) {
          return false;
        }

        return [
          <tr key={`header-${gc.header}`}>
            <td colSpan={3}>
              <strong>{gc.header}</strong>
            </td>
          </tr>,
          ...renderedChanges,
        ];
      })}
    </table>
  );
};

// This function adds food alias and custom item flags to meal item names
const getMealItemNameForDisplay = (
  customMealItemsDict: CustomItemFoodToFirstLog,
  changeId: number,
  changeFields?: { [key: string]: any },
) => {
  if (!changeFields || !changeFields.food_name) {
    return null;
  }

  const { food_name, food_name_alias } = changeFields;
  if (food_name == 'awaiting verification...') {
    return food_name;
  }

  const isCustomItem = food_name in customMealItemsDict
    && (!customMealItemsDict[food_name] || customMealItemsDict[food_name]! > changeId);

  if (!food_name_alias && !isCustomItem) {
    return food_name;
  }

  const flags = [
    isCustomItem && { short: 'C', name: 'Custom item', color: 'orange' },
  ].filter(Boolean) as { short: string, name: string, color: string }[];

  return (
    <>
      {food_name_alias
        ? (
          <>
            <span>{food_name_alias}</span> <span style={{ color: 'grey' }}>({food_name})</span>
          </>
        )
        : food_name}
      {flags.map(flag => (
        <span
          key={flag.short}
          style={{
            color: flag.color,
            marginLeft: 2,
            cursor: 'default',
          }}
          title={flag.name}
        >
          {flag.short}
        </span>
      ))}
    </>
  );
};
