import moment from 'moment';

import * as Sentry from '@sentry/react';
import { dataReviewApi } from 'api';
import {
  CcColumnsRequest as _CcColumnsRequest,
  MealItemCustomAddonResponse,
  MealItemResponse,
  MealPhotoQueueQualityAssuranceResponse,
  MealPhotoQueueResponse,
  NutrientOverrideCollection,
  PreparationMethodEnum,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import { QualityAssuranceLogResponse } from 'api/generated/MNT';
import { config } from 'config';
import { logTrackedError } from 'errorTracking';

export type QualityAssuranceMealItemNutrientFlag = {
  nutrient: string,
  value: number,
  meal_item_id: number,
  meal_id: number,
};
export type QualityAssuranceFlag = {
  description: string,
  meal_item_id: number,
  meal_id: number,
};

export type QualityAssuranceFlags = {
  nutrient_flags: QualityAssuranceMealItemNutrientFlag[],
  miscellaneous_flags: QualityAssuranceFlag[],
};

export type MealQueueItem = MealPhotoQueueResponse;

export type MealItem = {
  id?: number,
  addons: string[],
  cc_columns?: CcColumnsRequest,
  custom_item?: boolean | null,
  custom_usda_id?: number | null,
  food_name: string,
  food_name_alias?: string | null,
  food_replacement_name?: string | null,
  meal_photo_id?: number | null,
  note?: string | null,
  custom_nutrient_estimates?: Partial<NutrientEstimatesRequest>,
  nutrient_overrides?: Partial<NutrientOverrideCollection>,
  nutrition_source?: string | null,
  percent_eaten?: number,
  preparation_method?: PreparationMethodEnum,
  serving_type_label?: string | null,
  serving_type_multiplier?: number | null,
  serving_unit_amount: number,
  serving_unit_label: string,
  servings: number,
  custom_addons?: MealItemCustomAddonResponse[] | null,
  custom_tip?: string | null,

  carbohydrate_g?: number | null,
  netcarb_g?: number | null,
  energy_kcal?: number | null,
  protein_g?: number | null,
  fat_g?: number | null,
  fiber_g?: number | null,
  polyols_g?: number | null,

  last_updated_by_user_id?: number | null,
  patient_id?: number | null,

  custom_item_source?: string | null,
  custom_item_source_id?: string | null,

  food_ontology?: string[] | null,
};

export type CcColumnsRequest = _CcColumnsRequest;

export type NutrientEstimatesRequest = UsdaNutritionResponse;

export type RecentMealItemResponse = {
  results: MealItemResponse[],
};

export type QALog = QualityAssuranceLogResponse;

export const getMealQueueItem = async (item_id: number): Promise<MealQueueItem> => {
  const mealQueueItem = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueItem({ meal_photo_queue_id: item_id });
  return mealQueueItem.data;
};

export const getMealQueueItemQaLogs = async (
  data_reviewer_id: number,
  item_id: number,
): Promise<QualityAssuranceLogResponse[]> => {
  const mealQaLogs = await dataReviewApi.appApiQueueReviewGetQualityAssuranceLogs({
    data_reviewer_id,
    meal_photo_queue_id: item_id,
  });

  return mealQaLogs.data;
};

export const getRecents = async (
  reviewer_id: number,
  meal_id: number,
  access_token: string,
): Promise<MealItemResponse[]> => {
  const getMpqUrl = `${config.API_URL}/api/data_reviewer/${reviewer_id}/meal_photo_queue/${meal_id}/recent_items`;

  const response = await fetch(getMpqUrl, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
    },
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}`);
  }

  const items = await response.json();
  if (items.results.length == 0) {
    throw new Error('Empty history list, stop processing');
  }
  return items.results;
};

/** @deprecated */
export const submitMPQ = async (group_id: number, reviewer_id: number, queue_id: number, meal_items: MealItem[]) => {
  const submitMpqResponse = await dataReviewApi.appApiMealPostMealItemsFromDataReviewerGroupQueue({
    data_reviewer_group_id: group_id,
    data_reviewer_id: reviewer_id,
    meal_photo_queue_id: queue_id,
    CreateMealItemFromQueueRequest: meal_items.map(mi => ({
      ...mi,
      extra_addons: [],
    })),
  });

  return submitMpqResponse.data;
};

// Note: this is a hack used by the post-queue questionnaire to track whether the user has
// requested help.
export const onSendHelpCallback = {
  callback: null as null | ((helpMessage: string) => void),
};

export const sendHelpRequest = async (
  group_id: number,
  reviewer_id: number,
  queue_id: number,
  access_token: string,
  helpMessage: string,
) => {
  onSendHelpCallback.callback?.(helpMessage);
  const url =
    `${config.API_URL}/api/data_reviewer_group/${group_id}/data_reviewer/${reviewer_id}/meal_photo_queue/${queue_id}/help`;
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ message: helpMessage }),
  });
  console.log(response);

  if (response.status !== 200) {
    logTrackedError({
      sourceName: 'sendHelpRequest',
      origin: response,
      stackError: new Error(),
      context: {
        reviewer_id,
        queue_id,
        helpMessage,
      },
      userMessage: 'Error submitting help request. Please use alternative means to obtain assistance.',
    });
    return false;
  }

  return true;
};

export const sendConfidenceScores = async (
  group_id: number,
  reviewer_id: number,
  queue_id: number,
  confidence_identification: number,
  confidence_sizing: number,
  data_reviewer_note: string | null,
) => {
  const confResp = await dataReviewApi.appApiDataReviewerPostDataReviewerConfidence({
    data_reviewer_group_id: group_id,
    data_reviewer_id: reviewer_id,
    meal_photo_queue_id: queue_id,
    CreateQueueFeedbackRequest: {
      confidence_identification,
      confidence_sizing,
      data_reviewer_note,
    },
  });

  return true;
};

export const getQueuesForQa = async (access_token: string, params: {
  data_reviewer_id: number,
  date_oldest?: string,
  date_newest?: string,
  is_reviewed?: string,
  is_approved?: string,
  is_escalated?: string,
  is_auto_logged?: string,
  is_priority?: string,
  is_initial?: string,
  has_custom_items?: string,
  patient_id?: number,
  limit?: number,
  is_from_recent?: string,
}): Promise<MealPhotoQueueQualityAssuranceResponse[]> => {
  const q = new URLSearchParams();
  Object.entries(params).forEach(([name, value]) => {
    if (name === 'data_reviewer_id') {
      return;
    }
    if (value === null || value === undefined || value === '') {
      return;
    }
    q.append(name, value.toString());
  });
  const url = `${config.API_URL}/api/data_reviewer/${params.data_reviewer_id}/quality_assurance?${q.toString()}`;

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}: ${await response.text()}`);
  }

  return response.json();
};

export const getFeedback = async (access_token: string, params: {
  data_reviewer_id: number,
  perPage: number,
  highestId: number,
}): Promise<QALog[]> => {
  const q = new URLSearchParams();
  q.append('per_page', params.perPage.toString());

  if (Number.isFinite(params.highestId)) {
    q.append('cursor', params.highestId.toString());
  }

  const url = `${config.API_URL}/api/data_reviewer/${params.data_reviewer_id}/unacknowledged_logs?${q.toString()}`;

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}: ${await response.text()}`);
  }

  return response.json();
};

export const acknowledgeFeedback = async (access_token: string, params: {
  data_reviewer_id: number,
  item_id: number,
}): Promise<QALog[]> => {
  const url = `${config.API_URL}/api/data_reviewer/${params.data_reviewer_id}/logs/${params.item_id}/acknowledge`;

  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`${response.status}: ${await response.text()}`);
  }

  return response.json();
};

export const getQueueOriginalPhoto = async (dataReviewGroupId: number, mealItemId: number) => {
  const photoResponse = await dataReviewApi.appApiDataReviewerGetMealPhotoQueueOriginalPhoto({
    data_reviewer_group_id: dataReviewGroupId,
    meal_photo_queue_id: mealItemId,
  }, {
    responseType: 'blob',
  });
  return photoResponse;
};
